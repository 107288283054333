import React from "react";
import { Card, Typography } from "antd";
const { Text } = Typography;
export default ({ uniqueURL }) => {
  return (
    <>
      <Card>
        <Text>
          we have sent you a link on your registered email. Please open the link
          in your smart phone to complete KYC. If you haven't recieved the link
          you can type in the below given URL directly in your browser to
          complete KYC.
        </Text>
        <br />
        <br />
        <a href={uniqueURL} rel="noopener noreferrer" target="_blank">
          {uniqueURL}
        </a>
        <br />
        <br />
        <Text>
          <b>
            Once you complete KYC please refresh the page to continue your
            investment process.
          </b>
        </Text>
      </Card>
    </>
  );
};
