import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification, Modal, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { AdminService } from "../../IssuerSuperAdmin/Admin/Admin.service";
import { SharedService } from "../Shared.service";
import { User } from "../interfaces";
import { IssuerSuperAdminService } from "../../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { AuthService } from "../Auth.service";
import { BankService } from "./BankInfoRequests.service";
import moment from "moment";


const { Title } = Typography;
const { TabPane } = Tabs;

const authService = new AuthService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => authService.useUserContext();
const bankService = new BankService()

const BankInfoRequests = () => {
    const { userInfo } = useUserContext();
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [BankInfoDataSource, setBankInfoDataSource] = useState<any>([]);
    const [BankInfoDocs, setBankInfoDocs] = useState<any>([]);
    const [rejectReason, setRejectReason] = useState("");
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [selectedBankRecord, setSelectedBankRecord] = useState<any>();
    const [repaint, setRepaint] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const statusByRoles = {
        issuercomplianceadmin: 'llpCA',
        mastercompanycomplianceadmin: 'mca',
        platformissueradmin:'psa'
    }

    useEffect(() => {
        fetchBankInfo(pagination.current, pagination.pageSize);
    }, [repaint, pagination.current, pagination.pageSize]);

    const fetchBankInfo = async (page, pageSize) => {
        setLoading(true);
        try {
            let bankInfo;
            if (userInfo?.role === "master company compliance admin" || userInfo?.role === "platform issuer admin") {
                bankInfo = await bankService.getBankInfoForMCAOrPSA(page, pageSize);
                if (bankInfo.success) {
                    const filteredBankData = userInfo?.role === "master company compliance admin"
                        ? bankInfo.data.allBankDetails.filter(data => ['pending', 'approved', 'rejected'].includes(data.mca) && data.llpCA === 'approved')
                        : bankInfo.data.allBankDetails.filter(data => ['pending', 'approved', 'rejected'].includes(data.psa) && data.mca === 'approved');
                    setBankInfoDataSource(filteredBankData.sort((x, y) => y.creationTS - x.creationTS));
                   setTotalItems (bankInfo.data?.totalCount || 0);
                }
            } else {
                bankInfo = await bankService.getBankInfoByCompanyId(userInfo?.company?.id, page, pageSize);
                if (bankInfo.success) {
                    setBankInfoDataSource([bankInfo.data]);
                   setTotalItems (bankInfo.data?.totalCount || 0);
                }
            }
        } catch (error) {
            console.error('Failed to fetch bank info:', error);
        }
        setLoading(false);
    };

    const columns = [
        {
            title: "Account Holder",
            dataIndex: "accountHolder",
            key: "accountHolder",
        },
        {
            title: "Bank Name",
            dataIndex: "bankName",
            key: "bankName",
        },
        {
            title: "Branch Name",
            dataIndex: "branchName",
            key: "branchName",
        },
        {
            title: "Account Number",
            dataIndex: "accountNo",
            key: "accountNo",
        },
        {
            title: "Status",
            dataIndex: userInfo && statusByRoles[userInfo?.role.replaceAll(' ','')],
            key: "status",
        },
        {
            title: "Create Date",
            dataIndex: "creationTS",
            key: "creationTS",
            render: (d) => moment(d).format("LLL"),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record, index) => {
                return (
                    <>
                        <Button type="link" onClick={() => bankInfoDetails(record?._id)}>Details</Button>
                    </>
                );
            },
        },
    ];

    const bankInfoDetails = (id: number) => {
        var selectedRecord = BankInfoDataSource.find(x => x._id === id);
        if (selectedRecord && selectedRecord.escrowDocs) {
          setBankInfoDocs(selectedRecord.escrowDocs);
          setSelectedBankRecord(selectedRecord)
          setShowDetails(true);
        } else {
          console.error("Escrow docs not available for the selected record");
        }
    
    }

    const approveOrReject = async(status) =>{
        try {
          // console.log(data)
          
          
          let payload = {status,rejectionMessage:''}
          if(status == 'rejected'){

            if(rejectReason == ''){
                notification.info({message: 'Info', description: 'Provide valid rejection message'});
                return;
              }

            payload.rejectionMessage = rejectReason;
            setShowRejectPopup(false)
          }
          console.log(payload)
          const role = userInfo?.role.replaceAll(' ','');
          
          let companyId = userInfo?.company?.id;
          //incase of these below roles use the companyId of the previous api 
          if(userInfo?.role == 'master company compliance admin' || userInfo?.role == 'platform issuer admin'){
            companyId = selectedBankRecord?.companyId;
          }

          const res = await bankService.approveOrRejectBankInfo(companyId,payload,role)
          console.log(res)
          if(res.success){
            notification.success({message: 'Success', description: `Bank Info ${status} successfully`});
            setRepaint(prev => !prev)
            setShowDetails(false)
          }else{
            notification.error({message: 'Error', description: res.error.message});
          }

        } catch (error) {
          console.log(error);
          notification.error({message:'error', description:error})
        }
      }

    return (
        <>
            <br />
            <br />
            <Row justify="center">
                <Col span={23}>
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    )}
                    {!loading && !showDetails && (
                        <Card>
                            <Title level={1} style={{ textAlign: "center" }}>
                                Bank Information Request
                            </Title>
                            <Row>
                                <Col
                                    span={3}
                                    offset={21}
                                    style={{ display: "flex", justifyContent: "end" }}
                                ></Col>
                            </Row>
                            <Table dataSource={BankInfoDataSource} columns={columns}
                           pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: totalItems,
                            onChange: (current, pageSize) => {
                                if (typeof current === 'number' && typeof pageSize === 'number') {
                                    setPagination({ ...pagination, current, pageSize });
                                    fetchBankInfo(current, pageSize);
                                    }
                                },
                            }}
                            loading={loading}
                                                        />
                        </Card>
                    )}

                    {showDetails && <Card title="Bank Information">

                        <div className="document-details">
                            <h4>Escrow Docs</h4>
                            {BankInfoDocs?.map((doc, i) => {
                                return <>
                                    <div key={i} style={{ textAlign: 'left', cursor: 'pointer',marginBottom:'10px' }}><a href={doc.url} target='_blank'>{doc.name}</a><br /></div>
                                </>

                            })}
                        </div>

                        <div className="controls" style={{display:'flex', justifyContent:'space-between',marginTop:'50px'}}>
                            <Button htmlType='submit' size='large' type='primary' onClick={()=> setShowDetails(false)} disabled={false} loading={loading}>
                                Back
                            </Button>
                            
                            { userInfo && selectedBankRecord[statusByRoles[userInfo?.role.replaceAll(' ','')]] === 'pending' &&<div className="controls-action">
                                <Button htmlType='submit' style={{marginRight:'10px'}} onClick={()=>approveOrReject('approved')} size='large' type='primary' disabled={false} loading={loading}>
                                    Approve
                                </Button>
                                <Button htmlType='submit' size='large' type='primary' onClick={()=>{setShowRejectPopup(true);}} disabled={false} loading={loading}>
                                    Reject
                                </Button>
                            </div>}
                        </div>
                    </Card>}
                </Col>
            </Row>


            <Modal
                title="Reject Bank Info"
                visible={showRejectPopup}
                onCancel={() => setShowRejectPopup(false)}
                footer={[
                    <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
                        Submit
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default BankInfoRequests;