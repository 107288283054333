import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Steps, Descriptions, Modal, Radio} from 'antd';
import { CheckOutlined, LineOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import AddBasicInfo from './AddBasicInfo.js';
import AdditionalDetails from './AdditionalDetails.js';
import AddDocuments from './AddDocuments';
import OfferingInformation from './OfferingInformation.js';
import RERA from './RERA';
const {Title} = Typography;
const { Step } = Steps;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const configTypeToCurrentStep = {
  'basic-information': 1,
  'Offering-information': 2,
  'additional-details': 3,
  'documents': 4
};
const currentStepToConfigType = Object.fromEntries(
  Object.entries(configTypeToCurrentStep).map((entry) => entry.reverse())
);

const useUserContext = () => new AuthService().useUserContext();

export default function AddProperty(props:any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { userInfo } = useUserContext();
  const [stepNo, setStepNo] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState();
  const [basicInfoDone, setBasicInfoDone] = useState(false);
  const [offeringDisabled, setOfferingDisabled] = useState(true);
  const [offeringDone, setOfferingDone] = useState(false);
  const [additionalDisabled, setAdditionalDisabled] = useState(true);
  const [additionalDetailsDone, setAdditionalDetailsDone] = useState(false);
  const [documentsDisabled, setDocumentsDisabled] = useState(true);
  const [reraDisabled, setReraDisabled] = useState(true);
  const [documentsDone, setDocumentsDone] = useState(false);
  const [reraDone, setReraDone] = useState(false);
  const [numberOfTeamMembers, setNumberOfTeamMembers] = useState(0);
  const [isMSA, setIsMSA] = useState(userInfo?.roles.includes('master company super admin'));
  const [isICA, setIsICA] = useState(userInfo?.role === 'issuer compliance admin');
  const [visible, setVisible] = useState(false);
  const [noOptionChoosedByUser, setNooptionChoosedByUser] = useState(true);
  const [propertyType, setPropertyType] = useState('residential');
  const [commercialOption, setCommercialOption] = useState('');
  const [hidePropertyTypeBtn, setHidePropertyTypeBtn] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      console.log('call from ',' id ',)
      let id = userInfo?.company?.id
      if(isMSA || isICA){
        // id = sessionStorage.getItem('seriesManagerId') ?? ''
         id = userInfo?.company?.id 
      }
      let res = await issuerSuperAdminService.getPropertyDetails(id);
      if(res.data.length){
        setPropertyInfo(res.data[0]);
        if(res.data[0]){
          if(isNaN(res.data[0].stepDone)){
            setStepNo(1);
          }else{
            if(sessionStorage.getItem('savedPageNo') === '1') {
              setTimeout(() => sessionStorage.removeItem('savedPageNo'), 1000);
            }else {
              setStepNo(res.data[0].stepDone + 1);
            }
          }
          if(res.data[0].basicInfo=='done'){
            setBasicInfoDone(true);
            setOfferingDisabled(false);
          }
          if(res.data[0].offering=='done'){
            setOfferingDone(true);
            setAdditionalDisabled(false);
          }
          if(res.data[0].additionalDetails=='done'){
            setAdditionalDetailsDone(true);
            setDocumentsDisabled(false);
          }
          if(res.data[0].propertyDocuments=='done'){
            setDocumentsDone(true);
            setReraDisabled(false)
          }
          if(res.data[0].reraInfo=='done'){
            setReraDone(true);
          }
          // if(Array.isArray(res.data[0].teamMembers)){
          //   setNumberOfTeamMembers(res.data[0].teamMembers.length);
          //   if(res.data[0].teamMembers.length < 2){
          //     setOfferingDisabled(true);
          //     setAdditionalDisabled(true);
          //     setDocumentsDisabled(true);
          //   }
          // }
          setPropertyType(res.data[0].propertyType);
          setCommercialOption(res.data[0].commercialOption);
          localStorage.removeItem('propertyType');
          localStorage.removeItem('commercialOption');
        }
      }else{
        setVisible(true);
      }
      setLoaded(true);
      setLoading(false);
      setUpdated(false);
      if(isMSA){setStepNo(1)}
    })();
  }, [updated]);

  useEffect(() => {
    let redirectConfigType: string | null = null;

    if (stepNo == 1) {
       redirectConfigType = 'basic-information';
    } else if (stepNo === 2) {
      redirectConfigType = 'offering-information';
    } else if (stepNo === 3) {
      redirectConfigType = 'additional-details';
    } else if (stepNo === 4) {
      redirectConfigType = 'documents';
    }else {
      redirectConfigType = 'rera';
      setHidePropertyTypeBtn(true);
    }
    if (!redirectConfigType) return;
    history.push(
      `/issuer-super-admin/add-property/${redirectConfigType}`
    );
  }, [stepNo]);

  useEffect(()=>{
    try {
      let propertytype = localStorage.getItem('propertyType');
      let commercialOption = localStorage.getItem('commercialOption');
      if(propertytype){
        setPropertyType(propertytype);
        setNooptionChoosedByUser(false); // if value already choosen by user then no popup
      }
      if(commercialOption)
        setCommercialOption(commercialOption);

    } catch (error) {
      throw new Error('something went wrong'+ error);
    }

  },[]);

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
    setCommercialOption('');
    if(e.target.value === 'commercial')
      setCommercialOption('sale');
  }

  const handleCommercialOptionChange = (e) => {
    setCommercialOption(e.target.value);
  }

  const handleOk = () => {
    setVisible(false);
    console.log(propertyType)
    console.log(commercialOption)
    try {
      localStorage.setItem('propertyType',propertyType);
      localStorage.setItem('commercialOption',commercialOption);
    } catch (error) {
      throw new Error('local storage unavailable '+ error);
    }
  }
  const handleCancel = () => {
    setVisible(false);
  }


  return (
    <>
    {loading && 
      <div style={{textAlign:'center'}}>
        <br/>
        <Spin size='large'/>
      </div>}
      <br/>
      {!loading && loaded && (
        <Card>
          <Row justify="center" style={{ marginBottom: '50px' }}>
            <Col span={24}>
            {(userInfo?.role === 'issuer super admin' || userInfo?.role === 'issuer compliance admin') && (
              <Title level={2} style={{ color: '#186AB4', textAlign: 'center' }}>
                Property Type : {propertyType?.toUpperCase()}
              </Title>
            )}
              {userInfo?.role === 'issuer company manager' && (
              <Title level={2} style={{ color: '#186AB4', textAlign: 'center' }}>
                Add Property Page
              </Title>
              )
              }

                <div className="bttn d-flex justify-content-center w-100 mb-1">
                {hidePropertyTypeBtn && userInfo?.role === 'issuer company manager' && (
                  <div>Property Type : {propertyType?.toUpperCase()}</div>
                )}
                  {!hidePropertyTypeBtn && userInfo?.role === 'issuer company manager' && <Button type="primary" icon={<PlusOutlined />}
                  onClick={()=> {setVisible(true); setNooptionChoosedByUser(true);}}
                  >
                    Select Property Type
                  </Button>}
                </div>
              
              <Steps
                type="navigation"
                current={stepNo-1}
              >
                <Step
                  status={
                    stepNo>1 && numberOfTeamMembers>=2 ? 'finish' : undefined
                  }
                  title="Basic Information"
                  onClick={()=>{
                    setStepNo(1);
                    history.push(
                      '/issuer-super-admin/add-property/basic-information'
                    )}}
                    style={{cursor:'pointer'}}
                ></Step>
                <Step
                  disabled={offeringDisabled}
                  status={
                    stepNo>2
                      ? 'finish'
                      : undefined
                  }
                  title="Offering Information"
                  onClick={()=>{
                    setStepNo(2);
                    history.push(
                      '/issuer-super-admin/add-property/offering-information'
                    )}}
                    style={{cursor:`${!offeringDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={additionalDisabled}
                  status={
                    stepNo>3
                      ? 'finish'
                      : undefined
                  }
                  title="Additional Details"
                  onClick={()=>{
                    setStepNo(3);
                    history.push(
                      '/issuer-super-admin/add-property/additional-details'
                    )}}
                    style={{cursor:`${!additionalDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={documentsDisabled}
                  status={
                    stepNo>4
                      ? 'finish'
                      : undefined
                  }
                  onClick={()=>{
                    setStepNo(4);
                    history.push(
                      '/issuer-super-admin/add-property/documents'
                    )}}
                    style={{cursor:`${!documentsDisabled?'pointer':'not-allowed'}`}}
                  title="Documents"
                ></Step>

                <Step
                  disabled={reraDisabled}
                  status={
                    stepNo>=4
                      ? 'finish'
                      : undefined
                  }
                  onClick={()=>{
                    setStepNo(5);
                    history.push(
                      '/issuer-super-admin/add-property/rera'
                    )}}
                    style={{cursor:`${!reraDisabled?'pointer':'not-allowed'}`}}
                  title="RERA"
                ></Step>
              </Steps>
              {stepNo == 1 && loaded && (
                <AddBasicInfo
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  basicInfoDone={basicInfoDone}
                  propertyType={propertyType}
                  commercialOption={commercialOption}
                />
              )}
              {stepNo == 2 && loaded && (
                <OfferingInformation
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  offeringDone={offeringDone}
                  propertyType={propertyType}
                  commercialOption={commercialOption}
                />
              )}
              {stepNo == 3 && loaded && (
                <AdditionalDetails
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  additionalDetailsDone={additionalDetailsDone}
                  propertyType={propertyType}
                  commercialOption={commercialOption}
                />
              )}
              {stepNo == 4 && loaded && (
                <AddDocuments 
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  documentsDone={documentsDone}
                  propertyType={propertyType}
                  commercialOption={commercialOption}
                />
              )}
              {stepNo >= 5 && loaded && (
                <RERA 
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  documentsDone={reraDone}
                  propertyType={propertyType}
                  commercialOption={commercialOption}
                />
              )}

      <Modal
        title="Select Type of Property"
        visible={visible && noOptionChoosedByUser}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        closable={false}
        mask={true}
        maskClosable={false}
      >
        <Radio.Group onChange={handlePropertyTypeChange} value={propertyType}>
          <Radio value="residential">Residential Flat</Radio><br />
          <Radio value="commercial">Single Commercial Unit (Shop, Office, Retail, Other)</Radio><br />
          <Radio value="project">Project (Residential & Commercial)</Radio>
        </Radio.Group>

        {propertyType === 'commercial' && (
          <div style={{ marginTop: '1rem' }}>
            <label>Select Commercial Option:</label>
            <select value={commercialOption} onChange={handleCommercialOptionChange} style={{ width: '100%' }}>
              <option value="sale">On Possession Available for Sale</option>
              <option value="rent">On Possession Available for Rent</option>
            </select>
          </div>
        )}
      </Modal>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}