import React, { useState, useEffect } from 'react';
import { Tabs, Card, Row, Col, Typography, message, Button } from 'antd';
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';
import { IssuerBasicInfoVerificationRequest } from '../../Shared/interfaces';
import './IssuerBasicInfoVerifRequests.scss';
import ReqTable from './ReqTable';
import Details from './Details';

const { TabPane } = Tabs;
const {Title} = Typography;


const platformIssuerAdminService = new PlatformIssuerAdminService();



export default () => {
  const [pendingFilter, setPendingFilter] = useState({ size: 10, page: 1 });
  const [approvedRejectedFilter, setApprovedRejectedFilter] = useState({ size: 10, page: 1 });

  const [pendingReqs, setPendingReqs] = useState<IssuerBasicInfoVerificationRequest[] | null>(null);
  const [approvedRejectedReqs, setApprovedRejectedReqs] = useState<IssuerBasicInfoVerificationRequest[] | null>(null);

  const [selectedIssuerBasicInfoVerifReqs, setSelectedIssuerBasicInfoVerifReqs] = useState<IssuerBasicInfoVerificationRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    const fetchIssuerBasicInfoVerifReqs = async () => {
      setLoading(true);
      const filter = activeTab === '1' ? pendingFilter : approvedRejectedFilter;
      const statuses = activeTab === '1' ? ['pending'] : ['verified', 'rejected'];

      const response = await platformIssuerAdminService.getIssuerBasicInfoVerifReqs(
        statuses.toString(),
        filter.size,
        filter.page
      );

      const reqs: IssuerBasicInfoVerificationRequest[] = response?.data?.allRequest || [];
      if (activeTab === '1') {
        setPendingReqs(reqs);
      } else {
        setApprovedRejectedReqs(reqs);
      }
      setTotalItems(response?.data?.totalCount || 0);
      setLoading(false);
    };

    fetchIssuerBasicInfoVerifReqs();
  }, [currentPage, pendingFilter.size, approvedRejectedFilter.size, activeTab]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (activeTab === '1') {
      setPendingFilter(prev => ({ ...prev, page: page }));
    } else {
      setApprovedRejectedFilter(prev => ({ ...prev, page: page }));
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setCurrentPage(1);
  };


  const reviewBasicInfoVerifReq = async (status: 'verified' | 'rejected', comments: string) => {
    setSelectedIssuerBasicInfoVerifReqs(prev => prev ? ({ ...prev, [status === 'rejected' ? 'rejecting' : 'verifying']: true }) : null as any);
    const response = await platformIssuerAdminService.reviewBasicInfoVerifReq({
      requestId: selectedIssuerBasicInfoVerifReqs?._id as string,
      companyId: selectedIssuerBasicInfoVerifReqs?.companyId as string,
      status,
      comments,
    });

    if (response.success) {
      if (activeTab === '1') {
        setPendingReqs(prev => (prev ? prev.filter(req => req._id !== selectedIssuerBasicInfoVerifReqs?._id) : null));
      } else {
        setApprovedRejectedReqs(prev => (prev ? prev.filter(req => req._id !== selectedIssuerBasicInfoVerifReqs?._id) : null));
      }
      hideDetails();
      message.success(status === 'verified' ? 'Request verified successfully' : 'Request rejected successfully');
    } else {
      message.error(response.error.message || response.error);
      setSelectedIssuerBasicInfoVerifReqs(prev => prev ? ({ ...prev, [status === 'rejected' ? 'rejecting' : 'verifying']: false }) : null as any);
    }
  };

  const showDetails = async(issuerBasicInfoVerifReq: IssuerBasicInfoVerificationRequest) => {
    setSelectedIssuerBasicInfoVerifReqs({...issuerBasicInfoVerifReq});    
  }

  const hideDetails = () => {
    setSelectedIssuerBasicInfoVerifReqs(null);
  }


  return (
    <>
      <br/>
      <Row justify="center">
        <Col span={22} style={{textAlign:'center'}}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
            >
            Issuer-LLP Basic Info Verification Requests
          </Title>

          <Tabs defaultActiveKey='1' style={{textAlign:'center'}} onChange={handleTabChange}>
          <TabPane
              tab={
                <span>
                  <PlaySquareOutlined/>
                  Pending Issuer-LLP Basic Info Verification Requests
                </span>
              }
              key='1'
            >
              <ReqTable
                  data={pendingReqs}
                  loading={loading}
                  statuses={['pending']}
                  showDetails={showDetails}
                  pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize: pendingFilter.size,
                    onChange: handlePageChange,
                    pageSizeOptions: [],
                    showSizeChanger: false,
                  }}
                />
            </TabPane>

            <TabPane
              tab={
                <span>
                  <ProjectOutlined/>
                  Approved/Rejected Issuer-LLP Basic Info Verification Requests
                </span>
              }
              key='2'
            >
                <ReqTable
                  data={approvedRejectedReqs}
                  loading={loading}
                  statuses={['verified', 'rejected']}
                  showDetails={showDetails}
                  pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize: approvedRejectedFilter.size,
                    onChange: handlePageChange,
                    pageSizeOptions: [],
                    showSizeChanger: false,
                  }}
                />

            </TabPane>

          </Tabs>
          </Card>

          <Details 
            record={selectedIssuerBasicInfoVerifReqs} 
            visible={!!selectedIssuerBasicInfoVerifReqs}
            reviewBasicInfoVerifReq={reviewBasicInfoVerifReq}
            onCancel={hideDetails}/>
        </Col>
      </Row>
    </>
  );
}