import { environment } from "../../../environments/environment";
import axios from 'axios';
import { SharedService } from "../Shared.service";
import { APIResponse } from "../interfaces";

const sharedService = new SharedService();

export class BankService {
    private APIURL = environment.APIURL;

     routes = {
        issuercomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByLLPCAByCompanyId',
        mastercompanycomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByMCAByCompanyId',
        platformissueradmin:'issuerSuperAdmin/approveOrRejectBankInfoByPSAByCompanyId'
    }

    async approveOrRejectBankInfo(companyId,payload,role) {
        return axios.post<any, APIResponse>(
          `${this.APIURL}/${this.routes[role]}?companyId=${companyId}`,
          payload,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getBankInfoByCompanyId(companyId,page:number=1,size:number = 10) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getBankInfo?companyId=${companyId}`,
          { params:{page,size},headers: await sharedService.getAuthHeader()});
      }

      async getBankInfoForMCAOrPSA(page:number,size:number) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getBankInfoForMCAOrPSA`,
          {params:{page,size},headers: await sharedService.getAuthHeader()}
        );
      }
}