import React, { useState, useEffect } from "react";
import {
  Result,
  Button,
  Card,
  Col,
  Row,
  Typography,
  Descriptions,
  Divider,
  Carousel,
} from "antd";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { InvestorService } from "../Investor.service";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../Shared/Auth.service";
import moment from "moment";
import Process from "../Process";
import KYCUniqueURLCard from "../KYCUniqueURLCard";

const { Title } = Typography;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

export default () => {
  const history = useHistory();
  const { userInfo, setUserInfo } = useUserContext();
  const [currentStatus, setCurrentStatus] = useState(null);
  const [investorDetails, setInvestorDetails] = useState(null);
  const [previousState, setPreviousState] = useState(null);

  const [KYCNotSubmitted, setKYCNotSubmitted] = useState(false);
  const [KYCPendingForApproval, setKYCPendingForApproval] = useState(false);
  const [KYCApproved, setKYCApproved] = useState(false);
  const [KYCRejected, setKYCRejected] = useState(false);
  const [KYCLink, setKYCLink] = useState(null);

 

  useEffect(() => {
    (async () => {
      const response = await investorService.getKYCDetails({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setCurrentStatus("notSubmitted");
        } else {
          setInvestorDetails(response.data[0]);
          setCurrentStatus("submitted");
          if (response.data[0].approvedByICA) {
            setCurrentStatus("approved");
          }
          if (response.data[0].rejected) {
            setCurrentStatus("rejected");
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      // setLoading2(true);
      if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'approved'){
        setKYCApproved(true);
      }else if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'rejected'){
        setKYCRejected(true);
      }else if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'pending'){
        setKYCPendingForApproval(true);
      } else if(userInfo.status.ndaSigned == true) {
        console.log('generating link for kyc');
        await generateLinkForKYC();
        setKYCNotSubmitted(true);
      }
      // setLoading(false);
      // setLoading2(false);
    } )();
  },[]);

  const generateLinkForKYC = async (company) => {
    let dataToSend = {
      _id: userInfo._id,
      email: userInfo.email,
      role: userInfo.role,
      company,
    };
    const response = await investorService.generateLinkForKYC(dataToSend);
    if (response.success && response.data) {
      setKYCLink(response.data);
    }
  };

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Process />
            <Divider />
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              KYC Status
            </Title>
            {currentStatus === "approved" && (
              <Result
                status="success"
                title="Successfully verified!"
                subTitle="your KYC details are verified successfully."
                extra={[
                  // <Button
                  //   type="primary"
                  //   onClick={() => history.push("/investor/home")}
                  // >
                  //   Home
                  // </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentStatus("viewDetails");
                      setPreviousState("approved");
                    }}
                  >
                    Details
                  </Button>,
                ]}
              ></Result>)}

            {currentStatus === "rejected" && (
              <Result
                status="error"
                title="Rejected!"
                subTitle="Your KYC details are rejected"
                extra={[
                  // <Button
                  //   type="primary"
                  //   onClick={() => history.push("/investor/home")}
                  // >
                  //   Home
                  // </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentStatus("viewDetails");
                      setPreviousState("rejected");
                    }}
                  >
                    Details
                  </Button>,
                ]}
              ></Result>)}

            {currentStatus === "submitted" && (
              <Result
                title="Successfully Submitted!"
                subTitle="your KYC details are successfully submitted please wait for the result."
                extra={[
                  // <Button
                  //   type="primary"
                  //   onClick={() => history.push("/investor/home")}
                  // >
                  //   Home
                  // </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentStatus("viewDetails");
                      setPreviousState("submitted");
                    }}
                  >
                    Details
                  </Button>,
                ]}
              ></Result>)}
            {currentStatus === "notSubmitted" && (
              <Result
                status="warning"
                title="Not Submitted!"
                subTitle="you have not submitted your KYC details."
                extra={[
                  // <Button
                  //   type="primary"
                  //   onClick={() => history.push("/investor/home")}
                  // >
                  //   Home
                  // </Button>,
                ]}
              ></Result>)}

            <div hidden={currentStatus !== "viewDetails"}>
              <Carousel autoplay>
                <div>
                  <Card style={{ backgroundColor: "#ebeff5" }}>
                    <p style={{ fontSize: "25px" }}>Selfie</p>
                    <img
                      style={{
                        margin: "auto",
                        maxHeight: "500px",
                        height: "500px",
                        objectFit: "cover",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                      src={investorDetails?.selfie}
                    ></img>
                  </Card>
                </div>

                <div>
                  <Card style={{ backgroundColor: "#ebeff5" }}>
                    <p style={{ fontSize: "25px" }}>Document Front Side</p>
                    <img
                      height={300}
                      width={400}
                      style={{
                        margin: "auto",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                      src={investorDetails?.IDFront}
                    ></img>
                  </Card>
                </div>
                <div>
                  <Card style={{ backgroundColor: "#ebeff5" }}>
                    <p style={{ fontSize: "25px" }}>Document Back Side</p>
                    <img
                      height={300}
                      width={400}
                      style={{
                        margin: "auto",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                      src={investorDetails?.IDBack}
                    ></img>
                  </Card>
                </div>
              </Carousel>
              <Card
                style={{
                  margin: "auto",
                  width: 900,
                  boxShadow:
                    "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  PROFILE
                </p>
                <table style={{ margin: "auto", width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Name
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.firstName} {investorDetails?.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Present Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {
                        <div>
                          {investorDetails?.pAStreetAddress1}{" "}
                          {investorDetails?.pAStreetAddress2}{" "}
                          {investorDetails?.pACity} {investorDetails?.pAState}{" "}
                          {investorDetails?.pACountry}{" "}
                          {investorDetails?.pAZipCode}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Permanent Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {
                        <div>
                          {investorDetails?.pStreetAddress1}{" "}
                          {investorDetails?.pStreetAddress2}{" "}
                          {investorDetails?.pCity} {investorDetails?.pAState}{" "}
                          {investorDetails?.pACountry}{" "}
                          {investorDetails?.pZipCode}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Contact Number
                    </td>
                    {investorDetails?.contactNumber && (
                      <td style={{ textAlign: "left" }}>
                        {formatPhoneNumberIntl(
                          "+" + investorDetails?.contactNumber
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Email
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.email}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Last Stage run
                    </td>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>3</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Date of KYC Request
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {moment(investorDetails?.dateOfSubmission).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Customer since
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {moment(userInfo?.creationTS).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Priority
                    </td>
                    <td style={{ textAlign: "left" }}>Normal</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Progress
                    </td>
                    <td style={{ textAlign: "left" }}>New</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Name of fraud rule
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult.frn}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Result of fraud evaluation
                    </td>
                    {investorDetails?.fullResult.frp === "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "green" }}>
                        Accepted
                      </td>
                    )}
                    {investorDetails?.fullResult.frp !== "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "red" }}>
                        Rejected
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Result of policy evaluation
                    </td>
                    {investorDetails?.fullResult.res === "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "green" }}>
                        Accepted
                      </td>
                    )}
                    {investorDetails?.fullResult.res !== "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "red" }}>
                        Rejected
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      User reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.user}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      User previous reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.upr}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Reason for reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.erd}
                    </td>
                  </tr>
                </table>
              </Card>
              <br />
              <Button
                type="primary"
                onClick={() => setCurrentStatus(previousState)}
              >
                Back
              </Button>
              &nbsp;&nbsp;&nbsp;
              {/* <Button
                type="primary"
                onClick={() => history.push("/investor/home")}
              >
                Home
              </Button> */}
            </div>

            {!KYCPendingForApproval && KYCNotSubmitted && !userInfo?.company?.id && (
              <div >
                You need to first click on Invest Button of a property that you want to invest from dashboard page to start the KYC Process!
              </div>
            )}

            {!KYCPendingForApproval && KYCNotSubmitted && userInfo?.company?.id && (
              <div >
                {KYCLink && <KYCUniqueURLCard uniqueURL={KYCLink} />}
              </div>
            )}

          </Card>
        </Col>
      </Row>
    </>
  );
};