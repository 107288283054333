import React from "react";
import { Button, Col, Form, Image, Input, Row, message } from "antd";
import AgentsPhoto from "../../../assets/images/Agents/agentsPhoto.png";
import AgentsDesign from "../../../assets/images/Agents/agentsDesign.svg";
import { useHistory } from "react-router-dom";
import { AccountService } from "../../../components/Account/Account.service";

const { TextArea } = Input;
const accountService = new AccountService();

const InterestAgents = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const AgentsStyle = {
    container: {
      padding: "100px 20% 20px 20%",
    },
    heading: {
      color: "black",
    },
    subHeading: {
      fontWeight: "bold",
      color: "#F8921F",
    },
    button: {
      textAlign: "center",
      color: "white",
      borderRadius: "20px",
      backgroundColor: "#F8921F",
      width:"30%",
      height:"20%"
    },
  };
  const saveInviteData = async () => {
    try {
      const values = await form.getFieldsValue();
      console.info({ values });
      // setLoading(true);
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyName: values.companyName,
        comments: values.comments,
        type: "agents",
      };
      let response = await accountService.submitInterestToRegisterAsPCA(data);
      if (!response.success) {
        message.warning({ content: response.error.message, duration: 5, style:{marginTop:"30px"}});
        
      }else {
      message.success({
        content: "Successfully send interest to marketplace super admin.",
        duration: 5,
        style: {marginTop:"30px" },
      });
      history.push("/")
    }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  return (
    <div style={AgentsStyle.container}>
      <Row>
        <Col
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={AgentsStyle.heading}>
                Maximize your profits through Our Expert Selling Solutions.
              </h4>
              <h2 style={AgentsStyle.subHeading}>Get Started !</h2>
            </span>
            <span
              style={{
                position: "relative",
                right: "-50px",
                overflow: "hidden",
              }}
            >
              <img
                src={AgentsDesign}
                alt="AgentsDesign"
                width="150px"
                height="150px"
                loading="lazy"
                style={{
                  position: "relative",
                  top: "-20px",
                }}
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={12} lg={12}>
          <img src={AgentsPhoto} alt="AgentsPhoto" height="100%" loading="lazy"/>
        </Col>
        <Col sm={24} md={12} lg={12}>
          <Form form={form} onFinish={saveInviteData} layout="vertical">
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input your Company Name",
                },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
            <Form.Item label="Comments" name="comments">
              <TextArea placeholder="Comments" />
            </Form.Item>

            <Button type="primary" htmlType="submit" style={AgentsStyle.button}>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default InterestAgents;
