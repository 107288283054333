import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Radio,
  Select,
  Input,
  Button,
  message,
  Checkbox,
} from "antd";
import { useHistory } from "react-router-dom";
import { IssuerSuperAdminService } from "../../../IssuerSuperAdmin.service";
import { AuthService } from "../../../../Shared/Auth.service";
import { TeamDepartment, TeamRole } from "../../../../Shared/interfaces";

const { Title } = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();

const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const { userInfo } = useUserContext();
  const history = useHistory();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>();
  const [teamRoles, setTeamRoles] = useState<TeamRole[] | null>();

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      const response = await issuerSuperAdminService.getTeamDepartments();
      const _teamDepartments: TeamDepartment[] = response.data;
      setTeamDepartments(_teamDepartments);
    })();
  }, [userInfo]);

  const selectTeamDepartments = async (departmentId: string) => {
    setTeamRoles(null);
    const response = await issuerSuperAdminService.getTeamRoles(departmentId);
    const _teamRoles: TeamRole[] = response.data;
    setTeamRoles(_teamRoles);
  };

  const sendInviteToTeamMember = async (formValue) => {
    const req = { ...formValue };
    console.log(req);
    setSubmitting(true);
    const response = await issuerSuperAdminService.sendInviteToTeamMember(req);

    if (response.success) {
      message.success("Invite sent successfully");
      history.push(`/issuer-super-admin/admin/team-managment`);
    } else {
      message.error(response.error.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          <Card>
            <Title level={1} style={{ textAlign: "center" }}>
              New Employee/Member
            </Title>
            <Row justify="center">
              <Col span={20}>
                <Card bordered={false} className="card-shadow">
                  <Form
                    labelAlign="left"
                    initialValues={{ team: "employee" }}
                    form={form}
                    onFinish={sendInviteToTeamMember}
                  >
                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7, offset: 3 },
                      }}
                      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                      label="Select The team"
                      name="team"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={() => {
                          form.resetFields(["department", "teamRoleId"]);
                          setTeamRoles(null);
                        }}
                      >
                        <Radio key="employee" value="employee">
                          Partner
                        </Radio>
                        <Radio
                          key="contractor_consultant_advisor"
                          value="contractor_consultant_advisor"
                        >
                          Contractor/Consultant/Advisor
                        </Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.team !== currentValues.team
                      }
                    >
                      {({ getFieldValue }) => (
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 7, offset: 3 },
                          }}
                          wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                          label="Department name"
                          name="department"
                          validateStatus={teamDepartments ? "" : "validating"}
                          hasFeedback
                          help={teamDepartments ? "" : "Loading Departments..."}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Department"
                            onChange={(e) => selectTeamDepartments(e as string)}
                          >
                            {teamDepartments
                              ?.filter(
                                (teamDepartment) =>
                                  teamDepartment.team === getFieldValue("team")
                              )
                              .map((teamDepartment) => (
                                <Option
                                  key={teamDepartment._id}
                                  value={teamDepartment._id}
                                >
                                  {teamDepartment.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.department !== currentValues.department
                      }
                    >
                      {({ getFieldValue }) => (
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 7, offset: 3 },
                          }}
                          wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                          label="Role"
                          name="teamRoleId"
                          validateStatus={
                            getFieldValue("department") && !teamRoles
                              ? "validating"
                              : ""
                          }
                          hasFeedback
                          help={
                            getFieldValue("department") && !teamRoles
                              ? "Loading Roles..."
                              : ""
                          }
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select showSearch placeholder="Select Role">
                            {teamRoles
                              ?.filter(
                                (teamRole) =>
                                  teamRole.departmentId ===
                                  getFieldValue("department")
                              )
                              .map((teamRole) => (
                                <Option key={teamRole._id} value={teamRole._id}>
                                  {teamRole.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>

                    {/* <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7, offset: 3 },
                      }}
                      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                      label={"Corporate Role if Member"}
                      name="corporateRoles"
                      rules={[]}
                    >
                      <Checkbox.Group>
                        <Row>
                          <Col span={24}>
                            <Checkbox
                              value="director"
                              style={{ lineHeight: "32px" }}
                            >
                              Director
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox
                              value="large shareholder"
                              style={{ lineHeight: "32px" }}
                            >
                              Large Shareholder
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox
                              value="executive officer"
                              style={{ lineHeight: "32px" }}
                            >
                              Executive Officer
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox
                              value="inital member"
                              style={{ lineHeight: "32px" }}
                            >
                              Initial Member
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item> */}

                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 7, offset: 3 },
                      }}
                      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                      label={`Enter email`}
                      name="email"
                      validateTrigger={"onBlur"}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                          whitespace: true,
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <div style={{ textAlign: "right" }}>
                      <Button
                        style={{ marginRight: "5px" }}
                        htmlType="button"
                        size="large"
                        onClick={() =>
                          history.push(
                            `/issuer-super-admin/admin/team-managment`
                          )
                        }
                      >
                        BACK
                      </Button>
                      <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                        loading={submitting}
                      >
                        SEND INVITE
                      </Button>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
