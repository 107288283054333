import React, { useEffect } from 'react'
import { environment } from '../../environments/environment'
import "../../layout/aboutUs/AboutUs.scss"
import AboutUsBanner from "../../assets/images/about-us/AboutUsBanner.png"
import AboutUsImagee from "../../assets/images/about-us/Group 21472.png"
import WhatWeDo from "../../assets/images/about-us/WhatWeDo.png";
import JoinClubImage from "../../assets/images/layouts/about-us/join-club.png"
import Group21723  from '../../assets/images/about-us/Group21723.png'
import EhnanceLogo from '../../assets/images/about-us/EhnanceLogo.png'

 
const AboutUs = () => {

    useEffect(()=>{
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
           });
    },[])
    
    return (
        <>
            <div className='AboutUs'>
                <div className='AboutUs__Bannerr'>
                <img  src={AboutUsBanner} alt='About Us'></img>
                <div className='AboutUs__Banner-contentt'>
                <p className='AboutUs__Banner-title'>
                    Real Estate For Everyone!
                </p>
                <span className='AboutUs__Banner-title-text'>Discover Fractional Investment with <br></br> <span style={{textAlign:'center',textShadow:' 4px 4px 6px #000'}}>Vruddhi<span style={{ color: 'green',textShadow:' 4px 4px 6px #000' }}>X</span></span> </span>
                </div>
                </div>  
                <div className="AboutUs__about-us">
                    <div className="AboutUs__about-us__content__header">
                        <span className="AboutUs__about-us__content__header__top">
                            About us
                        </span>
                        <span className="AboutUs__about-us__content__sub-head">
                            Welcome to a revolutionary platform dedicated to empowering your real estate ventures!
                        </span>
                        <span className="AboutUs__about-us__content__description">
                            We provide a seamless and user-friendly platform tailored for your capital-raising needs. Elevate your properties through cutting-edge digitalization process, featuring robust KYC procedures, swift admin approvals, and secure blockchain-based smart contracts. Rest assured, We believe to ensure the legitimacy of properties, fostering trust for potential Real Estate Agents, Real Estate Builders, Real Estate Developers, Investors, Real Estate Property Management Companies.
                        </span>
                        <span className="AboutUs__about-us__content__description">
                            Our expansive team of expert developers is poised to guide you through the development of a bespoke real estate digitalization platform designed for Real Estate Agents, Real Estate Builders, Real Estate Developers, Investors, Real Estate Property Management Companies. We have expertise in offering standardized legally compliant procedures that help to increase customer performance.
                        </span>
                        <span className="AboutUs__about-us__content__description">
                            Discover a new era in real estate investment at VruddhiX, your premier destination for cutting-edge digitalized solutions in the property market. Embracing the potential of blockchain technology, we are dedicated to driving innovation and transforming the real estate industry.
                        </span>
                        <span className="AboutUs__about-us__content__description">
                            Whether you're a seasoned real estate investor or just entering the world of digitalized assets, VruddhiX has something to offer you. Join us on this exciting journey as we reshape the future of real estate investment through blockchain technology. Together, let's redefine the possibilities and make real estate digitalization accessible to all.
                        </span>
                    </div>
                    <img
                        src={AboutUsImagee}
                        className="AboutUs__about-us__img"
                        alt="Tokenization Banner"
                        loading="lazy"
                    />
                    </div>
                         <div class=" achievements-aboutus  container  text-center">
                            <div class="row justify-content-between ">
                                <div class="col-3 mx-2 p-3 custom-col">
                               <div className='AboutUs__achievements__content__icion'>
                               <img src={Group21723} alt='AboutUs'></img>
                               </div>
                               <span className="AboutUs__achievements__content__title" >Access</span>
                               {/* <p className="AboutUs__achievements__content__description" >Ensure that the Grade A commercial real estate has been thoroughly vetted and offers yields of 8-10%, with projected returns ranging from 17-20%.</p> */}
                                </div>
                                <div class="col-3  mx-2 p-3 custom-col">
                               <div className='AboutUs__achievements__content__icion'>
                               <img src={EhnanceLogo} alt='AboutUs'></img>
                               </div>
                               <span className="AboutUs__achievements__content__title" >Enhance Liquidity</span>
                               {/* <p className="AboutUs__achievements__content__description" > Enhance liquidity by leveraging access to an exclusive, proprietary resale platform.</p> */}
                                </div>
                                <div class="col-3 mx-2 p-3 custom-col">
                               <div className='AboutUs__achievements__content__icion'>
                               <img src={Group21723} alt='AboutUs'></img>
                               </div>
                               <span className="AboutUs__achievements__content__title" >Asset Management Strategies</span>
                               {/* <p className="AboutUs__achievements__content__description" >Enhance liquidity by leveraging access to an exclusive, proprietary resale platform.</p> */}
                                </div>
                            </div>
                            <br/>
                            <div class="row justify-content-between ">
                            <div class="col-3 mx-2 p-3 ">
                               <span className="customnumber" >25+</span>
                               <br/>
                               <span className="customtitle" >Channel Partners</span>
                                </div>
                            <div class="col-3 mx-2 p-3 ">
                               <span className="customnumber" >40+</span>
                               <br/>
                               <span className="customtitle" >Builders</span>
                                </div>
                            <div class="col-3 mx-2 p-3 ">
                               <span className="customnumber" >30,000+</span>
                               <br/>
                               <span className="customtitle" >Real Estate Agents</span>
                            </div>
                            </div>
                        </div>
                <div className="AboutUs__what-we-do">
                    <img
                        src={WhatWeDo}
                        className="AboutUs__what-we-do__img"
                        alt="Tokenization Banner"
                        loading="lazy"
                    />
                    <div className="AboutUs__what-we-do__content__header">
                        <span className="AboutUs__what-we-do__content__header__top">
                            What We Do
                        </span>
                        <span className="AboutUs__what-we-do__content__description">
                            Our authentic intelligent solutions follow thorough industry standards with legally compliant processes to solve customer problems. So let's Invest with confidence in a platform crafted for efficiency and tailored to meet your specific needs since we believe intuitive care for our customers plays a key role.
                        </span>
                        <span className="AboutUs__what-we-do__content__description">
                            In this future, we see a real estate landscape transformed, breaking free from traditional barriers within India. VruddhiX aims to lead this evolution, paving the way for a world where real estate investment is not only a sound financial decision but an inclusive and straightforward endeavor for Indian investors of all backgrounds. Join us in shaping this vision where the doors to real estate opportunities in India are wide open, and the journey is as secure as it is transformative.
                        </span>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default AboutUs