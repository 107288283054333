import { PlaySquareOutlined, ProjectOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Space, Tabs, Upload, message, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { TabPane } from 'reactstrap'
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { useHistory } from 'react-router-dom';

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    }
  };
const reraformItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    }
  };

export const reraFields = [
    {label:'RERA Number',value:'RERANumber',type:'number',required:true},
    {label:'Proposed Date of Completion',value:'ProposedDateofCompletion',type:'date',required:true},
    {label:'Are there any Promoter(Land Owner/ Investor) (as defined by RERA Order) in the project',value:'AreThereAnyPromoterInTheProject',type:'default',required:true},
    {label:'Revised Proposed Date of Completion',value:'RevisedProposedDateofCompletion',type:'date',required:false},
    {label:'Project Status',value:'ProjectStatus',type:'default',required:false},
    {label:'Plot Bearing No / CTS no / Survey Number/Final Plot no',value:'PlotBearingNo',type:'default',required:false},
    {label:'1 CERSAI details',value:'1CERSAIdetails',type:'default',required:false},
    {label:'Plan Passing Authority (AUDA)',value:'PlanPassingAuthority',type:'default',required:false},
    {label:'Total Number of Proposed Building/Wings (In the Layout/Plot)',value:'TotalNumberofProposedBuilding',type:'default',required:false},
    {label:'Total Recreational Open Space as Per Sanctioned Plan',value:'TotalRecreationalOpenSpaceAsPerSanctionedPlan',type:'default',required:false},
    {label:'Sanctioned FSI of the project applied for registration (Sanctioned Built-up Area)',value:'SSanctionedBuiltupAre',type:'default',required:false},
    {label:'Permissible Total FSI of Plot (Permissible Built-up Area)',value:'PermissibleBuiltupArea)',type:'default',required:false},
    {label:'Built-up-Area as per Proposed FSI (In sqmts)',value:'BuiltupAreaAsPerProposedFSI',type:'default',required:false},
    {label:'Number of Garages ( In Numbers)',value:'NumberofGarages',type:'number',required:false},
    {label:'Covered Parking ( In Numbers)',value:'CoveredParking',type:'number',required:false},
    {label:'Total Units',value:'TotalUnits',type:'number',required:false},
    {label:'Plan Passing Authority',value:'PlanPassingAuthority',type:'default',required:false},
    {label:'Project Registration Date',value:'ProjectRegistrationDate',type:'date',required:false},
    {label:'Project Coordinator Number',value:'ProjectCordinato Number',type:'number',required:false},
    {label:'Project Cost (in Lacs)',value:'ProjectCost',type:'number',required:false},
];

const reraDocFields =[
   {label:'Commencement Certificate',value:'CommencementCertificate',type:'default',required:false},
   {label:'Proforma For Sale Deed',value:'ProformaForSaleDeed',type:'default',required:false},
   {label:'Proforma of Agreement for Sale',value:'ProformaofAgreementforSale',type:'default',required:false},
   {label:'Encumbrance Certificate',value:'EncumbranceCertificate',type:'default',required:false},
   {label:'Approved Layout Plan',value:'ApprovedLayoutPlan',type:'default',required:false},
   {label:'Balance Sheet',value:'BalanceSheet',type:'default',required:false},
   {label:'Audited Profit and Loss Statement',value:'AuditedProfitAndLossStatement',type:'default',required:false},
   {label:'Director\'s Report',value:'DirectorsReport',type:'default',required:false},
   {label:'Cash Flow Statement',value:'CashFlowStatement',type:'default',required:false},
   {label:'Auditor\'s Report',value:'AuditorsReport',type:'default',required:false},
   {label:'Income Tax Return Acknowledgement',value:'IncomeTaxReturnAcknowledgement',type:'default',required:false},
   {label:'Declaration (Form B)',value:'DeclarationFormB',type:'default',required:false},
   {label:'Registration Certificate Of Project (FORM C)',value:'RegistrationCertificateOfProjectFORMC',type:'default',required:false},
   {label:'1 Copy Of The Legal Title Report',value:'CopyOfTheLegalTitleReport',type:'default',required:false},
   {label:'1 Details Of Encumbrances',value:'DetailsOfEncumbrances',type:'default',required:false},
   {label:'1 Details Of Encumbrances Concerned To Legal',value:'DetailsOfEncumbrancesConcernedToLegal',type:'default',required:false},
   {label:'1 Copy of Layout Approval (in case of layout)',value:'CopyOfLayoutApproval',type:'default',required:false},
   {label:'1 Commencement Certificates / NA Order for plotted development',value:'CommencementCertificates',type:'default',required:false},
   {label:'1 Declaration About Commencement Certificate',value:'DeclarationAboutCommencementCertificate',type:'default',required:false},
   {label:'1 Declaration in FORM B',value:'DeclarationInFORMB',type:'default',required:false},
   {label:'1 CERSAI Details',value:'CERSAIDetails',type:'default',required:false},
   {label:'1 Status of Conveyance',value:'StatusofConveyance',type:'default',required:false},
   {label:'Registry Document',value:'RegistryDocument',type:'default',required:false},
   {label:'Allotment Letter Issued By Competent Authority',value:'AllotmentLetterIssuedByCompetentAuthority',type:'default',required:false},
   {label:'Proforma of Conveyance Deed',value:'ProformaOfConveyanceDeed',type:'default',required:false},
   {label:'Proforma of Completion Certificate(Occupancy)',value:'ProformaOfCompletionCertificate',type:'default',required:false},
   //{label:'Details of Encumbrances',value:'DetailsofEncumbrances',type:'default',required:false},
   //{label:'Commencement Certificate',value:'CommencementCertificate',type:'default',required:false},
   {label:'Proforma of Allotment Letter',value:'ProformaofAllotmentLetter',type:'default',required:false},
   {label:'Affidavit If any',value:'AffidavitIfany',type:'default',required:false},
   {label:'Authentic Copy Of Approval Of Project',value:'AuthenticCopyOfApprovalOfProject',type:'default',required:false},
   //{label:'Commencement Certificate',value:'RERANumber',type:'default',required:false},
   //{label:'Approved Layout Plan',value:'ApprovedLayoutPlan',type:'default',required:false},
   {label:'Non Agriculture (NA) Order and Fire Opinion from Authority',value:'NonAgricultureOrderandFireOpinionfromAuthority',type:'default',required:false},
   {label:'Affidavit by Promoter (Form B)',value:'AffidavitByPromoterFormB',type:'default',required:false},
   {label:'Original 7/12 Document',value:'Original712Document',type:'default',required:false},
   {label:'Title Certificate',value:'TitleCertificate',type:'default',required:false},
   {label:'Title Report',value:'TitleReport',type:'default',required:false},
   //{label:'Encumbrance Certificate',value:'EncumbranceCertificate',type:'default',required:false},
   {label:'Performa for Sale Agreement',value:'PerformaforSaleAgreement',type:'default',required:false},
   {label:'Draft Allotment Letter',value:'DraftAllotmentLetter',type:'default',required:false},
   {label:'Draft Sale Deed',value:'DraftSaleDeed',type:'default',required:false},
   //{label:'REGISTRATION CERTIFICATE OF PROJECT (FORM C)',value:'RERANumber',type:'default',required:false},
   //{label:'1 Copy of the legal title report',value:'RERANumber',type:'default',required:false},
   //{label:'1 Copy of Layout Approval (in case of layout)',value:'RERANumber',type:'default',required:false},
   {label:'1 Building Plan Approval (IOD)',value:'1BuildingPlanApproval',type:'default',required:false},
   {label:'2 Building Plan Approval (IOD)',value:'2BuildingPlanApproval',type:'default',required:false},
   {label:'3 Building Plan Approval (IOD)',value:'3BuildingPlanApproval',type:'default',required:false},
   //{label:'1 Commencement Certificates / NA Order for plotted development',value:'RERANumber',type:'default',required:false},
   //{label:'2 Commencement Certificates / NA Order for plotted development',value:'RERANumber',type:'default',required:false},
   //{label:'1 Declaration about Commencement Certificate',value:'RERANumber',type:'default',required:false},
   //{label:'1 Declaration in FORM B',value:'RERANumber',type:'default',required:false},
   {label:'1 Certificates of Architect (Form 1)(Mandatory for only ongoing project)',value:'CertificatesofArchitectForm1',type:'default',required:false},
   {label:'1 Certificates of Engineer (Form 2)(Mandatory for only ongoing project)',value:'CertificatesofEngineerForm2',type:'default',required:false},
   {label:'1 Disclosure of sold/ booked inventory',value:'Disclosureofsoldbookedinventory',type:'default',required:false},
   {label:'1 Proforma of Allotment letter',value:'ProformaofAllotmentletter',type:'default',required:false},
   {label:'1 Occupancy Certificate/ Completion Certificate/ Architect\'s certificate of completion for plotted development:',value:'OccupancyCertificate',type:'default',required:false},
   {label:'1 Certificates of Architect (Form 4)',value:'CertificatesofArchitectForm4',type:'default',required:false},
   {label:'1 Status of Formation of Legal Entity (Society/Co Op etc.)',value:'StatusofFormationofLegalEntity',type:'default',required:false},
   {label:'1 Foreclosure of the Project',value:'ForeclosureoftheProject',type:'default',required:false},
];

const RERA = (props) => {
    const [loading, setLoading] = useState(false);
    const [isEditionMode, setIsEditionMode] = useState(false);
    const [tabChange, setTabChange] = useState(false);
    const [fileList, setFileList] = useState<any>([]);
    const [sendForApprovalVisibility, setSendForApprovalVisibility] = useState(false);
    const [propertyInfoStatus, setPropertyInfoStatus] = useState('');
    const { userInfo } = useUserContext();
    const [reraForm] = Form.useForm();
    const [reraDocs] = Form.useForm();
    const history = useHistory();

    const [rejectReason, setRejectReason] = useState("");
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); 
    const [isSentForApproval, setIsSentForApproval] = useState(false);
    const [hasEdited, setHasEdited] = useState(false);
    const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
    const [isNewProperty, setIsNewProperty] = useState(true);
    

    useEffect(()=>{
      setFieldValues();
      if(props.propertyInfo?.reraDocsStatus == 'done'){
        setSendForApprovalVisibility(true)
        setPropertyInfoStatus(props.propertyInfo.status ?? '');
      }
      
      const fileList = props.propertyInfo?.reraDocs?.map(item => {
        const key = Object.keys(item)[0];
        return {
            uid: key, 
            name: item[key].name,
            status: 'done',
            url: item[key].URL
        };
    });
    setFileList(fileList);

    console.log('filles..', fileList)
    },[]);


    useEffect(() => {
      const approvalStatus = localStorage.getItem('isSentForApproval') === 'true';
      const hasReraDocs = props.propertyInfo?.reraDocs?.length > 0;
      const isNew = !hasReraDocs;
      const fieldsDisabled = approvalStatus && !isNew;
      const editMode = !approvalStatus && !isNew;
      setIsSentForApproval(approvalStatus);
      setIsEditMode(editMode);
      setIsFieldsDisabled(fieldsDisabled);
      setIsNewProperty(isNew);
      setHasEdited(false);
    }, [props.propertyInfo?.reraDocs]);

    const getButtonText = () => {
      if (isNewProperty) {
        return 'Save';
      }
      if (isSentForApproval) {
        return isEditMode ? 'Update' : 'Edit';
      }
      return isEditMode ? 'Update' : 'Edit';
    };
  
   

    const setFieldValues = () => {
      console.log('propety info loading...',props.propertyInfo)
      if(props.propertyInfo && props.propertyInfo.RERANumber){
        setIsEditionMode(true);
        reraFields.forEach(field => {
          reraForm.setFieldsValue({[field['value']]: field.type === 'date' ? (props['propertyInfo'][field.value] ? moment(new Date(props['propertyInfo'][field.value]), 'YYYY-MM-DD') : null) :props['propertyInfo'][field.value]})
        })
      }
      
    }
    const saveReraInfo = async(_form)=>{
      const form = { ..._form };
      form.reraInfo = 'done';
      form.stepDone = 5;
      form.userId = props.userInfo._id;
      form.companyId = props.userInfo?.company?.id;
     

      setLoading(true);
      const response = await issuerSuperAdminService.saveReraInfo(form);
      if (response.success) {
        notification.success({
          message: 'Success',
          duration: 0,
        });
        sessionStorage.setItem('savedPageNo', '5');
        setTimeout(()=>window.location.reload(), 500);

      } else {
        notification.error({
          message: 'Error',
          description: response.error.message,
          duration: 0,
        });
      }
          props.setStepNo(5);
          props.setUpdated(true);
          setLoading(false);
    }

    const beforeUpload = (file) => {
      const isImageOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
      if (!isImageOrPdf) {
        message.error('You can only upload JPG/PNG images or PDF files!');
      }
      return isImageOrPdf;
    };

    const saveReraDocs = async(values)=>{
      try {
        console.log('values are...',values)
        setIsEditMode(false); 
        setHasEdited(false); 
        localStorage.setItem('isSentForApproval', 'false');
        console.log('saved rera docs are..',props.propertyInfo.reraDocs)
        props?.propertyInfo?.reraDocs?.forEach(x => {
          if(Object.keys(values).includes(Object.keys(x)[0])){
            if(values[Object.keys(x)[0]]=== undefined){
              values = {...values, ...x}
            }
          }  
        })
       console.log('saving rera docs..',values);//return;
      const uploadDocPromiseArr = Object.keys(values).map(async(key) => {
        if(values[key] !== undefined){
          setLoading(true)
          if(values[key]?.file?.originFileObj){
              console.log('img...',values[key].file.originFileObj)
              const { hash } = await sharedService.uploadDocumentToIPFS({file:values[key].file.originFileObj});
              const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
              return {[key]:{
                URL,
                name:values[key].file.name,
                type:values[key].file.type,
                label:reraDocFields.find(x => x.value === key)?.label

              }};
            }else{
              return {[key]:values[key]};
            }
        }
      });

      let uploadedDocs = await Promise.all(uploadDocPromiseArr);
      uploadedDocs = uploadedDocs.filter(doc => doc)
      persistData(uploadedDocs);
      props.setStepNo(5);
      sessionStorage.setItem('savedPageNo', '5');

    return uploadedDocs.filter(doc => doc); 
    } catch (error) {
      console.log('error..',error)
      throw error;
    }
    finally {
      setLoading(false);
    }
  }

  const persistData = async(docs) =>{
    try {
      const data = {reraDocs:docs,userId: props.userInfo._id,reraDocsStatus:'done'}
      const response = await issuerSuperAdminService.savePropertyDocuments(data);
      if (response.success) {
        notification.success({ message: 'Success' });
      } else {
        message.error(response.error.message);
        notification.error({ message: 'Error', description: 'response.error.message' });
      }
  
      props.setUpdated(true);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log('error..',error);
      throw error;
    }
  }

  const handleChange = (e,field)=>{
    const obj = {
      uid: field.value, 
      name: e.file.name,
      status: 'done',
      url: '',
    }
    console.log('event..',e)
    console.log('field..',field)
    if(fileList)
      setFileList(prev => [...prev.filter(x => x.uid != field.value),obj])
    console.log(fileList)
  }
  
  const sendPropertyDetailsForApproval  = async()=>{
    try {
      if(!userInfo) return;

      setLoading(true);
      const propertyId = props.propertyInfo?._id
      const res = await issuerSuperAdminService.sendPropertyDetailsForApprovalToMSA(userInfo?.company?.id ,propertyId);
      if(!res.success){
        notification.error({ message: 'Error', description: res?.error?.message })
        setLoading(false);
        return;
      }
      notification.success({ message: 'Success', description: 'Property details sent for approval successfully.'});
      localStorage.setItem('isSentForApproval', 'true');
      setIsSentForApproval(true);
      setIsEditMode(false);
      setHasEdited(false);
      setIsFieldsDisabled(true);
       setIsNewProperty(false);
      setTimeout(()=> window.location.reload(), 1000);
      setLoading(false);

    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  const approveOrReject = async(status) =>{
    try {
      const propertyId = props.propertyInfo?._id
      let payload = {status,rejectionMessage:'' }
      if(status == 'rejected'){

        if(rejectReason == ''){
            notification.info({message: 'Info', description: 'Provide valid rejection message'});
            return;
          }

        payload.rejectionMessage = rejectReason;
        setShowRejectPopup(false)
      }
      console.log(payload)    
      const res = await issuerSuperAdminService.approveOrRejectPropertyDetails(userInfo?.company?.id,propertyId,payload)
      console.log(res)
      if(res.success){
        notification.success({message: 'Success', description: `Property info ${status} successfully`});
        setTimeout(() => {
          window.location.reload();
        }, 3000);   
      }else{
        notification.error({message: 'Error', description: res.error.message});
      }

    } catch (error) {
      console.log(error);
      notification.error({message:'error', description:error})
    }
  }

  const handleEdit = () => {
    setIsEditMode(true); 
    setIsSentForApproval(true); 
    setHasEdited(true); 
    
  };

  const handleButtonClick = () => {
    if (isEditMode) {
      reraDocs.submit(); 
    } else {
      handleEdit();
    }
  };

  return (
    <>
        <Tabs defaultActiveKey={'rerainfo'} activeKey={isEditionMode && !tabChange ? 'reraDocs': 'rerainfo'} centered onChange={(e) => {console.log('umar',e);setTabChange(p=> !p)}}>
            <Tabs.TabPane tab={<span>RERA Info</span>} key="rerainfo">
              <Row justify="center">
              <Col span={20}>
                <Form {...formItemLayout} labelAlign="left" form={reraForm} onFinish={saveReraInfo}>
                    {reraFields.map((field,i) =>(
                      <Form.Item
                        key={i}
                        label={field.label}
                        name={field.value}
                        rules={[
                          {
                            required: field.required ? true: false,
                            message: 'This field is required',
                            // whitespace: field.type != 'date' ? true : false
                          }
                        ]}
                        colon={false}
                        >
                         {(() => {
                            switch (field.type) {
                              case 'date':
                                return <DatePicker placeholder="" style={{width:'100%'}} disabled={false} />;
                              case 'number':
                                return <Input type="number" placeholder="" disabled={false} />;
                              default:
                                return <Input placeholder="" disabled={false} />
                            }
                          })()}
                      </Form.Item>
                      ))
                    }
                  {(props.userInfo?.role === 'issuer company manager') && (
                  <Button size='large' style={{ marginTop: '10px',float:'right' }} type='primary' htmlType="submit" loading={loading}>{isEditionMode ? 'Update' :'Save'}</Button>
                  )}
                  </Form>
              </Col>
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab={<span>RERA Docs</span>} key="reraDocs">
            <Row justify="center">
              <Col span={20}>
              <Form {...reraformItemLayout} labelAlign="left" form={reraDocs} onFinish={saveReraDocs} initialValues={props.propertyInfo?.reraDocs || {}} >
                    {reraDocFields.map((field,i) =>(
                      <Form.Item
                        key={i}
                        label={field.label}
                        name={field.value}
                        colon={false}
                        rules={[
                          {
                            required: field.required ? true: false,
                            message: 'This field is required',
                            //whitespace: true
                          }
                        ]}>
                        
                        <Upload
                          //beforeUpload={beforeUpload}
                          //multiple
                          showUploadList={{showRemoveIcon:false}}
                          onChange={(e) => handleChange(e,field)}
                          fileList={fileList?.map(x => x.uid === field.value ? x : undefined)?.filter(x=>x)}
                          disabled={isFieldsDisabled && !isEditMode}
                        >
                          {(props.userInfo?.role === 'issuer company manager') && (
                          <Button icon={<UploadOutlined />}disabled={isFieldsDisabled && !isEditMode}>Click to upload</Button>
                          )}
                        </Upload>
                      </Form.Item>
                      ))
                    }
                    {(props.userInfo?.role === 'issuer company manager') && (
              <>
                <Button
                  size='large'
                  style={{ marginTop: '10px', float: 'right' }}
                  type='primary'
                  htmlType="button" 
                  loading={loading}
                  onClick={handleButtonClick}
                >
                  {/* {!isSentForApproval ? (isEditMode  ? 'Update' : 'Edit') : (props.propertyInfo?.reraDocs?.length > 0 ? 'Update' : 'Save')} */}
                  {getButtonText()}
                
                </Button>
                {sendForApprovalVisibility && (
                  <Button
                    type="primary"
                    size='large'
                    style={{ marginTop: '10px', float: 'right', marginRight: '5px' }}
                    disabled={propertyInfoStatus?.toLowerCase() === 'pending' || propertyInfoStatus?.toLowerCase() === 'approved' || isFieldsDisabled}
                    onClick={sendPropertyDetailsForApproval}
                  >
                    {propertyInfoStatus?.toLowerCase() === 'approved' ? 'Approved' : 'Send For Approval'}
                  </Button>
                )}
              </>
            )}

                {(['master company super admin',
                'issuer compliance admin',
                'issuer super admin'].includes(props.userInfo.role))
                   &&
                  <>
                    <Space style={{ float: 'right' }}>
                      {propertyInfoStatus?.toLowerCase() !== 'rejected' && <Button
                        type="primary"
                        size='large'
                        onClick={() => approveOrReject('approved')}
                        disabled={propertyInfoStatus?.toLowerCase() == 'approved' || propertyInfoStatus?.toLowerCase() == 'rejected'}
                      >
                        {propertyInfoStatus?.toLowerCase() == 'approved' ? 'Approved' : 'Approve'}
                      </Button>}

                      {propertyInfoStatus?.toLowerCase() !== 'approved' && <Button
                        type="primary"
                        size='large'
                        disabled={propertyInfoStatus?.toLowerCase() == 'rejected'}
                        onClick={() => setShowRejectPopup(true)}
                      >
                        {propertyInfoStatus?.toLowerCase() == 'rejected' ? 'Rejected' :'Reject'}
                      </Button>}
                    </Space>
                  </>
                }
                    
                </Form>
                </Col>
              </Row>
            </Tabs.TabPane>
        </Tabs>

        <Modal
        title="Reject Property Info"
        visible={showRejectPopup}
        onCancel={() => setShowRejectPopup(false)}
        footer={[
          <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
        </Modal>  
    </>

    
  )
}

export default RERA