import React, { useEffect, useState } from "react";
import { InvestorService } from "../Investor/Investor.service";
import { AuthService } from "../Shared/Auth.service";
import { Link,useHistory } from 'react-router-dom';
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Button, Card, Spin, notification } from "antd";
import { environment } from "../../environments/environment";
import axios from "axios";
import { APIResponse } from "../Shared/interfaces";
import { SharedService } from "../Shared/Shared.service";
import { TokentransferService } from "../services/Tokentransfer.services";

declare global {
  interface Window {
    ethereum: any;
  }
}
const authService = new AuthService();
const tokentransferService = new TokentransferService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService();

function TokenRecovery() {
  const APIURL = environment.APIURL;
  const history = useHistory();
  const [companyId, setCompanyId] = useState("");
  const [investorId, setinvesterId] = useState("");

  const [walletAdress, setWalletAdress] = useState("");
  const getWalletAdress = () => {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      // Return the address of the wallet
      console.log("Wallet address: ", res);
      setWalletAdress(res[0]!.toString());
    });
    window.ethereum.on("accountsChanged", function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log("accounts: ", accounts[0]);
      setWalletAdress(accounts[0]);
    });
  };

  const { userInfo, setUserInfo } = useUserContext();
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [oldWalletAddress, setOldWalletAddress] = useState("");
  const [investorDetails, setInvestorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const response = await investorService.getKYCDetails({
        investorId: userInfo?._id,
      });
      setInvestorDetails(response.data["0"]);
      setName(response.data["0"].firstName + " " + response.data["0"].lastName);
      setContactNumber(response.data["0"].contactNumber);
      setEmail(response.data["0"].email);
      getWalletAdress();
      getUsers();
      // uploadDeedDocuments();
    })();
  }, []);

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        setCompanyId(response.data.company.id);
        setinvesterId(response.data._id);
        setOldWalletAddress(response.data.walletAddress);
        setLoading(false);
        console.log("companyId: ", response);
      });
  };

  const uploadDeedDocuments = async () => {
    const response = await tokentransferService.uploadDeedDocumentsApi(companyId,investorId, name, contactNumber, email, oldWalletAddress, walletAdress)
    if(response.data) {
      if (response.success == true) {
        notification.success({
          message: "Success",
          description: "Successfully sent request for Token recovery",
        });
      }
      if (response.success == false) {
        notification.error({
          message: "Error",
          description: response.error.message,
        });
      }
      setCompanyId(response.data._id);
    }
  };

  return (
    <>
      {loading && (
        <div style={{ textAlign: "center", margin: "50px 0" }}>
          <Spin size="large" style={{ textAlign: "center" }} />
        </div>
      )}
      {console.log('oldWalletAddress: ', oldWalletAddress)}
      {!loading && !oldWalletAddress && (<>
        <Card >
            <div className="noWallet" style={{textAlign:'center'}}>
              <h3>Please connect your wallet first!</h3>
              <Button
                type="link"
                onClick={() => history.push('/connect-wallet')}
              >
                Connet Wallet
              </Button>
            </div>
          </Card>
      </>)}
      {!loading && oldWalletAddress && (
        <div style={{ display: "grid" }}>
          <p
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "left",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20px",
            }}
          >
            Token Recovery
          </p>
          <Card
            style={{
              margin: "auto",
              width: 900,
              boxShadow:
                "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <table style={{ margin: "auto", width: "100%" }}>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>Name</td>
                <td style={{ textAlign: "left", height: "2rem" }}>{name}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>
                  Contact Number
                </td>
                <td style={{ textAlign: "left", height: "2rem" }}>
                  {contactNumber}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>Email</td>
                <td style={{ textAlign: "left", height: "2rem" }}>{email}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>
                  Old Wallet Adress
                </td>
                <td style={{ textAlign: "left", height: "2rem" }}>
                  {oldWalletAddress}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>
                  New Wallet Adress
                </td>
                <td style={{ textAlign: "left", height: "2rem" }}>
                  {walletAdress}
                </td>
              </tr>
            </table>
          </Card>
          <button
            style={{
              background: "blue",
              color: "white",
              width: "fitontent",
              padding: "4px 16px",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "6px",
              margin: "10px auto 10px auto",
            }}
            onClick={uploadDeedDocuments}
          >
            Recover Token
          </button>
        </div>
      )}
    </>
  );
}

export default TokenRecovery;
