import React, { useEffect, useState, useRef } from 'react';
import { SharedService } from '../../Shared/Shared.service';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin.service';
import { AuthService } from '../../Shared/Auth.service';
import {
  List,
  Checkbox,
  Row,
  Input,
  Typography,
  Card,
  Col,
  Button,
  Divider, Spin, notification, Result,
} from 'antd';
import GeneralTransferManagerFacet from "../../Shared/SecurityToken/Facets/GeneralTransferManagerFacet";
import {MetamaskService} from "../../Shared/Metamask.service";
import {Country, SymbolDetailsAndSTData, TokenConfigurationProcess} from "../../Shared/interfaces";
import {TokenConfigurationService} from "../../TokenConfigurations/TokenConfiguration.service";
import {SecurityTokenRegistryService} from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import TransactionModal from "../../Shared/TransactionModal/TransactionModal";
import WrongMetamaskWalletWarning from "../../Shared/WrongMetamaskWalletWarning/WrongMetamaskWalletWarning";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";
// import OwnershipFacet from "../../Shared/SecurityToken/Facets/OwnershipFacet";
// import MainFacet from "../../Shared/SecurityToken/Facets/MainFacet";

const { Title } = Typography;

const sharedService = new SharedService();
const generalTransferManagerFacet = new GeneralTransferManagerFacet();
const tokenConfigurationService = new TokenConfigurationService();
// const ownershipFacet = new OwnershipFacet();
// const mainFacet = new MainFacet();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const AllowCountries = () => {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [allowedCountriesSearchText, setAllowedCountriesSearchText] = useState<string>();
  const [notAllowedCountriesSearchText, setNotAllowedCountriesSearchText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const [allowedCountriesHex, setAllowedCountriesHex] = useState<string[]>();
  const [allCountries, setAllCountries] = useState<Country[]>();

  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
    >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [txParams, setTxParams] = useState<any>();

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      console.warn(_tokenConfigurationProcess);

      if (!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData =
        await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
        console.warn(securityTokenRegisteryService);
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const [_allCountriesRes, _allowedCountriesHex] = await Promise.all([
        sharedService.getCountries(),
        generalTransferManagerFacet.allowedCountries(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);

      // console.log('owner:', await ownershipFacet.owner(_symbolDetailsAndSTData.securityTokenData.contractAddress));
      // console.log('trustedForwarder:', await mainFacet.trustedForwarder(_symbolDetailsAndSTData.securityTokenData.contractAddress));
      setAllCountries(_allCountriesRes.data);
      setAllowedCountriesHex(_allowedCountriesHex);

      setLoading(false);

    })();
  }, [userInfo, selectedWallet]);

  const allowedCountries = allCountries
    ?.filter(
      country => allowedCountriesHex
        ?.find(allowedCountry => sharedService.bytes32ToString(allowedCountry) === country._id)
    );

  const notAllowedCountries = allCountries
    ?.filter(
      country => !allowedCountriesHex
        ?.find(allowedCountry => sharedService.bytes32ToString(allowedCountry) === country._id)
    );



  const allowedCountriesFiltered = allowedCountriesSearchText
    ?
    allowedCountries
      ?.filter(country => country.name.en.toLowerCase().startsWith(allowedCountriesSearchText.toLowerCase()))
      ?.sort((c1, c2) => c1.name.en > c2.name.en? 1 : -1)
    :
    allowedCountries?.sort((c1, c2) => c1.name.en > c2.name.en? 1 : -1);

  const notAllowedCountriesFiltered = notAllowedCountriesSearchText
    ?
    notAllowedCountries
      ?.filter(country => country.name.en.toLowerCase().startsWith(notAllowedCountriesSearchText.toLowerCase()))
      ?.sort((c1, c2) => c1.name.en > c2.name.en? 1 : -1)
    :
    notAllowedCountries?.sort((c1, c2) => c1.name.en > c2.name.en? 1 : -1);


  const selectCountry = (e) => {
    setAllCountries(prev => {
      const current = sharedService.clone(prev);
      const country = current.find(country => country._id == e.target.id);
      country['selected'] = !country['selected'];
      return current;
    });
  }

  const clearSelectedCountries = () => {
    setAllowedCountriesSearchText('');
    setNotAllowedCountriesSearchText('');

    setAllCountries(prev => {
      const current = sharedService.clone(prev);
      current.forEach(country => country['selected'] = false);

      return current;
    });
  }


  const openTxFeeDelegationModal = async(formValue) => {
    setTxParams(formValue);
    setIsDelegationModalVisible(true);
  }


  const onAdd = async (prop: {delegate: boolean}) => {
    const countries = txParams.all? notAllowedCountries : notAllowedCountries?.filter(country => country['selected']);

    if(!countries?.length) return notification.error({ message: txParams.all? 'No Countries to add' : 'Select Country'});

    console.log(countries);

    setIsModalVisible(true);
    setTransactions([{ details: 'Adding Countries', submitting: true }]);

    try {

      const receipt = await generalTransferManagerFacet.addAllowedCountries(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        countries.map(country => sharedService.stringToBytes32(country._id)),
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt;
        return current;
      });

      if(!receipt.status) return;

      const [_allowedCountriesHex] = await Promise.all([
        generalTransferManagerFacet.allowedCountries(symbolDetailsAndSTData?.securityTokenData.contractAddress as string)
      ]);

      setAllowedCountriesHex(_allowedCountriesHex);
      clearSelectedCountries();


    }catch (e) {
      console.error(e);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });

  }

  const onRemove = async (prop: {delegate: boolean}) => {
    const countries = txParams.all? allowedCountries : allowedCountries?.filter(country => country['selected']);

    if(!countries?.length) return notification.error({ message: txParams.all? 'No Countries to remove' : 'Select Country'});

    console.log(countries);

    setIsModalVisible(true);
    setTransactions([{ details: 'Removing Countries', submitting: true }]);

    try {

      const receipt = await generalTransferManagerFacet.removeAllowedCountries(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        countries.map(country => sharedService.stringToBytes32(country._id)),
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt;
        return current;
      });

      if(!receipt.status) return;

      const [_allowedCountriesHex] = await Promise.all([
        generalTransferManagerFacet.allowedCountries(symbolDetailsAndSTData?.securityTokenData.contractAddress as string)
      ]);

      setAllowedCountriesHex(_allowedCountriesHex);
      clearSelectedCountries();


    }catch (e) {
      console.error(e);
    }

    setTransactions(prev => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach(transaction => transaction.submitting = false);
      return current;
    });

  }

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              <Spin size="large" />
            </div>
          )}

          {!loading &&
            <Card>
              <Title
                level={2}
                style={{
                  textAlign: 'left',
                  color: '#1890ff',
                  fontWeight: 'bold'
                }}
              >
                Transfer Restriction
            </Title>
              <Divider></Divider>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <>
                  {selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() &&
                    <WrongMetamaskWalletWarning address={symbolDetailsAndSTData.symbolDetails.owner}/>
                  }

                  {selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() &&
                    <Row>
                      <Col span={10} offset={1}>
                        <h4> Select Countries To Allow Exchange </h4>
                        <Input
                          size='large'
                          placeholder='Search'
                          value={notAllowedCountriesSearchText}
                          onChange={e => setNotAllowedCountriesSearchText(e.target.value)}
                        />
                        <div style={{
                          height: "50vh",
                          overflow: 'scroll',
                        }}>
                          <List bordered>
                            {
                              notAllowedCountriesFiltered?.map((country) =>
                                <List.Item key={country._id}>
                                  <Checkbox
                                    id={country._id}
                                    checked={country['selected']}
                                    onChange={selectCountry}
                                  >
                                    {country.name.en}
                                  </Checkbox>
                                </List.Item>)
                            }
                          </List>
                        </div>
                        <Row align='bottom' justify='end'>
                          <Col>
                            <Button style={{
                              marginTop: 15,
                              marginRight: 15
                            }}
                              type='primary'
                              onClick={() => openTxFeeDelegationModal({all: true, txType: 'onAdd'})}>
                              Add All
                            </Button>
                          </Col>
                          <Col>
                            <Button style={{
                              marginTop: 15
                            }}
                              type='primary'
                              onClick={() => openTxFeeDelegationModal({all: false, txType: 'onAdd'})}>
                              Add
                            </Button>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={10} offset={2}>
                        <h4> Selected Country </h4>
                        <Input
                          placeholder='Search'
                          value={allowedCountriesSearchText}
                          onChange={e => setAllowedCountriesSearchText(e.target.value)}
                        />
                        <div style={{
                          height: '50vh',
                          overflow: 'scroll',
                        }}>
                          <List bordered>
                            {
                              allowedCountriesFiltered?.map((country) =>
                                <List.Item key={country._id}>
                                  <Checkbox
                                    id={`${country._id}`}
                                    checked={country['selected']}
                                    onChange={selectCountry}
                                  >
                                    {country.name.en}
                                  </Checkbox>
                                </List.Item>)
                            }
                          </List>
                        </div>
                        <Row align='bottom' justify='end'>
                          <Col>
                            <Button style={{
                              marginTop: 15,
                              marginRight: 15
                            }}
                              type='primary'
                              onClick={() => openTxFeeDelegationModal({all: true, txType: 'onRemove'})}>
                              Remove All
                            </Button>
                          </Col>
                          <Col>
                            <Button style={{
                              marginTop: 15
                            }}
                              onClick={() => openTxFeeDelegationModal({all: false, txType: 'onRemove'})}
                              type='primary'
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                </>
              }

            </Card>


          }
        </Col>
      </Row>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          txParams.txType === 'onAdd'? onAdd({delegate}).then() : onRemove({delegate}).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

      <TransactionModal
        title={'Modifying Restrictions'}
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
    </>
  );
}
export default AllowCountries;
