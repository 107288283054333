import React, { useEffect,useState } from 'react'
import {Table,Card,Col,Row,Typography, Descriptions,Button,Tabs, Modal, Input, message, notification} from 'antd'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
const {Title}=Typography
const {TabPane}=Tabs;

const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

const InvestorRegReqList = () => {
    const [pendingInvestorList,setPendingInvestorList]=useState<any>()
    const [detailsDivision,setDetailsDivision]=useState(true)
    const [shareholderDetails,setShareholderDetails]=useState<any>(null)
    const [submitting,setSubmitting]=useState(false)
    const [approvedAndRejectedInvestors,setApprovedRejectedInvestors]=useState([])
    const { userInfo } = useUserContext();
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [investorStatus, setInvestorStatus] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState('');

    const columns=[
        {
          title:'FirstName',
          dataIndex: 'firstName',
          key:'firstName',
        },
        {
          title:'LastName',
          dataIndex: 'lastName',
          key:'lastName',
        },
        {
          title:'Email',
          dataIndex: 'email',
          key:'email',
        },
        {
          title:'Country',
          dataIndex: 'country',
          key:'country',
        },
        {
          title:'Contact Number',
          dataIndex: 'contactNumber',
          key:'contractNumber',
        },
        {
          title:'Address',
          dataIndex: 'address',
          key:'address',
          render: (_, record) => {
            const { street_address_1, street_address_2, state, city, zipCode } = record;
            const fullAddress = [street_address_1, street_address_2, city, zipCode, state].filter(Boolean).join(', ');
            return <span>{fullAddress}</span>;
          },     
        },
        {
          title:'DateOfBirth',
          dataIndex: 'dateOfBirth',
          key:'dateOfBirth',
          render: (_, record) => {
            const dateOfBirth = moment(record.dateOfBirth);
            const formattedDate = dateOfBirth.format('Do MMMM YYYY');
            return <span>{formattedDate}</span>;
          },         
        },
        {
          title:'Status',
          dataIndex: 'status',
          key:'status',
          render: (_, record) => {
            return record?.status?.registrationRequest
          },         
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => {
            const { status, email } = record;
    
            if (status.registrationRequest === 'pending') {
              return (
                <>
                  <Button
                    type='primary'
                    onClick={() => {
                      setSelectedEmail(email);
                      approveOrReject('approved', email)}
                    }
                  >
                    Approve
                  </Button>{' '}

                  <Button
                  style={{marginTop: '20px'}}
                    type='primary'
                    onClick={() => {
                      setSelectedEmail(email);
                      setShowRejectPopup(true);
                    }}
                  >
                    Reject
                  </Button>
                </>
              );
            }
    
            return null;
          },
        },
    
      ]
   

    const shListcolumns=[
        {
            title:'Name',
            dataIndex: 'name',
            key:'name',
          },
          {
            title:'Email',
            dataIndex: 'email',
            key:'email',
          },
      
    ]

    const showDetails=investor=>{
        setDetailsDivision(false)
        setShareholderDetails(investor.shareHolderList)
        setCompanyId(investor.companyId);
        setInvestorStatus(investor.status)
      }

    useEffect(
        ()=>{
          (async ()=>{
            setIsLoading(true)
            const response= await issuerSuperAdminService.getInvestorRegistrationDetailsByCompanyId(userInfo?.company?.id);
            console.log(response);
            if(response.success && response.data?.length > 0) {
              let tempPendingList:any = [], tempApprovedList:any = [];
              response.data.forEach((res: any) => {
                if(res?.status?.registrationRequest === 'pending') {
                  tempPendingList.push(res);
                } else {
                  tempApprovedList.push(res);
                }
              });

              setPendingInvestorList(tempPendingList);
              setApprovedRejectedInvestors(tempApprovedList);
            } else if(!response.success) {
              notification.error({message: 'Error', description: 'Error when fetching investor request list'});
            }

            setIsLoading(false)
          }
            
            )();
        },[userInfo]
      )
    

      const approveOrReject = async(status: string, email: string = '') =>{
        try {
          setIsLoading(true);
          
          let payload = {status,rejectionMessage:'', email: email || selectedEmail}
          if(status == 'rejected'){

            if(rejectReason == ''){
              notification.info({message: 'Info', description: 'Provide valid rejection message'});
              return;
            }
            
            payload.rejectionMessage = rejectReason;
            setShowRejectPopup(false)
          }
          console.log(payload)
          const res = await issuerSuperAdminService.approveOrRejectInvestorDetails(payload);
          if(res.success){
            message.success(`Registration request ${status} successfully`)
            setDetailsDivision(true);
            setTimeout(() => window.location.reload(), 1000);
          } else {
            notification.error({message: 'Error', description: 'Error when updating investor registration request'});
          }

        } catch (error) {
          console.log(error);
          message.error(error)
        }
        setIsLoading(false);
      }

    
  return (
    <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Investor Registration Request
          </Title>
          <Tabs defaultActiveKey="request" centered onChange={() => setDetailsDivision(true)}>
            <TabPane tab={<span><PlaySquareOutlined/>Pending Investor Requests</span>} key="request">
          <div hidden={!detailsDivision}>
          <Table
            scroll={{ x: true }}
            dataSource={pendingInvestorList}
            columns={columns}
            loading={isLoading}
          />
          </div>
          <div hidden={detailsDivision}>
            <h4>Investor Details</h4>
              <Table scroll={{ x: true }} columns={shListcolumns} dataSource={shareholderDetails}  loading={isLoading} ></Table>
            <br/>
            {investorStatus == 'rejected' || investorStatus == 'pending' &&<Button type='primary' onClick={()=>approveOrReject('approved')}>Approve</Button>} &nbsp;
            {investorStatus == 'approved'|| investorStatus == 'pending' &&<Button type='primary' onClick={()=>{setShowRejectPopup(true);}}>Reject</Button>}&nbsp;
            <Button onClick={()=>setDetailsDivision(true) } disabled={submitting}>Back</Button>
          </div>
          </TabPane>
          <TabPane tab={<span><ProjectOutlined/> Approved/Rejected Registration Request</span>}>
          <div hidden={!detailsDivision}>
            <Table scroll={{ x: true }} columns={columns} dataSource={approvedAndRejectedInvestors} loading={isLoading} ></Table>
            </div>
            <div hidden={detailsDivision}>
            <h4>Investor Details</h4>
              <Table columns={shListcolumns} dataSource={shareholderDetails}  loading={isLoading} ></Table>
            <br/>
            {investorStatus == 'rejected' &&<Button type='primary' onClick={()=>approveOrReject('approved')}>Approve</Button>} &nbsp;
            {investorStatus == 'approved' &&<Button type='primary' onClick={()=>{setShowRejectPopup(true);}}>Reject</Button>}&nbsp;
            <Button onClick={()=>setDetailsDivision(true) } disabled={submitting}>Back</Button>
          </div>
          </TabPane>
          </Tabs>
          
          
          </Card>
      </Col>
     </Row>

     <Modal
        title="Reject Shareholder"
        visible={showRejectPopup}
        onCancel={()=>setShowRejectPopup(false)}
        footer={[
          <Button key="back" onClick={()=>{setShowRejectPopup(false); setCompanyId(null)}}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={()=>approveOrReject('rejected')}>
            Submit
          </Button>,
        ]}
      >
        <Input 
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
  </>
  )
}

export default InvestorRegReqList