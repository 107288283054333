import React from "react";

const termsAndConditionsStyle = {
  container:{
    padding: "100px 20% 20px 20%"
  },
  subContainer:{
    marginLeft:"50px", marginRight:"50px"
  },
  headline: {
    textAlign: "center",
    color: "black",
    fontWeight: "bold",
    
  },
  subheadline: {
    fontWeight: "bold",
    color: "black",
    fontSize: "15px",
  },
  paragraph:{
    textAlign:"justify"
  }
};
const TermsAndConditions = () => {
  return (
    <>
      <div style={termsAndConditionsStyle.container}>
      
        <h2 style={termsAndConditionsStyle.headline}>Terms of Condition</h2>
        <p style={termsAndConditionsStyle.paragraph}>
          Welcome to VruddhiX! Before you engage with our platform, please
          carefully read the following terms and conditions that manage your use
          of our services. By accessing or using VruddhiX, you agree to be bound
          by these terms and conditions. If you do not agree with any part of
          these terms, you may not access the platform.
        </p>
        <div style={termsAndConditionsStyle.subContainer}>
          <p style={termsAndConditionsStyle.paragraph}>
            1.&nbsp;
            <span style={termsAndConditionsStyle.subheadline}>
              {" "}
              Use of Platform:&nbsp;
            </span>
            VruddhiX provides a digitalized platform Customized for real estate
            investment, offering services to Real Estate Agents, Real Estate
            Builders, Real Estate Developers, Investors, and Real Estate
            Property Management Companies. You agree to use our platform solely
            for its intended purpose and in compliance with all applicable laws
            and regulations.
          </p>

          <p style={termsAndConditionsStyle.paragraph}>
            2.&nbsp;
            <span style={termsAndConditionsStyle.subheadline}>
              {" "}
              Account Registration:&nbsp;
            </span>
            VruddhiX require account registration. When creating an account, you
            agree to provide accurate and complete information and to keep your
            login credentials secure. You are responsible for all activities
            that occur under your account.
          </p>
        

        <p style={termsAndConditionsStyle.paragraph}>
          3.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Intellectual Property:&nbsp;
          </span>
          All content and materials on VruddhiX, including text, graphics,
          logos, images, and software, are the property of VruddhiX or its
          licensors and are protected by copyright, trademark, and other
          intellectual property laws.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          4.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Prohibited Conduct:&nbsp;

          </span>
            You agree not to engage in any of the following prohibited activities:
            <ul>
              <li>• Violating any laws or regulations</li>
              <li>•	Impersonating another person or entity</li>
              <li>•	Interfering with the operation of our platform</li>
              <li>•	Attempting to gain unauthorized access to our systems</li>
              <li>•	Posting or transmitting any unlawful, threatening, defamatory, obscene, or otherwise objectionable content</li>
            </ul>
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          5.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Third-Party Links:&nbsp;
          </span>
          VruddhiX may contain links to third-party websites or services that
          are not owned or controlled by us. We are not responsible for the
          content or privacy practices of these third-party sites and encourage
          you to review their terms of use and privacy policies.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          6.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Disclaimer of Warranties:&nbsp;
          </span>
          VruddhiX is provided on an "as is" and "as available" basis, without
          warranties of any kind, either express or implied. We do not warrant
          that our platform will be uninterrupted, error-free, or secure.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          7.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Investment Risks:&nbsp;
          </span>
          Investing in real estate involves risks, including the potential loss
          of capital. VruddhiX does not guarantee investment returns and is not
          liable for any investment losses incurred by users.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          8.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Security and Privacy:&nbsp;
          </span>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. VruddhiX employs security measures to protect user data, but
          we cannot guarantee absolute security. Please review our Privacy
          Policy for more information on how we collect, use, and protect your
          personal information.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          9.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Changes to Terms:&nbsp;
          </span>
          We reserve the right to modify or update these Terms of Use at any
          time without prior notice. By continuing to use VruddhiX after any
          such changes, you agree to be bound by the revised terms.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          10.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Acceptance of Terms:&nbsp;
          </span>
          By accessing or using VruddhiX, you acknowledge and agree to be bound
          by these Terms of Use, as well as all applicable laws and regulations
          governing the use of our platform. If you do not agree with any part
          of these terms, you may not access or use VruddhiX.
        </p>
        <p style={termsAndConditionsStyle.paragraph}>
          11.&nbsp;
          <span style={termsAndConditionsStyle.subheadline}>
            {" "}
            Contact Us:&nbsp;
          </span>
          If you have any questions or concerns about these Terms of Use or our
          platform, please contact us at [contact email].
        </p>
        </div>
        <p style={termsAndConditionsStyle.paragraph}>
          By using VruddhiX, you acknowledge that you have read, understood, and
          agree to be bound by these Terms of Use.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
