import React, { useEffect, useState } from 'react';
import { useHistory,Link, useLocation } from 'react-router-dom';
import {Row, Col, Card, Form, Input, Button, message, Result, Select, Checkbox,Tooltip,Progress,  DatePicker,} from 'antd';
import { AccountService } from '../../Account.service';
import queryString from 'query-string';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { SharedService } from '../../../Shared/Shared.service';
import ConnectToMetamask from '../../../ConnectToMetamask';
import {Country} from "../../../Shared/interfaces";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { environment } from "../../../../environments/environment";
import validationPatterns from '../../../Shared/validationPatterns';


const { Option } = Select;

const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
const metamaskService = new MetamaskService();
const sharedService = new SharedService();

const useSelectedWallet = (userId: string) => metamaskService.useSelectedWallet(userId);
interface State {
  _id: string;
  state_name: string;
  state_id: string;
}

export default () => {
  const history = useHistory();
  const location = useLocation();
  const {code} = queryString.parse(location.search);
  const [form] = Form.useForm();
  const [cCountry, setCCountry] = useState(false);
  const [usaStates, setUSAStates] = useState<State[]>();
  
  const [submitting, setSubmitting] = useState(false);
  const [screenState, setScreenState] = useState<'register' | 'success'>('register');

  const [countries, setCountries] = useState<Country[]>();
  const [termsCheck, setTermsCheck] = useState(false);

  const [isIssuerComplianceAdmin, setIsIssuerComplianceAdmin] = useState(false);
  const [hasFinancialOrCPRole, setHasFinancialOrCPRole] = useState(false);

  const [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet, setNetworkId, setIsMetamaskAuthenticated] = useSelectedWallet('REGISTRATION');
  const isNotConnectedToMetamask = (!metamaskService.isMetamaskInstalled || isMetamaskAuthenticated===false || (networkId && !sharedService.allowedNetworks.includes(networkId)));

  useEffect(() => {
    (async ()=>{
      const response = await accountService.getTeamMemberDetails({ code });
      if (response.success) {
        form.setFieldsValue({
          email: response.data.email
        });
        const hasIssuerComplianceAdminRole = response?.data?.roles?.includes('issuer compliance admin');
        const hasFinancialOrCP = response?.data?.roles?.includes('master company channel partner') || response?.data?.roles?.includes('master company financial advisor');                 
        setHasFinancialOrCPRole(hasFinancialOrCP);
        setIsIssuerComplianceAdmin(hasIssuerComplianceAdminRole);
      } else {
        message.error("Unable to fetch details !");
      }
    })();
    
  },[]);

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      form.setFieldsValue({ country: 'India' });
      setCountries(response.data);
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue({wallet: selectedWallet});
  }, [selectedWallet, form]);

  const currentCountry = (value) => {
    if (value == 'United States of America') setCCountry(true);
    else setCCountry(false);
  }

  const calculateAge = (dateOfBirth) => {
    var dob = new Date(dateOfBirth);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    return age;
  };

  const register = async (formValue) => {
    // const req = {...formValue, code};
    const req = {...formValue, code,...propertyFiles,...professionalCertificatesPhoto};
    const age = calculateAge(req.dateOfBirth);
    setSubmitting(true);

    if (age < 18) {
      message.error("Investor age should be greater than or eqauls to 18!");
      setSubmitting(false);
      return;
    }

    const response = await accountService.registerTeamMember(req);
    console.log(response);

    if (response.success) {
      setScreenState('success');
    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  }

  const [propertyFiles, setPropertyFiles] = useState({
    designatedPartnerPhoto: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    }
  
  });

  const [professionalCertificatesPhoto, setProfessionalCertificatesPhoto] = useState({
    professionalCertificates: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    }
  
  });

  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setPropertyFiles((prev) => ({
      [key]: { URL: null, ...prev[key] },
      ...prev,
    }));
    setProfessionalCertificatesPhoto((prev) => ({
      [key]: { URL: null, ...prev[key] },
      ...prev,
    }));

    try {
      setPropertyFiles((prev) => {
        return {
          ...prev,
          [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 },
        };
      });
      setProfessionalCertificatesPhoto((prev) => {
        return {
          ...prev,
          [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 },
        };
      });
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setPropertyFiles((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          URL,
          uploading: false,
          uploadFilePercentage: 100,
          name: file.name,
          type: fileType.toLowerCase(),
        },
      }));
      setProfessionalCertificatesPhoto((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          URL,
          uploading: false,
          uploadFilePercentage: 100,
          name: file.name,
          type: fileType.toLowerCase(),
        },
      }));
      form.setFieldsValue({ [key]: file.name });
    } catch (err) {
      console.error(err);
      setPropertyFiles((prev) => ({
        ...prev,
        [key]: { ...prev[key], uploading: false },
      }));
      setProfessionalCertificatesPhoto((prev) => ({
        ...prev,
        [key]: { ...prev[key], uploading: false },
      }));
    }
  };


  
  return (
    <>
    {isNotConnectedToMetamask && 
      <ConnectToMetamask
        isMetamaskInstalled={metamaskService.isMetamaskInstalled}
        isMetamaskAuthenticated={isMetamaskAuthenticated}
        setSelectedWallet={setSelectedWallet}
        setNetworkId={setNetworkId}
        setIsMetamaskAuthenticated={setIsMetamaskAuthenticated}
      />
    }


    
    {!isNotConnectedToMetamask && 
      <Row justify="center" style={{margin:"60px 0"}}>
        <Col span={16} style={{textAlign:'center'}}>
          {screenState === 'register' && 
            <Card>
              <h2 style={{textAlign:'center'}}>{isIssuerComplianceAdmin ? 'Designated Partner Registration' : 'Sign Up'}</h2>
              <Form form={form} labelAlign="left" 
              {...formItemLayout}
              onFinish={register}
              style={{ margin: '2% 10% 0 10%', width: '72%' }}>
                <Form.Item 
                  label='First name'
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First Name!',
                      whitespace: true
                    },
                    {
                      validator: (rule, value) => validationPatterns?.name?.validate(value)
                    }
                  ]}>
                  <Input />
                </Form.Item>
          
                <Form.Item 
                  label='Last name'
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Last Name!',
                      whitespace: true
                    },
                    {
                      validator: (rule, value) => validationPatterns?.name?.validate(value)
                    }
                  ]}>
                  <Input />
                </Form.Item>
              <Form.Item
                label='Email'
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'if it is not autofilled please refersh the page!'
                  }
                ]}>
                <Input style={{color: 'green'}} type="email" disabled/>
              </Form.Item>

              <Form.Item 
                label='Phone number'
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Enter Phone number here',
                    whitespace: true
                  },
                  {
                    validator: (rule, value) => validationPatterns?.phoneNumber?.validate(value)
                  }
                ]}>
                  <PhoneInput country={"in"}/>
              </Form.Item>
  

              {hasFinancialOrCPRole &&
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Please enter your date of birth",
                  },
                ]}
              >
                <DatePicker
                  placeholder="(YYYY-MM-DD)"
                  style={{width:'100%' }}
                />
              </Form.Item>
              }

              <Form.Item
                label="Country"
                name="country"
                validateStatus={countries ? "" : "validating"}
                //hasFeedback
                help={countries ? "" : "Loading Countries..."}
                rules={[
                  {
                    required: true,
                    message: "Select Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  onChange={currentCountry}
                >
                  {countries?.map((country) => (
                    <Option key={country._id} value={country.name["en"]}>
                      {country.name["en"]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {cCountry &&
                <Form.Item
                  label='State'
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                      whitespace: true
                    },
                    {
                      validator: (rule, value) => validationPatterns?.cityAndState?.validate(value)
                    }
                  ]}>

                  <Select showSearch placeholder="Select state">
                    {usaStates?.map(state =>
                      <Option key={state?.state_id} value={state.state_name}>{state.state_name}</Option>
                    )}
                  </Select>

                </Form.Item>
              }

              {!cCountry &&
                <Form.Item
                  label='State'
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                      whitespace: true
                    },
                    {
                      validator: (rule, value) => validationPatterns?.cityAndState?.validate(value)
                    }
                  ]}>
                  <Input />
                </Form.Item>
              }
              {hasFinancialOrCPRole && (
              <>       
              <Form.Item 
                  label='City'
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your City!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

              <Form.Item 
                  label='Street Address 1'
                  name="streetAddress1"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Street Address 1!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Street Address 2'
                  name="streetAddress2"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Street Address 2!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Pincode'
                  name="pincode"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Pincode!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label={
                    <Tooltip title="Upload Professional Certificates or Licenses( Document) ">
                      Upload Certificate
                    </Tooltip>
                  }
                  name="professionalCertificates"
                  valuePropName="propFileList"
                  style={{margin:'5px 2px 2px 10px'}}
                  >
                   <>
                    <Tooltip title="Upload Professional Certificates or Licenses( Document)">
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "professionalCertificates");
                        }}
                      />
                      </Tooltip>
                      {professionalCertificatesPhoto.professionalCertificates
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            professionalCertificatesPhoto.professionalCertificates
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                </Form.Item>

                </>
              )}
              {isIssuerComplianceAdmin && (
              <>
              <Form.Item 
                  label='Street Address 1'
                  name="streetAddress1"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Street Address 1!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Street Address 2'
                  name="streetAddress2"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Street Address 2!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label={
                    <Tooltip title="Your DPIN is a unique Designated Partner Identification Number">
                      DPIN
                    </Tooltip>
                  }
                  name="dPIN"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your DPIN!',
                      whitespace: true
                    },
                    {
                      validator: (rule, value) => validationPatterns?.number?.validate(value)
                    }
                  ]}>
                   <Input />
                </Form.Item>

                <Form.Item
                    label="DP Photo"
                    name="designatedPartnerPhoto"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Document required",
                    //   },
                    // ]}
                    valuePropName="fileList"
                    style={{margin:'5px 2px 2px 10px'}}
                  >
                    <>
                    <Tooltip title="Designated Partner Photo">
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "designatedPartnerPhoto");
                        }}
                      />
                      </Tooltip>
                      {propertyFiles.designatedPartnerPhoto
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.designatedPartnerPhoto
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                </>
              )}
              <br />

                <Form.Item 
                  label='Password'
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    },
                    {
                      validator: (rule, value) => validationPatterns?.strongPassword?.validate(value)
                    }
                  ]}>
                  <Input.Password />
                </Form.Item>
          
                <Form.Item 
                  label='Confirm Password'
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}>
                  <Input.Password />
                </Form.Item>                    
  
                {/* <Form.Item 
                  label='Ethereum Wallet'
                  name="wallet"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the wallet!',
                      whitespace: true
                    }
                  ]}>
                  <Input disabled style={{color:'blue', fontWeight: 'bold'}}/>
                </Form.Item> */}

                <Checkbox checked={termsCheck} onChange={()=>setTermsCheck(!termsCheck)} style={{width: '100%', marginBottom: '20px'}}>I hereby accept all <Link to="/terms-and-conditions" target="_blank">terms and conditions</Link> of the platform</Checkbox>
  
                <Form.Item {...tailFormItemLayout}>
                  <Button type='primary' htmlType='submit' loading={submitting} disabled={!termsCheck}>Register</Button>
                  {/* <br />
                  <>Or </><Link to='/account/login'>login now!</Link> */}
                </Form.Item>
              </Form> 
            </Card>
          
          }
  
          {screenState === 'success' && 
            <Card>
              <h2 style={{textAlign:'center'}}>Account created</h2>
              <Result
                title={`Your account has been created successfully`}
                extra={
                  <Button key="console" onClick={() => history.push('/account/login')}>
                    Click here to login
                  </Button>
                }/>
            </Card>
          }
  
        </Col>
      </Row>

    }
    </>
 );
}