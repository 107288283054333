export const environment = {
    production: true,
    apiKey: "5f6b7dff0f95ad9f02444cd0",
    captchaKey: "6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI",
    APIURL: "https://vruddhix-prod-backend-da3cabbac63d.herokuapp.com",
    headerTitle: "Vruddhi",
    securityTokenRegistryAddress: '0x4306CcA1537Ee26c4d912848C873413395eB25d0',
    PlatformAddress: '0x4306CcA1537Ee26c4d912848C873413395eB25d0',
    ipfsProjectId: '2KF2NTvuZal3wrF7ydGKQ9Q2ZHV',
    ipfsProjectSecret: '821b0ce59b8246bb4b5296ed3ec73285',
    ipfsDomain: 'https://retap-master-stage.infura-ipfs.io',
    allowedNetworks: ['137'], // polygon mainnet,
    loginCaptha: 'false',
    twoFactorEmailVerification: false,
    currencySign: '₹',
    acuantUrl: 'https://staging.identitymind.com/edna/applications',
    companyName: "vruddhix",
    nowPaymentAPIURL: "https://api.nowpayments.io/v1",
    commission: 2
  };