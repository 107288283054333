import { SharedService } from '../Shared/Shared.service';
import { APIResponse } from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';
import {MetamaskService} from '../Shared/Metamask.service';

const sharedService = new SharedService();

export class TokenConfigurationService {
  private APIURL = environment.APIURL;

  private get web3() {
    return MetamaskService.web3;
  }

  public contract = {
    ABI: require('../Shared/LLPFactory/ABI.json')
  }

  public llpContract = {
    ABI: require('../Shared/LLP/ABI.json')
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(
      `${this.APIURL}/tokenConfiguration/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }

  async getTokenConfigurationProcesses() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getTokenConfigurationProcesses`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMasterCompanyDetails() {
    return axios.get<any, APIResponse>(`${this.APIURL}/masterCompany/getMasterCompanyDetails`, {headers: await sharedService.getAuthHeader()});
  }

  async getAllAdminsWallets(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getLLPAdminsWalletAddress?companyId=${companyId}`,
      {
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async transferTokens(
    from: string, 
    to: string, 
    amount: string,
    llpContract: string,
    admin: string
    ) {
    const contractInstance = new this.web3.eth.Contract(this.llpContract.ABI, llpContract);
    const method = contractInstance.methods.controllerTransfer(
      from,
      to, 
      amount
    );
    console.log('method',method)
    return method.send({ from: admin });
  }

  async issueTokens(
    to: string[], 
    amount: string[],
    llpContract: string,
    admin: string
    ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, llpContract);
    const method = contractInstance.methods.bulkMint(
      to, 
      amount
    );
    console.log('method',method)
    return method.send({ from: admin });
  }

  async saveLLPSymbol(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/addDeployedTokenSymbol`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveLLPAddress(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/addDeployedSmartContract`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getLLPAddress(id) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getDeployedSmartContract`,
      {params: { companyId: id }, headers: await sharedService.getAuthHeader() }
    );
  }

  async closeTokenSale(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/closeTokenSale`,
      {params: { companyId: companyId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenConfigurationProcessById(params: {
    tokenConfigurationProcessId: string;
  }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getTokenConfigurationProcessById`,
      { params, headers: await sharedService.getAuthHeader() }
    );
  }
  // async getTokenConfigurationProcess(){
  //   return axios.get<any, APIResponse>(`${this.APIURL}/tokenConfiguration/getTokenConfigurationProcess`, {headers: await sharedService.getAuthHeader()});
  // }

  async getTokenDocuments(seriesId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getTokenIssuerDocuments`,
      { headers: await sharedService.getAuthHeader(), params: {seriesId} }
    );
  }

  async getAllTokenSeries() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getAllTokenSeries`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getLastTokenConfigurationProcess() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getLastTokenConfigurationProcess`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveTokenTypeAndDetails(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/saveTokenTypeAndDetails`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenTypeAndDetails(tokenTypeAndDetailsId: string, companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getTokenTypeAndDetails`,
      {
        params: { tokenTypeAndDetailsId , companyId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getPreviousTokenTypeAndDetails() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getPreviousTokenTypeAndDetails`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveRule144Config(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/saveRule144Config`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async currentOfferingStatus(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/currentOfferingStatus`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handlePrimeTrustEscrowAccount(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/handlePrimeTrustEscrowAccount`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async handlePrimeTrustSign(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/handlePrimeTrustSign`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async fileEscrowServiceApplication(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/fileEscrowServiceApplication`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getRule144Config(rule144ConfigId: string, companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getRule144Config`,
      {
        params: { rule144ConfigId, companyId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getPreviousRule144Config() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getPreviousRule144Config`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveOfferingModel(prop: {
    name: string;
    tokenConfigurationProcessId: string;
    investmentAmounts: {
      min: number;
    };
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/saveOfferingModel`,
      prop,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getOfferingModels(prop: {
    tokenConfigurationProcessId: string;
    limit: number | null;
    searchPage: number | null;
    all?: boolean | null;
  }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getOfferingModels`,
      {
        params: prop,
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getOfferingModelById(prop: { modelId: string }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getOfferingModelById`,
      {
        params: prop,
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async savePhase(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/savePhase`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async editPhase(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/editPhase`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPhasesByConfigProcessId(prop: {
    tokenConfigurationProcessId: string;
    limit: number | null;
    searchPage: number | null;
    all?: boolean;
  }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getPhasesByConfigProcessId`,
      {
        params: prop,
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getPhasesByModelId(prop: {
    tokenOfferingModelId: string;
    limit: number;
    searchPage: number;
  }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getPhasesByModelId`,
      {
        params: prop,
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  // async getPhases(
  //   tokenOfferingModelId: string,
  //   limit: number | null,
  //   searchPage: number | null,
  //   all?: boolean
  // ) {
  //   return axios.get<any, APIResponse>(
  //     `${this.APIURL}/tokenConfiguration/getPhases`,
  //     {
  //       params: { tokenOfferingModelId, limit, searchPage, all },
  //       headers: await sharedService.getAuthHeader(),
  //     }
  //   );
  // }

  async getPhase(tokenPhaseId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getPhase`,
      { params: { tokenPhaseId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async saveTokenSymbolReservation(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/saveTokenSymbolReservation`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async submitTokenConfigForReview(req: {
    tokenConfigurationProcessId: string;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/submitTokenConfigForReview`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async reviewFinalTokenConfig(req: {
    status: 'verified' | 'rejected';
    comments: string;
    tokenConfigurationProcessId: string;
    companyId: any
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/reviewFinalTokenConfig`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getRegulation() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getRegulation`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveCheckboxesStatus(req: { checkboxes: { [key: string]: string } }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/saveCheckboxesStatus`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async submitCheckboxesStatus() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/submitCheckboxesStatus`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async adminsRolesCreated() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/adminsRolesCreated`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuersWallets() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getIssuersWallets`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuerQuestionAnswer() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getIssuerQuestionAnswer`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async closeSale(req: { tokenConfigurationProcessId: string }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/closeSale`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async addLegend(req: { tokenConfigurationProcessId: string , companyId: any}) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/addLegend`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async submitPublicDocs(req) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/submitPublicDocs`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPropertyDetails(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getPropertyDetails/${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }
}
