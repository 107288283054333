import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, message, notification, Typography } from "antd";
import BankInfo from "../BankInfo";
import CardHOC from "../../CardHOC";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
import EscrowPaymentDeployment from "../EscrowPaymentDeployment";
const issuerSuperAdminService = new IssuerSuperAdminService();
const authService = new AuthService();
const { Text } = Typography;
const useUserContext = () => authService.useUserContext();
const ManagePaymentMethod = () => {
  const { userInfo } = useUserContext();
  const [coinbaseSelected, setCoinbaseSelected] = useState(false);
  const [primeTrustSelected, setPrimeTrustSelected] = useState(false);
  const [othersSelected, setOthersSelected] = useState(false);
  const [northCapitalSelected, setNorthCapitalSelected] = useState(false);
  const [skrillSelected, setSkrillSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true)
  const [disableApprovalBtn, setDisableApprovalBtn] = useState(true)
  const [repaint, setRepaint] = useState(false)

  const coinbaseRef = useRef(null);
  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.getSavedPaymentMethod({
        companyId: userInfo?.company?.id,
      });
      if (response.success && response.data) {
        console.log(response.data?.coinbaseSelected);
        console.log(response.data?.primeTrustSelected);
        console.log(response.data?.skrillSelected);
        setCoinbaseSelected(response.data?.coinbaseSelected);
        setPrimeTrustSelected(response.data?.primeTrustSelected);
        setNorthCapitalSelected(response.data?.northCapitalSelected);
        setOthersSelected(response.data?.othersSelected);
        if(response.data?.coinbaseSelected && response.data?.othersSelected) {
          setAllSelected(true);
        }
        setSkrillSelected(response.data?.skrillSelected);
        setDisableApprovalBtn(false)
      }
    })();
  }, [repaint]);

  const onSave = async () => {
    setSubmitting(true);
    if (!coinbaseSelected && !primeTrustSelected && !othersSelected && !skrillSelected && !northCapitalSelected) {
      setSubmitting(false);
      message.error("Please select atleast one payment method");
      return;
    }
    const dataToSend = {
      coinbaseSelected,
      primeTrustSelected,
      northCapitalSelected,
      othersSelected,
      skrillSelected,
      companyName: userInfo?.company?.name,
      companyId: userInfo?.company?.id,
    };
    const response = await issuerSuperAdminService.savePaymentMethod(
      dataToSend
    );
    if (response.success && response.data) {
      console.log(response.data?.coinbaseSelected);
      console.log(response.data?.primeTrustSelected);
      setRepaint(prev => !prev);
      setCoinbaseSelected(response.data?.coinbaseSelected);
      setPrimeTrustSelected(response.data?.primeTrustSelected);
      setNorthCapitalSelected(response.data?.northCapitalSelected);
      setOthersSelected(response.data?.othersSelected);
      // message.success("Succesfully saved");
      console.log('payment method saved');
      notification.success({message: 'Success', description: 'Payment Methods saved successfully'});
      if(coinbaseSelected && othersSelected) {
        setAllSelected(true);
      }
    } else {
      notification.error({message: 'Error', description: 'Failed to update payment methods!'});
    }
    setSubmitting(false);
  };

  const setButtonProperty = () =>{
    setDisabledButton(false)
  }

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Text>Please select the payment methods you want to use:</Text>
        <br />
        <br />
        <Checkbox
          checked={othersSelected}
          onChange={(e) => setOthersSelected(e.target.checked)}
          disabled={submitting}
          style={{marginRight: '20px'}}
        >
          Bank Payment
        </Checkbox>
        <Checkbox
          checked={coinbaseSelected}
          onChange={(e) => setCoinbaseSelected(e.target.checked)}
          disabled={submitting}
        >
          Escrow Payment CryptoCurrency (Provider: NOWPayments)
        </Checkbox>
        <br />
        {/* <Checkbox
          checked={primeTrustSelected}
          onChange={(e) => setPrimeTrustSelected(e.target.checked)}
          disabled={submitting}
        >
          ACH/Wire-Transfer/Mail a check/Credit/Debit (Provider: Prime Trust)
        </Checkbox>
        <br /> */}
        {/* <Checkbox
          checked={skrillSelected}
          onChange={(e) => setSkrillSelected(e.target.checked)}
          disabled={submitting}
        >
          Skrill Checkout
        </Checkbox>
        <br /> */}
      </div>
      {(othersSelected) && (<BankInfo savePaymentMethod={onSave} bankInfoEvent={setButtonProperty} disabled={disableApprovalBtn} />)}
      <br/><br/>
      {(coinbaseSelected) && (<EscrowPaymentDeployment savePaymentMethod={onSave} />)}
      <br/>
      <br/>
      {!allSelected && (<Button
        type="primary"
        htmlType="button"
        onClick={onSave}
        loading={submitting}
        disabled={disabledButton}
      >
        Save Payment Methods
      </Button>)}
    </>
  );
};
export default () => {
  return (
    <CardHOC
      component={<ManagePaymentMethod></ManagePaymentMethod>}
    ></CardHOC>
  );
};
