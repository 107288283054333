import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Descriptions, Spin, Table, Button, Modal, Result } from 'antd';
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';
import { AuthService } from '../../../../Shared/Auth.service';
import { useParams } from 'react-router-dom';
import { User, SymbolDetailsAndSTData, VestingScheduleFromContract, TokenModule } from '../../../../Shared/interfaces';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { MetamaskService } from '../../../../Shared/Metamask.service';
import { SharedService } from '../../../../Shared/Shared.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';

import {SecurityTokenService} from '../../../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../../../Shared/SecurityToken/Facets/MainFacet/index'
import VestingFacet from '../../../../Shared/SecurityToken/Facets/VestingFacet/index';
import TxFeeDelegationModal from "../../../../Shared/TxFeeDelegationModal";

const {Title, Text} = Typography;
const issuerSuperAdminService = new IssuerSuperAdminService();

const securityTokenService = new SecurityTokenService();
const vestingFacet = new VestingFacet();
const mainFacet = new MainFacet();

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService(); 

export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  const {id} = useParams<{id: string}>();
  
  const [user, setUser] = useState<User>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [vestingSchedule, setVestingSchedule] = useState<VestingScheduleFromContract>();
  const [percentageToWithdraw, setPercentageToWithdraw] = useState<string>();
  const [monthUnit, setMonthUnit] = useState('60');
  const precision = securityTokenService.precision;

  const [displayableDepartment, setDisplayableDepartment] = useState<string>();
  const [displayableTeamRole, setDisplayableTeamRole] = useState<string>();
  const [hasVesting, setHasVesting] = useState<boolean>();

  const [submitting, setSubmitting] = useState<boolean>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactionReceipt, setTransactionReceipt] = useState<any>();

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);


  useEffect(() => {
    (async () => {
      if(!userInfo || !id) return;

      let _user: User = (await issuerSuperAdminService.getTeamMember(id)).data;
      let _symbolDetailsAndSTData: SymbolDetailsAndSTData | null = null;
      let _hasVesting: boolean | null = null;
      let _monthUnit: string | null = null;

      const [department, teamRole, _tokenConfigurationProcess] = (await Promise.all([
        issuerSuperAdminService.getTeamDepartment(_user.departmentId as string),
        issuerSuperAdminService.getTeamRole(_user.teamRoleId as string),
        tokenConfigurationService.getLastTokenConfigurationProcess()
      ])).map(response => response.data);

      setDisplayableDepartment(department.name);
      setDisplayableTeamRole(teamRole.name);

      // if(_tokenConfigurationProcess?.tokenSymbol){
      //   _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      //   setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      //   if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setUser(_user);
        

      //   [_monthUnit, _hasVesting] = await Promise.all([
      //     mainFacet.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //     vestingFacet.hasVestingSchedule(_symbolDetailsAndSTData.securityTokenData.contractAddress, _user.wallet as string)
      //   ]);


      //   setMonthUnit(_monthUnit);
      //   setHasVesting(_hasVesting);
      // }

      setUser(_user);


      // if(_hasVesting) {
      //   const [_vestingScheduleTemp, _percentageToWithdraw] = await Promise.all([
      //     vestingFacet.getVestingSchedule(_symbolDetailsAndSTData?.securityTokenData.contractAddress as string, _user.wallet as string),
      //     vestingFacet.getPercentageToWithdrawFromVesting(_symbolDetailsAndSTData?.securityTokenData.contractAddress as string, _user.wallet as string),
      //   ]);

      //   const _vestingSchedule = {..._vestingScheduleTemp};
      //   _vestingSchedule.vesting = _vestingScheduleTemp.vesting.map(schedule => ({endOfMonth: schedule.endOfMonth, isFixed: schedule.isFixed, lockPeriod: schedule.lockPeriod, percent: schedule.percent}));
      //   _vestingSchedule.vesting.forEach((schedule, index) => schedule['key'] = index);

      //   setVestingSchedule(_vestingSchedule);
      //   setPercentageToWithdraw(_percentageToWithdraw);
      //   console.log(_vestingSchedule);
      // }
      
    })();
  }, [id, userInfo]);

  const previousVestingDuration = (record: VestingScheduleFromContract['vesting'][0]) => {
    const index = vestingSchedule?.vesting.findIndex(schedule => schedule['key'] === record['key']);
    return index? +(vestingSchedule?.vesting[index-1].endOfMonth as string) : 0;
  };

  const columns = [
    {
      title: 'Vesting Schedule',
      dataIndex: 'vestingScheduleNumber',
      render: (value: string, record) => `Vesting ${record['key'] + 1}`
    },
    {
      title: <>Fixed or divide equaly</>,
      dataIndex: 'isFixed',
      render: (value: boolean, record) => {
        return (
          <div>
            {value? 'Fixed' : 'Divide Equaly'}
          </div>
        );
      }
    },
    {
      title: 'End of vesting month',
      dataIndex: 'endOfMonth',
      render: (value: string, record: VestingScheduleFromContract['vesting'][0]) => {
        const formatedEndOfMonth = (endOfMonth: string | number, creationTS: string) => moment(new BigNumber(endOfMonth).times(monthUnit).plus(creationTS).times(1000).toNumber()).format('lll');
        
        return (
          <>
            {record.isFixed && formatedEndOfMonth(record.endOfMonth, vestingSchedule?.creationTS as string)}
            {!record.isFixed && 
              `${formatedEndOfMonth(previousVestingDuration(record) + 1, vestingSchedule?.creationTS as string)} 
                - 
              ${formatedEndOfMonth(record.endOfMonth, vestingSchedule?.creationTS as string)}`
            }

          </>
        );
 
      }
    },
    {
      title: 'Vesting %',
      dataIndex: 'percent',
      render: (value: string) => new BigNumber(value).div(new BigNumber(10).pow(precision)).toFixed()
    },
    {
      title: 'Lock Period',
      dataIndex: 'lockPeriod',
      render: (value: string) => `${value} months`
    },
  ];


  const openTxFeeDelegationModal = async() => {
    setIsDelegationModalVisible(true);
  }

  const deleteVestingSchedule = async(prop: {delegate: boolean}) => {
    setSubmitting(true);
    setIsModalVisible(true);

    try {
      const receipt = await vestingFacet.deleteVestingSchedule(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        user?.wallet as string,
        {delegate: prop.delegate}
      );

      setTransactionReceipt(receipt);
      console.log(receipt);
  
      if(receipt?.status) setHasVesting(false);
      
    } catch (err) {
      console.error(err);
    }

    setSubmitting(false);
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {!user && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          }
          {user && 
          <>
            <Card>
              <Title level={1} style={{textAlign:'center'}}>User's details</Title>
              <Descriptions bordered column={2} style={{}}>
                <Descriptions.Item style={{textAlign:'center'}} label="First name">
                  {user.firstName}
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'center'}} label="Last name">
                  {user.lastName}
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'center'}} label="Email">
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'center'}} label="Team">
                  {user.roles.includes('employee') ? 'Employee' : 'Contractor/Consultant/Advisor'}
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'center'}} label="Department">
                  {displayableDepartment}
                </Descriptions.Item>
                <Descriptions.Item style={{textAlign:'center'}} label="Role">
                  {displayableTeamRole}
                </Descriptions.Item>
                {user.isAffiliate && user.corporateRoles &&
                  <Descriptions.Item style={{textAlign:'center'}} label="CORPORATE ROLES">
                    {user.corporateRoles.join(', ')}
                  </Descriptions.Item>
                }
                <Descriptions.Item style={{textAlign:'center'}} label="Has Vesting">
                  {hasVesting? 'Yes' : 'No'}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br/><br/>
            {hasVesting && 
              <>
                {!vestingSchedule &&  
                  <div style={{textAlign:'center'}}>
                    <br/>
                    <Spin size='large'/>
                  </div>
                }

                {vestingSchedule && percentageToWithdraw && symbolDetailsAndSTData &&
                  <Card style={{marginBottom:'120px'}}>
                    <Title level={1} style={{textAlign:'center'}}>Vesting Schedule</Title>
                    {(userInfo?.role === 'issuer super admin' || selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()) && 
                      <>
                        <Descriptions bordered column={2} style={{}}>
                          <Descriptions.Item style={{textAlign:'center'}} label="Total amount for Vesting">
                            {
                              new BigNumber(vestingSchedule.amount)
                              .div(new BigNumber(10)
                              .pow(symbolDetailsAndSTData.securityTokenData.decimals))
                              .toFixed()
                            }
                            {' '}
                            {symbolDetailsAndSTData.securityTokenData.symbol}
                          </Descriptions.Item>
                          <Descriptions.Item style={{textAlign:'center'}} label="Total Schedules">
                            {vestingSchedule.vesting.length}
                          </Descriptions.Item>


                          <Descriptions.Item style={{textAlign:'center'}} label="Created on">
                            {moment(new BigNumber(vestingSchedule.creationTS).times(1000).toNumber()).format('LLL')}
                          </Descriptions.Item>

                          <Descriptions.Item style={{textAlign:'center'}} label="Duration">
                          {vestingSchedule.duration} months
                          </Descriptions.Item>

                          <Descriptions.Item style={{textAlign:'center'}} label="Amount withdrawn">
                            {
                              new BigNumber(vestingSchedule.amount)
                              .times(vestingSchedule.vestedPercent)
                              .div(new BigNumber(10).pow(symbolDetailsAndSTData.securityTokenData.decimals))
                              .div(new BigNumber(10).pow(precision))
                              .div(100)
                              .toFixed()
                            }
                            {' '} 
                            {symbolDetailsAndSTData.securityTokenData.symbol}
                            {' '}
                            ({new BigNumber(vestingSchedule.vestedPercent).div(new BigNumber(10).pow(precision)).decimalPlaces(4).toFixed()}%)
                          </Descriptions.Item>

                          <Descriptions.Item style={{textAlign:'center'}} label="Available to withdraw">
                            {
                              new BigNumber(vestingSchedule.amount)
                              .times(percentageToWithdraw)
                              .div(new BigNumber(10).pow(symbolDetailsAndSTData.securityTokenData.decimals))
                              .div(new BigNumber(10).pow(precision))
                              .div(100)
                              .toFixed()
                            }
                            {' '}
                            {symbolDetailsAndSTData.securityTokenData.symbol}
                            {' '}
                            ({new BigNumber(percentageToWithdraw).div(new BigNumber(10).pow(precision)).decimalPlaces(4).toFixed()}%)
                          </Descriptions.Item>
                        </Descriptions>
                        <br/>
                        
                        <Table
                          columns={columns}
                          dataSource={vestingSchedule.vesting}
                          pagination={false}
                          footer={()=>
                            <div style={{textAlign:'right'}}>
                              {userInfo?.role === 'issuer token admin' && 
                                <Button size='large' danger type='primary' onClick={openTxFeeDelegationModal}>STOP VESTING SCHEDULE</Button>
                              }
                            </div>
                          }
                        />
                      </>
                    }

                    {userInfo?.role === 'issuer token admin' && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                      <>
                        <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                        <Result
                          status="error"
                          title = {
                            <p>
                              Select the wallet {' '}
                              <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                                {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                              </a> 
                              {' '} in order Manage Vesting Schedule
                            </p>
                          }
                        />
                      </>
                    }

                  </Card>
                }
              </>  
            }
          </>
          }
        </Col>
      </Row>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          deleteVestingSchedule({delegate}).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />


      <Modal 
        title={`Transaction processing`} 
        closable={false}
        onOk={() => setIsModalVisible(false)}
        keyboard={false}
        maskClosable={false}
        okText={'Close'}
        cancelButtonProps = {{hidden: true}}
        okButtonProps={{hidden: !(!submitting)}}
        // okButtonProps={{hidden: !(!submitting && transactionReceipt?.status), loading: updatingSymbolDetailsAndSTData}}
        // cancelButtonProps={{hidden: !(!submitting && (!transactionReceipt || !transactionReceipt.status))}}
        visible={isModalVisible}>
      <Row>
        {isModalVisible && 
          <Col span={24}>
            <Title level={3}>Vesting schedule</Title>
            <br/>
          </Col>
        }
        <Col span={1}>
          {submitting && <Spin size='large'/>}
          {!submitting && transactionReceipt?.status && <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:'25px'}}/>}
          {!submitting && (!transactionReceipt || !transactionReceipt.status) && <CloseCircleTwoTone twoToneColor="#ff0000" style={{fontSize:'25px'}}/>}
        </Col>
        <Col span={22} offset={1}>
          <Text style={{fontWeight:'bold'}}>
            Stopping Vesting Schedule
          </Text>
          <br/><br/>
          {transactionReceipt && 
            <Text style={{color:'grey'}}>
              Transaction details on Etherscan: 
              <a style={{marginLeft:'6px'}} href={`${sharedService.etherscanURL[networkId as string]}/tx/${transactionReceipt.transactionHash}`} target="_blank" rel="noopener noreferrer">
                {sharedService.minifyTxHash(transactionReceipt.transactionHash)}
              </a>
            </Text>
          }
        </Col>
      </Row>
    </Modal>
    </>
  );
};
