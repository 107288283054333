import React from 'react'
import { environment } from '../../environments/environment'
import careerBanner from '../../assets/images/about-us/CareerBanner.png'
import '../career/career.scss';

const Career = () => {
  return (
    <>
      <div className="container-fulid">
      <div className="row">

      <div className="career-Banner">
      <img src={careerBanner} alt='careerr' ></img>
         
          <div className='career-Banner__Banner-content'> 
          <p className="career-Banner__Banner-title"> 
          Rethinking Real Estate
         </p>
          <span className="career-Banner__Banner-title-text">Digitalization Is the Era</span>
          </div>
          
         
        </div>

      <h2 className='career-title' style={{textAlign:'center',margin:'55px 10px',paddingTop:'270px'}}>Career</h2>
          <br/>

          <div className='col-12'>
          <div className='career-content mx-4'>
          <p>
          "Joining <span style={{fontWeight:'bold'}}>VruddhiX</span> means becoming part of a team dedicated to transforming real estate investment. We offer dynamic career opportunities for individuals who are passionate about innovation, compliance, and making a meaningful impact. Whether you're an experienced professional or a fresh talent, explore a rewarding career with us."
        </p>

        <blockquote>
          <p>
            "<span style={{fontWeight:'bold'}}>VruddhiX</span> presents an exclusive platform tailored to meet the diverse needs of Real Estate Agents, Builders, Developers, Investors, and Property Management Companies. By seamlessly registering your business on our advanced blockchain platform, you gain access to a dynamic ecosystem designed to elevate your real estate endeavours. Uniting cutting-edge technology with industry expertise, <span style={{fontWeight:'bold'}}>VruddhiX</span> empowers professionals to navigate the real estate landscape with precision, unlocking new opportunities and enhancing operational efficiency. Join us in redefining the future of real estate."
          </p>
        </blockquote>

        <blockquote>
          <p>
            "Empowering the real estate ventures with <span style={{fontWeight:'bold'}}>VruddhiX</span>,
            with an innovative approach ensures that each property is securely owned by the LLP, meticulously recorded on a smart contract, complete with its individualized token and unique address. Join us in embracing the future of real estate ownership, where transparency, security, and efficiency converge to redefine industry standards for Real Estate Agents, Builders, Developers, Investors, and Property Management Companies." 
          </p>
        </blockquote>

        <blockquote>
          <p>
            "Elevate your real estate portfolio with <span style={{fontWeight:'bold'}}>VruddhiX</span>, where tokens generated adhere strictly to legal compliance standards, classifying them as legally compliant Digital Securities. Designed to meet the regulatory requirements and standards, these tokens offer a secure and transparent avenue for Real Estate Agents, Builders, Developers, Investors, and Property Management Companies to engage in real estate investments with confidence. Join us in embracing innovation and legal rigor as we redefine the landscape of real estate digitalization." 

          </p>
        </blockquote>

        <blockquote>
          <p>
            "Experience unparalleled ownership and governance with{" "}
            <span style={{fontWeight:'bold'}}>VruddhiX</span>, where each property is exclusively owned by the investors who acquire its distinctive tokens. As a stakeholder, you not only hold a unique position in the property but also wield ultimate governance authority. So many exciting opportunities coming soon, if you are an interested to explore career opportunities with new cutting edge technologies, please join us today."
          </p>
        </blockquote>
          </div>
          </div>
        
        <br/>
      </div>
      </div>
    </>
  );
}

export default Career