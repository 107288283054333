import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Table, Spin, Button, Modal, Input } from "antd";
import axios from "axios";
import { environment } from "../../environments/environment";
import { SharedService } from "../Shared/Shared.service";
import { APIResponse } from "../Shared/interfaces";
import { AuthService } from "../Shared/Auth.service";
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const sharedService = new SharedService();

const { Title } = Typography;

const PaymentApproval = () => {
  const APIURL = environment.APIURL;
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const { userInfo } = useUserContext();
  const companyId = userInfo?.company?.id;

  async function getAllInvestedTokenDetailsByInvestorId(companyId: any) {
    return axios.get<any, APIResponse>(
      `${APIURL}/issuerSuperAdmin/getAllPaymentDetailsWithAllInvestorDetailsByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async function fetchData() {
    try {
      const response = await getAllInvestedTokenDetailsByInvestorId(companyId);
      setPaymentData(response?.data);
      setLoading(false);
      console.log("Data fetched successfully:", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const handleApprove = async (investorId, paymentId) => {
    try {
      const response = await axios.post(
        `${APIURL}/issuerSuperAdmin/approveOrRejectPaymentRequestByCompanyId?companyId=${companyId}`,
        {
          investorId: investorId,
          status: "approved",
          paymentId: paymentId,
        },
        { headers: await sharedService.getAuthHeader() }
      );

      fetchData();
    } catch (error) {
      console.error("Error approving payment:", error);
    }
  };

  const openRejectPopup = (investorId, paymentId) => {
    setSelectedInvestorId(investorId);
    setSelectedPaymentId(paymentId);
    setShowRejectPopup(true);
  };

  const closeRejectPopup = () => {
    setSelectedInvestorId(null);
    setSelectedPaymentId(null);
    setShowRejectPopup(false);
    setRejectReason("");
  };

  const handleReject = async () => {
    if (rejectReason && selectedInvestorId && selectedPaymentId) {
      try {
        const response = await axios.post(
          `${APIURL}/issuerSuperAdmin/approveOrRejectPaymentRequestByCompanyId?companyId=${companyId}`,
          {
            investorId: selectedInvestorId,
            status: "rejected",
            message: rejectReason,
            paymentId: selectedPaymentId,
          },
          { headers: await sharedService.getAuthHeader() }
        );

        fetchData();
        closeRejectPopup();
      } catch (error) {
        console.error("Error rejecting payment:", error);
      }
    }
  };

  const columns = [
    {
      title: "Payment id",
      dataIndex: "investmentId",
      key: "investmentId",
    },
    {
      title: "Token Count",
      dataIndex: "tokenCount",
      key: "tokenCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Token Issued",
      dataIndex: "tokenIssued",
      key: "tokenIssued",
      render: (tokenIssued) => (tokenIssued ? "Yes" : "No"),
    },
    {
      title: "First Name",
      dataIndex: ["investorDetails", "firstName"],
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: ["investorDetails", "lastName"],
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: ["investorDetails", "email"],
      key: "email",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        if (record.status === "approved") {
          return <Button disabled>Approved</Button>;
        } else if (record.status === "rejected") {
          return <Button disabled>Rejected</Button>;
        }
        return (
          <>
            <Button onClick={() => handleApprove(record.investorId, record._id)}>
              Approve
            </Button>
            <Button onClick={() => openRejectPopup(record.investorId, record._id)}>Reject</Button>
          </>
        );
      },
    },
  ]

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          ) : (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Investor Payments Approval
              </Title>
              <Table dataSource={paymentData} columns={columns}  scroll={{ x: true }}/>
            </Card>
          )}
        </Col>
      </Row>
      <Modal
        title="Reject Payment"
        visible={showRejectPopup}
        onCancel={closeRejectPopup}
        footer={[
          <Button key="back" onClick={closeRejectPopup}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleReject}>
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default PaymentApproval;
