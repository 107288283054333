import React, { useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Input, Button, message, Result } from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';


const {Title} = Typography;
const { Option } = Select;


const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

export default () => {
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [screenState, setScreenState] = useState<'invite' | 'success'>('invite');


  const sendInviteToIssuerRole = async(formValue) => {
    const req = sharedService.clone(formValue);

    console.log(req);
    setSubmitting(true);
    const response = await issuerSuperAdminService.sendInviteToIssuerRole(req);

    if (response.success) {
      setScreenState('success');
      // message.success('Invite sent successfully');
      // history.push(`/issuer-super-admin/team`);
    } else {
      message.error(response.error.message);
    }
    setSubmitting(false);
    
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Create Issuer Role</Title>
            <Row justify="center">
              <Col span={20}>
                <Card bordered={false} className='card-shadow'>
                <br/>

                {screenState === 'invite' && 
                  <Form labelAlign="left" form={form} onFinish={sendInviteToIssuerRole}>
                    <Form.Item 
                      labelCol={{xs: { span: 24 }, sm: { span: 12, push: 4 }}}
                      wrapperCol={{xs: { span: 24 }, sm: { span: 8 }}}
                      label='Select the Role you want to create'
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Select showSearch placeholder="Select">
                        <Option key='issuer token admin' value='issuer token admin'>LLP Token Admin</Option>
                        <Option key='issuer company officer' value='issuer company officer'>Issuer Company Officer</Option>
                        <Option key='issuer counsel' value='issuer counsel'>LLP Counsel</Option>
                        <Option disabled key='issuer compliance admin' value='issuer compliance admin'>LLP Compliance Admin</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item 
                      labelCol={{xs: { span: 24 }, sm: { span: 12, push: 4 }}}
                      wrapperCol={{xs: { span: 24 }, sm: { span: 8 }}}
                      label={`Enter email`}
                      name="email"
                      validateTrigger={'onBlur'}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true,
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!'
                        },
                      ]}>
                      <Input/>
                    </Form.Item>

                    <div style={{textAlign:'right'}}>
                      <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                        SEND INVITE
                      </Button>
                    </div>
                  </Form>
                }

                {screenState === 'success' && 
                  <>
                    {/* <h2 style={{textAlign:'center'}}>Account created</h2> */}
                    <Result status='success' title={`Invite sent successfully`}/>
                  </>
                }

                </Card>
              </Col>
            </Row>
          </Card>

        </Col>
      </Row>
    </>
  );
}