import React,{useState, useEffect} from 'react';
import { Result, Card , Form, Col, Radio, Input, Button, Spin} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const investorService = new InvestorService();

const RegistrationRequest = () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [registrationRequestRejected, setRegistrationRequestRejected] = useState(false);
  const [registrationRequestApproved, setRegistrationRequestApproved] = useState(false);

  useEffect(()=>{
    if(userInfo.status.registrationRequest === 'rejected'){
      setRegistrationRequestRejected(true);
    }
    if(userInfo.status.registrationRequest === 'approved' || userInfo.status.registrationRequest === 'verified' ){
      setRegistrationRequestApproved(true);
    }
  },[userInfo])

    return (
        <>
        <Card style={{margin: '20px 50px', padding: '20px 40px'}}>
          {registrationRequestRejected && (<h6>Sorry! Your Registration request is rejected by LLP Manager. You can not proceed ahead!</h6>)}
          {!registrationRequestRejected && !registrationRequestApproved && (<h6>Your Registration request is pending with LLP Manager. Once he approves your details you can proceed!</h6>)}
          {!registrationRequestRejected && registrationRequestApproved && (<h6>You have been successfully registered. You can proceed with investment!</h6>)}
          </Card>
        </>
      )
}

export default RegistrationRequest