import React from "react";
import { environment } from "../../environments/environment";
import "../../layout/corporateRsponsibility/corporateRsponsibility.scss";
import BannerImage from "../../assets/images/layouts/corporate-responsibility/banner.jpg";
import PositiveImpactImage from "../../assets/images/layouts/corporate-responsibility/positive-impact.png";
import SustainabilityImage from "../../assets/images/layouts/corporate-responsibility/sustainability.png";
import EmpoweringCommunities from "../../assets/images/about-us/EmpoweringCommunities.png"
import EthicalBusinessPracticesImage from "../../assets/images/layouts/corporate-responsibility/ethical-business-practices.png";
import InclusionImage from "../../assets/images/layouts/corporate-responsibility/inclusion.png";
import SafetyImage from "../../assets/images/layouts/corporate-responsibility/safety.png";
import ContiniousImprovment from "../../assets/images/about-us/ContiniousImprovement.png";
import BuildingImage from '../../assets/images/about-us/BuildingImage.png'

const CorporateResponsibility = () => {
  return (
    <>
      <div className="corporateResponsibility">
        <div className="wrapper">
        <img className="corporateResponsibility" src={BannerImage} style={{width: "100%"}} alt="About Us"></img>
        </div>
        <div className="corporateResponsibility__header">
          <div className="corporateResponsibility__header__large">
            Corporate
          </div>
          <div className="corporateResponsibility__header__large">
            Responsibility
          </div>
        </div>
        <div className="corporateResponsibility__description">
          At VruddhiX, being at the forefront of innovation in the real estate
          sector, we deeply understand the essence of corporate responsibility.
          It's not just about meeting business goals for us; it's about
          embracing sustainability, ethical practices, and actively engaging
          with our community.
        </div>
        <div className="corporateResponsibility__description">
          We aspire to uphold the highest standards, driven by a genuine desire
          to contribute positively to society and the environment. At VruddhiX,
          our commitment to corporate responsibility isn't a checklist; it's a
          heartfelt pledge, woven into the fabric of our identity, guiding our
          every action as we endeavor to make a meaningful and enduring impact
          beyond the realms of real estate innovation.
        </div>
        <div className="corporateResponsibility__cards">
          <div className="corporateResponsibility__cards__card">
            <img
              src={SustainabilityImage}
              style={{ height: "fit-content" }}
              alt="Sustainability"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Our Commitment to Sustainability
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                Sustainability is at the core of our corporate responsibility
                strategy. We are dedicated to minimizing our environmental
                footprint and promoting sustainable practices throughout our
                operations. This commitment includes:
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                <strong>Reducing Carbon Emissions:</strong> We strive to reduce
                our carbon emissions through energy-efficient practices,
                renewable energy sources, and responsible supply chain
                management.
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                <strong>Waste Reduction:</strong> We are actively working to
                minimize waste generation and promote recycling and responsible
                disposal methods.
              </span>
              {/* <span className="corporateResponsibility__cards__card__content__description">
                <strong>Resource Conservation:</strong> We are committed to
                conserving natural resources and optimizing resource use in our
                products and services.
              </span> */}
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={EmpoweringCommunities}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Empowering Communities
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                We believe in giving back to the communities where we operate.
                Our community empowerment initiatives include:
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                <strong>Education:</strong> We support educational programs and
                scholarships to empower future generations.
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                <strong>Skills Development:</strong> We provide training and
                skill development opportunities to create a more skilled
                workforce.
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                <strong>Local Sourcing:</strong> We prioritize local sourcing
                and partnerships to support local businesses and economies.
              </span>
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={PositiveImpactImage}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Making a Positive Impact
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                At VruddhiX, we believe that true success goes hand-in-hand with
                responsible business practices. We are committed to making a
                positive impact on society and the environment while achieving
                our business goals. Our corporate responsibility initiatives are
                an integral part of who we are and what we stand for.
              </span>
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={EthicalBusinessPracticesImage}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Ethical Business Practices
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                Integrity and ethical behavior are non-negotiable at VruddhiX. We uphold the highest standards of ethics in all our
                business dealings, ensuring transparency, fairness, and
                trustworthiness.
              </span>
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={InclusionImage}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Diversity and Inclusion
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                We value diversity and inclusivity within our workforce. Our
                commitment to diversity not only fosters a more creative and
                dynamic workplace but also reflects our dedication to equal
                opportunities for all.
              </span>
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={SafetyImage}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Health and Safety
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                The well-being of our employees and partners is of paramount
                importance. We maintain rigorous health and safety standards to
                provide a safe and secure working environment.
              </span>
            </span>
          </div>
          <div className="corporateResponsibility__cards__card">
            <img
              src={ContiniousImprovment}
              style={{ height: "fit-content" }}
              alt="Positive Impact"
            ></img>
            <span className="corporateResponsibility__cards__card__content">
              <span className="corporateResponsibility__cards__card__content__title">
                Continuous Improvement
              </span>
              <span className="corporateResponsibility__cards__card__content__description">
                Corporate responsibility is not a one-time effort. We are
                committed to continually assessing our practices, seeking
                opportunities for improvement, and adapting to changing societal
                and environmental needs.
              </span>
            </span>
          </div>
        </div>
        
        <img src={BuildingImage} alt='About Us'></img>     
        
       </div>
    </>
  );
};

export default CorporateResponsibility;
