import { APIResponse, User } from "../Shared/interfaces";
import { environment } from "../../environments/environment";
import axios from "axios";
import { SharedService } from "../Shared/Shared.service";

const sharedService = new SharedService();

export class TokentransferService {
  private APIURL = environment.APIURL;

  async allPendingKycRequestApi(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getPendingApprovalKYCRequestForTokenTransfer?companyId=${companyId}`,
      { params: {}, headers: await sharedService.getAuthHeader() }
    );
  }

  async allApprovedOrRejectedRequestApi(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getAllApprovedOrRejectedKYCRequestForTokenTransfer?companyId=${companyId}`,
      { params: {}, headers: await sharedService.getAuthHeader() }
    );
  }

  async tokenTransferBYLLPTokenAdminForInvestorApprovedRequestApi(
    compId,
    investorId,
    tokenRecoveryDetailsId
  ) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/tokenTransferBYLLPTokenAdminForInvestorApprovedRequest?companyId=${compId}`,
      {
        investorId: investorId,
        tokenRecoveryDetailsId: tokenRecoveryDetailsId,
      },
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveKYCRequestForTokenTransferApi(
    compId,
    investorId,
    tokenRecoveryDetailsId
  ) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/approveOrRejectKYCRequestForTokenTransfer?companyId=${compId}`,
      {
        investorId: investorId,
        status: "approved",
        tokenRecoveryDetailsId: tokenRecoveryDetailsId,
      },
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async rejectKYCRequestForTokenTransferApi(
    compId,
    investorId,
    tokenRecoveryDetailsId,
    rejectionMessage
  ) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/approveOrRejectKYCRequestForTokenTransfer?companyId=${compId}`,
      {
        investorId: investorId,
        status: "rejected",
        rejectionMessage: rejectionMessage,
        tokenRecoveryDetailsId,
      },
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async uploadDeedDocumentsApi(
    companyId,
    investorId,
    name,
    contactNumber,
    email,
    oldWalletAddress,
    walletAdress
  ) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/submitKYCDetailsForRecoveryOfToken?companyId=${companyId}`,
      {
        investorId: investorId,
        name: name,
        contactNumber: contactNumber,
        email: email,
        oldWalletAddress: oldWalletAddress,
        newWalletAddress: walletAdress,
      },
      { headers: await sharedService.getAuthHeader() }
    );
  }
}
