import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../Shared/Shared.service";
import { APIResponse, User } from "../Shared/interfaces";
import { AdminService } from "../IssuerSuperAdmin/Admin/Admin.service";
import {LLP} from "../Shared/LLP/LLP.service";
import TransactionModal from "../Shared/TransactionModal";
import { MetamaskService } from "../Shared/Metamask.service";
import axios from "axios";
import { environment } from "../../environments/environment";
import { TokentransferService } from "../services/Tokentransfer.services";

const { Title } = Typography;
const { TabPane } = Tabs;

const adminService = new AdminService();
const sharedService = new SharedService();
const tokentransferService = new TokentransferService();
const llp = new LLP();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const WhitelistRecovery = () => {
  const APIURL = environment.APIURL;
  const history = useHistory();
  const [admins, setAdmins] = useState<User[]>();
  const [adminRoles, setAdminRoles] = useState<any[]>();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [allPendingRequestsDataSource, setAllPendingRequestsDataSource] = useState([]);
  const [allApprovedOrRejectedRequestsDataSource, setAllApprovedOrRejectedRequestsDataSource] = useState([]);
  const { userInfo, setUserInfo } = useUserContext();

  useEffect(() => {
    getUsers();
  }, []);

  const [showModal, setshowModal] = useState(false);
  const openModal = () => {
    setshowModal(!showModal);
  };

  const getUsers = async () => {
    const response = await sharedService.getUsers();
    if (response.data) {
      console.log("getUsers Response: ", response);
      allPendingKycRequest(response.data?.company?.id);
      allApprovedOrRejectedRequest(response.data?.company?.id);
    }
  };

  const allPendingKycRequest = async (companyId) => {
    setAllPendingRequestsDataSource([]);
    const response = await tokentransferService.allPendingKycRequestApi(companyId);
    if (response.data) {
      console.log("allPendingKycRequest Response: ", response);
      if(response.data?.length >0) {
        setAllPendingRequestsDataSource(response.data.filter(element => element.companyId === userInfo?.company?.id));
      }
    }
  };

  const allApprovedOrRejectedRequest = async (companyId) => {
    const response = await tokentransferService.allApprovedOrRejectedRequestApi(companyId);
    if (response.data) {
      console.log("allPendingKycRequest Response: ", response);
      if(response.data?.length >0) {
        setAllApprovedOrRejectedRequestsDataSource(response.data.filter(element => element.companyId === userInfo?.company?.id));
      }
    }
  };

  const approveKYCRequestForTokenTransfer = async (compId, investorId, tokenRecoveryDetailsId) => {
    const response = await tokentransferService.approveKYCRequestForTokenTransferApi(compId, investorId, tokenRecoveryDetailsId);
    if (response.data) {
      notification.success({
        message: 'Success',
        description: 'Successfully approved the request',
      });
      getUsers();
    }
  };

  const rejectKYCRequestForTokenTransfer = async (compId, investorId, tokenRecoveryDetailsId) => {
    const response = await tokentransferService.rejectKYCRequestForTokenTransferApi(compId, investorId, tokenRecoveryDetailsId, rejectionMessage);
    if (response.data) {
      notification.success({
        message: 'Success',
        description: 'Successfully rejected the request',
      });
      setshowModal(!showModal);
      getUsers();
    }
  };

  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [currentInvestorId, setCurrentInvestorId] = useState("");
  const [currentId, setCurrentId] = useState("");
  const showRejectionMessageModel = (companyId, investorId, _id) => {
    setshowModal(!showModal);
    setCurrentCompanyId(companyId);
    setCurrentInvestorId(investorId);
    setCurrentId(_id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Old Wallet Address",
      dataIndex: "oldWalletAddress",
      key: "oldWalletAddress",
    },
    {
      title: "newWalletAddress",
      dataIndex: "newWalletAddress",
      key: "newWalletAddress",
    },
    {
      title: "Action",
      key: "action",
      render: (value) => {
        return (
          <>
            <div style={{ display: "flex" }}>
              <Button
                type="link"
                onClick={() =>
                  approveKYCRequestForTokenTransfer(
                    value.companyId,
                    value.investorId,
                    value._id,
                  )
                }
              >
                Approve
              </Button>
              <Button
                type="link"
                onClick={() =>
                  showRejectionMessageModel(
                    value.companyId,
                    value.investorId,
                    value._id
                  )
                }
              >
                Reject
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Old Wallet Address",
      dataIndex: "oldWalletAddress",
      key: "oldWalletAddress",
    },
    {
      title: "newWalletAddress",
      dataIndex: "newWalletAddress",
      key: "newWalletAddress",
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await adminService.getAdmins();

      const _admins: User[] = response.data;

      const _adminRoles = sharedService.adminRolesOptions.map((roleOpt) => {
        const result = sharedService.clone(roleOpt);
        result["total"] = _admins.filter((admin) =>
          admin.roles.includes(roleOpt.value as any)
        ).length;

        return result;
      });

      console.log(_adminRoles);

      setAdmins(_admins);
      setAdminRoles(_adminRoles);
    })();
  }, []);

  const Recoverywhitelist = async () => {
    setIsModalVisible(true);
    setTransactions([{details: 'listing the recovered wallet address', submitting: true}]);

    try {

      const receipt = await llp.modifyWhitelist(
        '0x5891cd3cDEf219f5533Af3db08d1311aF1294E4D',
        selectedWallet as string,
        [selectedWallet as string],
        []
      );


      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
       return current;
     });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {!admins && (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          {admins && (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Whitelist Recovery
              </Title>
              <Row>
                <Col
                  span={3}
                  offset={21}
                  style={{ display: "flex", justifyContent: "end" }}
                ></Col>
              </Row>
              <h3>All Pending KYC Requests</h3>
              <Table dataSource={allPendingRequestsDataSource} columns={columns} style={{lineBreak: "anywhere"}} />
              <h3>All Approved or Rejected KYC Requests</h3>
              <Table dataSource={allApprovedOrRejectedRequestsDataSource} columns={columns2} style={{lineBreak: "anywhere"}} />
            </Card>
          )}
        </Col>
      </Row>
      <Modal
        title={`Please write any reason for rejection`}
        okText="Reject"
        cancelText="CANCEL"
        visible={showModal}
        onOk={() => {
          rejectKYCRequestForTokenTransfer(currentCompanyId, currentInvestorId, currentId);
        }}
        onCancel={() => {
          openModal();
        }}
      >
        <textarea
          value={rejectionMessage}
          onChange={(e) => setRejectionMessage(e.target.value)}
          style={{width: "100%", height: "5rem", borderRadius: "8px", padding: "5px"}}
        ></textarea>
      </Modal>
      <TransactionModal
        title = 'whitelisting the recovered wallet address'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
};

export default WhitelistRecovery;
