import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import {SeriesService} from "../Series.service";
import {Button, Form, Input, message, Modal, notification, Spin, Table,Tag} from "antd";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import {SeriesDetails} from "../../Shared/interfaces";
import validationPatterns from '../../Shared/validationPatterns';
import { environment } from '../../../environments/environment';



const seriesService = new SeriesService();

const useUserContext = () => new AuthService().useUserContext();


export default function SeriesList({newSeries = true}) {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<SeriesDetails[]>();
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [modalForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState<SeriesDetails>();
  const [smartDeployed, setSmartDeployed] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [totalSeries, setTotalSeries] = useState<number>(0);

  const isFinancialAdvisor = userInfo?.role === "master company financial advisor";
  const isChannelPartner = userInfo?.role == "master company channel partner";

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await seriesService.getSeries(isChannelPartner ? userInfo._id : '');
      const sortedSeries = response?.data.sort((a: SeriesDetails, b: SeriesDetails) => b.creationTS - a.creationTS);
      setSeries(sortedSeries);
      setTotalSeries(sortedSeries.length); 
      setSeries(sortedSeries);

      const res = await seriesService.getSmart();
      if(res.data === 'true'){
        setSmartDeployed(true);
      }

      setLoading(false);

    })();
  }, [userInfo]);

  const openInviteSeriesModal = (prop: {selectedSeries: SeriesDetails}) => {
    modalForm.resetFields();
    setSelectedSeries(prop.selectedSeries);
    setIsInviteModalVisible(true);
  }

  const inviteSeriesManager = async(formValue) => {
    setSubmitting(true);

    const commission = formValue.commission ? formValue.commission : environment.commission;

    const response = await seriesService.inviteSeriesManager({
      email: formValue.email,
      seriesId: selectedSeries?._id as string,
      userId: userInfo?._id as string,
      commission: commission
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to LLP Super Admin Successfully'
      });

      setIsInviteModalVisible(false);

    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setSubmitting(false);
  }

  const seriesName = (value) => `${value}`;

  const columns = [
    {
      title: 'LLP NUMBER',
      render: (text, record, index) => {
      const globalIndex = (pagination.current - 1) * pagination.pageSize + index + 1;
        return globalIndex;
      }
    },
    {
      title: 'LLP NAME',
      dataIndex: 'name',
      render: (value: string) => seriesName(value)
    },
    {
      title: 'Property Company Super Admin NAME',
      dataIndex: 'seriesManagerName',
      render: (value, record: SeriesDetails) =>
        record.superAdminDetails
        ? `${record.superAdminDetails.firstName} ${record.superAdminDetails.lastName}`
        : '-'
    },
    {
      title: 'Property Company Super Admin EMAIL ADDRESS',
      dataIndex: 'seriesManagerEmail',
      render: (value: string, record: SeriesDetails) =>
        record.superAdminDetails
          ? record?.superAdminDetails?.email
          : '-'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'INVITED BY',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   // render: (status: string) => {
    //   //   let color = status === 'completed' ? 'green' : ( status === 'rejected' ? 'red': '#3dc2d3');
    //   //   return (
    //   //     <Tag color={color} key={status}>
    //   //       {status.toUpperCase()}
    //   //     </Tag>
    //   //   );
    //   // },
    // },
    ...(isChannelPartner) ? [
      {
          title: 'COMMISSION %',
          dataIndex: 'commission',
           render: (value: number) => value !== undefined ? `${value}%` : '-'
      },
      {
        title: 'COMMISSION AMOUNT',
        dataIndex: 'commissionAmount',
        render: (value, record: SeriesDetails) => {
          const commissionPercentage = record.commission;
          const amountToRaise = record?.issuerRegistrationDetails?.amountToRaise;
          if (!isNaN(commissionPercentage) && amountToRaise !== undefined) {
            const commissionAmount = (amountToRaise * commissionPercentage) / 100;
            return `${commissionAmount.toFixed(2)}`;
          } else {
            return 'N/A';
          }
        }
      },  
  ] : [],
    ...(isFinancialAdvisor ? [] : [{
      title: 'ACTION',
      dataIndex: 'action',
      render: (value, record: SeriesDetails) => (
        <>
          {record?.companyId ? (
            <span>Already invited</span>
          ) : (
            <Button
              size='small'
              style={{ marginLeft: '5px' }}
              type='primary'
              onClick={() => openInviteSeriesModal({ selectedSeries: record })}
            >
              INVITE LLP Super Admin
            </Button>
          )}
        </>
      )
    }])
  ];
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };



  return (
    <CardHOC
      heading={'List of All Customers (Properties)'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            {newSeries && (<div style={{textAlign:'right', marginBottom: '10px'}}>
            {smartDeployed && (
              <Button type="primary" size='large' onClick={() => history.push(`/series/new`)}>
                NEW LLP {isChannelPartner}
              </Button>)}
            </div>)}
           

            <Table scroll={{ x: true }}
              columns={columns}
              dataSource={series}
              rowKey='_id'
              pagination={{
                ...pagination,
                pageSize: 10,
                total: series?.length,
                showTotal: (total) => `Total ${total} items`,
                position: ['bottomCenter']
              }}
              onChange={handleTableChange}
            />
          </>
        }


        <Modal
          title={`Invite LLP Super Admin to ${seriesName(selectedSeries?.name)}`}
          okText='SUBMIT'
          cancelText='CANCEL'
          visible={isInviteModalVisible}
          onOk={()=> modalForm.submit()}
          onCancel={()=>{
            setIsInviteModalVisible(false);
          }}
          okButtonProps={{
            loading: submitting,
          }}
        >

          <Form
            form={modalForm}
            autoComplete={'off'}
            onFinish={inviteSeriesManager}
          >

            <Form.Item
              name={'email'}
              label={'Email'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 20 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  validator: (rule, value) => validationPatterns?.email?.validate(value)
                }
              ]}
            >
              <Input size="large"  placeholder={'Email'}/>
            </Form.Item>

            {isChannelPartner &&(
              <Form.Item
                name={'commission'}
                label={'Commission %'}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 20 } }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  }
                ]}
              >
                <Input type="number" size="large" addonAfter={'%'} max={100} />
              </Form.Item>
             )}

          </Form>

        </Modal>




      </>}
    />
  );
}
