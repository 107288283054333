import { Button, Col, Modal, Row, Tabs,Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Spin } from "antd";
import { Card, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { Container, TabPane } from "reactstrap";
import queryString from "query-string";
import sign from "../../assets/images/icons/tick.png";
//import "./site-common.min.css";
import "./index.css";
import "./PropertyDetails.css";
import pimage from "../../assets/images/icons/home2.jpg";
import { Carousel } from "antd";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Item from "antd/lib/list/Item";
// import { Link } from "react-feather";
import { VideoPlayer } from "../mainSlider/VideoPlayer";
import { PropertyAdminService } from "./PropertyAdmin.service";
import { AuthService } from "../Shared/Auth.service";
import InvestModel from "../Shared/InvestModel/InvestModel";
import { environment } from "../../environments/environment";
import { SharedService } from "../Shared/Shared.service";
import Banner from "../../layout/Homepage/Banner";
import waterfall from "../../assets/images/marketplace/Waterfall.png"
import union from "../../assets/images/marketplace/Union.png"
import currency from "../../assets/images/marketplace/currency.png"
import lineup from "../../assets/images/marketplace/lineup.png"
import MarketPlaceHeaderMenu from "../../layout/marketplaceHeader/MarketPlaceHeaderMenu";
import Widget_light from '../../assets/images/marketplace/Widget_light.png'
import group_share from '../../assets/images/marketplace/group_share.png'
import Book_check from '../../assets/images/marketplace/Book_check.png'
import Line_up from '../../assets/images/marketplace/Line_up.png'
import Pin from '../../assets/images/marketplace/Pin.png'
import Order_light from '../../assets/images/marketplace/Order_light.png'
import Question from '../../assets/images/marketplace/Question.png'
import Molecule from '../../assets/images/marketplace/Molecule.png'
import File_doc_add from '../../assets/images/marketplace/File_dock_add.png'
import { reraFields } from "../IssuerSuperAdmin/AddProperty/RERA";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { InfoCircleOutlined } from '@ant-design/icons';

const propertyAdminService = new PropertyAdminService();
const useUserContext = () => new AuthService().useUserContext();
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();

const stopHandler = (e) => { };

const PropertyDetails = () => {
  const { userInfo } = useUserContext();
  const [properties, setProperties] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { propertyId } = queryString.parse(location.search);
  const [data1, setData1] = useState([]);
  const [tabNo, setTabNo] = useState(1);
  const [offeringText, setOfferingText] = useState([]);
  const [offeringImages, setOfferingImages] = useState([]);
  const [displayInvestModel, setDisplayInvestModel] = useState(false);
  const [showDashboardOption, setShowDashboardOption] = useState(false);
  const history = useHistory();
  const [bankInfo, setBankInfo] = useState();
  const [feeManagement, setFeeManagement] = useState([{}]);

  const menus = [
    {tabNo:1,label:'Overview',icon: Widget_light,
        submenu:[]
    },
    {tabNo:2,label:'Sponsor',icon: group_share,
        submenu:[]
    },
    {tabNo:3,label:'Diligence Report',icon: Book_check,
        submenu:[]
    },
    {tabNo:4,label:'Financials',icon: Line_up,
        submenu:[]
    },
    {tabNo:5,label:'Location',icon: Pin,
        submenu:[]
    },
    {tabNo:6,label:'RERA',icon: Order_light,
        submenu:[]
    },
    {tabNo:7,label:'Documents',icon: Order_light,
        submenu:[]
    },
    {tabNo:8,label:'FAQs',icon: Question,
    submenu:[]
    },
    {tabNo:9,label:'Links',icon: Molecule,
    submenu:[]
    },
    {tabNo:10,label:'Due-Diligence',icon: File_doc_add,
    submenu:[]
    },
    {tabNo:11,label:'Management Fee',icon: Order_light,
    submenu:[]
    },

]

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties() {
      setLoading(true);
      if (propertyId && propertyId.length > 0) {
        const propertyDetails = await propertyAdminService.getPropertyById(
          propertyId
        );
        if (
          propertyDetails &&
          propertyDetails.data &&
          propertyDetails.data.length > 0
        ) {
          setPropertyDetails(propertyDetails.data[0]);
          console.log(propertyDetails.data[0]);
          let ab = propertyDetails.data[0];
          setData1([
            propertyDetails.data[0].targetedInvestorIrr,
            propertyDetails.data[0].targetedSumContribution,
            propertyDetails.data[0].periodOfSale,
            "Initial",
            "31 Dec, 2021",
            propertyDetails.data[0]?.nameOfToken,
            propertyDetails.data[0].tokenSymbol,
            propertyDetails.data[0].typeOfToken,
            propertyDetails.data[0].tokenStandard,
          ]);
          setOfferingText([
            ab.distributionStrategy,
            ab.financials,
            ab.sources,
            ab.organizationalChart,
            ab.fees,
          ]);
          setOfferingImages([
            ab.distributionStrategyFile?.URL,
            ab.financialsFile?.URL,
            ab.sourcesFile?.URL,
            ab.organizationalChartFile?.URL,
            ab.feesFile?.URL,
          ]);
        }
      }
      setLoading(false);
    })();
  }, []);

  const images = [
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
  ];

  const convertToMillion = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
          : Math.abs(Number(labelValue));
  };

  const warning = () => {
    Modal.warning({
      title: 'KYC Incomplete',
      content: (
        <div>
          <p>Please complete your KYC first. </p>
        </div>
      ),
      onOk() {
        history.push("/investor/dashboard");
      },
    });
  };

  const investNow = () => {

    if (!isUserLoggedIn) {
        return history.push("account/register/investor");
    }

    switch (userKycStatus) {
        case "approved":
          setDisplayInvestModel(true); 
            break;
        case "pending":
            warning(); 
            break;
        default:
            break;
    }
};

  const cancelInvestModel = () => {
    setDisplayInvestModel(false);
  }

  useEffect(() => {
    getIssuerDueDiligenceByCompanyId(null);
  }, [propertyDetails]);

  const [dueDiligence, setDueDiligence] = useState([{}]);


  const getIssuerDueDiligenceByCompanyId = async (companyId) => {
    const companyIdDueDiligence = propertyDetails.companyId;
    const response = await sharedService.getIssuerDueDiligenceByCompanyId(
      companyIdDueDiligence
    );

    if (response.data) {
      //if investor is logged in the get the private duediligence
      let responsePrivate = []
      if (userInfo?._id) {
        responsePrivate = await sharedService.getPrivateIssuerDueDiligenceByCompanyId(companyIdDueDiligence);
        setDueDiligence([...response.data, ...responsePrivate.data]); //merge private data
        setShowDashboardOption(true);
        return;
      }
      setDueDiligence(response.data); //show only public data
    }
  };

  const renderURL = (object) => {
    const newObject = object[0]?.URL;
    return newObject;
  };
  const renderName = (object) => {
    const newObject = object[0]?.name;
    return newObject;
  };
  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => value,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (value) =>
        typeof value != "object" ? (
          value
        ) : (
          <span>{<a target="_blank" style={{ textDecoration: "underline" }} href={renderURL(value)}>{renderName(value)}</a>}</span>
        ),
    },
  ];

  const selectedMenuAction = (index) =>{
    setTabNo(index);
  }

  const companyId =  userInfo?.company?.id;

  const isUserLoggedIn = !!userInfo;
  const userKycStatus =  userInfo?.status?.kyc;
  const buttonDisable = isUserLoggedIn ? (userKycStatus === "approved") : false;


  useEffect(()=>{
    (async () => {
      setLoading(true);

      const _bankInfo = await issuerSuperAdminService.getBankInfoByCompanyId(companyId)
      console.log('bank info ',_bankInfo)
      if(_bankInfo.success && _bankInfo.data?._id){
        setBankInfo(_bankInfo.data);
        // setIsEditionMode(true);
      }else{
        setBankInfo({})
      }

      setLoading(false);
    })();
  },[companyId])


   const companyData = {
  bankData:{
  IFSCCode: bankInfo?.IFSCCode,
  accountHolder: bankInfo?.accountHolder,
  accountNo: bankInfo?.accountNo,
  apiKey: bankInfo?.apiKey,
  bankName: bankInfo?.bankName,
  branchName: bankInfo?.branchName,
  companyId: bankInfo?.companyId,
  creationTS: bankInfo?.creationTS,
  },
 pricePerToken:bankInfo?.tokenTypeAndDetails?.pricePerToken,
    }

    useEffect(()=>{
      (async () => {
        setLoading(true);
        const feeManagementData = await issuerSuperAdminService.getIssuerManagementFees(propertyDetails?.companyId)
        if(feeManagementData.success && feeManagementData.data?._id){
          setFeeManagement(feeManagementData.data);
        }else{
          setFeeManagement({})
        }
  
        setLoading(false);
      })();
    },[propertyDetails?.companyId])

  return (
    <div className="wd_scroll_wrap overflow-y-hidden">
      {showDashboardOption ? <span style={{ cursor: "pointer", display: "flex", margin: "20px 0 0 20px", width: "min-content", border: "1px solid dodgerblue", padding: "4px 12px", borderRadius: "14px" }} onClick={() => history.push("/investor/dashboard")}><span style={{ fontSize: "38px", color: "dodgerblue" }}>⬅</span><span style={{ fontSize: "19px", color: "dodgerblue", fontWeight: "600" }}>Dashboard</span></span> : ""}

      <InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel} companyData={companyData}>
      </InvestModel>

      {loading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <>
          {!propertyDetails && (
            <h3 className="no-property">No Propery Found! Please try again.</h3>
          )}
          {propertyDetails && (
            <>
              <div className="container pd-wrap" style={{ marginTop: '10rem' }}>
                <div class="bannerWrapper " style={{ position: 'relative' }}>
                  <Banner isHomePage={false} imgArr={propertyDetails['propertyImages']?.map(img => img.URL)} size={'700px'} />

                  <div class="propertyinfoWrapper d-flex justify-content-center">
                    <div class="propertyInfoBar container col-11">
                      <div className="row" style={{ lineHeight: 'normal',padding:'5px' }}>
                        <div className="col-md-2 u-info">
                          <span className="text-primary">{propertyDetails.propertyName}</span>
                          <div class="d-flex align-items-center gap-3">
                            <img src={union} alt="" height='12px'></img>
                            <span className="u-info-sub">{propertyDetails.propertyCity}{propertyDetails.propertyState ? ", " + propertyDetails.propertyState : ''}</span>
                          </div>
                        </div>
                        {propertyDetails.propertyType !== 'residential' ?
                        (<div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={waterfall} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.targetedInvestorIRR}%</span><span className="u-info-sub">IRR</span></div>
                        </div>)
                        :
                        (propertyDetails.carpetArea && (<div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={waterfall} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.carpetArea}</span><span className="u-info-sub">Carpet Area(Sq.ft)</span></div>
                        </div>))
                        }
                        
                        {propertyDetails.propertyType !== 'residential' ?
                          (<div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={lineup} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.preferredReturn}%</span><span className="u-info-sub">Preferred Returns</span></div>
                        </div>)
                        :
                        (propertyDetails.builtUpArea &&(
                          <div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={lineup} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.builtUpArea}</span><span className="u-info-sub">Built-up Area (Sq.ft)</span></div>
                        </div>
                      ))
                        }
                        {(propertyDetails.propertyType == 'residential' && propertyDetails.pricePerSqFT) &&
                         (<div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={currency} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.pricePerSqFT}</span><span className="u-info-sub">Price Per (Sq.ft)</span></div>
                        </div>) 
                        }
                        
                        {propertyDetails.totalInvestmentTarget && 
                        <div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={currency} alt=""></img>
                          </div>
                          <div class="u-info"><span>{sharedService.formatIndianCurrency(propertyDetails.totalInvestmentTarget)}</span><span className="u-info-sub">Investment Target</span></div>
                        </div>}
                        
                        {(propertyDetails.propertyType !== 'residential' && propertyDetails.locationMsa) && 
                          <div className="col d-flex align-items-center gap-3">
                          <div className="u-info-icon">
                            <img src={union} alt=""></img>
                          </div>
                          <div class="u-info"><span>{propertyDetails.locationMsa}</span> <span className="u-info-sub">Location</span></div>
                        </div>}
                        {(userInfo?.role === "investor" || !isUserLoggedIn) && (
                        <div className={`col-12 col-md-2 investBtn ${!buttonDisable && isUserLoggedIn ? 'disabled' : ''}`} onClick={investNow} style={{ cursor: 'pointer'  }} >
                        <button style={{ width: '100%', height: '100%', border: 'none', background: 'transparent',color: 'inherit',font: 'inherit',cursor: 'inherit'}} >
                        Invest Now
                        </button> </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="mt-5" style={{color:'#F3BC03',borderBottom:'4px solid black',display:'inline-block'}}>{propertyDetails?.propertyType?.toUpperCase()}  {propertyDetails.commercialOption ? ' - '+ propertyDetails.commercialOption?.toUpperCase() :""} </h2>
                <div class="container">
                  <div className="row targets g-3">
                    <div className="col col-12 ">
                      <ul className="propertyDetailsCardInfo">
                        {propertyDetails.pricePerSqFT && <li className="flexItem">
                          <span className="f-label">Price Per(Sq.ft)</span>
                          <span className="f-value">
                            {sharedService.formatIndianCurrency(propertyDetails.pricePerSqFT)}
                          </span>
                        </li>}
                        {propertyDetails.carpetArea && <li className="flexItem">
                          <span className="f-label">Carpet Area(Sq.ft)</span>
                          <span className="f-value">
                            {propertyDetails.carpetArea}
                          </span>
                        </li>}
                        {propertyDetails.builtUpArea && <li className="flexItem">
                          <span className="f-label">Built-up Area(Sq.ft)</span>
                          <span className="f-value">
                            {propertyDetails.builtUpArea}
                          </span>
                        </li>}
                        {propertyDetails.landmark && <li className="flexItem">
                          <span className="f-label">Landmark</span>
                          <span className="f-value">
                            {propertyDetails.landmark}
                          </span>
                        </li>}
                        {propertyDetails.propertyCountry && <li className="flexItem">
                          <span className="f-label">Country</span>
                          <span className="f-value">
                            {propertyDetails.propertyCountry}
                          </span>
                        </li>}
                        {propertyDetails.state && <li className="flexItem">
                          <span className="f-label">State</span>
                          <span className="f-value">
                            {propertyDetails.state}
                          </span>
                        </li>}
                        {propertyDetails.propertyCity && <li className="flexItem">
                          <span className="f-label">City</span>
                          <span className="f-value">
                            {propertyDetails.propertyCity}
                          </span>
                        </li>}
                        {propertyDetails.propertyZipCode && <li className="flexItem">
                          <span className="f-label">PIN Code</span>
                          <span className="f-value">
                            {propertyDetails.propertyZipCode}
                          </span>
                        </li>}
                        

                        {propertyDetails.targetedInvestorIRR && <li className="flexItem">
                          <span className="f-label">IRR</span>
                          <span className="f-value">
                            {propertyDetails.targetedInvestorIRR}%
                          </span>
                        </li>}
                        {propertyDetails.targetedEquityMultiple && <li className="flexItem">
                          <span className="f-label">Equity Multiple</span>
                          <span className="f-value">
                            {propertyDetails.targetedEquityMultiple}x
                          </span>
                        </li>}
                        {propertyDetails.yearOneCashOnCash && <li className="flexItem">
                          <span className="f-label">Year 1 Cash on Cash</span>
                          <span className="f-value">
                            {propertyDetails.yearOneCashOnCash}%
                          </span>
                        </li>}
                        {propertyDetails.stabilizedCashOnCash && <li className="flexItem">
                          <span className="f-label">Stabilized Cash on Cash</span>
                          <span className="f-value">
                            {propertyDetails.stabilizedCashOnCash}%
                          </span>
                        </li>}
                        {propertyDetails.coInvestment && propertyDetails.totalInvestmentTarget && <li className="flexItem">
                          <span className="f-label">Co-Investment</span>
                          <span className="f-value">
                            {parseInt(
                              (propertyDetails.coInvestment /
                                propertyDetails.totalInvestmentTarget) *
                              100
                            )}
                            % ({convertToMillion(propertyDetails.coInvestment)}
                            )
                          </span>
                        </li>}
                        {propertyDetails.preferredReturn && <li className="flexItem">
                          <span className="f-label">Preferred Return</span>
                          <span className="f-value">
                            {propertyDetails.preferredReturn}%
                          </span>
                        </li>}
                        {propertyDetails.investorProfitShare && <li className="flexItem">
                          <span className="f-label">Investor Profit Share</span>
                          <span className="f-value">
                            {propertyDetails.investorProfitShare}%
                          </span>
                        </li>}
                        {propertyDetails.holdPeriod && <li className="flexItem">
                          <span className="f-label">Hold Period</span>
                          <span className="f-value">
                            {propertyDetails.holdPeriod}Y
                          </span>
                        </li>}
                        {propertyDetails.minimumInvestment && <li className="flexItem">
                          <span className="f-label">Minimum Investment</span>
                          <span className="f-value">
                            {environment.currencySign} {sharedService.formatIndianCurrency(propertyDetails.minimumInvestment)}
                          </span>
                        </li>}
                        {propertyDetails.firstDistribution && <li className="flexItem">
                          <span className="f-label">First Distribution</span>
                          <span className="f-value">
                          ₹{sharedService.formatIndianCurrency(propertyDetails.firstDistribution)}
                          </span>
                        </li>}

                        {propertyDetails.distributionFrequency && <li className="flexItem">
                          <span className="f-label">
                            Distribution Frequency
                          </span>
                          <span className="f-value">
                            {propertyDetails.distributionFrequency}
                          </span>
                        </li>}
                        {propertyDetails.assetProfile && <li className="flexItem">
                          <span className="f-label">Asset Profile</span>
                          <span className="f-value">
                            {propertyDetails.assetProfile}
                          </span>
                        </li>}
                        {propertyDetails.loanToValue && <li className="flexItem">
                          <span className="f-label">Loan-to-Value</span>
                          <span className="f-value">
                            {propertyDetails.loanToValue}%
                          </span>
                        </li>}
                        {propertyDetails.currentOccupancy && <li className="flexItem">
                          <span className="f-label">Current Occupancy</span>
                          <span className="f-value">
                            {propertyDetails.currentOccupancy}%
                          </span>
                        </li>}
                        {propertyDetails.totalInvestmentTarget && <li className="flexItem">
                          <span className="f-label">Total Investment Target</span>
                          <span className="f-value">
                          ₹ {sharedService.formatIndianCurrency(propertyDetails.totalInvestmentTarget)}
                          </span>
                        </li>}
                        {propertyDetails.projectedFundingDeadline && <li className="flexItem">
                          <span className="f-label">Funding Deadline</span>
                          <span className="f-value">
                            {new Date(propertyDetails.projectedFundingDeadline).toLocaleString()}
                          </span>
                        </li>}
                        {propertyDetails.coInvestment && <li className="flexItem">
                          <span className="f-label">Co-Investment</span>
                          <span className="f-value">
                            {propertyDetails.coInvestment}
                          </span>
                        </li>}
                        {propertyDetails.targetedEquityMultiple && <li className="flexItem">
                          <span className="f-label">Avg Cash Yeild</span>
                          <span className="f-value">
                            {propertyDetails.targetedEquityMultiple}%
                          </span>
                        </li>}
                        {propertyDetails.targetedInvestmentPeriod && <li className="flexItem">
                          <span className="f-label">Investment Period</span>
                          <span className="f-value">
                            {propertyDetails.targetedInvestmentPeriod}
                          </span>
                        </li>}
                        {propertyDetails.tokenIssuance && <li className="flexItem">
                          <span className="f-label">Token Issuance</span>
                          <span className="f-value">
                            {propertyDetails.tokenIssuance}
                          </span>
                        </li>}

                        {propertyDetails.locationMsa && <li className="flexItem">
                          <span className="f-label">Location | MSA</span>
                          <span className="f-value">
                            {propertyDetails.locationMsa}
                          </span>
                        </li>}
                        {propertyDetails.propertyType && <li className="flexItem">
                          <span className="f-label">Property Type</span>
                          <span className="f-value">
                            {propertyDetails.propertyType[0]?.toUpperCase() + propertyDetails.propertyType?.slice(1) } {propertyDetails.commercialOption ? ' - '+ propertyDetails.commercialOption[0]?.toUpperCase() + propertyDetails.commercialOption?.slice(1) :""}
                          </span>
                        </li>}
                        {propertyDetails.periodOfSale && <li className="flexItem">
                          <span className="f-label">Period of Sale</span>
                          <span className="f-value">
                            {propertyDetails.periodOfSale}
                          </span>
                        </li>}
                        {propertyDetails.typeOfToken && <li className="flexItem">
                          <span className="f-label">Type Of Token</span>
                          <span className="f-value">
                            {propertyDetails.typeOfToken}
                          </span>
                        </li>}
                        {propertyDetails.tokenProperties && <li className="flexItem">
                          <span className="f-label">Token Properties</span>
                          <span className="f-value">
                            {propertyDetails.tokenProperties}
                          </span>
                        </li>}
                        {propertyDetails.minimumSumContribution && <li className="flexItem">
                          <span className="f-label">Minimum Sum of Contributions</span>
                          <span className="f-value">
                          ₹{sharedService.formatIndianCurrency(propertyDetails.minimumSumContribution)}
                          </span>
                        </li>}
                        {propertyDetails.targetedSumContribution && <li className="flexItem">
                          <span className="f-label">Targeted Sum of Contributions</span>
                          <span className="f-value">
                          ₹{sharedService.formatIndianCurrency(propertyDetails.targetedSumContribution)}
                          </span>
                        </li>}
                        {propertyDetails.acceptedCurrencies && <li className="flexItem">
                          <span className="f-label">Accepted Currencies</span>
                          <span className="f-value">
                            {propertyDetails.acceptedCurrencies}
                          </span>
                        </li>}
                        {propertyDetails.tokenStandard && <li className="flexItem">
                          <span className="f-label">Token Symbol</span>
                          <span className="f-value">
                            {propertyDetails.tokenStandard}
                          </span>
                        </li>}
                      </ul>

                    </div>

                  </div>
                </div>
              </div>

              <div className="container text-center about-property d-flex justify-content-center flex-column align-items-center mb-5" style={{marginTop:'65px'}}>
                  <h2 className="mb-3 text-dark">About This Property</h2>
                  <span className="text-dark">{propertyDetails.descriptionOfProperty}</span>          
              </div>

              <div className="container">
                


                {/* multiple tabs section */}
                <MarketPlaceHeaderMenu view='propertyDetails' menus={menus.filter(x => propertyDetails.propertyType === 'residential' ? x.label !='Financials' : x  )} selectedMenu={selectedMenuAction}/>

                <div
                  className="tabs-details-wrapper my-5 "
                  style={{ textAlign: "center" }}
                >
                  {/* Summary Section */}
                  {tabNo == 1 && (
                    <>
                      <div className="summary row" style={{ padding: "0 20px" }}>
                       
                        {propertyDetails?.offeringVideoFile &&
                          propertyDetails?.offeringVideoFile[0] && (
                            <div
                              className="video col-12 col-md-6"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="animate__animated animate__fadeInUp animate__slower  animate__delay-1s"
                                style={{ width: "700px" }}
                              >
                                <VideoPlayer
                                  style={{ height: "700px", width: "500px" }}
                                  linkSrc={`${propertyDetails?.offeringVideoFile[0]?.URL}`}
                                  playerId="player-1"
                                  coverId="cover-2"
                                  playButtonId="play-1"
                                  subsButtonId="subs-1"
                                />
                              </div>
                            </div>
                          )}

                        <div
                          className="row-striped-wrapper text-dark col-12 col-md-6"
                        >
                          {propertyDetails.propertyName &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Name</span>
                            <span className="right-sec">
                              {propertyDetails.propertyName}
                            </span>
                          </div>}
                          {propertyDetails.propertyTitle &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Title</span>
                            <span className="right-sec">
                              {propertyDetails.propertyTitle}
                            </span>
                          </div>}
                          
                          {propertyDetails.propertyAddress &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Address</span>
                            <span className="right-sec">
                              {propertyDetails.propertyAddress}
                            </span>
                          </div>}
                          {propertyDetails.landmark &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Landmark</span>
                            <span className="right-sec">
                              {propertyDetails.landmark}
                            </span>
                          </div>}
                          {propertyDetails.propertyCountry &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Property Country</span>
                            <span className="right-sec">
                              {propertyDetails.propertyCountry}
                            </span>
                          </div>}
                          {propertyDetails.state &&
                            <div className="row-striped col-12">
                            <span className="left-sec">State</span>
                            <span className="right-sec">
                              {propertyDetails.state}
                            </span>
                          </div>}
                          {propertyDetails.propertyCity &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Property City</span>
                            <span className="right-sec">
                              {propertyDetails.propertyCity}
                            </span>
                          </div>}
                          {propertyDetails.propertyZipCode &&
                            <div className="row-striped col-12">
                            <span className="left-sec">PIN Code</span>
                            <span className="right-sec">
                              {propertyDetails.propertyZipCode}
                            </span>
                          </div>}

                          {propertyDetails.pricePerSqFT &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Price Per Sq FT</span>
                            <span className="right-sec">
                              {propertyDetails.pricePerSqFT}
                            </span>
                          </div>}
                          {propertyDetails.carpetArea &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Carpet Area</span>
                            <span className="right-sec">
                              {propertyDetails.carpetArea}
                            </span>
                          </div>}
                          {propertyDetails.builtUpArea &&
                            <div className="row-striped col-12">
                            <span className="left-sec">BuiltUp Area</span>
                            <span className="right-sec">
                              {propertyDetails.builtUpArea}
                            </span>
                          </div>}
                          {propertyDetails.propertyAge &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Property Age</span>
                            <span className="right-sec">
                              {propertyDetails.propertyAge}
                            </span>
                          </div>}
                          
                          
                          {propertyDetails.suarefootage &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Square Footage</span>
                            <span className="right-sec">
                              {propertyDetails.suarefootage} sq. ft.
                            </span>
                          </div>}
                          {propertyDetails.numberOfUnits &&
                            <div className="row-striped col-12">
                            <span className="left-sec">No of Units</span>
                            <span className="right-sec">
                              {propertyDetails.numberOfUnits}
                            </span>
                          </div>}
                          {propertyDetails.yearBuilt &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Year Built</span>
                            <span className="right-sec">
                              {propertyDetails.yearBuilt} years
                            </span>
                          </div>}
                          {propertyDetails.yearRenovated &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Year Renovated</span>
                            <span className="right-sec">
                              {propertyDetails.yearRenovated || "N/A"}
                            </span>
                          </div>}
                          {propertyDetails.currentOccupancy &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Current Occupancy</span>
                            <span className="right-sec">
                              {propertyDetails.currentOccupancy}%
                            </span>
                          </div>}
                          {propertyDetails.marketOccupancy &&
                            <div className="row-striped col-12">
                            <span className="left-sec">Market Occupancy</span>
                            <span className="right-sec">
                              {/* {">"} */}
                              {propertyDetails.marketOccupancy}%
                            </span>
                          </div>}
                          {propertyDetails.currentAverageRent &&
                            <div className="row-striped col-12">
                            <span className="left-sec">
                              Current Average Rents
                            </span>
                            <span className="right-sec">
                              ${propertyDetails.currentAverageRent}
                            </span>
                          </div>}
                          {propertyDetails.averageMarketRent &&
                            <div className="row-striped col-12">
                            <span className="left-sec">
                              Average Market Rents
                            </span>
                            <span className="right-sec">
                              {propertyDetails.averageMarketRent || "N/A"}
                            </span>
                          </div>}
                          {propertyDetails.purchasedPrice &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Purchase Price</span>
                            <span className="right-sec">
                              {environment.currencySign}{sharedService.formatIndianCurrency(propertyDetails.purchasedPrice)}
                            </span>
                          </div>}
                          
                          {propertyDetails.purchasedPrice && propertyDetails.suarefootage &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Price/Sq. Ft.</span>
                            <span className="right-sec">
                              $
                              {(
                                propertyDetails.purchasedPrice /
                                propertyDetails.suarefootage
                              )?.toFixed(2)}
                              /Sq. Ft.
                            </span>
                          </div>}
                          {propertyDetails.stabilizedLoanToValue &&
                            <div className="row-striped col-12">
                            <span className="left-sec">
                              Stabilized Loan to Value
                            </span>
                            <span className="right-sec">
                              {propertyDetails.stabilizedLoanToValue}%
                            </span>
                          </div>}
                          {propertyDetails.assetType &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Asset Type</span>
                            <span className="right-sec">{propertyDetails.assetType}</span>
                          </div>}
                          {propertyDetails.primaryPropertyType &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Primary Property Type</span>
                            <span className="right-sec">{propertyDetails.primaryPropertyType}</span>
                          </div>}
                          {propertyDetails.primarySubType && 
                          <div className="row-striped col-12">
                            <span className="left-sec">Primary Sub-Type</span>
                            <span className="right-sec">{propertyDetails.primarySubType}</span>
                          </div>}
                          {propertyDetails.buildingSize &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Building Size</span>
                            <span className="right-sec">{propertyDetails.buildingSize}</span>
                          </div>}
                          {propertyDetails.lotSize &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Lot Size (Acres)</span>
                            <span className="right-sec">{propertyDetails.lotSize}</span>
                          </div>}
                          {propertyDetails.parkingCount &&
                          <div className="row-striped col-12">
                            <span className="left-sec">Parking Count</span>
                            <span className="right-sec">{propertyDetails.parkingCount}</span>
                          </div>}
                        </div>
                      </div>
                    </>
                  )}

                  {/* sponsor or team members section */}
                  {tabNo == 2 && (
                    <>
                      {console.log(tabNo)}
                      <div className="contaier">
                        <div class="u-p-wrapper text-dark mt-3 mb-5">
                          <p
                            style={{ textAlign: "center",fontSize:'20px',fontWeight:'bold' }}
                            id="teamDetails"
                            className="col-md-12 propertyDetailsHeader"
                          >
                            Management Team Details
                          </p>
                          <p style={{ textAlign: "center" }}>
                            {propertyDetails?.descriptionOfManagementTeam}
                          </p>
                        </div>

                        <div class="row">
                          {propertyDetails.teamMembers &&
                            propertyDetails.teamMembers.map((img, index) => (
                              <div
                                className="col"
                                key={index}
                                
                              >
                                <h5 className="membertext text-start">
                                  Member {index + 1}
                                </h5>
                                <table className="table member-table">
                                  <tbody className="member-table-body">
                                    {propertyDetails?.teamMembers[index]
                                      ?.memberName !== null && (
                                        <tr>
                                          <th>Name</th>
                                          <td
                                            className="memberDetailValue"
                                            style={{ textAlign: "left" }}
                                          >
                                            {
                                              propertyDetails?.teamMembers[index]
                                                ?.memberName
                                            }
                                          </td>
                                        </tr>
                                      )}
                                    {propertyDetails?.teamMembers[index]
                                      ?.memberPhoto && propertyDetails?.teamMembers[index]
                                      ?.memberPhoto?.URL !== null && (
                                        <tr>
                                          <th>Photo</th>
                                          <td
                                            className="memberDetailValue"
                                            style={{ textAlign: "left" }}
                                          >
                                            <img
                                              style={{ height: "100px" }}
                                              src={
                                                propertyDetails?.teamMembers[index]
                                                  ?.memberPhoto?.URL
                                              }
                                              alt="Member Photo"
                                            />
                                          </td>
                                        </tr>
                                      )}
                                    {propertyDetails?.teamMembers[index]
                                      ?.memberContact !== null && (
                                        <>
                                          <tr>
                                            <th>Contact</th>
                                            <td
                                              className="memberDetailValue"
                                              style={{ textAlign: "justify" }}
                                            >
                                              {
                                                propertyDetails?.teamMembers[index]
                                                  ?.memberContact
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    {propertyDetails?.teamMembers[index]
                                      ?.memberRole !== null && (
                                        <>
                                          <tr>
                                            <th>Role</th>
                                            <td
                                              className="memberDetailValue"
                                              style={{ textAlign: "justify" }}
                                            >
                                              Designated Partner
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  )}

                  {/* diligence report Section */}
                  {tabNo == 3 && (
                    <>
                      <div className="summary text-dark">
                        <h4>{propertyDetails.diligenceReport}</h4>
                        <br />
                        {['jpg','png','jpeg'].includes(propertyDetails.diligenceReportFile.type) ? 
                        (<img
                          src={propertyDetails.diligenceReportFile?.URL}
                          alt="diligence report"
                          style={{height: "400px" }}
                        />)
                      :
                      (<><br /><iframe src={propertyDetails.diligenceReportFile?.URL} width="100%" height="500px"></iframe></>)
                      }
                        
                      </div>
                    </>
                  )}

                  {/* financial Section */}
                  {tabNo == 4 && (
                    <>
                      <div className="summary text-dark">
                        <h4>{propertyDetails.financials}</h4>
                        <br />
                        <div class="img-grid">
                          {propertyDetails.financialsFile?.map(file => (
                            file.type.split('/')[0]==='image' ? (<img
                            src={file?.URL}
                            alt="financials report"
                            style={{height: "300px" }}
                          />)
                        
                        :
                        <><br /><iframe src={file.URL} width="100%" height="500px"></iframe></>
                        ))}
                        </div>
                      </div>
                    </>
                  )}

                  {/* Location Section */}
                  {tabNo == 5 && (
                    <>
                      <div className="summary text-dark">
                        <h4>{propertyDetails.location}</h4>
                        <br />
                        <div class="img-grid">
                          {propertyDetails.locationFile?.map(file => (
                            file.type.split('/')[0]==='image' ? (<img
                            src={file?.URL}
                            alt="financials report"
                            style={{height: "300px" }}
                          />)
                        
                        :
                        <><br /><iframe src={file.URL} width="100%" height="500px"></iframe></>
                        ))}
                        </div>
                      </div>
                    </>
                  )}


                  {tabNo == 6 && (
                    <>
                    <Tabs   centered onChange={(e) => {console.log('umar',e);}}>
                        <Tabs.TabPane tab={<span>RERA Info</span>} key="rerainfo">
                        <div
                          className="row-striped-wrapper text-dark offset-md-2 col-12 col-md-8"
                        >
                          { reraFields.map(field => (
                            propertyDetails[field.value] && (
                            <div className="row-striped col-12">
                            <span className="left-sec">{field.label}</span>
                            <span className="right-sec">
                              {propertyDetails[field.value] ? propertyDetails[field.value] : 'NA' }
                            </span>
                            </div>)
                          
                          ))
                          }
                           
                        </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span>RERA Docs</span>} key="reradocs">
                          <div
                            className="col-sm-6 offset-sm-3"
                            style={{ paddingLeft: "20px" }}
                          >
                            <div className="card mb-5">
                              <div
                                className="card-header fw-bold text-dark text-center h5 p-3"
                                style={{ background: "#E0E0E0" }}
                              >
                                RERA Documents
                              </div>
                              <div
                                className="card-body"
                                style={{ background: "#F8F8F8" }}
                              >
                                <table className="table">
                                  <tbody>
                                    
                                    {propertyDetails?.reraDocs ?
                                      <>
                                      {
                                        propertyDetails?.reraDocs?.map(doc => (

                                        
                                        <tr>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          {doc[Object.keys(doc)]?.label}
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={doc[Object.keys(doc)]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </tr>

                                      ))
                                      }
                                      </>
                                      :
                                      <p>No Data available!</p>
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    </>
                  )}

                  {/* Document Section */}
                  {tabNo == 7 && (
                    <>
                      <div
                        className="col-sm-6 offset-sm-3"
                        style={{ paddingLeft: "20px" }}
                      >
                        <div className="card mb-5">
                          <div
                            className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}
                          >
                            DOCUMENTS
                          </div>
                          <div
                            className="card-body"
                            style={{ background: "#F8F8F8" }}
                          >
                            <table className="table">
                              <tbody>
                                {/* legal docuemnts starts */}
                                <tr>
                                  {propertyDetails.initialPartnershipAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Initial Partnership Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}  
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.initialPartnershipAgreement[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.modifiedPartnershipAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Modified Partnership Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.modifiedPartnershipAgreement                                                  [0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.disclaimer?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Disclaimer
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.disclaimer[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                               
                                {/* legal documents ends */}

                                {/* property documents starts */}
                                <tr>
                                  {propertyDetails.titleClearnaceCertificate?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Title Clearnace Certificate
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.titleClearnaceCertificate[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.purchaseAgreementOrSaleAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Purchase Agreement / Sale Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.purchaseAgreementOrSaleAgreement[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.mortgageOrLoanAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Mortgage or Loan Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.mortgageOrLoanAgreement[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.propertyDueDilligenceDocuments?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Property Due Dilligence Documents
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.propertyDueDilligenceDocuments[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.poa?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          POA
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.poa[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.leaseAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Lease Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.leaseAgreement[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.propertyManagementAgreements?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Property Management Agreements
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.propertyManagementAgreements[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.propertyAppraisalReport?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Property Appraisal Report
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.propertyAppraisalReport[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                {/* property documents ends */}


                                {/* financial documents starts */}
                                {propertyDetails.financialProjectPlan
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Financial Project Plan
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .financialProjectPlan?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .financialProjectPlan[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.projectPlan
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Project Plan
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .projectPlan?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .projectPlan[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.projectPlan
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Project Plan
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .projectPlan?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .projectPlan[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.whitepaper
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Whitepaper
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .whitepaper?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .whitepaper[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.operatingAndFinancials
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Operating and Financials
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .operatingAndFinancials?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .operatingAndFinancials[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.taxReturns
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Tax Returns
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .taxReturns?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .taxReturns[0]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}
                                {/* financial documents ends */}

                                {/* Other Documents starts */}
                                <tr>
                                  {propertyDetails.propertyManagementAgreements?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Business Registration
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={propertyDetails.propertyManagementAgreements[0]?.URL}
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                {propertyDetails.noObjectionCertificates
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          No Objection Certificates (NOCs)
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .noObjectionCertificates?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .noObjectionCertificates[
                                                      propertyDetails
                                                        .noObjectionCertificates
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}
                                {/* Other Documents ends */}


                                {/* RERA Documents starts */}
                                {propertyDetails.certificateOfExtensionOfRegistrationOfProject
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                        Certificate For Extension Of Registration Of Project (Form F)
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .certificateOfExtensionOfRegistrationOfProject?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .certificateOfExtensionOfRegistrationOfProject[
                                                      propertyDetails
                                                        .certificateOfExtensionOfRegistrationOfProject
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.registrationCertificateOfProject
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                        Registration Certificate Of Project (Form C)
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .registrationCertificateOfProject?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .registrationCertificateOfProject[
                                                      propertyDetails
                                                        .registrationCertificateOfProject
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}
                                {/* RERA Documents ends */}



                                {/* <tr>
                                  {propertyDetails.llpAgreement?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          LLP Agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={
                                                  propertyDetails
                                                    .llpAgreement[
                                                    propertyDetails
                                                      .llpAgreement
                                                      .length - 1
                                                  ]?.URL
                                                }
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                
                                <tr>
                                  {propertyDetails.propertyTitleDocument?.length >
                                    0 && (
                                      <>
                                        <th
                                          className="text-start"
                                          style={{ minWidth: "150px" }}
                                        >
                                          Property Title Document
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {
                                              <a
                                                className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                style={{ background: "#149EC3" }}
                                                target="_blank"
                                                href={
                                                  propertyDetails
                                                    .propertyTitleDocument[
                                                    propertyDetails
                                                      .propertyTitleDocument
                                                      .length - 1
                                                  ]?.URL
                                                }
                                              >
                                                DOWNLOAD
                                              </a>
                                            }
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.encumbranceCertificate
                                    ?.length > 0 && (
                                      <>
                                        <th className="text-start">
                                          Encumbrance Certificate
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .encumbranceCertificate?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .encumbranceCertificate[
                                                      propertyDetails
                                                        .encumbranceCertificate
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                <tr>
                                  {propertyDetails.titleSearchReport
                                    ?.length > 0 && (
                                      <>
                                        <th className="text-start">
                                          Title Search Report
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.titleSearchReport
                                              ?.length > 0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .titleSearchReport[
                                                      propertyDetails
                                                        .titleSearchReport
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </>
                                    )}
                                </tr>
                                {propertyDetails.possessionCertificate
                                  ?.length > 0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Possession Certificate
                                          <br />

                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails
                                              .possessionCertificate?.length >
                                              0 && (
                                                <a
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  style={{ background: "#149EC3" }}
                                                  target="_blank"
                                                  href={
                                                    propertyDetails
                                                      .possessionCertificate[
                                                      propertyDetails
                                                        .possessionCertificate
                                                        .length - 1
                                                    ]?.URL
                                                  }
                                                >
                                                  DOWNLOAD
                                                </a>
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                               

                                {propertyDetails.extractDocument?.length >
                                  0 && propertyDetails?.assetType === 'Land' && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          7/12 Extract Document
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.extractDocument
                                              ?.length > 0 &&
                                              propertyDetails.extractDocument.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.saleDeed?.length >
                                  0 && propertyDetails?.assetType === 'Land' && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Sale deed
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.saleDeed
                                              ?.length > 0 &&
                                              propertyDetails.saleDeed.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.saleAgreement?.length >
                                  0 && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Sale agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.saleAgreement
                                              ?.length > 0 &&
                                              propertyDetails.saleAgreement.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.titleDeed?.length >
                                  0 && propertyDetails?.assetType === 'Land' && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Title deed
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.titleDeed
                                              ?.length > 0 &&
                                              propertyDetails.titleDeed.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.completionCertificate?.length >
                                  0 && propertyDetails?.assetType === 'Land' && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Completion certificate
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.completionCertificate
                                              ?.length > 0 &&
                                              propertyDetails.completionCertificate.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.businessRegistration?.length >
                                  0 && propertyDetails?.assetType === 'Retail' && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Business registration
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.businessRegistration
                                              ?.length > 0 &&
                                              propertyDetails.businessRegistration.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.leaseAgreement?.length >
                                  0 && (propertyDetails?.assetType === 'Shop' || propertyDetails?.assetType === 'Office') && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Lease agreement
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.leaseAgreement
                                              ?.length > 0 &&
                                              propertyDetails.leaseAgreement.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )}

                                {propertyDetails.businessIncorporationCertificate?.length >
                                  0 && (propertyDetails?.assetType === 'Shop' || propertyDetails?.assetType === 'Office') && (
                                    <>
                                      <tr>
                                        <th className="text-start">
                                          Business incorporation certificate
                                          <br />
                                        </th>
                                        {userInfo?._id && (
                                          <td style={{ textAlign: "right" }}>
                                            {" "}
                                            {propertyDetails.businessIncorporationCertificate
                                              ?.length > 0 &&
                                              propertyDetails.businessIncorporationCertificate.map(
                                                (doc, index) => (
                                                  <a
                                                    key={index}
                                                    className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                    target="_blank"
                                                    style={{
                                                      borderRadius: "20px",
                                                      marginBottom: "10px",
                                                      background: "#149EC3",
                                                    }}
                                                    href={doc?.URL}
                                                  >
                                                    DOWNLOAD
                                                  </a>
                                                )
                                              )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  )} */}

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* FAQ Section */}
                  {tabNo == 8 && (
                    <>
                      <div className="summary text-dark">
                        <h4>{propertyDetails.FAQs}</h4>
                        <br />
                        {propertyDetails.FAQsFile?.URL && (
                          <img
                            src={propertyDetails.FAQsFile?.URL}
                            alt="Location report"
                            style={{ width: "500px", height: "300px" }}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {/* Link Section */}
                  {tabNo == 9 && (
                    <>
                      <div className="summary">
                        <br />
                        {propertyDetails?.facebook != null ? <span>
                          <span>Facebook: </span> <u><a target="_blank"
                            href={propertyDetails?.facebook}
                          >{propertyDetails?.facebook}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.linkedin != null ? <span>
                          <span>Linkedin: </span> <u><a target="_blank"
                            href={propertyDetails?.linkedin}
                          >{propertyDetails?.linkedin}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.twitter != null ? <span>
                          <span>Twitter: </span> <u><a target="_blank"
                            href={propertyDetails?.twitter}
                          >{propertyDetails?.twitter}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.otherLinks != null ? <span>
                          <span>Other links: </span> <u><a target="_blank"
                            href={propertyDetails?.otherLinks}
                          >{propertyDetails?.otherLinks}</a></u>
                        </span> : ""}
                      </div>
                    </>
                  )}

                  {/* Due-Diligence Section */}
                  {tabNo == 10 && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={dueDiligence}
                        rowKey="_id"
                        pagination={false}
                      />
                    </>
                  )}

                   {/*Fee Management Section */}
                  {tabNo == 11 && (
                    <>
                    <div className="col-sm-7 offset-sm-3"  >
                      <div className="card mb-5">
                        <div className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}> Management Fee  </div>
                          <div className="card-body" style={{ background: "#F8F8F8" }}>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                      LLP Maintenance Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.monthlyFees)}
                                      </span>
                                    }
                                     </td> 
                                    )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Acquisition Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.acquisitionFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Deposition Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.depositionFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Asset Management Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.assetManagementFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Compliance Training &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.complianceTrainingFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Commission on Property Sales &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.commissionOnPropertySales)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Monthly Marketplace Fees &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.monthlyMarketplaceFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Marketplace Management KYC Fees per Person &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹ {sharedService.formatIndianCurrency(feeManagement?.marketplaceManagementKYCFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    User KYC Fees per Person &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                        ₹ {sharedService.formatIndianCurrency(feeManagement?.userKYCFee)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Yearly Fees to DP (Designated Partner) &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLP.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       ₹  {sharedService.formatIndianCurrency(feeManagement?.yearlyFeeDP)}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PropertyDetails;
