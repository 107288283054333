import React, { useEffect, useState } from 'react';
import { Empty, Divider, Card, Row, Table, Progress ,Statistic , Button, Col, Spin, notification} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import { Link,useHistory } from 'react-router-dom';
import InvestModel from '../../Shared/InvestModel/InvestModel';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import eversign from "./Eversign";

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const issuerSuperAdminService = new IssuerSuperAdminService();
const investorService = new InvestorService();
const colorConfiguration = {
    textAlign: 'left',
    color: '#1890ff',
    fontSize: '15px',
    fontWeight: 'bold',
}

export default () => {
    const history = useHistory();
    const { userInfo, setUserInfo } = useUserContext();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [displayInvestModel, setDisplayInvestModel] = useState(false);
    const [investedTokens, setInvestedTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [investmentCompanyId, setInvestmentCompanyId] = useState('');
    const [selectedCompanyData, setSelectedCompanyData] =useState('');
    const [walletAddressPresent] = useState(userInfo && userInfo.walletAddress ? userInfo.walletAddress : '');
    const [partnershipAgreementSigned, setPartnershipAgreementSigned] = useState(false);
    const [openEversign, setOpenEversign] = useState(false);
    const [signURL, setSignURL] = useState(null);
    const [tokenDataSource, setTokenDataSource] = useState([]);
    const [showEscrowPayment, setShowEscrowPayment] = useState(false);
    const [paymentOptionsView, setPaymentOptionsView] = useState(false);
    const [filter, setFilter] = useState({ size: 5, page: 1 });
    const [totalItems, setTotalItems] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    const columns = [
        {
        title: 'Type of Security',
        dataIndex: 'typeOfSecurity',
        key: 'typeOfSecurity',
        width: 200,
        },
        {
        title: 'Issuer Company',
        dataIndex: 'issuer',
        key: 'issuer',
        },
        {
        title: 'Token Address',
        dataIndex: 'deployedSmartContractAddress',
        key: 'tokenid',
        },
        {
        title: 'Token Count',
        dataIndex: 'tokenCount',
        key: 'tokenCount',
        },
        {
        title: 'Total Investors',
        dataIndex: 'totalInvestors',
        key: 'totalInvestors',
        },
        {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        },
        {
        title: 'Lock Period',
        dataIndex: 'lockPeriod',
        key: 'lockPeriod',
        },
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        },
        {
        title: 'Price Per Token',
        dataIndex: 'pricePerToken',
        key: 'pricePerToken',
        },
  ];


  const fetchInvestedTokens = async (page, size) => {
    try {
      setTableLoading(true);
      const investorId = userInfo._id;
      const result = await investorService.getAllInvestedTokenDetailsByInvestorId(investorId,page,size);
      if (result.success && result.data) {
          const tokensData = result.data.map(tokenDetails => {
            return {
              key: tokenDetails._id,
              issuer: tokenDetails.companyDetails?.name,
              tokenCount: tokenDetails.tokenCount,
              totalInvestors: tokenDetails.totalInvestors,
              description: tokenDetails.description,
              lockPeriod: tokenDetails.lockPeriod,
              status: tokenDetails.status,
              pricePerToken: tokenDetails.pricePerToken,
              typeOfSecurity: tokenDetails.typeOfSecurity,
              tokenid: tokenDetails?.tokenTypeAndDetailsId,
              deployedSmartContractAddress: tokenDetails?.deployedSmartContractAddress,
              creationDate: new Date(tokenDetails.creationTS),
            };
          });
          tokensData.sort((a, b) => b.creationDate - a.creationDate);
          setInvestedTokens(tokensData);
          setTotalItems(result.data[0]?.totalCount || 0);
        }
        setTableLoading(false);
        setIsLoading(false);
    } catch (error) {
      console.error('Error fetching invested tokens:', error);
      setTableLoading(false);
      setIsLoading(false);
    }
};
useEffect(() => {
  if (userInfo) {
    fetchInvestedTokens(filter.page, filter.size);
    getAllAvailableTokensForInvestor();
}
}, [userInfo, filter.page, filter.size]);

const handleInvestModelSubmit = () => {
  fetchInvestedTokens();
};

const getAllAvailableTokensForInvestor = async () => {
try{
  const results = await investorService.getAllAvailableTokensForInvestor();
  if(results?.data?.length > 0) {
    let tempTokenDataSource = results.data.map(token => {
      if(token.bankInfo?.status === 'approved') {
        return {
          key: token._id,
          companyname: token.companyDetails.name  ? token.companyDetails.name : 'NA',
          companyId: token.companyId,
          tokenid: token._id,
          propertyId: token?.propertyDetails?._id,
          tokendate:new Date(token.creationTS).toLocaleString(),
          status: token.status ? token.status :'',
          isTokenSaleClosed: token.hasOwnProperty('isTokenSaleClosed') && token.isTokenSaleClosed ? true : false,
          pricePerToken: token?.pricePerToken,
          bankData:token?.bankData,
          projectedFundingDeadline: new Date(token?.propertyDetails?.projectedFundingDeadline).toLocaleString(),
          bankData: token.bankInfo,
          deployedSmartContractAddress: token?.deployedSmartContracts?.deployedSmartContractAddress,
          creationDate: new Date(token.creationTS),
        };
      }
    });

    tempTokenDataSource = tempTokenDataSource.filter((dt) => dt?.key)
    tempTokenDataSource.sort((a, b) => b.creationDate - a.creationDate);
    setTokenDataSource(tempTokenDataSource);
  }
  setIsLoading(false);
  } catch (error) {
        console.error('Error fetching available tokens:', error);
         setIsLoading(false);
    }
};

  useEffect(() => {
  if (userInfo) {
    fetchInvestedTokens(filter.page, filter.size);
    getAllAvailableTokensForInvestor();
  }

  return () => {
    setTokenDataSource([]);
  };
}, [userInfo]);



  useEffect(() => {
    (async () => {
        const result = await new InvestorService().getPayments({ investorId: userInfo._id });
        if (result.success && result.data) {
            setPaymentDetails(result.data.filter(t => t.accept === true));
        }else{
        }
    })();

  }, [userInfo._id]);
    
  const openBankPaymentForm = () => {
    setPaymentOptionsView(false);
    setPartnershipAgreementSigned(true);
    setDisplayInvestModel(true);
    setOpenEversign(false);
  }

  const openPaymentOptionsView = async (companyId) => {
    if(companyId) {
      setInvestmentCompanyId(companyId);
      const esrowDetails = await investorService.getCompanyTokenDetailsByCompanyId(companyId);
      if(esrowDetails?.success && esrowDetails?.data?.escrowPayment?.paymentGatewayAPIKey?.length > 0) {
        setPaymentOptionsView(true);
      } else {
        setPaymentOptionsView(false);
        openBankPaymentForm();
      }
    }
  }

  const clickedInvestButton = async (record, data) => {
    setIsLoading(true);
    setInvestmentCompanyId(record.companyId);
    setSelectedCompanyData(data);

    //if investor is of type PUBLIC, then assign assing company and redirct to kyc
    if(userInfo?.investorType?.toLocaleLowerCase() ==='public' && userInfo?.status?.kyc !== 'approved'){
      if(userInfo?.company?.id){
        window.location.href='/investor/kyc-status';
        return;
      }
      console.log('public user assingin company')
      assignCompanyToInvestor(record);
      return
    }
    //here he is public user and company not assigned then first call the company assign api and then redirect to kyc page and dont let the below agreement open for him.

    // now opening the partnership agreement agreement to sign it, once it is signed  ccesfully we will open the investment modal.
    // check if already signed then proceed to next step
    try {
      const res = await investorService.getPartnershipAgreementSigned({investorId: userInfo?._id, investmentCompanyId: record.companyId });

      if(res.success && res.data?._id?.length > 0) {
        console.log('next step')
        openPaymentOptionsView(record.companyId);
        await fetchInvestedTokens(filter.page, filter.size);
      } else {
        const response = await investorService.getPartnershipAgreementURL(userInfo);
        if(!response.success) {
          notification.open({
            message: 'Error',
            description: response.error.code,
            duration: 0,
          });
          setIsLoading(false);
          return false;
        }

        setIsLoading(false);
        setSignURL(response.data);
        setOpenEversign(true);
      }
    } catch (error) {
      notification.open({
        message: 'Error',
        description: error,
        duration: 0,
      });
    }
    
    setIsLoading(false);
  };

  async function assignCompanyToInvestor(record) {
    try {
      setIsLoading(true);
      const response = await investorService.updatePublicInvestorWithCompanyDetails(record.companyId,{investorId:userInfo?._id});
        if(!response.success) {
          notification.open({
            message: 'Error',
            description: response.error.code,
            duration: 0,
          });
          setIsLoading(false);
          return;
        }
        notification.open({
          message: 'Success',
          description: 'Success',
          duration: 0,
        });
        
        setTimeout(() => {
          window.location.href='/investor/kyc-status';
        }, 500);
    } catch (error) {
      notification.open({
        message: 'Error',
        description: error,
        duration: 0,
      });
    }
    setIsLoading(false);
  }


  const tokenColumns = [
      {
        title: 'Company Name',
        dataIndex: 'companyname',
        key: 'companyname',
      },
      {
        title: 'Token Address',
        dataIndex: "deployedSmartContractAddress",
        key: 'deployedSmartContractAddress',
      },
      {
        title: 'Token Creation Date',
        dataIndex: 'tokendate',
        key: 'tokendate',
      },
      // {
      //   title: 'Token Sale End Date',
      //   dataIndex: 'projectedFundingDeadline',
      //   key: 'projectedFundingDeadline',
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Property link',
        key: 'propertylink',
        render: (record) => {
            return (
              <>
                  <Link to={`/property-details?propertyId=${record.propertyId}`}><Button type="link">Link</Button></Link>
              </>
            );
          },
      },

      
      {
        title: 'Invest ',
        key: 'invest',
        render: (record,data) => {
            return (
              <>
                  {record.isTokenSaleClosed ? 'CLOSED': ((<Button
                    type="link"
                    onClick={() => clickedInvestButton(record, data)}
                    onSubmit={handleInvestModelSubmit}
                  >
                    Invest
                  </Button>))}
              </>
            );
          },
      },
  ];

  const cancelInvestModel = ()=>{
    setDisplayInvestModel(false);
  }

  const onSign = async () => {
    setPartnershipAgreementSigned(true);
    const response = await investorService.setPartnershipAgreementSigned({
      investorId: userInfo?._id,
      investmentCompanyId: investmentCompanyId,
      signedDocumentUrl: signURL,
    });
    if (response.success && response.data) {
      openPaymentOptionsView(investmentCompanyId);
      fetchInvestedTokens(filter.page, filter.size);
    }
  };


    return (
        <>
        {isLoading && <Spin size='large' style={{textAlign: 'center'}} />}
        {!isLoading && (<>
          {!openEversign && displayInvestModel && !paymentOptionsView && (<InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel} companyData={selectedCompanyData} investmentCompanyId={investmentCompanyId}></InvestModel>)}

          {/* Payment Options View */}
          {!openEversign && !displayInvestModel && paymentOptionsView && (
          
            <div className='row col-12' style={{display: 'flex', justifyContent: 'center', margin: '50px 0'}}>
              <Button
                type="primary"
                className='col-4'
                onClick={openBankPaymentForm}
                style={{height: '40px'}}
              >
                Payment using Manual Bank Transfer
              </Button>
              <Button
                type="default"
                className='col-4 btn btn-info'
                onClick={() => history.push(`/investor/escrow/${investmentCompanyId}`)}
                style={{height: '40px', marginLeft: '50px'}}
              >
                Payment using USDC/USDT Currency
              </Button>
            </div>
          )}

          {(!openEversign && !walletAddressPresent)  && (
          
          
            <Card >
              <div className="noWallet" style={{textAlign:'center'}}>
                <h3>Wallet Address Not Found</h3>
                <Button
                  type="link"
                  onClick={() => history.push('/connect-wallet')}
                >
                  Connet Wallet
                </Button>
              </div>
            </Card>
          
          )}

          {/* {!openEversign && (walletAddressPresent && walletAddressPresent.length > 0 || userInfo?.investorType === 'public') && !paymentOptionsView &&  */}
          {walletAddressPresent && !openEversign && (walletAddressPresent.length > 0 || userInfo?.investorType === 'public') && !paymentOptionsView && (
          <>
          <Row>
            <Col span={23}>
              <Table dataSource={tokenDataSource} loading={isLoading} columns={tokenColumns} scroll={{x: true}} pagination={{ pageSize: 5}} />
            </Col>
          </Row>
          <Row>
            <br></br>
          </Row>
          <Row>
            <Card title={
              <Divider orientation="left" style={colorConfiguration}> Invested Tokens </Divider>
            } bordered={true} style={{ width: "100%" }}>
               {!isLoading ? (  
              <Table dataSource={investedTokens} columns={columns} scroll={{x: true}} 
              pagination={{ 
                current: filter.page,
                pageSize: filter.size,
                total: totalItems,
                onChange: (page, pageSize) => {
                    setFilter({ page, size: pageSize });
                    fetchInvestedTokens(page, pageSize);
                },
              }}
              loading={tableLoading} 
              />
              ) : (
                <Empty description="No invested tokens found" />
              )}
            </Card>
          </Row>
          </>
           )}

          {(!openEversign && !walletAddressPresent) && userInfo?.investorType !== 'public'  && (
          
          
            <Card >
              <div className="noWallet" style={{textAlign:'center'}}>
                <h3>Wallet Address Not Found</h3>
                <Button
                  type="link"
                  onClick={() => history.push('/connect-wallet')}
                >
                  Connect Wallet
                </Button>
              </div>
            </Card>
            )}
          

          {/* Partnership Agreement View Starts Here */}
          <div hidden={partnershipAgreementSigned} id="embedSignDivision">
          {openEversign &&
            eversign.open({
              url: signURL,
              containerID: "embedSignDivision",
              width: 750,
              height: 800,
              events: {
                loaded: () => console.log("loading successful"),
                signed: onSign,
                error: () => console.log("EVERSIGN ERROR"),
                declined: () => console.log("declined"),
              },
            })}
          </div>
          {/* Partnership Agreement View ends here */}

          <Row>
              <br></br>
          </Row>
        </>
        )}
        </>
    )
}