import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  message,
  notification,
  Progress,
  Spin,
  Space,
  Modal
} from "antd";
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import './AddDocument.scss';
import { environment } from "../../../environments/environment";
import  {Erc1643} from"../../Shared/ERC1643/ERC1643.service"
import { MetamaskService } from '../../Shared/Metamask.service';
import TransactionModal from '../../Shared/TransactionModal';
import { AuthService } from "../../Shared/Auth.service";
const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const erc1643 = new Erc1643();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const useUserContext = () => new AuthService().useUserContext();

export default function AddDocuments(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [editView, setEditView] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [mergedFileLink, setMergedFileLink] = useState('');
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userInfo } = useUserContext();

  useEffect(() => {
    console.log('props.propertyInfo: ', props.propertyInfo);
    if(props.documentsDone){
      setSendForApprovalVisibility(true)
      console.log('status ', props.propertyInfo.status)
      setPropertyInfoStatus(props.propertyInfo.status ?? '')
    }
  }, [])
  const [propertyFiles, setPropertyFiles] = useState(
    {
      // llpAgreement: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      initialPartnershipAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      modifiedPartnershipAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      disclaimer: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },

      propertyDueDilligenceDocuments: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      propertyManagementAgreements: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      leaseAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      mortgageOrLoanAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      purchaseAgreementOrSaleAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      titleClearnaceCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      propertyAppraisalReport: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      encumbranceCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      // whitePaperFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      titleSearchReport: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      possessionCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      noObjectionCertificates: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      extractDocument: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      saleDeed: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      saleAgreement: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      titleDeed: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      completionCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      businessRegistration: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      businessIncorporationCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      CertificationFromNpoFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      GoverningDocumentFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      UniformListingAgreementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      propertyTitleDocument: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      otherDocumentsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      taxReturns: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      operatingAndFinancials: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      whitepaper: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      projectPlan: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      financialProjectPlan: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      noObejctionCertificate: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      poa: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      registrationCertificateOfProject: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      certificateOfExtensionOfRegistrationOfProject: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      // minInvestmentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // }
      // titleClearanceFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // titleDocumentsFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // floorPlanFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // mortgageDeedsFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // leanDocumentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // constructionTimelineFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // constructionBudgetFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // otherDocumentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
    }
  );
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 14,
        offset: 10
      }
    }
  };

  const [uploadFiles, setUploadFiles] = useState(props.propertyInfo);
  const [encumbranceCertificate, setPrivatePlacementFile] = useState(props.propertyInfo?.encumbranceCertificate || "");
  const [titleSearchReport, setSubscriptionAgreementFile] = useState(props.propertyInfo?.titleSearchReport || "");
  const [possessionCertificate, setFinancialProjectionsFile] = useState(props.propertyInfo?.possessionCertificate || "");
  const [noObjectionCertificates, setOperatingAgreementFile] = useState(props.propertyInfo?.noObjectionCertificates || "");
  const [extractDocument, setPropertyDetailsFile] = useState(props.propertyInfo?.extractDocument || "");
  const [saleDeed, setDraftFundFile] = useState(props.propertyInfo?.saleDeed || "");
 
  const [
    titleDeed,
    settitleDeed,
  ] = useState(props.propertyInfo?.titleDeed ? props.propertyInfo?.titleDeed : "");
  const [
    completionCertificate,
    setcompletionCertificate,
  ] = useState(props.propertyInfo?.completionCertificate ? props.propertyInfo?.completionCertificate : "");
  
  const [
    businessIncorporationCertificate,
    setbusinessIncorporationCertificate,
  ] = useState(props.propertyInfo?.businessIncorporationCertificate ? props.propertyInfo?.businessIncorporationCertificate : "");
  const [CertificationFromNpoFile, setCertificationFromNpoFile] = useState(
    props.propertyInfo?.CertificationFromNpoFile ? props.propertyInfo?.CertificationFromNpoFile : ""
  );
  const [GoverningDocumentFile, setGoverningDocumentFile] = useState(
    props.propertyInfo?.GoverningDocumentFile ?  props.propertyInfo?.GoverningDocumentFile : ""
  );
  const [UniformListingAgreementFile, setUniformListingAgreementFile] =
    useState(props.propertyInfo?.UniformListingAgreementFile ? props.propertyInfo?.UniformListingAgreementFile : "");
  
    const [propertyTitleDocument, setpropertyTitleDocument] =
    useState(props.propertyInfo?.propertyTitleDocument ? props.propertyInfo?.propertyTitleDocument : "");

    // const [llpAgreement, setllpAgreement] =
    // useState(props.propertyInfo?.llpAgreement ? props.propertyInfo?.llpAgreement : "");

      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      const [modifiedPartnershipAgreement, setModifiedPartnershipAgreement] =
      useState(props.propertyInfo?.modifiedPartnershipAgreement ? props.propertyInfo?.modifiedPartnershipAgreement : "");
      const [initialPartnershipAgreement, setInitialPartnershipAgreement] =
      useState(props.propertyInfo?.initialPartnershipAgreement ? props.propertyInfo?.initialPartnershipAgreement : "");
      const [disclaimer, setDisclaimer] =
      useState(props.propertyInfo?.disclaimer ? props.propertyInfo?.disclaimer : "");

      // PROPERTY DOCS:
      const [propertyDueDilligenceDocuments, setPropertyDueDilligenceDocuments] =
      useState(props.propertyInfo?.modifiedPartnershipAgreement ? props.propertyInfo?.modifiedPartnershipAgreement : "");
      const [propertyManagementAgreements, setPropertyManagementAgreements] =
      useState(props.propertyInfo?.initialPartnershipAgreement ? props.propertyInfo?.initialPartnershipAgreement : "");
      const [leaseAgreement, setleaseAgreement] =
      useState(props.propertyInfo?.disclaimer ? props.propertyInfo?.disclaimer : "");
      const [mortgageOrLoanAgreement, setMortgageOrLoanAgreement] =
      useState(props.propertyInfo?.modifiedPartnershipAgreement ? props.propertyInfo?.modifiedPartnershipAgreement : "");
      const [purchaseAgreementOrSaleAgreement, setPurchaseAgreementOrSaleAgreement] =
      useState(props.propertyInfo?.initialPartnershipAgreement ? props.propertyInfo?.initialPartnershipAgreement : "");
      const [titleClearanceCertificate, setTitleClearanceCertificate] =
      useState(props.propertyInfo?.disclaimer ? props.propertyInfo?.disclaimer : "");
      const [propertyAppraisalReport, setPropertyAppraisalReport] =
      useState(props.propertyInfo?.disclaimer ? props.propertyInfo?.disclaimer : "");

      //FINANCIAL DOCS:
      const [taxReturns, setTaxReturns] =
      useState(props.propertyInfo?.modifiedPartnershipAg?.initialPareement ? props.propertyInfo?.modifiedPartnershipAgreement : "");
      const [operatingAndFinancials, setOperatingAndFinancials] =
      useState(props.propertyInfortnershipAgreement ? props.propertyInfo?.initialPartnershipAgreement : "");
      const [whitepaper, setWhitepaper] =
      useState(props.propertyInfo?.disclaimer ? props.propertyInfo?.disclaimer : "");
      const [projectPlan, setProjectPlan] =
      useState(props.propertyInfo?.projectPlan ? props.propertyInfo?.projectPlan : "");
      const [financialProjectPlan, setFinancialProjectPlan] =
      useState(props.propertyInfo?.financialProjectPlan ? props.propertyInfo?.financialProjectPlan : "");

      //OTHER DOCS
      const [otherDocumentsFile, setOtherDocumentsFile] = useState(props.propertyInfo?.otherDocumentsFile || "")
      const [noObejctionCertificate, setNoObejctionCertificate] =
      useState(props.propertyInfo?.noObejctionCertificate ? props.propertyInfo?.noObejctionCertificate : "");
      const [businessRegistration, setBusinessRegistration] =
      useState(props.propertyInfo?.businessRegistration ? props.propertyInfo?.businessRegistration : "");
      const [saleAgreement, setSaleAgreement] =
      useState(props.propertyInfo?.saleAgreement ? props.propertyInfo?.saleAgreement : "");
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      const [poa, setPoa] = useState(props.propertyInfo?.poa ? props.propertyInfo?.poa : "");
      const [registrationCertificateOfProject, setRegistrationCertificateOfProject] = useState(props.propertyInfo?.registrationCertificateOfProject ? props.propertyInfo?.registrationCertificateOfProject : "");
      const [certificateOfExtensionOfRegistrationOfProject, setCertificateOfExtensionOfRegistrationOfProject] = useState(props.propertyInfo?.certificateOfExtensionOfRegistrationOfProject ? props.propertyInfo?.certificateOfExtensionOfRegistrationOfProject : "");

    const [sendForApprovalVisibility, setSendForApprovalVisibility] = useState(false);
    const [propertyInfoStatus, setPropertyInfoStatus] = useState('');

  // multi upload screen
  const multiUploadFile = async (e, name) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);
    if (!enableEdit) setEnableEdit(true);

    switch (name) {
      case 'private':
        {
          let prev = encumbranceCertificate;
          setPrivatePlacementFile([...prev, { URL: null }]);
          try {
            setPrivatePlacementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPrivatePlacementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPrivatePlacementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'subscription':
        {
          let prev = titleSearchReport;
          setSubscriptionAgreementFile([...prev, { URL: null }]);
          try {
            setSubscriptionAgreementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setSubscriptionAgreementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setSubscriptionAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'financial':
        {
          let prev = possessionCertificate ? possessionCertificate : [];
          setFinancialProjectionsFile([...prev, { URL: null }]);
          try {
            setFinancialProjectionsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setFinancialProjectionsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setFinancialProjectionsFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'operating':
        {
          let prev = noObjectionCertificates;
          setOperatingAgreementFile([...prev, { URL: null }]);
          try {
            setOperatingAgreementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setOperatingAgreementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setOperatingAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'property':
        {
          let prev = extractDocument;
          setPropertyDetailsFile([...prev, { URL: null }]);
          try {
            setPropertyDetailsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPropertyDetailsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPropertyDetailsFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'draftfund':
        {
          let prev = saleDeed;
          // setDraftFundFile([...prev, { URL: null }]);
          try {
            setDraftFundFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setDraftFundFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            // setDraftFundFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "titleDeed": {
          let prev = titleDeed;
          settitleDeed([...prev, { URL: null }]);
          try {
            settitleDeed([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            settitleDeed([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            settitleDeed([...prev, { uploading: false }]);
          }
          break;
        }
        case "completionCertificate": {
          let prev = completionCertificate;
          setcompletionCertificate([...prev, { URL: null }]);
          try {
            setcompletionCertificate([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setcompletionCertificate([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setcompletionCertificate([...prev, { uploading: false }]);
          }
          break;
        }
        case "leaseAgreement": {
          let prev = leaseAgreement;
          setleaseAgreement([...prev, { URL: null }]);
          try {
            setleaseAgreement([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setleaseAgreement([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setleaseAgreement([...prev, { uploading: false }]);
          }
          break;
        }
        case "businessIncorporationCertificate": {
          let prev = businessIncorporationCertificate;
          setbusinessIncorporationCertificate([...prev, { URL: null }]);
          try {
            setbusinessIncorporationCertificate([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setbusinessIncorporationCertificate([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setbusinessIncorporationCertificate([
              ...prev,
              { uploading: false },
            ]);
          }
          break;
        }
        case "CertificationFromNpoFile": {
          let prev = CertificationFromNpoFile;
          setCertificationFromNpoFile([...prev, { URL: null }]);
          try {
            setCertificationFromNpoFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setCertificationFromNpoFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setCertificationFromNpoFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "GoverningDocumentFile": {
          let prev = GoverningDocumentFile;
          setGoverningDocumentFile([...prev, { URL: null }]);
          try {
            setGoverningDocumentFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setGoverningDocumentFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setGoverningDocumentFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "UniformListingAgreementFile": {
          let prev = UniformListingAgreementFile;
          setUniformListingAgreementFile([...prev, { URL: null }]);
          try {
            setUniformListingAgreementFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setUniformListingAgreementFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setUniformListingAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "propertyTitleDocument": {
          let prev = propertyTitleDocument;
          setpropertyTitleDocument([...prev, { URL: null }]);
          try {
            setpropertyTitleDocument([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setpropertyTitleDocument([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setpropertyTitleDocument([...prev, { uploading: false }]);
          }
          break;
        }

        // case "llpAgreement": {
        //   let prev = llpAgreement;
        //   setllpAgreement([...prev, { URL: null }]);
        //   try {
        //     setllpAgreement([
        //       ...prev,
        //       { uploading: true, uploadFilePercentage: 10 },
        //     ]);
        //     const { hash } = await sharedService.uploadDocumentToIPFS({ file });
        //     const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        //     setllpAgreement([
        //       ...prev,
        //       {
        //         URL,
        //         uploading: false,
        //         uploadFilePercentage: 100,
        //         name: file.name,
        //         type: fileType.toLowerCase(),
        //       },
        //     ]);
        //     form2.setFieldsValue({ [name]: file.name });
        //   } catch (err) {
        //     console.error(err);
        //     setllpAgreement([...prev, { uploading: false }]);
        //   }
        //   break;
        // }
      case 'other':
        {
          let prev = otherDocumentsFile;
          setOtherDocumentsFile([...prev, { URL: null }]);
          try {
            setOtherDocumentsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setOtherDocumentsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setOtherDocumentsFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'noObejctionCertificate':
        {
          let prev = noObejctionCertificate;
          setNoObejctionCertificate([...prev, { URL: null }]);
          try {
            setNoObejctionCertificate([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setNoObejctionCertificate([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setNoObejctionCertificate([...prev, { uploading: false }]);
          }
          break;
        }
      case 'businessRegistration':
        {
          let prev = otherDocumentsFile;
          setBusinessRegistration([...prev, { URL: null }]);
          try {
            setBusinessRegistration([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setBusinessRegistration([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setBusinessRegistration([...prev, { uploading: false }]);
          }
          break;
        }
      case 'saleAgreement':
        {
          let prev = otherDocumentsFile;
          setSaleAgreement([...prev, { URL: null }]);
          try {
            setSaleAgreement([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setSaleAgreement([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setSaleAgreement([...prev, { uploading: false }]);
          }
          break;
        }
      case 'initialPartnershipAgreement':
        {
          let prev = initialPartnershipAgreement;
          setInitialPartnershipAgreement([...prev, { URL: null }]);
          try {
            setInitialPartnershipAgreement([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setInitialPartnershipAgreement([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setInitialPartnershipAgreement([...prev, { uploading: false }]);
          }
          break;
        }
      case 'modifiedPartnershipAgreement':
        {
          let prev = modifiedPartnershipAgreement;
          setModifiedPartnershipAgreement([...prev, { URL: null }]);
          try {
            setModifiedPartnershipAgreement([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setModifiedPartnershipAgreement([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setModifiedPartnershipAgreement([...prev, { uploading: false }]);
          }
          break;
        }
      case 'disclaimer':
        {
          let prev = disclaimer;
          setDisclaimer([...prev, { URL: null }]);
          try {
            setDisclaimer([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setDisclaimer([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setDisclaimer([...prev, { uploading: false }]);
          }
          break;
        }
      case 'propertyDueDilligenceDocuments':
        {
          let prev = propertyDueDilligenceDocuments;
          setPropertyDueDilligenceDocuments([...prev, { URL: null }]);
          try {
            setPropertyDueDilligenceDocuments([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPropertyDueDilligenceDocuments([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPropertyDueDilligenceDocuments([...prev, { uploading: false }]);
          }
          break;
        }
      case 'propertyManagementAgreements':
        {
          let prev = propertyManagementAgreements;
          setPropertyManagementAgreements([...prev, { URL: null }]);
          try {
            setPropertyManagementAgreements([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPropertyManagementAgreements([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPropertyManagementAgreements([...prev, { uploading: false }]);
          }
          break;
        }
      case 'mortgageOrLoanAgreement':
        {
          let prev = mortgageOrLoanAgreement;
          setMortgageOrLoanAgreement([...prev, { URL: null }]);
          try {
            setMortgageOrLoanAgreement([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setMortgageOrLoanAgreement([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setMortgageOrLoanAgreement([...prev, { uploading: false }]);
          }
          break;
        }
      case 'purchaseAgreementOrSaleAgreement':
        {
          let prev = purchaseAgreementOrSaleAgreement;
          setPurchaseAgreementOrSaleAgreement([...prev, { URL: null }]);
          try {
            setPurchaseAgreementOrSaleAgreement([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPurchaseAgreementOrSaleAgreement([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPurchaseAgreementOrSaleAgreement([...prev, { uploading: false }]);
          }
          break;
        }
      case 'titleClearnaceCertificate':
        {
          let prev = titleClearanceCertificate;
          setTitleClearanceCertificate([...prev, { URL: null }]);
          try {
            setTitleClearanceCertificate([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setTitleClearanceCertificate([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setTitleClearanceCertificate([...prev, { uploading: false }]);
          }
          break;
        }
      case 'propertyAppraisalReport':
        {
          let prev = propertyAppraisalReport;
          setPropertyAppraisalReport([...prev, { URL: null }]);
          try {
            setPropertyAppraisalReport([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPropertyAppraisalReport([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPropertyAppraisalReport([...prev, { uploading: false }]);
          }
          break;
        }
      case 'taxReturns':
        {
          let prev = taxReturns;
          setTaxReturns([...prev, { URL: null }]);
          try {
            setTaxReturns([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setTaxReturns([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setTaxReturns([...prev, { uploading: false }]);
          }
          break;
        }
      case 'operatingAndFinancials':
        {
          let prev = operatingAndFinancials;
          setOperatingAndFinancials([...prev, { URL: null }]);
          try {
            setOperatingAndFinancials([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setOperatingAndFinancials([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setOperatingAndFinancials([...prev, { uploading: false }]);
          }
          break;
        }
      case 'whitepaper':
        {
          let prev = whitepaper;
          setWhitepaper([...prev, { URL: null }]);
          try {
            setWhitepaper([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setWhitepaper([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setWhitepaper([...prev, { uploading: false }]);
          }
          break;
        }
      case 'projectPlan':
        {
          let prev = projectPlan;
          setProjectPlan([...prev, { URL: null }]);
          try {
            setProjectPlan([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setProjectPlan([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setProjectPlan([...prev, { uploading: false }]);
          }
          break;
        }
      case 'financialProjectPlan':
        {
          let prev = financialProjectPlan;
          setFinancialProjectPlan([...prev, { URL: null }]);
          try {
            setFinancialProjectPlan([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setFinancialProjectPlan([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setFinancialProjectPlan([...prev, { uploading: false }]);
          }
          break;
        }
      case 'poa':
        {
          let prev = poa;
          setPoa([...prev, { URL: null }]);
          try {
            setPoa([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPoa([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPoa([...prev, { uploading: false }]);
          }
          break;
        }
      case 'registrationCertificateOfProject':
        {
          let prev = registrationCertificateOfProject;
          setRegistrationCertificateOfProject([...prev, { URL: null }]);
          try {
            setRegistrationCertificateOfProject([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setRegistrationCertificateOfProject([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setRegistrationCertificateOfProject([...prev, { uploading: false }]);
          }
          break;
        }
      case 'certificateOfExtensionOfRegistrationOfProject':
        {
          let prev = certificateOfExtensionOfRegistrationOfProject;
          setCertificateOfExtensionOfRegistrationOfProject([...prev, { URL: null }]);
          try {
            setCertificateOfExtensionOfRegistrationOfProject([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setCertificateOfExtensionOfRegistrationOfProject([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setCertificateOfExtensionOfRegistrationOfProject([...prev, { uploading: false }]);
          }
          break;
        }
    }

  }

  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setPropertyFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

    try {
      setPropertyFiles((prev) => {
        return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
      });
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
      form.setFieldsValue({ [key]: file.name });
    } catch (err) {
      console.error(err);
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
    }

  }

  const updateUploadedDocuments = async (_form2) => {
    const form2 = { ..._form2 };
    console.log('update  ',_form2)
    setLoading(true);

    // form2.propertyTitleDocument = propertyTitleDocument.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    // form2.llpAgreement = llpAgreement.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.initialPartnershipAgreement = initialPartnershipAgreement && initialPartnershipAgreement?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.modifiedPartnershipAgreement = modifiedPartnershipAgreement && modifiedPartnershipAgreement?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.disclaimer = disclaimer && disclaimer?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    //property
    form2.propertyDueDilligenceDocuments = propertyDueDilligenceDocuments && propertyDueDilligenceDocuments?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.propertyManagementAgreements = propertyManagementAgreements && propertyManagementAgreements?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.mortgageOrLoanAgreement = mortgageOrLoanAgreement && mortgageOrLoanAgreement?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.purchaseAgreementOrSaleAgreement = purchaseAgreementOrSaleAgreement && purchaseAgreementOrSaleAgreement?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.titleClearnaceCertificate = titleClearanceCertificate && titleClearanceCertificate?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.propertyAppraisalReport = propertyAppraisalReport && propertyAppraisalReport?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    //financial
    if(props.propertyType === 'project'){
      form2.taxReturns = taxReturns && taxReturns?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
      form2.operatingAndFinancials = operatingAndFinancials && operatingAndFinancials?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
      form2.whitepaper = whitepaper && whitepaper?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
      form2.projectPlan = projectPlan && projectPlan?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
      form2.financialProjectPlan = financialProjectPlan && financialProjectPlan?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    }
    //other
    form2.otherDocumentsFile = otherDocumentsFile && otherDocumentsFile?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.noObejctionCertificate = noObejctionCertificate && noObejctionCertificate?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.businessRegistration = businessRegistration && businessRegistration?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.saleAgreement = saleAgreement && saleAgreement?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.poa = poa && poa?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.registrationCertificateOfProject = registrationCertificateOfProject && registrationCertificateOfProject?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.certificateOfExtensionOfRegistrationOfProject = certificateOfExtensionOfRegistrationOfProject && certificateOfExtensionOfRegistrationOfProject?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));

    // form2.encumbranceCertificate = encumbranceCertificate?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    // form2.titleSearchReport = titleSearchReport?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    // form2.possessionCertificate = possessionCertificate?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.noObjectionCertificates = noObjectionCertificates && noObjectionCertificates?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    // form2.extractDocument = extractDocument?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    // form2.saleDeed = saleDeed?.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
  // form2.titleDeed =
  //   titleDeed?.map((file) => ({
  //     URL: file.URL,
  //     name: file.name,
  //     type: file.type,
  //   }));
  // form2.completionCertificate =
  //   completionCertificate?.map((file) => ({
  //     URL: file.URL,
  //     name: file.name,
  //     type: file.type,
  //   }));
  form2.businessRegistration =
  businessRegistration && businessRegistration?.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.leaseAgreement = leaseAgreement && leaseAgreement?.map((file) => ({
    URL: file.URL,
    name: file.name,
    type: file.type,
  }));
  form2.businessIncorporationCertificate =
  businessIncorporationCertificate && businessIncorporationCertificate?.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));

    form2.userId = props.userInfo._id;
    form2.propertyDocuments = 'done';
    form2.stepDone = 4;

    const response = await issuerSuperAdminService.updateUploadedDocuments(form2);

    if (response.success) {
      history.push('/issuer-super-admin/add-property/basic-information');
      notification.success({ message: 'Success' });
    } else {
      message.error(response.error.message);
      notification.error({ message: 'Error', description: 'response.error.message' });
    }

    props.setUpdated(true);
    setLoading(false);
    //window.location.reload();
  }

  const submitUploadDocuments = async (_form) => {
    const form = { ..._form };
    setLoading(true);

    // form.propertyTitleDocument = propertyFiles.propertyTitleDocument?.URL ? [{
    //   name: propertyFiles.propertyTitleDocument?.name,
    //   URL: propertyFiles.propertyTitleDocument?.URL,
    //   type: propertyFiles.propertyTitleDocument.type,
    // }] : [];
    // form.llpAgreement = propertyFiles.llpAgreement?.URL ? [{
    //   name: propertyFiles.llpAgreement?.name,
    //   URL: propertyFiles.llpAgreement?.URL,
    //   type: propertyFiles.llpAgreement.type,
    // }] : [];
    form.initialPartnershipAgreement = propertyFiles.initialPartnershipAgreement?.URL ? [{
      name: propertyFiles.initialPartnershipAgreement?.name,
      URL: propertyFiles.initialPartnershipAgreement?.URL,
      type: propertyFiles.initialPartnershipAgreement.type,
    }] : [];
    form.modifiedPartnershipAgreement = propertyFiles.modifiedPartnershipAgreement?.URL ? [{
      name: propertyFiles.modifiedPartnershipAgreement?.name,
      URL: propertyFiles.modifiedPartnershipAgreement?.URL,
      type: propertyFiles.modifiedPartnershipAgreement.type,
    }] : [];
    form.disclaimer = propertyFiles.disclaimer?.URL ? [{
      name: propertyFiles.disclaimer?.name,
      URL: propertyFiles.disclaimer?.URL,
      type: propertyFiles.disclaimer.type,
    }] : [];
    form.propertyDueDilligenceDocuments = propertyFiles.propertyDueDilligenceDocuments?.URL ? [{
      name: propertyFiles.propertyDueDilligenceDocuments?.name,
      URL: propertyFiles.propertyDueDilligenceDocuments?.URL,
      type: propertyFiles.propertyDueDilligenceDocuments.type,
    }] : [];
    form.mortgageOrLoanAgreement = propertyFiles.mortgageOrLoanAgreement?.URL ? [{
      name: propertyFiles.mortgageOrLoanAgreement?.name,
      URL: propertyFiles.mortgageOrLoanAgreement?.URL,
      type: propertyFiles.mortgageOrLoanAgreement.type,
    }] : [];
    form.purchaseAgreementOrSaleAgreement = propertyFiles.purchaseAgreementOrSaleAgreement?.URL ? [{
      name: propertyFiles.purchaseAgreementOrSaleAgreement?.name,
      URL: propertyFiles.purchaseAgreementOrSaleAgreement?.URL,
      type: propertyFiles.purchaseAgreementOrSaleAgreement.type,
    }] : [];
    form.titleClearnaceCertificate = propertyFiles.titleClearnaceCertificate?.URL ? [{
      name: propertyFiles.titleClearnaceCertificate?.name,
      URL: propertyFiles.titleClearnaceCertificate?.URL,
      type: propertyFiles.titleClearnaceCertificate.type,
    }] : [];
    // form.encumbranceCertificate = propertyFiles.encumbranceCertificate?.URL ? [{
    //   name: propertyFiles.encumbranceCertificate?.name,
    //   URL: propertyFiles.encumbranceCertificate?.URL,
    //   type: propertyFiles.encumbranceCertificate.type,
    // }] : [];
    // form.titleSearchReport = propertyFiles.titleSearchReport?.URL ? [{
    //   name: propertyFiles.titleSearchReport?.name,
    //   URL: propertyFiles.titleSearchReport?.URL,
    //   type: propertyFiles.titleSearchReport.type,
    // }] : [];
    // form.possessionCertificate = propertyFiles.possessionCertificate?.URL ? [{
    //   name: propertyFiles.possessionCertificate?.name,
    //   URL: propertyFiles.possessionCertificate?.URL,
    //   type: propertyFiles.possessionCertificate.type,
    // }] : [];
    form.noObjectionCertificates = propertyFiles.noObjectionCertificates?.URL ? [{
      name: propertyFiles.noObjectionCertificates?.name,
      URL: propertyFiles.noObjectionCertificates?.URL,
      type: propertyFiles.noObjectionCertificates.type,
    }] : [];
    // form.extractDocument = propertyFiles.extractDocument?.URL ? [{
    //   name: propertyFiles.extractDocument?.name,
    //   URL: propertyFiles.extractDocument?.URL,
    //   type: propertyFiles.extractDocument.type,
    // }] : [];
    // form.saleDeed = propertyFiles.saleDeed?.URL ? [{
    //   name: propertyFiles.saleDeed?.name,
    //   URL: propertyFiles.saleDeed?.URL,
    //   type: propertyFiles.saleDeed.type,
    // }] : [];
    form.saleAgreement = propertyFiles
    .saleAgreement?.URL
    ? [
        {
          name: propertyFiles.saleAgreement?.name,
          URL: propertyFiles.saleAgreement?.URL,
          type: propertyFiles.saleAgreement.type,
        },
      ]
    : [];
    form.poa = propertyFiles
    .poa?.URL
    ? [
        {
          name: propertyFiles.poa?.name,
          URL: propertyFiles.poa?.URL,
          type: propertyFiles.poa.type,
        },
      ]
    : [];

    form.registrationCertificateOfProject = propertyFiles.registrationCertificateOfProject?.URL
    ? [
        {
          name: propertyFiles.registrationCertificateOfProject?.name,
          URL: propertyFiles.registrationCertificateOfProject?.URL,
          type: propertyFiles.registrationCertificateOfProject.type,
        },
      ]
    : [];
    form.certificateOfExtensionOfRegistrationOfProject = propertyFiles.certificateOfExtensionOfRegistrationOfProject?.URL
    ? [
        {
          name: propertyFiles.certificateOfExtensionOfRegistrationOfProject?.name,
          URL: propertyFiles.certificateOfExtensionOfRegistrationOfProject?.URL,
          type: propertyFiles.certificateOfExtensionOfRegistrationOfProject.type,
        },
      ]
    : [];
  // form.titleDeed = propertyFiles
  //   .titleDeed?.URL
  //   ? [
  //       {
  //         name: propertyFiles.titleDeed?.name,
  //         URL: propertyFiles.titleDeed?.URL,
  //         type: propertyFiles.titleDeed.type,
  //       },
  //     ]
  //   : [];
  form.completionCertificate = propertyFiles
    .completionCertificate?.URL
    ? [
        {
          name: propertyFiles.completionCertificate?.name,
          URL: propertyFiles.completionCertificate?.URL,
          type: propertyFiles.completionCertificate.type,
        },
      ]
    : [];
  form.businessRegistration = propertyFiles
    .businessRegistration?.URL
    ? [
        {
          name: propertyFiles.businessRegistration?.name,
          URL: propertyFiles.businessRegistration?.URL,
          type: propertyFiles.businessRegistration.type,
        },
      ]
    : [];
  form.leaseAgreement = propertyFiles.leaseAgreement?.URL
    ? [
        {
          name: propertyFiles.leaseAgreement?.name,
          URL: propertyFiles.leaseAgreement?.URL,
          type: propertyFiles.leaseAgreement.type,
        },
      ]
    : [];
  form.businessIncorporationCertificate = propertyFiles
    .businessIncorporationCertificate?.URL
    ? [
        {
          name: propertyFiles.businessIncorporationCertificate?.name,
          URL: propertyFiles.businessIncorporationCertificate?.URL,
          type: propertyFiles.businessIncorporationCertificate.type,
        },
      ]
    : [];

    form.userId = props.userInfo._id;
    form.companyId = props.userInfo?.company?.id;
    form.propertyDocuments = 'done';
    form.stepDone = 4;

    const response = await issuerSuperAdminService.savePropertyDocuments(form);

    if (response.success) {
      notification.success({ message: 'Success' });
      setSendForApprovalVisibility(true)
      history.push('/issuer-super-admin/add-property/basic-information')
    } else {
      message.error(response.error.message);
      notification.error({ message: 'Error', description: 'response.error.message' });
    }

    props.setUpdated(true);
    setLoading(false);
    window.location.reload();
  }

  const mergeFiles= async() =>{
    setLoading(true);
    console.log('api for merging files...')
    const response = await issuerSuperAdminService.mergePropertyDocuments({userid:props.userInfo._id});
    console.log(response)
    if(response.success && response.data.merged){
      setLoading(false)
      
      setMergedFileLink(response.data.file)
    }else{
      setLoading(false)
    }
  }

  const uploadMergedFile = async () => {
    try {
      setLoading(true);
      //download file and upload to ipfs
      
      if(!mergedFileLink){
        setLoading(false);
        notification.error({ message: 'Error', description: 'No file to upload' });
        return;
      }

      const resp = await fetch(mergedFileLink);
      const blob = await resp.blob();
      const fileobj = new Blob([blob], { type: "application/pdf" });
      const file = new File([fileobj], "mergedfile.pdf", {});

      console.log(blob);
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      console.log(URL);

      if(!hash){
        setLoading(false);
        notification.error({ message: 'Error', description: 'Error while uploading file' });
        return;
      }

      const data = {
        mergedFile: {
          name: mergedFileLink.split("/")[4],
          URL: URL,
          type: "application/pdf",
        },
        userId: props.userInfo._id,
      };

      try {
        setIsModalVisible(true);
        setTransactions([{details: 'Adding the combined document to blockchain', submitting: true}]);
        
        // blockchain call to add documents
        const receipt = await erc1643.setDocument(
          "0xD1237441f457A3d83279711bBBEE6eB8cB62d3C7", // contract address to add property
          selectedWallet as string, // owner to add document
          "0x00",  // name of the document as bytes32
          URL,  // url of the ipfs data as string
          "0x00" // hash of the document as string
          );

        setTransactions(prev => {
          const current = sharedService.clone(prev);
          current[0].receipt = receipt;
         return current;
       });    
        
      } catch (err) {
        console.error(err);
      }
  
      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].submitting = false;
        return current;
      });
      const res = await issuerSuperAdminService.updateUploadedDocuments(data);
      if(res.success) notification.success({ message: 'File uploaded successfully!' });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    
  };

  // const sendPropertyDetailsForApproval  = async()=>{
  //   try {
  //     if(!userInfo) return;

  //     setLoading(true);
  //     const res = await issuerSuperAdminService.sendPropertyDetailsForApprovalToMSA(userInfo?.company?.id);
  //     if(!res.success){
  //       notification.error({ message: 'Error', description: res?.error?.message })
  //       setLoading(false);
  //       return;
  //     }
  //     notification.success({ message: 'Success', description: 'Property details sent for approval successfully.'});
  //     setTimeout(()=> window.location.reload(), 1000);
  //     setLoading(false);

  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false);
  //   }
  // }


  return (
    <>
      {loading &&
        <div style={{ textAlign: 'center' }}>
          <br /><br />
          <Spin size='large' />
        </div>
      }
      {!loading && !props.documentsDone && (<Row justify="center">
        <Col span={20}>
          <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Offering Document</h2>
          <Form {...formItemLayout} labelAlign="left" form={form} initialValues={{ boardResolution: { option: 'upload' }, additionInfo: null }} onFinish={submitUploadDocuments}>
            <div>
              <Card>
              {/* <Form.Item
                    label="LLP Agreement"
                    name="llpAgreement"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "llpAgreement");
                        }}
                      />
                      {propertyFiles.llpAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.llpAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item> */}
              <Form.Item //initial partnership aggrement
                    label="Initial Partnership Agreement"
                    name="initialPartnershipAgreement"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "initialPartnershipAgreement");
                        }}
                      />
                      {propertyFiles.initialPartnershipAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.initialPartnershipAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                </Form.Item>
              <Form.Item //modified partnership aggrement
                    label="Modified Partnership Agreement"
                    name="modifiedPartnershipAgreement"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "modifiedPartnershipAgreement");
                        }}
                      />
                      {propertyFiles.modifiedPartnershipAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.modifiedPartnershipAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
              <Form.Item  //disclaimer
                    label="Disclaimer"
                    name="disclaimer"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "disclaimer");
                        }}
                      />
                      {propertyFiles.disclaimer
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.disclaimer
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item //Purchase Agreement / Sale Agreement
                    label="Purchase Agreement / Sale Agreement"
                    name="purchaseAgreementOrSaleAgreement"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "purchaseAgreementOrSaleAgreement");
                        }}
                      />
                      {propertyFiles.purchaseAgreementOrSaleAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.purchaseAgreementOrSaleAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item //title clearnace certificate
                    label="Title Clearnace Certificate"
                    name="titleClearnaceCertificate"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "titleClearnaceCertificate");
                        }}
                      />
                      {propertyFiles.titleClearnaceCertificate
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.titleClearnaceCertificate
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  { props.propertyType === 'residential' &&
                <>
                <Form.Item //REGISTRATION CERTIFICATE OF PROJECT (Form C)
                    label="Registration Certificate Of Project (Form C)"
                    name="registrationCertificateOfProject"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "registrationCertificateOfProject");
                        }}
                      />
                      {propertyFiles.registrationCertificateOfProject
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.registrationCertificateOfProject
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item //CERTIFICATE FOR EXTENSION OF REGISTRATION OF PROJECT (Form F)
                    label="Certificate For Extension Of Registration Of Project (Form F) :"
                    name="certificateOfExtensionOfRegistrationOfProject"
                    colon = {false}
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "certificateOfExtensionOfRegistrationOfProject");
                        }}
                      />
                      {propertyFiles.certificateOfExtensionOfRegistrationOfProject
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.certificateOfExtensionOfRegistrationOfProject
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  </>
                }


                  {/* <Form.Item //property title document
                    label="Property Title Document"
                    name="propertyTitleDocument"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "propertyTitleDocument");
                        }}
                      />
                      {propertyFiles.propertyTitleDocument
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.propertyTitleDocument
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item> */}
                {/* <Form.Item //encumbrance certificate
                  label="Encumbrance Certificate"
                  name='encumbranceCertificate'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'encumbranceCertificate') }} />
                    {propertyFiles.encumbranceCertificate.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.encumbranceCertificate.uploadFilePercentage} />
                    }
                  </>
                </Form.Item> */}
                {/* <Form.Item // titlesearch report
                  label="Title Search Report"
                  name='titleSearchReport'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'titleSearchReport') }} />
                    {propertyFiles.titleSearchReport.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.titleSearchReport.uploadFilePercentage} />
                    }
                  </>
                </Form.Item> */}

                {/* <Form.Item //possesstion certificate
                  label="Possession Certificate"
                  name='possessionCertificate'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'possessionCertificate') }} />
                    {propertyFiles.possessionCertificate.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.possessionCertificate.uploadFilePercentage} />
                    }
                  </>
                </Form.Item> */}
                

                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //extract document
                  label="7/12 Extract Document"
                  name='extractDocument'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'extractDocument') }} />
                    {propertyFiles.extractDocument.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.extractDocument.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>)} */}

                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //sale deed
                  label="Sale deed"
                  name='saleDeed'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'saleDeed') }} />
                    {propertyFiles.saleDeed.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.saleDeed.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>)} */}

                

                  {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item // title deed
                    label="Title Deed"
                    name="titleDeed"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "titleDeed");
                        }}
                      />
                      {propertyFiles.titleDeed
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.titleDeed
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)} */}

                  {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item // completion certificate
                    label="Completion certificate"
                    name="completionCertificate"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "completionCertificate");
                        }}
                      />
                      {propertyFiles.completionCertificate
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.completionCertificate
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)} */}

                  {(props?.propertyInfo?.assetType === 'Shop' 
                  || props?.propertyInfo?.assetType === 'Office') && (<Form.Item //lease agreement
                    label="Lease Agreement"
                    name="leaseAgreement"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "leaseAgreement");
                        }}
                      />
                      {propertyFiles.leaseAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.leaseAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}

                  {(props?.propertyInfo?.assetType === 'Shop' 
                  || props?.propertyInfo?.assetType === 'Office') && (<Form.Item //business incorporation certificate
                    label="Business Incorporation Certificate"
                    name="businessIncorporationCertificate"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "businessIncorporationCertificate");
                        }}
                      />
                      {propertyFiles.businessIncorporationCertificate
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.businessIncorporationCertificate
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}

                  {/* PROPERTY DOCS */}
                  <Form.Item //proprty duedeligence document
                    label="Property Due Dilligence Documents"
                    name="propertyDueDilligenceDocuments"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "propertyDueDilligenceDocuments");
                        }}
                      />
                      {propertyFiles.propertyDueDilligenceDocuments
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.propertyDueDilligenceDocuments
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  {['project','commercial'].includes(props.propertyType) &&<Form.Item // property management agreements
                    label="Property Management Agreements"
                    name="propertyManagementAgreements"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "propertyManagementAgreements");
                        }}
                      />
                      {propertyFiles.propertyManagementAgreements
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.propertyManagementAgreements
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>}
                  {(props?.propertyInfo?.assetType === 'Shop' 
                  || props?.propertyInfo?.assetType === 'Office'
                  || ['project','commercial'].includes(props.propertyType)) && (<Form.Item //lease agreement
                    label="Lease Agreement"
                    name="leaseAgreement"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "leaseAgreement");
                        }}
                      />
                      {propertyFiles.leaseAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.leaseAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}
                  <Form.Item //morgage or lone agreements
                    label="Mortgage Or Loan Agreement"
                    name="mortgageOrLoanAgreement"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "mortgageOrLoanAgreement");
                        }}
                      />
                      {propertyFiles.mortgageOrLoanAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.mortgageOrLoanAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  {'project' === props.propertyType &&<Form.Item //property apraisal report
                    label="Property Appraisal Report"
                    name="propertyAppraisalReport"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "propertyAppraisalReport");
                        }}
                      />
                      {propertyFiles.propertyAppraisalReport
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.propertyAppraisalReport
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>}

                {/* financial */}
                 {props.propertyType === 'project' && 
                 <>
                 <Form.Item //tax returns
                    label="Tax Returns"
                    name="taxReturns"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "taxReturns");
                        }}
                      />
                      {propertyFiles.taxReturns
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.taxReturns
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>         
                 <Form.Item //operating and financials
                    label="Operating And Financials"
                    name="operatingAndFinancials"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "operatingAndFinancials");
                        }}
                      />
                      {propertyFiles.operatingAndFinancials
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.operatingAndFinancials
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>         
                 <Form.Item //white paper
                    label="Whitepaper"
                    name="whitepaper"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "whitepaper");
                        }}
                      />
                      {propertyFiles.whitepaper
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.whitepaper
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>         
                 <Form.Item //project plan
                    label="Project Plan"
                    name="projectPlan"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "projectPlan");
                        }}
                      />
                      {propertyFiles.projectPlan
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.projectPlan
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>         
                 <Form.Item //financial project plan
                    label="Financial Project Plan"
                    name="financialProjectPlan"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "financialProjectPlan");
                        }}
                      />
                      {propertyFiles.financialProjectPlan
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.financialProjectPlan
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  </>
                  }

                  {/* other          */}
                  {['project','commercial'].includes(props.propertyType) &&<Form.Item //no objection certificate
                  label="No Objection Certificates (NOCs)"
                  name='noObjectionCertificates'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'noObjectionCertificates') }} />
                    {propertyFiles.noObjectionCertificates.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.noObjectionCertificates.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>}

                {(props?.propertyInfo?.assetType === 'Retail' 
                || ['project','commercial'].includes(props.propertyType) )&& (<Form.Item //business registration
                    label="Business Registration"
                    name="businessRegistration"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "businessRegistration");
                        }}
                      />
                      {propertyFiles.businessRegistration
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.businessRegistration
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}

                  {(false && props?.propertyInfo?.assetType === 'Land') && (<Form.Item //sale agreement
                    label="Sale Agreement"
                    name="saleAgreement"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "saleAgreement");
                        }}
                      />
                      {propertyFiles.saleAgreement
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.saleAgreement
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}

                  {props.propertyType === 'residential' && (<Form.Item //poa
                    label="POA"
                    name="poa"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "poa");
                        }}
                      />
                      {propertyFiles.poa
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.poa
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>)}
                  {/* <Form.Item
                    label="Undertaking/Certification from the NPO (As per Annexure I)"
                    name="CertificationFromNpoFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "CertificationFromNpoFile");
                        }}
                      />
                      {propertyFiles.CertificationFromNpoFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.CertificationFromNpoFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)"
                    name="GoverningDocumentFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "GoverningDocumentFile");
                        }}
                      />
                      {propertyFiles.GoverningDocumentFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.GoverningDocumentFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Uniform Listing Agreement"
                    name="UniformListingAgreementFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "UniformListingAgreementFile");
                        }}
                      />
                      {propertyFiles.UniformListingAgreementFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.UniformListingAgreementFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                <Form.Item
                  label="Other Documents File"
                  name='otherDocumentsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'otherDocumentsFile') }} />
                    {propertyFiles.otherDocumentsFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.otherDocumentsFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item> */}
              </Card>
            </div>

            <br /><br />
            <Form.Item {...tailFormItemLayout}>
              <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
            </Form.Item>
            <br /><br />

          </Form>
        </Col>
      </Row>)}

      {props.documentsDone && !loading && !editView &&
        <div>
          <Row justify="center">
            <Col span={16}>
              <Card>
                {/* <Row> //llpAgreement
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>LLP Agreement</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.llpAgreement?.length > 0 ? (<a href={uploadFiles.llpAgreement[uploadFiles.llpAgreement?.length - 1]?.URL} download={uploadFiles.llpAgreement[uploadFiles.llpAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.llpAgreement[uploadFiles.llpAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                </div>
              </Col>
            </Row> */}
                <Row> {/*Initial Partnership Agreement */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Initial Partnership Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.initialPartnershipAgreement?.length > 0 ? (<a href={uploadFiles.initialPartnershipAgreement[uploadFiles.initialPartnershipAgreement?.length - 1]?.URL} download={uploadFiles.initialPartnershipAgreement[uploadFiles.initialPartnershipAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.initialPartnershipAgreement[uploadFiles.initialPartnershipAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <Row> {/*Modified Partnership Agreement */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Modified Partnership Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.modifiedPartnershipAgreement?.length > 0 ? (<a href={uploadFiles.modifiedPartnershipAgreement[uploadFiles.modifiedPartnershipAgreement?.length - 1]?.URL} download={uploadFiles.modifiedPartnershipAgreement[uploadFiles.modifiedPartnershipAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.modifiedPartnershipAgreement[uploadFiles.modifiedPartnershipAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <Row>  {/*Disclaimer */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Disclaimer</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.disclaimer?.length > 0 ? (<a href={uploadFiles.disclaimer[uploadFiles.disclaimer?.length - 1]?.URL} download={uploadFiles.disclaimer[uploadFiles.disclaimer?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.disclaimer[uploadFiles.disclaimer?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <Row> {/* Purchase Agreement / Sale Agreement */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Purchase Agreement / Sale Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.purchaseAgreementOrSaleAgreement?.length > 0 ? (<a href={uploadFiles.purchaseAgreementOrSaleAgreement[uploadFiles.purchaseAgreementOrSaleAgreement?.length - 1]?.URL} download={uploadFiles.purchaseAgreementOrSaleAgreement[uploadFiles.purchaseAgreementOrSaleAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.purchaseAgreementOrSaleAgreement[uploadFiles.purchaseAgreementOrSaleAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                <Row> {/*Title Clearnace Certificate */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Title Clearnace Certificate</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.titleClearnaceCertificate?.length > 0 ? (<a href={uploadFiles.titleClearnaceCertificate[uploadFiles.titleClearnaceCertificate?.length - 1]?.URL} download={uploadFiles.titleClearnaceCertificate[uploadFiles.titleClearnaceCertificate?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.titleClearnaceCertificate[uploadFiles.titleClearnaceCertificate?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>

                {/*<Row> Property Title Document 
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Property Title Document</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.propertyTitleDocument?.length > 0 ? (<a href={uploadFiles.propertyTitleDocument[uploadFiles.propertyTitleDocument?.length - 1]?.URL} download={uploadFiles.propertyTitleDocument[uploadFiles.propertyTitleDocument?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.propertyTitleDocument[uploadFiles.propertyTitleDocument?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>*/}

                {/*<Row> Encumbrance Certificate 
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Encumbrance Certificate :</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.encumbranceCertificate?.length > 0 ? (<a href={uploadFiles.encumbranceCertificate[uploadFiles.encumbranceCertificate?.length - 1]?.URL} download={uploadFiles.encumbranceCertificate[uploadFiles.encumbranceCertificate?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.encumbranceCertificate[uploadFiles.encumbranceCertificate?.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>*/}
                {/* <br/>
            <Row>
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>WhitePaper- Business profile document :</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.whitePaperFile?.URL} download={uploadFiles.whitePaperFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.whitePaperFile?.name}</a></sub>
                </div>
              </Col>
            </Row> */}
                {/* <br /> */}
                 {/*<Row>Title Search Report: 
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Title Search Report: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.titleSearchReport?.length > 0 ? (<a href={uploadFiles.titleSearchReport[uploadFiles.titleSearchReport?.length - 1]?.URL} download={uploadFiles.titleSearchReport[uploadFiles.titleSearchReport?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.titleSearchReport[uploadFiles.titleSearchReport?.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>*/}
                {/* <br /> */}
                {/*<Row>Possession Certificate 
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Possession Certificate: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.possessionCertificate?.length > 0 ? (<a href={uploadFiles.possessionCertificate[uploadFiles.possessionCertificate?.length - 1]?.URL} download={uploadFiles.possessionCertificate[uploadFiles.possessionCertificate?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.possessionCertificate[uploadFiles.possessionCertificate?.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>*/}
                {/* <br /> */}
                 {/*{props?.propertyInfo?.assetType === 'Land' && (<Row>7/12 Extract Document *
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>7/12 Extract Document</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.extractDocument?.length > 0 ? (<a href={uploadFiles.extractDocument[uploadFiles.extractDocument?.length - 1]?.URL} download={uploadFiles.extractDocument[uploadFiles.extractDocument?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.extractDocument[uploadFiles.extractDocument?.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>)}/}

                {props?.propertyInfo?.assetType === 'Land' && (<Row> {/*Title Deed
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Title Deed</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.titleDeed?.length > 0 ? (<a href={uploadFiles.titleDeed[uploadFiles.titleDeed?.length - 1]?.URL} download={uploadFiles.titleDeed[uploadFiles.titleDeed?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.titleDeed[uploadFiles.titleDeed?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>)}*/}

                {/* {props?.propertyInfo?.assetType === 'Land' && (<Row>{/*Completion certificate
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Completion certificate</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.completionCertificate?.length > 0 ? (<a href={uploadFiles.completionCertificate[uploadFiles.completionCertificate?.length - 1]?.URL} download={uploadFiles.completionCertificate[uploadFiles.completionCertificate?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.completionCertificate[uploadFiles.completionCertificate?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>)} */}



                {(props?.propertyInfo?.assetType === 'Shop'
                  || props?.propertyInfo?.assetType === 'Office'
                  || ['project','commercial'].includes(props.propertyType)) && (<Row> {/*Lease Agreement*/}
                    <Col span={20}>
                      <div>
                        <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Lease Agreement</sub>
                        <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.leaseAgreement?.length > 0 ? (<a href={uploadFiles.leaseAgreement[uploadFiles.leaseAgreement?.length - 1]?.URL} download={uploadFiles.leaseAgreement[uploadFiles.leaseAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.leaseAgreement[uploadFiles.leaseAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                      </div>
                    </Col>
                    <br />
                  </Row>)}

                {(props?.propertyInfo?.assetType === 'Shop'
                  || props?.propertyInfo?.assetType === 'Office') && (<Row> {/*Business Incorporation Certificate*/}
                    <Col span={20}>
                      <div>
                        <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Business Incorporation Certificate</sub>
                        <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.businessIncorporationCertificate?.length > 0 ? (<a href={uploadFiles.businessIncorporationCertificate[uploadFiles.businessIncorporationCertificate?.length - 1]?.URL} download={uploadFiles.businessIncorporationCertificate[uploadFiles.businessIncorporationCertificate?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.businessIncorporationCertificate[uploadFiles.businessIncorporationCertificate?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                      </div>
                    </Col>
                  </Row>)}

                {/* PROPERTY DOCS */}
                {/* <br /> */}
                <Row> {/* Property Due Dilligence Documents */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Property Due Dilligence Documents</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.propertyDueDilligenceDocuments?.length > 0 ? (<a href={uploadFiles.propertyDueDilligenceDocuments[uploadFiles.propertyDueDilligenceDocuments?.length - 1]?.URL} download={uploadFiles.propertyDueDilligenceDocuments[uploadFiles.propertyDueDilligenceDocuments?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.propertyDueDilligenceDocuments[uploadFiles.propertyDueDilligenceDocuments?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                {['project','commercial'].includes(props.propertyType) && <Row> {/* Property Management Agreements */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Property Management Agreements</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.propertyManagementAgreements?.length > 0 ? (<a href={uploadFiles.propertyManagementAgreements[uploadFiles.propertyManagementAgreements?.length - 1]?.URL} download={uploadFiles.propertyManagementAgreements[uploadFiles.propertyManagementAgreements?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.propertyManagementAgreements[uploadFiles.propertyManagementAgreements?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}
                <Row> {/* Mortgage Or Loan Agreement */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Mortgage Or Loan Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.mortgageOrLoanAgreement?.length > 0 ? (<a href={uploadFiles.mortgageOrLoanAgreement[uploadFiles.mortgageOrLoanAgreement?.length - 1]?.URL} download={uploadFiles.mortgageOrLoanAgreement[uploadFiles.mortgageOrLoanAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.mortgageOrLoanAgreement[uploadFiles.mortgageOrLoanAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                {'project' === props.propertyType &&<Row> {/* Property Appraisal Report */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Property Appraisal Report</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.propertyAppraisalReport?.length > 0 ? (<a href={uploadFiles.propertyAppraisalReport[uploadFiles.propertyAppraisalReport?.length - 1]?.URL} download={uploadFiles.propertyAppraisalReport[uploadFiles.propertyAppraisalReport?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.propertyAppraisalReport[uploadFiles.propertyAppraisalReport?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}

                {/* financial */}
                <br />
                {'project' === props.propertyType &&
                <><Row> {/* Tax Returns */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Tax Returns</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.taxReturns?.length > 0 ? (<a href={uploadFiles.taxReturns[uploadFiles.taxReturns?.length - 1]?.URL} download={uploadFiles.taxReturns[uploadFiles.taxReturns?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.taxReturns[uploadFiles.taxReturns?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                <Row> {/* Operating And Financials */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Operating And Financials</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.operatingAndFinancials?.length > 0 ? (<a href={uploadFiles.operatingAndFinancials[uploadFiles.operatingAndFinancials?.length - 1]?.URL} download={uploadFiles.operatingAndFinancials[uploadFiles.operatingAndFinancials?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.operatingAndFinancials[uploadFiles.operatingAndFinancials?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                <Row> {/* Whitepaper */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Whitepaper</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.whitepaper?.length > 0 ? (<a href={uploadFiles.whitepaper[uploadFiles.whitepaper?.length - 1]?.URL} download={uploadFiles.whitepaper[uploadFiles.whitepaper?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.whitepaper[uploadFiles.whitepaper?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                <Row> {/* Project Plan */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Project Plan</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.projectPlan?.length > 0 ? (<a href={uploadFiles.projectPlan[uploadFiles.projectPlan?.length - 1]?.URL} download={uploadFiles.projectPlan[uploadFiles.projectPlan?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.projectPlan[uploadFiles.projectPlan?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>
                <Row> {/*Financial project plan */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Financial project plan</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.financialProjectPlan?.length > 0 ? (<a href={uploadFiles.financialProjectPlan[uploadFiles.financialProjectPlan?.length - 1]?.URL} download={uploadFiles.financialProjectPlan[uploadFiles.financialProjectPlan?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.financialProjectPlan[uploadFiles.financialProjectPlan?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row></>}

                {/* others */}
                {/* <br /> */}
                {['project','commercial'].includes(props.propertyType) &&  <Row> {/* No Objection Certificates (NOCs) */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>No Objection Certificates (NOCs)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.noObjectionCertificates?.length > 0 ? (<a href={uploadFiles.noObjectionCertificates[uploadFiles.noObjectionCertificates?.length - 1]?.URL} download={uploadFiles.noObjectionCertificates[uploadFiles.noObjectionCertificates?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.noObjectionCertificates[uploadFiles.noObjectionCertificates?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}
                {(props?.propertyInfo?.assetType === 'Retail' || ['project','commercial'].includes(props.propertyType)) && (<Row>{/*Business Registration*/}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Business Registration</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.businessRegistration?.length > 0 ? (<a href={uploadFiles.businessRegistration[uploadFiles.businessRegistration?.length - 1]?.URL} download={uploadFiles.businessRegistration[uploadFiles.businessRegistration?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.businessRegistration[uploadFiles.businessRegistration?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>)}
                {false && <Row> {/* Sale Agreement */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Sale Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.saleAgreement?.length > 0 ? (<a href={uploadFiles.saleAgreement[uploadFiles.saleAgreement?.length - 1]?.URL} download={uploadFiles.saleAgreement[uploadFiles.saleAgreement?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.saleAgreement[uploadFiles.saleAgreement?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}
                {props.propertyType == 'residential' && <Row> {/* POA */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>POA</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.poa?.length > 0 ? (<a href={uploadFiles.poa[uploadFiles.poa?.length - 1]?.URL} download={uploadFiles.poa[uploadFiles.poa?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.poa[uploadFiles.poa?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}
                {props.propertyType == 'residential' && <Row> {/* certificateOfExtensionOfRegistrationOfProject */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Certificate For Extenstion of Registration of Project (Form F)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.certificateOfExtensionOfRegistrationOfProject?.length > 0 ? (<a href={uploadFiles.certificateOfExtensionOfRegistrationOfProject[uploadFiles.certificateOfExtensionOfRegistrationOfProject?.length - 1]?.URL} download={uploadFiles.certificateOfExtensionOfRegistrationOfProject[uploadFiles.certificateOfExtensionOfRegistrationOfProject?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.certificateOfExtensionOfRegistrationOfProject[uploadFiles.certificateOfExtensionOfRegistrationOfProject?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}
                {props.propertyType == 'residential' && <Row> {/* registrationCertificateOfProject */}
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Registration Certificate of Project (Form C)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.registrationCertificateOfProject?.length > 0 ? (<a href={uploadFiles.registrationCertificateOfProject[uploadFiles.registrationCertificateOfProject?.length - 1]?.URL} download={uploadFiles.registrationCertificateOfProject[uploadFiles.registrationCertificateOfProject?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.registrationCertificateOfProject[uploadFiles.registrationCertificateOfProject?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                  <br />
                </Row>}


                {/* <br/>
            <Row>
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Undertaking/Certification from the NPO (As per Annexure I)</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.CertificationFromNpoFile?.length > 0 ? (<a href={uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.URL} download={uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.GoverningDocumentFile?.length > 0 ? (<a href={uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.URL} download={uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Uniform Listing Agreement</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.UniformListingAgreementFile?.length > 0 ? (<a href={uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.URL} download={uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                </div>
              </Col>
            </Row>
            <br/>
            <br/>
            <Row>
              <Col span={20}>
                <div>
                  <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Other Documents File</sub>
                  <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.otherDocumentsFile?.length > 0 ? (<a href={uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.URL} download={uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                </div>
              </Col>
            </Row> */}

                <br />
                {(props.userInfo?.role === 'issuer company manager') &&
                  <>
                    <Space style={{ display: "block", float: 'left' }}>
                      {/* {!mergedFileLink && <Button
                type="primary"
                onClick={() => mergeFiles()}
              >
                  Merge Files
              </Button>
              } */}

                      {mergedFileLink && <a href={mergedFileLink}>Meged file</a>}
                      {mergedFileLink && <Button
                        type="primary"
                        onClick={() => uploadMergedFile()}
                      >
                        Upload Merged File
                      </Button>
                      }
                    </Space>

                    <Space style={{ float: 'right' }}>
                      <Button
                        type="primary"
                        onClick={() => setEditView(true)}
                      >
                        Edit Documents
                      </Button>
                      {/* {sendForApprovalVisibility && <Button
                        type="primary"
                        disabled={propertyInfoStatus?.toLowerCase() == 'pending' || propertyInfoStatus?.toLowerCase() == 'approved'}
                        onClick={() => sendPropertyDetailsForApproval()}
                      >
                        {propertyInfoStatus?.toLowerCase() == 'approved' ? 'Approved' : 'Send For Approval'}
                      </Button>} */}
                    </Space>
                  </>
                }

              </Card>
            </Col>
          </Row>
        </div>
      }

      {props.documentsDone && !loading && editView && (
        <Row justify="center">
          <Col span={20}>
            <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Edit Offering Document</h2>
            <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={updateUploadedDocuments} className="edit-form">
              <Card>
                {/* <Form.Item
              label="LLP Agreement"
              name="llpAgreement"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <>
                <Input
                  type="file"
                  onChange={(e) => {
                    multiUploadFile(e, "llpAgreement");
                  }}
                />
                {llpAgreement[
                  llpAgreement?.length - 1
                ]?.uploadFilePercentage > 0 && (
                  <Progress
                    percent={
                      llpAgreement[
                        llpAgreement?.length - 1
                      ]?.uploadFilePercentage
                    }
                  />
                )}
                <div style={{}}>
                  <p>Files Uploaded:</p>
                  {llpAgreement?.length > 0 &&
                    llpAgreement?.map((file) => (
                      <h4 key={Math.random()}>{file.name}</h4>
                    ))}
                </div>
              </>
            </Form.Item> */}
                <Form.Item //Initial Partnership Agreement
                  label="Initial Partnership Agreement"
                  name="initialPartnershipAgreement"
                  rules={[
                    {
                      required: initialPartnershipAgreement?.length > 0 ?false:true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "initialPartnershipAgreement");
                      }}
                    />
                    {initialPartnershipAgreement[
                      initialPartnershipAgreement?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            initialPartnershipAgreement[
                              initialPartnershipAgreement?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {initialPartnershipAgreement?.length > 0 &&
                        initialPartnershipAgreement.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Modified Partnership Agreement
                  label="Modified Partnership Agreement"
                  name="modifiedPartnershipAgreement"
                  rules={[
                    {
                      required: modifiedPartnershipAgreement?.length > 0 ? false:true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "modifiedPartnershipAgreement");
                      }}
                    />
                    {modifiedPartnershipAgreement[
                      modifiedPartnershipAgreement?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            modifiedPartnershipAgreement[
                              modifiedPartnershipAgreement?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {modifiedPartnershipAgreement?.length > 0 &&
                        modifiedPartnershipAgreement.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Disclaimer
                  label="Disclaimer"
                  name="disclaimer"
                  rules={[
                    {
                      required: disclaimer?.length > 0 ? false : true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "disclaimer");
                      }}
                    />
                    {disclaimer[
                      disclaimer?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            disclaimer[
                              disclaimer?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {disclaimer?.length > 0 &&
                        disclaimer.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Purchase Agreement / Sale Agreement
                  label="Purchase Agreement / Sale Agreement"
                  name="purchaseAgreementOrSaleAgreement"
                  rules={[
                    {
                      required: purchaseAgreementOrSaleAgreement?.length > 0 ? false:true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "purchaseAgreementOrSaleAgreement"
                        );
                      }}
                    />
                    {purchaseAgreementOrSaleAgreement[
                      purchaseAgreementOrSaleAgreement?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            purchaseAgreementOrSaleAgreement[
                              purchaseAgreementOrSaleAgreement?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {purchaseAgreementOrSaleAgreement?.length > 0 &&
                        purchaseAgreementOrSaleAgreement.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Title Clearnace Certificate
                  label="Title Clearance Certificate"
                  name="titleClearanceCertificate"
                  rules={[
                    {
                      required: titleClearanceCertificate?.length > 0 ? false:true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "titleClearnaceCertificate"
                        );
                      }}
                    />
                    {titleClearanceCertificate[
                      titleClearanceCertificate?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            titleClearanceCertificate[
                              titleClearanceCertificate?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {titleClearanceCertificate?.length > 0 &&
                        titleClearanceCertificate.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>

                {/* <Form.Item //Property Title Document
                  label="Property Title Document"
                  name="propertyTitleDocument"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "propertyTitleDocument");
                      }}
                    />
                    {propertyTitleDocument[
                      propertyTitleDocument?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyTitleDocument[
                              propertyTitleDocument?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {propertyTitleDocument?.length > 0 &&
                        propertyTitleDocument.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item> */}
                {/* <Form.Item //Encumbrance Certificate
                  label="Encumbrance Certificate"
                  name='encumbranceCertificate'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    {console.log(encumbranceCertificate)}
                    <Input type='file' onChange={e => { multiUploadFile(e, 'private') }} />
                    {encumbranceCertificate[encumbranceCertificate?.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={encumbranceCertificate[encumbranceCertificate?.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {encumbranceCertificate?.length > 0 && encumbranceCertificate.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item> */}
                {/* <Form.Item //Title Search Report
                  label="Title Search Report"
                  name='titleSearchReport'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'subscription') }} />
                    {titleSearchReport[titleSearchReport?.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={titleSearchReport[titleSearchReport?.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {titleSearchReport?.length > 0 && titleSearchReport.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item> */}
                {/* {<Form.Item //Possession Certificate
                  label="Possession Certificate"
                  name='possessionCertificate'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'financial') }} />
                    {possessionCertificate[possessionCertificate?.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={possessionCertificate[possessionCertificate?.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {possessionCertificate?.length > 0 && possessionCertificate.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>} */}


                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //7/12 Extract Document
                  label="7/12 Extract Document"
                  name='extractDocument'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'property') }} />
                    {extractDocument[extractDocument?.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={extractDocument[extractDocument?.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {extractDocument?.length > 0 && extractDocument.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>)} */}

                {(false && props?.propertyInfo?.assetType === 'Land') && (<Form.Item //Sale Agreement
                  label="Sale Agreement"
                  name="saleAgreement"
                  rules={[
                    {
                      required: saleAgreement?.length > 0 ? false:true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "saleAgreement"
                        );
                      }}
                    />
                    {saleAgreement[
                      saleAgreement?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            saleAgreement[
                              saleAgreement?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {saleAgreement?.length > 0 &&
                        saleAgreement.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)}
                {props?.propertyType=== 'residential' && (<Form.Item //POA
                  label="POA"
                  name="poa"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "poa"
                        );
                      }}
                    />
                    {poa[
                      poa?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            poa[
                              poa?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {poa?.length > 0 &&
                        poa.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)}

                {props?.propertyType=== 'residential' && (<Form.Item //registrationCertificateOfProject
                  label="Registration Certificate Of Project (Form C)"
                  name="poa"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "registrationCertificateOfProject"
                        );
                      }}
                    />
                    {registrationCertificateOfProject[
                      registrationCertificateOfProject?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            registrationCertificateOfProject[
                              registrationCertificateOfProject?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {registrationCertificateOfProject?.length > 0 &&
                        registrationCertificateOfProject.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)}

                {props?.propertyType=== 'residential' && (<Form.Item //certificateOfExtensionOfRegistrationOfProject
                  label="Certificate For Extension Of Registration Of Project (Form F) :"
                  name="certificateOfExtensionOfRegistrationOfProject"
                  colon = {false}
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "certificateOfExtensionOfRegistrationOfProject"
                        );
                      }}
                    />
                    {certificateOfExtensionOfRegistrationOfProject[
                      certificateOfExtensionOfRegistrationOfProject?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            certificateOfExtensionOfRegistrationOfProject[
                              certificateOfExtensionOfRegistrationOfProject?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {certificateOfExtensionOfRegistrationOfProject?.length > 0 &&
                        certificateOfExtensionOfRegistrationOfProject.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)}

                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //Title Deed
                  label="Title Deed"
                  name="titleDeed"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "titleDeed");
                      }}
                    />
                    {titleDeed[
                      titleDeed?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            titleDeed[
                              titleDeed?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {titleDeed?.length > 0 &&
                        titleDeed.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)} */}

                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //Completion certificate
                  label="Completion certificate"
                  name="completionCertificate"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "completionCertificate");
                      }}
                    />
                    {completionCertificate[
                      completionCertificate?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            completionCertificate[
                              completionCertificate?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {completionCertificate?.length > 0 &&
                        completionCertificate.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)} */}

                {(props?.propertyInfo?.assetType === 'Shop'
                  || props?.propertyInfo?.assetType === 'Office') && (<Form.Item //Business Incorporation Certificate
                    label="Business Incorporation Certificate"
                    name="businessIncorporationCertificate"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          multiUploadFile(
                            e,
                            "businessIncorporationCertificate"
                          );
                        }}
                      />
                      {businessIncorporationCertificate[
                        businessIncorporationCertificate?.length - 1
                      ]?.uploadFilePercentage > 0 && (
                          <Progress
                            percent={
                              businessIncorporationCertificate[
                                businessIncorporationCertificate?.length - 1
                              ]?.uploadFilePercentage
                            }
                          />
                        )}
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {businessIncorporationCertificate?.length > 0 &&
                          businessIncorporationCertificate.map((file) => (
                            <h4 key={Math.random()}>{file.name}</h4>
                          ))}
                      </div>
                    </>
                  </Form.Item>)}

                {/* PROPERTY DOCS */}
                <Form.Item //Property Due Dilligence Documents
                  label="Property Due Dilligence Documents"
                  name="propertyDueDilligenceDocuments"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "propertyDueDilligenceDocuments"
                        );
                      }}
                    />
                    {propertyDueDilligenceDocuments[
                      propertyDueDilligenceDocuments?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyDueDilligenceDocuments[
                              propertyDueDilligenceDocuments?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {propertyDueDilligenceDocuments?.length > 0 &&
                        propertyDueDilligenceDocuments.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
               {['project','commercial'].includes(props.propertyType) && <Form.Item //Property Management Agreements
                  label="Property Management Agreements"
                  name="propertyManagementAgreements"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "propertyManagementAgreements"
                        );
                      }}
                    />
                    {propertyManagementAgreements[
                      propertyManagementAgreements?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyManagementAgreements[
                              propertyManagementAgreements?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {propertyManagementAgreements?.length > 0 &&
                        propertyManagementAgreements.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>}
                {(props?.propertyInfo?.assetType === 'Shop'
                  || props?.propertyInfo?.assetType === 'Office'
                  || ['project','commercial'].includes(props.propertyType)) && (<Form.Item //Lease Agreement
                    label="Lease Agreement"
                    name="leaseAgreement"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          multiUploadFile(e, "leaseAgreement");
                        }}
                      />
                      {leaseAgreement[leaseAgreement?.length - 1]
                        ?.uploadFilePercentage > 0 && (
                          <Progress
                            percent={
                              leaseAgreement[
                                leaseAgreement?.length - 1
                              ]?.uploadFilePercentage
                            }
                          />
                        )}
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {leaseAgreement?.length > 0 &&
                          leaseAgreement.map((file) => (
                            <h4 key={Math.random()}>{file.name}</h4>
                          ))}
                      </div>
                    </>
                  </Form.Item>)}
                <Form.Item //Mortgage Or Loan Agreement
                  label="Mortgage Or Loan Agreement"
                  name="mortgageOrLoanAgreement"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "mortgageOrLoanAgreement"
                        );
                      }}
                    />
                    {mortgageOrLoanAgreement[
                      mortgageOrLoanAgreement?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            mortgageOrLoanAgreement[
                              mortgageOrLoanAgreement?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {mortgageOrLoanAgreement?.length > 0 &&
                        mortgageOrLoanAgreement.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                {'project' === props.propertyType &&<Form.Item //Property Appraisal Report
                  label="Property Appraisal Report"
                  name="propertyAppraisalReport"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "propertyAppraisalReport"
                        );
                      }}
                    />
                    {propertyAppraisalReport[
                      propertyAppraisalReport?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyAppraisalReport[
                              propertyAppraisalReport?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {propertyAppraisalReport?.length > 0 &&
                        propertyAppraisalReport.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>}

                {/* financial */}
                {props.propertyType === 'project' && 
                 <>
                <Form.Item  //Tax Returns
                  label="Tax Returns"
                  name="taxReturns"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "taxReturns"
                        );
                      }}
                    />
                    {taxReturns[
                      taxReturns?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            taxReturns[
                              taxReturns?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {taxReturns?.length > 0 &&
                        taxReturns.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Operating And Financials
                  label="Operating And Financials"
                  name="operatingAndFinancials"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "operatingAndFinancials"
                        );
                      }}
                    />
                    {operatingAndFinancials[
                      operatingAndFinancials?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            operatingAndFinancials[
                              operatingAndFinancials?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {operatingAndFinancials?.length > 0 &&
                        operatingAndFinancials.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item  //Whitepaper
                  label="Whitepaper"
                  name="whitepaper"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "whitepaper"
                        );
                      }}
                    />
                    {whitepaper[
                      whitepaper?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            whitepaper[
                              whitepaper?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {whitepaper?.length > 0 &&
                        whitepaper.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item  //Project Plan
                  label="Project Plan"
                  name="projectPlan"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "projectPlan"
                        );
                      }}
                    />
                    {projectPlan[
                      projectPlan?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            projectPlan[
                              projectPlan?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {projectPlan?.length > 0 &&
                        projectPlan.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item //Financial Project Plan
                  label="Financial Project Plan"
                  name="financialProjectPlan"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "financialProjectPlan"
                        );
                      }}
                    />
                    {financialProjectPlan[
                      financialProjectPlan?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            financialProjectPlan[
                              financialProjectPlan?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {financialProjectPlan?.length > 0 &&
                        financialProjectPlan.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                </>
                }

                {/* other*/}
                {['project','commercial'].includes(props.propertyType) &&<Form.Item //No Objection Certificates (NOCs)
                  label="No Objection Certificates (NOCs)"
                  name="noObjectionCertificates"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "noObjectionCertificates"
                        );
                      }}
                    />
                    {noObjectionCertificates[
                      noObjectionCertificates?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            noObjectionCertificates[
                              noObjectionCertificates?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {noObjectionCertificates?.length > 0 &&
                        noObjectionCertificates.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>}
                {(props?.propertyInfo?.assetType === 'Retail'
                || ['project','commercial'].includes(props.propertyType))  && (<Form.Item //Business Registration 
                  label="Business Registration"
                  name="businessRegistration"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "businessRegistration"
                        );
                      }}
                    />
                    {businessRegistration[
                      businessRegistration?.length - 1
                    ]?.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            businessRegistration[
                              businessRegistration?.length - 1
                            ]?.uploadFilePercentage
                          }
                        />
                      )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {businessRegistration?.length > 0 &&
                        businessRegistration.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>)}
                {/* {props?.propertyInfo?.assetType === 'Land' && (<Form.Item //Sale deed
                  label="Sale deed"
                  name='saleDeed'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'draftfund') }} />
                    {saleDeed[saleDeed?.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={saleDeed[saleDeed?.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {saleDeed?.length > 0 && saleDeed.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>)} */}


                {/* <Form.Item
              label="Undertaking/Certification from the NPO (As per Annexure I)"
              name="CertificationFromNpoFile"
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <>
                <Input
                  type="file"
                  onChange={(e) => {
                    multiUploadFile(e, "CertificationFromNpoFile");
                  }}
                />
                {CertificationFromNpoFile[
                  CertificationFromNpoFile?.length - 1
                ]?.uploadFilePercentage > 0 && (
                  <Progress
                    percent={
                      CertificationFromNpoFile[
                        CertificationFromNpoFile?.length - 1
                      ]?.uploadFilePercentage
                    }
                  />
                )}
                <div style={{}}>
                  <p>Files Uploaded:</p>
                  {CertificationFromNpoFile?.length > 0 &&
                    CertificationFromNpoFile.map((file) => (
                      <h4 key={Math.random()}>{file.name}</h4>
                    ))}
                </div>
              </>
            </Form.Item>
            <Form.Item
              label="Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)"
              name="GoverningDocumentFile"
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <>
                <Input
                  type="file"
                  onChange={(e) => {
                    multiUploadFile(e, "GoverningDocumentFile");
                  }}
                />
                {GoverningDocumentFile[GoverningDocumentFile?.length - 1]
                  ?.uploadFilePercentage > 0 && (
                  <Progress
                    percent={
                      GoverningDocumentFile[
                        GoverningDocumentFile?.length - 1
                      ]?.uploadFilePercentage
                    }
                  />
                )}
                <div style={{}}>
                  <p>Files Uploaded:</p>
                  {GoverningDocumentFile?.length > 0 &&
                    GoverningDocumentFile.map((file) => (
                      <h4 key={Math.random()}>{file.name}</h4>
                    ))}
                </div>
              </>
            </Form.Item>
            <Form.Item
              label="Uniform Listing Agreement"
              name="UniformListingAgreementFile"
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <>
                <Input
                  type="file"
                  onChange={(e) => {
                    multiUploadFile(e, "UniformListingAgreementFile");
                  }}
                />
                {UniformListingAgreementFile[
                  UniformListingAgreementFile?.length - 1
                ]?.uploadFilePercentage > 0 && (
                  <Progress
                    percent={
                      UniformListingAgreementFile[
                        UniformListingAgreementFile?.length - 1
                      ]?.uploadFilePercentage
                    }
                  />
                )}
                <div style={{}}>
                  <p>Files Uploaded:</p>
                  {UniformListingAgreementFile?.length > 0 &&
                    UniformListingAgreementFile.map((file) => (
                      <h4 key={Math.random()}>{file.name}</h4>
                    ))}
                </div>
              </>
            </Form.Item>

            <Form.Item
              label="Other Documents File"
              name='otherDocumentsFile'
              rules={[
                {
                  required: false,
                  message: 'This field is required'
                }
              ]}>
              <>
                <Input type='file' onChange={e => { multiUploadFile(e, 'other') }} />
                {otherDocumentsFile[otherDocumentsFile?.length - 1]?.uploadFilePercentage > 0 &&
                  <Progress percent={otherDocumentsFile[otherDocumentsFile?.length - 1]?.uploadFilePercentage} />
                }
                <div style={{}}>
                  <p>Files Uploaded:</p>
                  {otherDocumentsFile?.length > 0 && otherDocumentsFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                </div>
              </>
            </Form.Item> */}
              </Card>

              <br /><br />
              <Form.Item {...tailFormItemLayout}>
                <Button size='large' disabled={!enableEdit} style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
              </Form.Item>
              <br /><br />
            </Form>
          </Col>
        </Row>
      )}
            
      
      <TransactionModal
        title = 'adding the document'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  )
};