import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Button, Spin, Input, Select, List, Progress, Popconfirm, notification, Space, Radio, Dropdown, Menu} from 'antd';
import { CheckOutlined, CloseCircleOutlined, DownOutlined, LineOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './addProperty.css';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import { environment } from '../../../environments/environment';
import TextArea from 'antd/lib/input/TextArea';
import { AlignCenter } from 'react-feather';
import validationPatterns from '../../Shared/validationPatterns';

const {Title} = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default function   AddBasicInfo(props) {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();
  const [cCountry, setCCountry] = useState(false);
  const [usaStates, setUSAStates] = useState();
  const [form, form2] = Form.useForm();
  const [form3, form4] = Form.useForm();
  const [form5, form6] = Form.useForm();
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [disbaleUpload, setDisableUpload] = useState(false);
  const [editUploadView, setEditUploadView] = useState(false);
  const [propertyDescription, setPropertyDescription] = useState('');
  const [managementTeamDescription, setManagementTeamDescription] = useState('');
  const [teamMembers, setTeamMembers] = useState([])
  const [memberName, setMemberName] = useState('')
  const [memberRole, setMemberRole] = useState('');
  const [memberContact, setMemberContact] = useState('');
  const [editBasicInfo, setEditBasicInfo] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [propertyCategoryTypeValue, setPropertyCategoryTypeValue] = useState('commercialProperty');
  const [selectedPartners, setSelectedPartners] = useState([{}]);
  const { userInfo } = useUserContext();
  const [designatedPartners, setDesignatedPartners] = useState([]);

  const [memberPhoto, setMemberPhoto] = useState({
    uploading: false,
    uploadFilePercentage: 0,
    URL: null,
    name: null,
    type: null,
  });
  const [filesObj, setFilesObj] = useState({
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  useEffect(() => {
    (async () => {
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
      const countryRes = await sharedService.getCountries();
      setCountries(countryRes.data);
      const resposnse = await issuerSuperAdminService.getAllDesignatedPartnerDetailsForCompanyByCompanyId(userInfo?.company?.id);
      setDesignatedPartners(resposnse.data);
      form.setFieldsValue({propertyCountry:'India'});

      if (props?.propertyInfo?.propertyImages?.length > 0) {
        setDocuments(props.propertyInfo.propertyImages);
        setDocumentsToSubmit(props.propertyInfo.propertyImages);
        if (props.propertyInfo.propertyImages?.length > 24) {
          setDisableUpload(true);
        }
      }
      if (props?.propertyInfo?.descriptionOfProperty) {
        setPropertyDescription(props?.propertyInfo?.descriptionOfProperty)
      }
      if (props?.propertyInfo?.descriptionOfManagementTeam) {
        setManagementTeamDescription(props?.propertyInfo?.descriptionOfManagementTeam)
      }
      if (props?.propertyInfo?.teamMembers?.length > 0) {
        setTeamMembers(props?.propertyInfo?.teamMembers)
      }

      if(props?.propertyInfo?.propertyCategoryType?.length > 1) {
        console.log(`props.propertyInfo.propertyCategoryType: ${props?.propertyInfo?.propertyCategoryType}`);
        setPropertyCategoryTypeValue(props?.propertyInfo?.propertyCategoryType);
      }

      if(props?.propertyInfo?.assetType?.length > 1) {
        console.log(`props.propertyInfo.assetType: ${props?.propertyInfo?.assetType}`);
        setSelectedValue(props?.propertyInfo?.assetType);
      }
    })();
  }, [props?.propertyInfo]);

  const currentCountry = (value) => {
    if (value == 'United States of America') setCCountry(true);
    else setCCountry(false);
  }

  //upload file start

  const uploadFile = async (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    if (file.size <= 10 * 1024 * 1024) { //10MB
      setFileSizeError(false);
    } else {
      setFileSizeError(true);
      return;
    }

    setFileCount(fileCount + 1);
    if (fileCount + 1 > 24) {
      setDisableUpload(true);
    }

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    try {
      issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });

      }, (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });

      }, async (response) => {

        if (response.success) {
          const firebaseUrl = response.data.url;
          console.log(firebaseUrl);
          /* upload to firebase ends here */
          if (firebaseUrl?.length > 0) {
            setFilesObj((prev) => {
              const current = sharedService.clone(prev);
              current[key].uploadFilePercentage = 100;
              current[key].URL = firebaseUrl;
              current[key].uploading = false;
              current[key].name = file.name;
              current[key].type = fileType.toLowerCase();
              return current;
            });
            let fileDetails = { [key]: { URL: firebaseUrl, name: file.name } };
            form.setFieldsValue(fileDetails);
            setDocuments((prev) => prev.concat(file.name));
            setDocumentsToSubmit((prev) => prev.concat({ URL: firebaseUrl, name: file.name }));
          } else {
            setFilesObj((prev) => {
              const current = sharedService.clone(prev);
              current[key].uploading = false;
              return current;
            });
          }
        }
      })
    } catch (error) {
      console.error(error);
      setFilesObj((prev) => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });
    }
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
    setDisableUpload(false);
  };

  const saveBasicInfo = async (_form) => {
    const form = { ..._form };
    form.basicInfo = 'done';
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.propertyImages = documentsToSubmit;
    let propertyCount = props.propertyInfo ? props.propertyInfo.newCount ? props.propertyInfo.newCount : 1 : 1;
    form.propertyCount = propertyCount;
    // form.propertyCategoryType = propertyCategoryTypeValue;
    form.propertyType = props.propertyType;
    form.commercialOption = props.commercialOption;
    form.companyId = props.userInfo?.company?.id;
    
    if(false && !selectedValue) {
      notification.open({
        message: 'Form Error!',
        description: 'You must select a asset type to save this property!',
        duration: 0,
      });
      return false;
    }

    // form.assetType = selectedValue;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.success({
        message: 'Success',
        duration: 0,
      });
      sessionStorage.setItem('savedPageNo', '1');
      setTimeout(()=>window.location.reload(), 500);

    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const updateBasicInfo = async (_form) => {
    const form = { ..._form };
    form.propertyCategoryType = propertyCategoryTypeValue;
    form.assetType = selectedValue;
    form.basicInfo = 'done';
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    form.companyId = props.userInfo?.company?.id;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      sessionStorage.setItem('savedPageNo', '2');
      notification.success({
        message: 'Success',
        duration: 0,
      });
      setTimeout(()=>window.location.reload(), 500);

    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
    setTimeout(() => window.location.reload(), 500)
  }

  const savePropertyAboutManagementTeam = async (_form) => {
    const form = {};
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    form.descriptionOfProperty = propertyDescription;
    form.descriptionOfManagementTeam = managementTeamDescription;
    form.companyId = props.userInfo?.company?.id;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      sessionStorage.setItem('savedPageNo', '1');
      notification.success({
        message: 'Success',
        duration: 0,
      });
      setTimeout(()=>window.location.reload(), 500);

    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const addTeamMember = async (_form) => {

    const selectedPartnersDetails = selectedPartners
    .filter(partner => partner.id) // Filter out any selections without an id
    .map(selectedPartner => {
      // Find the full details of each selected partner
      const partnerFullDetails = designatedPartners.find(partner => partner?._id === selectedPartner?.id);
      // Return only the required fields
      return {
        memberName: `${partnerFullDetails?.firstName} ${partnerFullDetails?.lastName}`,
        memberPhoto: partnerFullDetails?.designatedPartnerPhoto,
        memberContact: partnerFullDetails?.dPIN,
        memberRole: `${partnerFullDetails?.streetAddress1} ${partnerFullDetails?.streetAddress2}`
      };
    });

    const isEditMode = teamMembers.length > 0;
    
    if (!isEditMode &&  selectedPartnersDetails.length < 2) {
      notification.error({
        message: 'Selection Error',
        description: 'Please select at least two designated partners.',
      });
      return; 
    }
    const form = {};
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    //form.teamMembers = [...teamMembers, { memberName, memberPhoto, memberRole, memberContact }];
    form.teamMembers = [...teamMembers, ...selectedPartnersDetails];
    form.companyId = props.userInfo?.company?.id;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      sessionStorage.setItem('savedPageNo', '2');
      notification.success({
        message: 'Success',
        duration: 0,
      });
      setTimeout(()=>window.location.reload(), 500);

    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const deleteMember = async (index) => {
    const form = {};
    form.userId = props.userInfo._id;
    form.companyId = props.userInfo?.company?.id;
    form.updateBasicInfo = true;
    let newteamMembers = teamMembers.filter((mem, i) => i !== index)
    form.teamMembers = [...newteamMembers];
    setTeamMembers([...newteamMembers])
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      sessionStorage.setItem('savedPageNo', '1');
      notification.success({
        message: 'Success',
        duration: 0,
      });
      setTimeout(()=>window.location.reload(), 500);

    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const editPhotos = async (_form2) => {
    const form = {};
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.propertyImages = documentsToSubmit;
    form.companyId = props.userInfo?.company?.id;
    setLoading(true);
    const response = await issuerSuperAdminService.updatePropertyPhotos(form);
    if (response.success) {
      sessionStorage.setItem('savedPageNo', '1');
      notification.success({
        message: 'Success',
        duration: 0,
      });
    } else {
      //message.error(response.error.message);
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    //props.setStepNo(2);
    props.setUpdated(true);
    setLoading(false);
  }

  const uploadPhoto = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setMemberPhoto((prev) => ({ ...prev, URL: null }));

    try {
      issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
        setMemberPhoto((prev) => ({ ...prev, uploading: true, uploadFilePercentage: uploadFilePercent }));

      }, (err) => {
        console.error(err);
        setMemberPhoto((prev) => ({ ...prev, uploading: false }));

      }, async (response) => {

        if (response.success) {
          const firebaseUrl = response.data.url;
          console.log(firebaseUrl);
          /* upload to firebase ends here */
          if (firebaseUrl?.length > 0) {
            setMemberPhoto((prev) => ({ ...prev, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase(), URL: firebaseUrl }));
            form5.setFieldsValue({ [key]: file.name });
          } else {
            setMemberPhoto((prev) => ({ ...prev, uploading: false }));
          }
        }
      })
    } catch (error) {
      console.error(error);
      setMemberPhoto((prev) => ({ ...prev, uploading: false }));
    }

  }

  const onUploadBack = () => {
    editUploadView(false);
    props.setStepNo(1);
    props.setUpdated(true);
  }

  const handleSelectPartner = (value, index) => {
    // Create a new array with all existing selections
    let newSelectedPartners = selectedPartners.map((partner, idx) => {
      // If this is the dropdown that was changed, update its value
      if (idx === index) {
        return { ...partner, id: value };
      }
      // Otherwise, return the partner as is
      return partner;
    });

    if (index === selectedPartners.length - 1) {
      if (value !== undefined && value !== '') {
        newSelectedPartners.push({});
      }
    } else {
      if (!newSelectedPartners.some(partner => !partner.id)) {
        newSelectedPartners.push({});
      }
    }
    setSelectedPartners(newSelectedPartners);

  }

  const dropDownMenu = (
    <Menu onClick={(e) => setSelectedValue(e.key)}>
      <Menu.Item key="" value=""></Menu.Item>
      <Menu.Item key="Land">Land</Menu.Item>
      <Menu.Item key="Retail">Retail</Menu.Item>
      <Menu.Item key="Office">Office</Menu.Item>
      <Menu.Item key="Shop">Shop</Menu.Item>
    </Menu>
  );

  return (
    <>
      {loading &&
        <div style={{ textAlign: 'center' }}>
          <br />
          <Spin size='large' />
        </div>
      }
      {!loading && !props.basicInfoDone && (
        <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop: '20px' }}>Property Details</Title>
          <br /><br />
          <Row justify="center">
            <Col span={23}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveBasicInfo}>
                <Card>
                  <Form.Item
                    label={`Property Id`}
                    name="propertyId">
                    <Input placeholder={`${props.propertyInfo ? props.propertyInfo.newCount ? props.propertyInfo.newCount : '1' : '1'}`}
                      disabled={true} />
                  </Form.Item>

                  {/* <Form.Item
                      label={`Property Category Type`}
                      name="propertyCategoryType">
                      <Radio.Group defaultValue={propertyCategoryTypeValue}>
                          <Radio onClick={()=>setPropertyCategoryTypeValue('commercialProperty')} value="commercialProperty">Commercial Property</Radio>
                          <Radio onClick={()=>setPropertyCategoryTypeValue('residentialProperty')} value="residentialProperty" >Residential Property</Radio>
                      </Radio.Group>
                    </Form.Item> */}

                    {propertyCategoryTypeValue === 'residentialProperty' ? 
                    (<h3 style={{justifyContent: 'center', display: 'flex'}}>Coming Soon...</h3>) :
                    (false && <Form.Item
                          label={
                            <span>
                               <span style={{ color: '#ff4d4f', fontSize: '12px' }}>*</span> Asset Type
                            </span>
                          }
                          name="assetType"
                          rules={[
                            {
                              required: false,
                              message: 'This field is required',
                              whitespace: true,
                              validateTrigger: 'onChange',
                            }
                          ]}>
                          <Dropdown overlay={dropDownMenu}>
                            <Button>
                              {selectedValue} <DownOutlined />
                            </Button>
                          </Dropdown>
                    </Form.Item>)}

                    <Form.Item
                    label='Property Name'
                    name="propertyName"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Property Title'
                    name="propertyTitle"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Property Country'
                    name="propertyCountry"
                    validateStatus={countries ? '' : 'validating'}
                    hasFeedback
                    help={countries ? '' : "Loading Countries..."}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <Select autoClearSearchValue showSearch onChange={currentCountry} placeholder="Select Country" defaultValue={'India'} value='India'>
                      {countries?.sort(function (a, b) { return b.name['en'].localeCompare(a.name['en']); }).map(country =>
                        <Option key={country._id} value={country.name['en']} >{country.name['en']}</Option>
                      )}
                    </Select>
                  </Form.Item>

                  {cCountry &&
                    <Form.Item
                      label='Property State'
                      name="propertyState"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>

                      <Select showSearch placeholder="Select state"  >
                        {usaStates?.map(state =>
                          <Option key={state.state_id} value={state.state_name}>{state.state_name}</Option>
                        )}
                      </Select>

                    </Form.Item>
                  }

                  {!cCountry &&
                    <Form.Item
                      label='Company State'
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder='Company State'/>
                    </Form.Item>
                  }

                  <Form.Item
                    label='Property City'
                    name="propertyCity"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Property City' />
                  </Form.Item>

                  <Form.Item
                    label='Property PIN Code'
                    name="propertyZipCode"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      },
                      {
                        validator: (rule, value) => validationPatterns?.number?.validate(value)
                      }
                    ]}>
                    <Input placeholder='Enter PINCode' />
                  </Form.Item>

                  <Form.Item
                    label='Property Address'
                    name="propertyAddress"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Property Address' />
                  </Form.Item>

                  {(props.propertyType==='residential') && (<Form.Item
                    label='Carpet Area(Sq. Ft.)'
                    name="carpetArea"
                    rules={[
                      {
                        required: props.propertyType==='residential' ? true : false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="number" placeholder='Enter Carpet Area' />
                  </Form.Item>)}

                  {(props.propertyType==='residential') && (<Form.Item
                    label="Built up Area(Sq. Ft.)"
                    name="builtUpArea"
                    rules={[
                      {
                        required: props.propertyType==='residential' ? true : false,
                        message: "This field is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Built up Area" type="number" />
                  </Form.Item>)}

                  { (<Form.Item
                    label="Minimum Investment"
                    name="minimumInvestment"
                    rules={[
                      {
                        required:  true,
                        message: "This field is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Minimum Investment" type="number" addonAfter={environment.currencySign}/>
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Number of Units'
                    name="numberOfUnits"
                    rules={[
                      {
                        required: false,
                        whitespace: true
                      }
                    ]}>
                    <Input type="number" placeholder='Enter Number of Units' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && ( <Form.Item
                    label='Year Built(year)'
                    name="yearBuilt"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Year Built' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Primary Property Type' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Primary Sub-Type' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Building Size (Sq. Ft.)' />
                  </Form.Item>)}

                  {false && (selectedValue === 'Land' || props.propertyType==='residential') && (<Form.Item
                    label='Lot Size'
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true,
                        validateTrigger: 'onChange',
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Lot Size' addonAfter='Acers' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Parking Count' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Year Renovated'
                    name="yearRenovated"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Year Renovated' />
                  </Form.Item>)}

                  {(props.propertyType != 'residential' && props.propertyType !='project') && <Form.Item
                    label='Current Occupancy'
                    name="currentOccupancy"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Current Occupancy' type="number" addonAfter="%" />
                  </Form.Item>}

                  {props.propertyType != 'residential' && <Form.Item
                    label='Market Occupancy'
                    name="marketOccupancy"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Market Occupancy' type="number" addonAfter="%" />
                  </Form.Item>}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Current Average Rents'
                    name="currentAverageRent"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Current Average Rents' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>)}

                 {false && <Form.Item
                    label='Average Market Rents'
                    name="averageMarketRent"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Average Market Rents' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>}

                  {props.propertyType != 'residential' &&<Form.Item
                    label='Purchase Price'
                    name="purchasedPrice"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Purchase Price' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>}

                  {false && <Form.Item
                    label='Stabilized Loan to Value'
                    name="stabilizedLoanToValue"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Stabilized Loan to Value' type="number" addonAfter="%" />
                  </Form.Item>}

                  <Form.Item
                    label="Landmark"
                    name="landmark"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Landmark" type="text" />
                  </Form.Item>

                  {props.propertyType != 'residential' && <Form.Item
                    label="Property Age"
                    name="propertyAge"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Property Age" type="number" addonAfter="years" />
                  </Form.Item>}

                  <Form.Item
                    label="Price per Sq-FT"
                    name="pricePerSqFt"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Price per Sq-FT" type="number" addonAfter={environment.currencySign} />
                  </Form.Item>

                  {/* Image upload starts here */}
                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>Upload Property Photos</Title>

                    <Form.Item name="attachement" style={{ justifyContent: 'center' }}>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "attachement", setFilesObj, form);
                        }}
                        accept='.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp,.WEBP,.tiff,.TIFF'
                        disabled={loading || disbaleUpload}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                       
                      />
                      {fileSizeError && <h5 style={{ color: 'red', margin: '10px 0' }}>File Size can not be more than 10MB</h5>}
                      {filesObj.attachement.uploadFilePercentage > 0 && (
                        <>
                          <Progress
                            percent={filesObj.attachement.uploadFilePercentage}
                          />
                          <br />
                          <div align="left">
                            Files Uploaded:
                            <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                              {documents?.map((obj, index) => (
                                <List.Item
                                  textalign="left"
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  actions={[
                                    <Popconfirm
                                      title="This item will be deleted!"
                                      onConfirm={() => deleteItem(index)}
                                    >
                                      <Button type="primary" danger>
                                        X
                                      </Button>
                                    </Popconfirm>,
                                  ]}
                                >
                                  <List.Item.Meta title={obj} />
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        </>
                      )}{" "}
                    </Form.Item>
                  </div>

                  <br /><br />
                  {propertyCategoryTypeValue !== 'residentialProperty' && (<Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
                  </Form.Item>)}
                  <br /><br />
                </Card>
              </Form>
            </Col>
          </Row>
        </>
      )}

      {!loading && props.basicInfoDone && (
        <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop: '20px' }}>Property Details</Title>
          <br /><br />
          <Row justify="center">
          <Col span={23}>
              {!editBasicInfo && (<Form {...formItemLayout} labelAlign ="left" form={form} onFinish={saveBasicInfo}>
                <Card>
                  <div className='edit-basic-info'>
                    <Form.Item
                      label={`Property Id`}
                      name="propertyId">
                      <Input placeholder={`${props.propertyInfo.propertyCount}`}
                        disabled={true} />
                    </Form.Item>
                    {/* <Form.Item
                      label={`Property Category Type`}
                      name="propertyCategoryType">
                      <Radio.Group defaultValue={propertyCategoryTypeValue} disabled={true}>
                          <Radio onClick={()=>setPropertyCategoryTypeValue('commercialProperty')} value="commercialProperty">Commercial Property</Radio>
                          <Radio onClick={()=>setPropertyCategoryTypeValue('residentialProperty')} value="residentialProperty" disabled={true}>Residential Property</Radio>
                      </Radio.Group>
                    </Form.Item> */}

                   {false && <Form.Item
                      label={
                        <span>
                           <span style={{ color: '#ff4d4f', fontSize: '12px' }}>*</span> Asset Type
                        </span>
                      }
                      name="assetType"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true,
                          validateTrigger: 'onChange',
                        }
                      ]}>
                      <Dropdown overlay={dropDownMenu} disabled={true}>
                        <Button>
                          {selectedValue} <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Form.Item>}

                    <Form.Item
                      label='Property Name'
                      name="propertyName"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyName} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property Title'
                      name="propertyTitle"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyTitle} disabled={true} />
                    </Form.Item>

                    

                    <Form.Item
                      label='Property Country'
                      name="propertyCountry"
                      validateStatus={countries ? '' : 'validating'}
                      hasFeedback
                      help={countries ? '' : "Loading Countries..."}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCountry} disabled={true} />
                    </Form.Item>

                    {cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} disabled={true} />
                      </Form.Item>
                    }

                    {!cCountry &&
                      <Form.Item
                      label='Company State'
                      name="state"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.state} disabled={true} />
                      </Form.Item>
                    }

                    <Form.Item
                      label='Property City'
                      name="propertyCity"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCity} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property PIN Code'
                      name="propertyZipCode"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        },
                        {
                          validator: (rule, value) => validationPatterns?.number?.validate(value)
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyZipCode} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property Address'
                      name="propertyAddress"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyAddress} disabled={true} />
                    </Form.Item>

                    { (props.propertyType==='residential') && (<Form.Item
                      label='Carpet Area(Sq. Ft.)'
                      name="carpetArea"
                      rules={[
                        {
                          required: props.propertyType==='residential' ? true : false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.builtUpArea} disabled={true} />
                    </Form.Item>)}

                    {(props.propertyType==='residential') && (<Form.Item
                      label="Built up Area(Sq. Ft.)"
                      name="builtUpArea"
                      rules={[
                        {
                          required: props.propertyType==='residential' ? true : false,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={props.propertyInfo.builtUpArea} disabled={true} type="number" />
                    </Form.Item>)}

                    { (<Form.Item
                      label="Minimum Investment"
                      name="minimumIvestment"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={props.propertyInfo.minimumInvestment} disabled={true} type="number" addonAfter={environment.currencySign}/>
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                      label='Number of Units'
                      name="numberOfUnits"
                      rules={[
                        {
                          required: false,
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.numberOfUnits} disabled={true} />
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                      label='Year Built(year)'
                      name="yearBuilt"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearBuilt} disabled={true} />
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primaryPropertyType} disabled={true} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primarySubType} disabled={true} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.buildingSize} disabled={true} />
                  </Form.Item>)}

                  {false && (selectedValue === 'Land' || props.propertyType==='residential') && (<Form.Item
                    label={
                      <span>
                         <span style={{ color: '#ff4d4f', fontSize: '12px' }}>*</span> Lot Size
                      </span>
                    }
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true,
                        validateTrigger: 'onChange',
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.lotSize} disabled={true} defaultValue={props.propertyInfo.lotSize} value={props.propertyInfo.lotSize} addonAfter='Acers' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.parkingCount} disabled={true} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                      label='Year Renovated'
                      name="yearRenovated"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearRenovated} disabled={true} />
                    </Form.Item>)}

                    {(props.propertyType != 'residential' && props.propertyType !='project') && <Form.Item
                      label='Current Occupancy'
                      name="currentOccupancy"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentOccupancy} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>}

                    {props.propertyType != 'residential' && <Form.Item
                      label='Market Occupancy'
                      name="marketOccupancy"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.marketOccupancy} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>}

                    {false && selectedValue !== 'Land' && (<Form.Item
                      label='Current Average Rents'
                      name="currentAverageRent"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentAverageRent} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>)}

                    {false &&<Form.Item
                      label='Average Market Rents'
                      name="averageMarketRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.averageMarketRent} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>}

                    {props.propertyType != 'residential' &&<Form.Item
                      label='Purchase Price'
                      name="purchasedPrice"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.purchasedPrice} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>}

                    {false && <Form.Item
                      label='Stabilized Loan to Value'
                      name="stabilizedLoanToValue"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.stabilizedLoanToValue} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>}

                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.landmark}
                        disabled={true}
                        type="number"
                      />
                    </Form.Item>

                    {props.propertyType != 'residential' &&<Form.Item
                      label="Property Age"
                      name="propertyAge"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.propertyAge}
                        disabled={true}
                        addonAfter="years"
                        type="number"
                      />
                    </Form.Item>}

                     <Form.Item //disable mode
                      label="Price per Sq-FT"
                      name="pricePerSqFt"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      {(props.propertyInfo.purchasedPrice && !isNaN(props.propertyInfo.purchasedPrice) &&  !isNaN(props.propertyInfo.builtUpArea)) ? (
                        <Input
                        placeholder={(parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.builtUpArea))?.toFixed(2) + "/Sq. Ft."}
                        defaultValue={(parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.builtUpArea))?.toFixed(2) + "/Sq. Ft."}
                        type="number"
                        disabled={true}
                        addonAfter={environment.currencySign}
                      />
                      ) :(
                        <Input
                        type="number" defaultValue={props.propertyInfo.pricePerSqFt} value={props.propertyInfo.pricePerSqFt} addonAfter={environment.currencySign} disabled={true}
                      />
                      )}

                    </Form.Item> 

                    <br /><br />
                    <Form.Item style={{ display: "block", float: "right" }}>
                      {(userInfo?.role === 'issuer company manager') && <Button
                        type="primary"
                        size="large"
                        onClick={() => { setEditUploadView(false); setEditBasicInfo(true) }}
                      >
                        Edit
                      </Button>}
                    </Form.Item>
                    <br /><br />
                  </div>
                  <br /><br />

                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>{!editUploadView ? 'Uploaded Property Photos' : 'Edit Property Photos'}</Title>
                    {!editUploadView && props.propertyInfo.propertyImages ? (
                      <>
                        <ul>
                          {props.propertyInfo.propertyImages?.map((item, index) => (
                            <li key={index} className="image-list-item"
                              style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                              <a href={item.URL} download={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a></li>
                          ))}
                        </ul>
                        {(userInfo?.role === 'issuer company manager') && <Button size='large' style={{ float: 'right', marginTop: '5px' }} type='secondary' onClick={() => { setEditBasicInfo(false); setEditUploadView(true) }}>Edit Photos</Button>}
                      </>
                    ) : ''}
                    {editUploadView && props.propertyInfo.propertyImages && (
                      <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={editPhotos}>
                        <Form.Item name="attachement" style={{ justifyContent: 'center' }}>
                          <Input
                            type="file"
                            onChange={(e) => {
                              uploadFile(e, "attachement", setFilesObj, form);
                            }}
                            accept='.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp,.WEBP,.tiff,.TIFF'
                            disabled={loading || disbaleUpload}
                          />
                          {fileSizeError && <h5 style={{ color: 'red', margin: '10px 0' }}>File Size can not be more than 10MB</h5>}
                          {filesObj?.attachement && (<Progress
                            percent={filesObj.attachement.uploadFilePercentage}
                          />)}
                          <br />
                          <div align="left">
                            Files Uploaded:
                            <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                              {documents?.map((obj, index) => (
                                <List.Item
                                  textalign="left"
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  actions={[
                                    <Popconfirm
                                      title="This item will be deleted!"
                                      onConfirm={() => deleteItem(index)}
                                    >
                                      <Button type="primary" danger>
                                        X
                                      </Button>
                                    </Popconfirm>,
                                  ]}
                                >
                                  <List.Item.Meta title={obj ? obj.name ? obj.name : obj : null} />
                                </List.Item>
                              ))}
                              <Button size='large' style={{ marginRight: '50px', marginTop: '10px' }} type='secondary' onClick={onUploadBack}>Back</Button>
                              <Button size='large' style={{ marginTop: '10px' }} type='primary' htmlType="submit" loading={loading}>Update</Button>
                            </List>
                          </div>
                        </Form.Item>
                      </Form>
                    )}
                  </div>
                  <br /> <br />
                </Card>
              </Form>)}

              {editBasicInfo && (<Form {...formItemLayout} labelAlign="left" form={form2} onFinish={updateBasicInfo}>
                <Card>
                  <div className='edit-basic-info'>
                    <Form.Item
                      label={`Property Id`}
                      name="propertyId">
                      <Input placeholder={`${props.propertyInfo.propertyCount}`} defaultValue={props.propertyInfo.propertyCount} disabled
                      />
                    </Form.Item>

                    {console.log(`propertyCategoryTypeValue: ${propertyCategoryTypeValue}`)}
                    {/* <Form.Item label={`Property Category Type`} name="propertyCategoryType">
                      <Radio.Group defaultValue={propertyCategoryTypeValue} value={propertyCategoryTypeValue}>
                        <Radio onClick={() => setPropertyCategoryTypeValue('commercialProperty')} value="commercialProperty" checked="checked">
                          Commercial Property
                        </Radio>
                        <Radio onClick={() => setPropertyCategoryTypeValue('residentialProperty')} value="residentialProperty" disabled={true}>
                          Residential Property
                        </Radio>
                      </Radio.Group>
                    </Form.Item> */}

                    {/* <div style={{}}>
                      <label for="propertyCategoryType">Property Category Type:</label>
                      <label for="commercialProperty" style={{marginLeft: '80px'}}>
                        <input type="radio" id="commercialProperty" name="propertyCategoryType" value="commercialProperty" checked/> Commercial Property
                      </label>
                      <label for="residentialProperty" style={{marginLeft: '80px'}}>
                        <input type="radio" id="residentialProperty" name="residentialProperty" value="residentialProperty" disabled="true" /> Residential Property
                      </label>
                    </div> */}
                    <br/>

                    {false && <Form.Item
                      label={
                        <span>
                           <span style={{ color: '#ff4d4f', fontSize: '12px' }}>*</span> Asset Type
                        </span>
                      }
                      name="assetType"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: false,
                          validateTrigger: 'onChange',
                        }
                      ]}>
                      <Dropdown overlay={dropDownMenu}>
                        <Button>
                          {selectedValue} <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Form.Item>}
                    
                    <Form.Item
                      label='Property Name'
                      name="propertyName"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyName} defaultValue={props.propertyInfo.propertyName} />
                    </Form.Item>

                    <Form.Item
                      label='Property Title'
                      name="propertyTitle"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyTitle} defaultValue={props.propertyInfo.propertyTitle} />
                    </Form.Item>

                    <Form.Item
                      label='Property Country'
                      name="propertyCountry"
                      validateStatus={countries ? '' : 'validating'}
                      hasFeedback
                      help={countries ? '' : "Loading Countries..."}
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCountry} defaultValue={props.propertyInfo.propertyCountry} />
                    </Form.Item>

                    {cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} defaultValue={props.propertyInfo.propertyState} />
                      </Form.Item>
                    }

                    {!cCountry &&
                      <Form.Item
                      label='Company State'
                      name="state"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.state} defaultValue={props.propertyInfo.state} />
                      </Form.Item>
                    }

                    <Form.Item
                      label='Property City'
                      name="propertyCity"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCity} defaultValue={props.propertyInfo.propertyCity} />
                    </Form.Item>

                    <Form.Item
                      label='Property PIN Code'
                      name="propertyZipCode"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        },
                        {
                          validator: (rule, value) => validationPatterns?.number?.validate(value)
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyZipCode} defaultValue={props.propertyInfo.propertyZipCode} />
                    </Form.Item>

                    <Form.Item
                      label='Property Address'
                      name="propertyAddress"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyAddress} defaultValue={props.propertyInfo.propertyAddress} />
                    </Form.Item>

                    {(props.propertyType==='residential') && (<Form.Item
                      label='Carpet Area(Sq. Ft.)'
                      name="carpetArea"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.builtUpArea} defaultValue={props.propertyInfo.builtUpArea} />
                    </Form.Item>)}

                    {(props.propertyType==='residential') && (<Form.Item
                      label="Built up Area(Sq. Ft.)"
                      name="builtUpArea"
                      rules={[
                        {
                          required: false,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.builtUpArea} defaultValue={props.propertyInfo.builtUpArea}
                        type="number"
                      />
                    </Form.Item>)}

                    {(<Form.Item
                      label="Minimum Investment"
                      name="minimumInvestment"
                      rules={[
                        {
                          required: false,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.minimumInvestment} defaultValue={props.propertyInfo.minimumInvestment}
                        type="number"
                        addonAfter={environment.currencySign}
                      />
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                      label='Number of Units'
                      name="numberOfUnits"
                      rules={[
                        {
                          required: false,
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.numberOfUnits} defaultValue={props.propertyInfo.numberOfUnits} />
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                      label='Year Built(year)'
                      name="yearBuilt"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearBuilt} defaultValue={props.propertyInfo.yearBuilt} />
                    </Form.Item>)}

                    {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primaryPropertyType} defaultValue={props.propertyInfo.primaryPropertyType} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primarySubType} defaultValue={props.propertyInfo.primarySubType} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.buildingSize} defaultValue={props.propertyInfo.buildingSize} />
                  </Form.Item>)}

                  {false && selectedValue === 'Land' && (<Form.Item
                    label={
                      <span>
                         <span style={{ color: '#ff4d4f', fontSize: '12px' }}>*</span> Lot Size
                      </span>
                    }
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true,
                        validateTrigger: 'onChange',
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.lotSize} defaultValue={props.propertyInfo.lotSize} value={props.propertyInfo.lotSize} addonAfter='Acers' />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.parkingCount} defaultValue={props.propertyInfo.parkingCount} />
                  </Form.Item>)}

                  {false && selectedValue !== 'Land' && (<Form.Item
                      label='Year Renovated'
                      name="yearRenovated"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearRenovated} defaultValue={props.propertyInfo.yearRenovated} />
                    </Form.Item>)}

                    {(props.propertyType != 'residential' && props.propertyType !='project') && <Form.Item
                      label='Current Occupancy'
                      name="currentOccupancy"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentOccupancy} defaultValue={props.propertyInfo.currentOccupancy} type="number" addonAfter="%" />
                    </Form.Item>}

                   {props.propertyType != 'residential' && <Form.Item
                      label='Market Occupancy'
                      name="marketOccupancy"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.marketOccupancy} defaultValue={props.propertyInfo.marketOccupancy} type="number" addonAfter="%" />
                    </Form.Item>}

                    {false && selectedValue !== 'Land' && ( <Form.Item
                      label='Current Average Rents'
                      name="currentAverageRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentAverageRent} defaultValue={props.propertyInfo.currentAverageRent} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>)}

                   {false && <Form.Item
                      label='Average Market Rents'
                      name="averageMarketRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.averageMarketRent} defaultValue={props.propertyInfo.averageMarketRent} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>}

                    {props.propertyType != 'residential' && <Form.Item
                      label='Purchase Price'
                      name="purchasedPrice"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.purchasedPrice} defaultValue={props.propertyInfo.purchasedPrice} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>}

                    {false && <Form.Item
                      label='Stabilized Loan to Value'
                      name="stabilizedLoanToValue"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.stabilizedLoanToValue} defaultValue={props.propertyInfo.stabilizedLoanToValue} type="number" addonAfter="%" />
                    </Form.Item>}

                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.landmark}
                        defaultValue={props.propertyInfo.landmark}
                        type="text"
                      />
                    </Form.Item>

                    {props.propertyType != 'residential' &&<Form.Item
                      label="Property Age"
                      name="propertyAge"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.propertyAge}
                        defaultValue={props.propertyInfo.propertyAge}
                        type="number"
                        addonAfter="years"
                      />
                    </Form.Item>}

                    <Form.Item //edit mode
                      label="Price per Sq-FT"
                      name="pricePerSqFt"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      {(props.propertyInfo.purchasedPrice && !isNaN(props.propertyInfo.purchasedPrice) &&  !isNaN(props.propertyInfo.builtUpArea)) ? (
                        <Input
                        placeholder={(parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.builtUpArea))?.toFixed(2) + "/Sq. Ft."}
                        defaultValue={(parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.builtUpArea))?.toFixed(2) + "/Sq. Ft."}
                        type="number"
                        disabled={true} addonAfter={environment.currencySign}
                      />
                      ) :(
                        <Input
                        type="number" defaultValue={props.propertyInfo.pricePerSqFt} value={props.propertyInfo.pricePerSqFt} addonAfter={environment.currencySign}
                      />
                      )}

                    </Form.Item>

                    <br /><br />
                    <div className='btn-ctn' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button size='large' style={{ display: 'flex', justifyContent: 'flex-end' }} type='primary' htmlType="submit" loading={loading}>Update</Button>
                    </div>
                    <br /><br />
                  </div>

                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>{!editUploadView ? 'Uploaded Property Photos' : 'Edit Property Photos'}</Title>
                    {!editUploadView && props.propertyInfo.propertyImages ? (
                      <>
                        <ul>
                          {props.propertyInfo.propertyImages?.map((item, index) => (
                            <li key={index} className="image-list-item"
                              style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                              <a href={item.URL} download={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a></li>
                          ))}
                        </ul>
                        <Button size='large' style={{ float: 'right', marginTop: '5px' }} type='secondary' onClick={() => { setEditBasicInfo(false); setEditUploadView(true) }}>Edit Photos</Button>
                      </>
                    ) : ''}
                  </div>
                  <br /> <br />
                </Card>
              </Form>)}
          </Col>
          </Row>
        </>
      )}

      {/* property about section here & description of management team here */}
      {!loading && (<>
        <Row justify="center">
          <Col span={23}>
            <Form {...formItemLayout} labelAlign="left" form={form3} onFinish={savePropertyAboutManagementTeam}>
              <Card>
                <Form.Item
                  label='Description of Property'
                  name='descriptionOfProperty'
                  rules={[{ required: (propertyDescription) ? false : true , message: 'Description of Property is required' }]}>
                  <textarea defaultValue={propertyDescription} value={propertyDescription} onChange={(e) => setPropertyDescription(e.target.value)}  style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <br/>
        
              <Form.Item
                  label='Description of Management Team'
                  name='descriptionOfManagementTeam'
                  rules={[{ required: false, message: 'Description of Management Team is required' }]}>
                  <textarea defaultValue={managementTeamDescription} value={managementTeamDescription} onChange={(e) => setManagementTeamDescription(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {(userInfo?.role === 'issuer company manager')  && <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>}
                </Form.Item>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
      )}


    {/* team members details here */}
    {!loading && (
      <Row justify="center">
        <Col span={23}>
          <br/>
          {teamMembers?.length > 0 && (teamMembers?.map((mem, index) => (
            <div style={{marginBottom: '20px', border: '1px solid black', padding: '10px'}} key={index}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div style={{ flex: 1, marginRight: '20px' }}>
                <div>
                  <h3>Designated Partner Details:</h3>
                  <p>Name of Designated Partner: {mem?.memberName}</p>
                  <p>Address of Designated Partner: {mem?.memberRole || " "}</p>
                  <p>DPIN of Designated Partner: {mem?.memberContact || ""}</p>
                </div>
              </div>
              {mem?.memberPhoto?.URL && (
              <div style={{ width: '300px', height: 'auto', border: '1px solid black', padding: '10px' }}>
                <h6>Designated Partner Photo</h6>
                    <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <img src={mem?.memberPhoto?.URL} alt={`${mem?.memberName}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                      <p>{`${mem?.memberName}`}</p>
                    </div>
              </div>
              )}
            </div>
              {/* <Form.Item
                label='Name of Designated Partner'
                name='name'
                rules={[{ required: true, message: 'Name of Designated Partner is required' }]}
                >
                <Input value={mem?.memberName} defaultValue={mem?.memberName} disabled={true} style={{maxWidth: '300px'}}/>
              </Form.Item>

              <Form.Item
                style={{ marginLeft: '12px'}}
                label="Designated Partner Photo"
                name='photo'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <div style={{}}>
                    {mem?.memberPhoto && (<a key={Math.random()} href={`${mem?.memberPhoto.URL}`} target="_blank">{mem?.memberPhoto?.name}</a>)}
                  </div>
                </>
              </Form.Item>
              <Form.Item
                label='Address of Designated Partner'
                name='memberRole'
                rules={[{ required: true, message: 'Address of Designated Partner is required' }]}>
                <textarea defaultValue={mem?.memberRole} value={mem?.memberRole} disabled={true} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>
              <Form.Item
                label='DPIN of Designated Partner'
                name='memberCOntactDetails'
                rules={[{ required: true, message: 'DPIN of Designated Partner is required' }]}>
                <Input defaultValue={mem?.memberContact} value={mem?.memberContact} disabled={true} style={{maxWidth: '300px'}}/>
              </Form.Item> */}
              <br/>
              {teamMembers?.length > 2 ? <div style={{display: 'flex', justifyContent: 'center'}}>
                <Popconfirm
                  title="This member will be deleted!"
                  onConfirm={() => deleteMember(index)}>
                  {(userInfo?.role === 'issuer company manager')  &&
                  <Button type="primary" danger>
                    Delete Member X
                  </Button>}
                </Popconfirm>
              </div> : <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button type="primary" danger disabled={true}>
                    Delete Member X
                </Button>
              </div>}
            </div>
          )))}
          <br/>
          <Form {...formItemLayout} labelAlign="left" form={form5} onFinish={addTeamMember}>
          {(userInfo?.role === 'issuer company manager')  &&  (
          selectedPartners.map((selectedPartner, index) => (
            <div key={index} style={{ marginBottom: '20px', marginTop: '20px', border: '1px solid black', padding: '10px' }}>
               <Form.Item
                label={`Select Designated Partner ${index + 1}`}
                name={['partners', index]}
                validateStatus={designatedPartners ? '' : 'validating'}
                hasFeedback
                help={designatedPartners ? '' : "Loading Members..."}
                // rules={[{ required: index < 2, message: 'This field is required', }]}
              >
                <Select
                  autoClearSearchValue
                  showSearch
                  onChange={(value) => handleSelectPartner(value, index)}
                  placeholder="Select a designated partner"
                  value={selectedPartner.id || undefined}
                >
              `{designatedPartners
              .filter(partner => !teamMembers.find(tm => tm?.memberName === `${partner?.firstName} ${partner?.lastName}`) && !selectedPartners.find((sp, spIndex) => spIndex !== index && sp?.id === partner?._id))
              .map(partner => (
                <Option key={partner?._id} value={partner?._id}>{`${partner?.firstName} ${partner?.lastName}`}</Option>
            ))}`
                </Select>
              </Form.Item>
              
              {selectedPartner.id && (
                <div>
                  {designatedPartners.filter(p => p?._id === selectedPartner?.id).map(partner => (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div style={{ flex: 1, marginRight: '20px' }}>
                      <div key={partner._id}>
                        <h3>Selected Designated Partner Details:</h3>
                        <p>Name of Designated Partner: {`${partner?.firstName} ${partner?.lastName}`}</p>
                        <p>Address of Designated Partner: {`${partner?.streetAddress1 || ""} ${partner?.streetAddress2 || ""}`.trim()}</p>
                        <p>DPIN of Designated Partner: {partner?.dPIN || ""}</p>
                      </div>
                    </div>
                    {partner?.designatedPartnerPhoto?.URL &&(
                    <div style={{ width: '300px', height: 'auto', border: '1px solid black', padding: '10px' }}>
                      <h6>Designated Partner Photo</h6>
                          <div key={index} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={partner?.designatedPartnerPhoto?.URL} alt={`${partner?.firstName} ${partner?.lastName}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            <p>{`${partner?.firstName} ${partner?.lastName}`}</p>
                          </div>
                    </div>
                    )}
                    {partner?.llpSuperAdminPhoto?.URL &&(
                    <div style={{ width: '300px', height: 'auto', border: '1px solid black', padding: '10px' }}>
                      <h6>Designated Partner Photo</h6>
                          <div key={index} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={partner?.llpSuperAdminPhoto?.URL} alt={`${partner?.firstName} ${partner?.lastName}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            <p>{`${partner?.firstName} ${partner?.lastName}`}</p>
                          </div>
                    </div>
                    )}
                  </div>
                  ))}
                </div>
              )}
            </div>
          ))
          )}
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {(userInfo?.role === 'issuer company manager')  && (
              <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
            )}
          </Form.Item>
          </Form>



          {/* <Form {...formItemLayout} labelAlign="left" form={form5} onFinish={addTeamMember}>
            <Card>
              <Form.Item
                label='Name of Designated Partner'
                name='name'
                rules={[{ required: true, message: 'Name of Designated Partner is required' }]}>
                <Input value={memberName} onChange={(e)=> setMemberName(e.target.value)}/>
              </Form.Item>

              {!props.userInfo.roles.includes('master company super admin') &&
                <Form.Item
                label="Designated Partner Photo"
                name='photo'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadPhoto(e, 'photo') }} accept=".png,.PNG,.JPG,.jpg,.JPEF,.jpeg"/>
                  {memberPhoto?.uploadFilePercentage > 0 &&
                    <Progress percent={memberPhoto?.uploadFilePercentage} />
                  }
                  <div style={{}}>
                    <p>File Uploaded:</p>
                    {memberPhoto && (<h4 key={Math.random()}>{memberPhoto?.name}</h4>)}
                  </div>
                </>
              </Form.Item>}
              
              <Form.Item
                label='Address of Designated Partner'
                name='memberRole'
                rules={[{ required: true, message: 'Address of Designated Partner is required' }]}>
                <textarea defaultValue={memberRole} value={memberRole} onChange={(e)=> setMemberRole(e.target.value)} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>
              <Form.Item
                label='DPIN of Designated Partner'
                name='memberCOntactDetails'
                rules={[{ required: true, message: 'DPIN of Designated Partner is required' }]}>
                <textarea defaultValue={memberContact} value={memberContact} onChange={(e)=> setMemberContact(e.target.value)} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>

              <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                {!props.userInfo.roles.includes('master company super admin') && <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>}
              </Form.Item>
            </Card>
          </Form> */}
        </Col>
      </Row>
      )}

      {/* team members details here
      {!loading && (<>
        <Row justify="center">
          <Col span={23}>
            <br />
            {teamMembers?.length > 0 && (teamMembers.map((mem, index) => (
              <div style={{ marginBottom: '20px', border: '1px solid black', padding: '10px' }} key={index}>
                <Form.Item
                  label='Name of Member'
                  name='name'
                  rules={[{ required: true, message: 'Name of Member is required' }]}>
                  <Input value={mem.memberName} defaultValue={mem.memberName} disabled={true} style={{ maxWidth: '300px' }} />
                </Form.Item>

                <Form.Item
                  label="Member Photo"
                  name='photo'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <div style={{}}>
                      {mem.memberPhoto && (<a key={Math.random()} href={`${mem.memberPhoto.URL}`} target="_blank">{mem.memberPhoto?.name}</a>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label='Description of Member Role'
                  name='memberRole'
                  rules={[{ required: true, message: 'Description of Member Role is required' }]}>
                  <textarea defaultValue={mem.memberRole} value={mem.memberRole} disabled={true} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item
                  label='Description of Member Contact Details'
                  name='memberCOntactDetails'
                  rules={[{ required: true, message: 'Description of Member Contact Details' }]}>
                  <textarea defaultValue={mem.memberContact} value={mem.memberContact} disabled={true} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Popconfirm
                    title="This member will be deleted!"
                    onConfirm={() => deleteMember(index)}>
                    <Button type="primary" danger>
                      Delete Member X
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )))}
            <br />
            <Form {...formItemLayout} labelAlign="left" form={form5} onFinish={addTeamMember}>
              <Card>
                <Form.Item
                  label='Name of Member'
                  name='name'
                  rules={[{ required: true, message: 'Name of Member is required' }]}>
                  <Input value={memberName} onChange={(e) => setMemberName(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="Member Photo"
                  name='photo'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadPhoto(e, 'photo') }} accept=".png,.PNG,.JPG,.jpg,.JPEF,.jpeg" />
                    {memberPhoto?.uploadFilePercentage > 0 &&
                      <Progress percent={memberPhoto?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>File Uploaded:</p>
                      {memberPhoto && (<h4 key={Math.random()}>{memberPhoto?.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label='Description of Member Role'
                  name='memberRole'
                  rules={[{ required: true, message: 'Description of Member Role is required' }]}>
                  <textarea defaultValue={memberRole} value={memberRole} onChange={(e) => setMemberRole(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item
                  label='Description of Member Contact Details'
                  name='memberCOntactDetails'
                  rules={[{ required: true, message: 'Description of Member Contact Details' }]}>
                  <textarea defaultValue={memberContact} value={memberContact} onChange={(e) => setMemberContact(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}}/>
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
                </Form.Item>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
      )} */}

    </>
  
  )
}
