import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Button, Spin, Input, Select, List, Progress, Popconfirm, notification} from 'antd';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import './addProperty.css';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import TextArea from 'antd/lib/input/TextArea';
import { environment } from '../../../environments/environment';
const {Title} = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default function OfferingInformation(props) {
  const [loading, setLoading] = useState(false);
  const [form, form2] = Form.useForm();
  const [offeringOverview, setOfferingOverview] = useState(false);
  const [offeringVideo, setOfferingVideo] = useState(false);
  const [diligenceReport, setDiligenceReport] = useState(false);
  const [financials, setFinancials] = useState(false);
  const [location, setLocation] = useState(false);
  const [FAQs, setFAQs] = useState(false);
  const [fees, setFees] = useState(false);
  const [diligenceReportText, setDiligenceReportText] = useState();
  const [financialsText, setFinancialsText] = useState();
  const [locationText, setLocationText] = useState();
  const [FAQsText, setFAQsText] = useState();
  const [feesText, setFeesText] = useState();
  const [content, setContent] = useState("");
  const [text, setText] = useState("");
  const [tabName, setTabName] = useState(1);
  const [active, setActive] = useState(1);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [uploading, setUploading] = useState(0);
  const [disbaleUpload, setDisableUpload] = useState(false);
  const [editUploadView, setEditUploadView] = useState(false);
  const [videos, setVideos] = useState([]);
  const [editTab, setEditTab] = useState(false);
  const [videoFiles, setVideoFiles] = useState({
   offeringVideoFile: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null
   }
 });
 const [keyFiles, setKeyFiles] = useState(
   {
     diligenceReportFile: {
       uploading: false,
       uploadFilePercentage: 0,
       URL: null,
       name: null,
       type: null
     },
     financialsFile: {
       uploading: false,
       uploadFilePercentage: 0,
       URL: null,
       name: null,
       type: null
     },
     locationFile: {
       uploading: false,
       uploadFilePercentage: 0,
       URL: null,
       name: null,
       type: null
     },
     FAQsFile: {
       uploading: false,
       uploadFilePercentage: 0,
       URL: null,
       name: null,
       type: null
     },
     feesFile: {
       uploading: false,
       uploadFilePercentage: 0,
       URL: null,
       name: null,
       type: null
     },
   }
 );

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };
  const tailFormItemLayout = {
   wrapperCol: {
     xs: {
       span: 24,
       offset: 0
     },
     sm: {
       span: 14,
       offset: 10
     }
   }
 };

  useEffect(()=>{
   if(props.propertyInfo){
      let pro= props.propertyInfo;
      if(pro.offeringOverview){
         setOfferingOverview(true);
         setContent(pro.offeringOverview);
      }
      if(pro.offeringVideoFile){
         setOfferingVideo(true);
      }
      if(pro.offeringVideoFile?.length > 0) {
         setVideos(pro.offeringVideoFile);
         if(pro.offeringVideoFile?.length > 4) {
           setDisableUpload(true);
         }
       }
      if(pro.diligenceReport){
         setDiligenceReport(true);
         setDiligenceReportText(pro.diligenceReport);
         if(props.propertyType === 'residential'){
            setTabName(3);
            setActive(3);
         }else{
            setTabName(2);
            setActive(2);
         }

      }
      if(pro.financials){
         setFinancials(true);
         setFinancialsText(pro.financials);
         setTabName(3);
         setActive(3);
      }
      if(pro.location){
         setLocation(true);
         setLocationText(pro.location);
         setTabName(2);
         setActive(2);
      }
      if(pro.FAQs){
         setFAQs(true);
         setFAQsText(pro.FAQs);
         // setTabName(5);
         // setActive(5);
         setTabName(2);
         setActive(2);
         
      }
      if(pro.fees){
         setFees(true);
         setFeesText(pro.fees);
         setTabName(1);
         setActive(1);
      }
      if(props.offeringDone){
         setActive(0);
         setTabName(1);
      }
   }
  },[])

  const handleChange = (content, editor) => {
   setContent(content);
 };
 useEffect(() => {
   if (tabName === 4) {
     setCanSubmit(true);
   } else {
     setCanSubmit(false);
   }
 }, [tabName]);

 const handleTabChange = (tabIndex) => {
   setTabName(tabIndex);
   setActive(tabIndex);
 };

  const saveOfferingInformation = async (form1) => {
    //const form = { ..._form };
    setLoading(true);
    const form ={};
    form.userId = props.userInfo._id;
    form.companyId = props.userInfo?.company?.id;
    //->if(active==1){
       form.offeringOverview = form1.offeringOverview;
      //  form.offeringVideoFile = {
      //    name: videoFiles.offeringVideoFile?.name,
      //    URL: videoFiles.offeringVideoFile?.URL,
      //    type: videoFiles.offeringVideoFile.type,
      //  };
      form.offeringVideoFile = videos;
       form.diligenceReport = diligenceReportText;
       if(keyFiles.diligenceReportFile?.URL){
       form.diligenceReportFile = {
         name: keyFiles.diligenceReportFile?.name,
         URL: keyFiles.diligenceReportFile?.URL,
         type: keyFiles.diligenceReportFile.type,
       }
      }
    //}else if(active==2){
       form.financials = financialsText;
       if(keyFiles.financialsFile?.length){
       form.financialsFile = keyFiles.financialsFile;
      }
    //}else if(active==3){
      form.location = locationText;
      if(keyFiles.locationFile?.length){
      form.locationFile = keyFiles.locationFile;
   }
    //}else if(active==4){
      form.FAQs = FAQsText;
      if(keyFiles.FAQsFile?.URL){
      form.FAQsFile = {
        name: keyFiles.FAQsFile?.name,
        URL: keyFiles.FAQsFile?.URL,
        type: keyFiles.FAQsFile.type,
      }
   }
   //}else if(active==5){
   //    form.fees = feesText;
   //    if(keyFiles.feesFile?.URL){
   //    form.feesFile = {
   //      name: keyFiles.feesFile?.name,
   //      URL: keyFiles.feesFile?.URL,
   //      type: keyFiles.feesFile.type,
   //    }
   // }
   //    form.offering='done';
   //    form.stepDone=2;
   //}
   form.offering='done';
   form.stepDone = 2;
   // props.setStepNo(3);
    const response = await issuerSuperAdminService.saveOfferingInfo(form);
   if (response.success) {
      notification.success({
         message: 'Success',
         duration: 0,
       });
         // props.setStepNo(3);
      
   } else {
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
   }
   // props.setStepNo(3)
    props.setUpdated(Math.random());
    setLoading(false);
  }


//   const checkCompletion = () => {
//    if (diligenceReport && financials && location && FAQs) {
//      props.setStepNo(3);
//    }
//  };

//  useEffect(() => {
//    checkCompletion();
//  }, [diligenceReport, financials, location, FAQs]);

  const uploadVideo = async (e, key) => {
   if (!e.target.files) return;
   const file = e.target.files[0];
   if (!file) return;
   const fileType = sharedService.getFileType(file.name);

   if(file.size <= 250*1024*1024){ //250MB
      setFileSizeError(false);
    }else{
      setFileSizeError(true);
      return;
    }
    setFileCount(fileCount+1);
    if(fileCount+1 > 4){
      setDisableUpload(true);
    }
    setVideoFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

   // issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
   //   setVideoFiles((prev) => {
   //     return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent } });
   //   });

   // }, (err) => {
   //   console.error(err);
   //   setVideoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));

   // }, async (response) => {

   //   if (response.success) {
   //     const URL = response.data.url;
   //     setVideoFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase() } }));
   //     form.setFieldsValue({ [key]: file.name });

   //   } else {
   //     console.error(response.error);
   //     setVideoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
   //   }

   // });

   try{
      setVideoFiles((prev) => {
         return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
       });
      const {hash} = await sharedService.uploadDocumentToIPFS({file});
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setVideoFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
      setVideos([...videos, { URL, name: file.name, type: file.type}]);
      form.setFieldsValue({ [key]: file.name });
   } catch(err){
      console.error(err);
      setVideoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
   }
 }

 const deleteItem = (index) => {
   setVideos(videos.filter((video) => video !== videos[index]));
   setDisableUpload(false);
 };

 const uploadFile = async (e, key) => {
   if (!e.target.files) return;
   const file = e.target.files[0];
   if (!file) return;
   const fileType = sharedService.getFileType(file.name);
   if(['locationFile','financialsFile'].includes(key)){
      uploadMultipleFiles(e.target.files,fileType,key)
      return;
   }
   setKeyFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

   // issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
   //   setKeyFiles((prev) => {
   //     return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent } });
   //   });

   // }, (err) => {
   //   console.error(err);
   //   setKeyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));

   // }, async (response) => {

   //   if (response.success) {
   //     const URL = response.data.url;
   //     setKeyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase() } }));
   //     form.setFieldsValue({ [key]: file.name });

   //   } else {
   //     console.error(response.error);
   //     setKeyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
   //   }
   // });

   try{
      setKeyFiles((prev) => {
         return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
      });
      const {hash} = await sharedService.uploadDocumentToIPFS({file});
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setKeyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
      form.setFieldsValue({ [key]: file.name });
   }catch(err){
      console.error(err);
      setKeyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
   }
 }

 const uploadMultipleFiles = async(files,fileType,key) => {
   console.log(key)
   try {
      let updatedLocationFile = []; 
      setUploading(10);
      for (const file of files) {
        const { hash } = await sharedService.uploadDocumentToIPFS({ file }); 
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`; 
        

        const fileObject = { 
          URL: URL,
          name: file.name, 
          type: file.type 
        };
  
        updatedLocationFile.push(fileObject); 
      }
      setUploading(100);
      console.log(updatedLocationFile)
      setKeyFiles((prev) => ({
        ...prev,
        [key]: updatedLocationFile
      }));
      form.setFieldsValue({ [key]: key });
    } catch (err) {
      console.error(err);
    }
 }

 const editVideos = async (_form2) => {
   const form = {};
   form.stepDone=2;
   form.userId = props.userInfo._id;
   form.offeringVideoFile = videos;
   setLoading(true);
   const response = await issuerSuperAdminService.updateOfferingVideos(form);
  if (response.success) {
     notification.success({
        message: 'Success',
        duration: 0,
      });
  } else {
     //message.error(response.error.message);
     notification.error({
       message: 'Error',
       description: response.error.message,
       duration: 0,
     });
  }
   props.setStepNo(2);
   props.setUpdated(true);
   setLoading(false);
 }

 const onUploadBack = ()=>{
   setEditUploadView(false);
   props.setStepNo(2);
   props.setUpdated(true);
 }

 const tabClickFunction = (tabNo) => {
   if(props.offeringDone) {
      setEditTab(false);
      setActive(0);
   }
   setTabName(tabNo);
   setEditTab(false);
 }

  return (
    <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && (
          <>
          <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>{!editUploadView ? 'Offering Overviews' : 'Edit Offering Overviews'}</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={20}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveOfferingInformation}>
                  {/* <Form.Item name="offeringOverview" className="rte"
                  rules={[
                     {
                     required: active==1,
                     message: 'This field is required'
                     }
                  ]}>
                     <SunEditor setContents={content} onChange={handleChange} showToolbar={props.propertyInfo?!props.propertyInfo.offeringOverview:true}/>
                  </Form.Item> */}

                  <div className='upload-container' style={{border: '1px solid black', padding: '50px 10px'}}>
                     <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>Upload Offering Video</Title>
                     {!offeringVideo && (
                        <Form.Item
                        label="Offering Videos"
                           name='offeringVideoFile'>
                           <>
                              <Input type='file' accept='.mp4,.mov,.wmv,.flv,.avi.mkv,,.webM' onChange={e => { uploadVideo(e, 'offeringVideoFile') }} disabled={loading || disbaleUpload}/>
                              {fileSizeError && <h5 style={{color: 'red', margin: '10px 0'}}>File Size can not be more than 250MB</h5>}
                              {videoFiles.offeringVideoFile.uploadFilePercentage > 0 && (
                                 <>
                                    <Progress percent={videoFiles.offeringVideoFile.uploadFilePercentage} />
                                    <br />
                                    <div align="left">
                                       Videos Uploaded:
                                       <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                                          {videos?.length > 0 && videos.map((obj, index) => (
                                             <List.Item
                                                textalign="left"
                                                style={{ cursor: "pointer" }}
                                                key={index}
                                                actions={[
                                                   <Popconfirm
                                                      title="This item will be deleted!"
                                                      onConfirm={() => deleteItem(index)}
                                                   >
                                                      <Button type="primary" danger>
                                                      X
                                                      </Button>
                                                   </Popconfirm>,
                                                ]}
                                             >
                                             <List.Item.Meta title={obj?.name} />
                                             </List.Item>
                                          ))}
                                       </List>
                                    </div>
                                 </>
                              )}
                           </>
                        </Form.Item>)}
                     {/* {offeringVideo && (
                     <div style={{textAlign: 'center'}}>
                        <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Offering Video :</sub>
                        <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={props.propertyInfo.offeringVideoFile?.URL} download={props.propertyInfo.offeringVideoFile?.name} target="_blank" rel="noopener noreferrer">{props.propertyInfo.offeringVideoFile?.name}</a></sub>
                     </div>)} */}

                     {!editUploadView && offeringVideo && props.propertyInfo.offeringVideoFile && (
                          <>
                            <ul>
                              {props.propertyInfo.offeringVideoFile?.map((item, index) => (
                                  <li key={index} className="image-list-item" 
                                  style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                    <a href={item.URL} download={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a></li>
                                ))}
                            </ul>
                            {(props.userInfo?.role === 'issuer company manager') && <Button size='large' style={{ float: 'right', marginTop: '5px' }} type='secondary' onClick={()=>setEditUploadView(true)}>Edit Videos</Button>}
                          </>
                        )}

                        {editUploadView && offeringVideo && props.propertyInfo.offeringVideoFile && (
                          <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={editVideos}>
                            <Form.Item name="attachement" style={{justifyContent: 'center'}}>
                            <Input type='file' accept='.mp4,.mov,.wmv,.flv,.avi.mkv,,.webM' onChange={e => { uploadVideo(e, 'offeringVideoFile') }} disabled={loading || disbaleUpload}/>
                              {fileSizeError && <h5 style={{color: 'red', margin: '10px 0'}}>File Size can not be more than 10MB</h5>}
                              {videoFiles.offeringVideoFile.uploadFilePercentage > 0 && (<Progress
                                percent={videoFiles.offeringVideoFile.uploadFilePercentage}
                              />)}
                              <br />
                              <div align="left">
                                Files Uploaded:
                                <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                                  {videos?.map((obj, index) => (
                                    <List.Item
                                      textalign="left"
                                      style={{ cursor: "pointer" }}
                                      key={index}
                                      actions={[
                                        <Popconfirm
                                          title="This item will be deleted!"
                                          onConfirm={() => deleteItem(index)}
                                        >
                                          <Button type="primary" danger>
                                            X
                                          </Button>
                                        </Popconfirm>,
                                      ]}
                                    >
                                      <List.Item.Meta title={obj ? obj.name ? obj.name : obj : null} />
                                    </List.Item>
                                  ))}
                                </List>
                              </div>
                            </Form.Item>
                            <Form.Item style={{display: 'inline-block', width: '100%', textAlign: 'center', marginLeft: '200px'}}>
                              <Button size='large' style={{ marginRight: '50px' }} type='secondary' onClick={onUploadBack}>Back</Button>
                              <Button size='large' type='primary' htmlType="submit" loading={loading}>Update</Button>
                            </Form.Item>
                          </Form>
                        )}
                  </div>

                  <Title level={3} style={{ color: '#186AB4', textAlign: 'center', marginTop:'100px' }}>Key Facts</Title>
                  <Row className='tabs-row'>
                     <Col span={5} onClick={()=>{tabClickFunction(1)}} className='offering-tab'>
                        <p className={tabName==1 ? 'selected' : ''}>Diligence Report</p>
                     </Col>
                    { props.propertyType != 'residential' &&   <Col span={5} onClick={()=>{tabClickFunction(2)}} className='offering-tab'>
                        <p className={tabName==2 ? 'selected' : ''}>Financial</p>
                     </Col>}
                     { <Col span={5} onClick={()=>{tabClickFunction(3)}} className='offering-tab'>
                        <p className={tabName==3 ? 'selected' : ''}>Location</p>
                     </Col>}
                     <Col span={5} onClick={()=>{tabClickFunction(4)}} className='offering-tab'>
                        <p className={tabName==4 ? 'selected' : ''}>FAQs</p>
                     </Col>
                  </Row>
                  {tabName == 1 && ( //due deligence
                     <>
                        <Form.Item name="diligenceReport" className='text-area'
                        rules={[
                              {
                              required: false, //active==1,
                              message: 'This field is required'
                              }
                           ]}>
                              {false && active!=tabName ? (
                                 <textarea placeholder={diligenceReportText} defaultValue={diligenceReportText} disabled={true}/>
                              ) :(
                                 <textarea defaultValue={diligenceReportText} value={diligenceReportText} onChange={(e)=>setDiligenceReportText(e.target.value)} />
                              )}
                        </Form.Item>
                        {!diligenceReport && (<Form.Item
                           label="Diligence Reports"
                           name='diligenceReportFile'
                           rules={[
                              {
                              required: true, //active==1,
                              message: 'This field is required'
                              }
                           ]}>
                           <>
                              <Input type='file' onChange={e => { uploadFile(e, 'diligenceReportFile') }} />
                              {keyFiles.diligenceReportFile.uploadFilePercentage > 0 &&
                              <Progress percent={keyFiles.diligenceReportFile.uploadFilePercentage} />
                              }
                           </>
                        </Form.Item>)}
                        {diligenceReport && (
                           <div>
                              <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Exisitng Diligence Reports :</sub>
                              <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                 <a href={props.propertyInfo.diligenceReportFile?.URL}
                                  download={props.propertyInfo.diligenceReportFile?.name}
                                  target="_blank" rel="noopener noreferrer">
                                    {props.propertyInfo.diligenceReportFile?.name}</a></sub>
                           <br/>
                           {active==tabName && (<Form.Item
                              label="New Diligence Reports"
                              name='diligenceReportFile'
                              rules={[
                                 {
                                 required: false,
                                 message: 'This field is required'
                                 }
                              ]}>
                              <>
                                 <Input type='file' onChange={e => { uploadFile(e, 'diligenceReportFile') }} />
                                 {keyFiles.diligenceReportFile.uploadFilePercentage > 0 &&
                                 <Progress percent={keyFiles.diligenceReportFile.uploadFilePercentage} />
                                 }
                              </>
                           </Form.Item>)}
                        </div>
                        )}
                     </>
                  )}
                  {(tabName == 2 && props.propertyType != 'residential') && ( //financials
                     <>
                        <Form.Item name="financials" className='text-area'
                        rules={[
                              {
                              required: active==2,
                              message: 'This field is required'
                              }
                           ]}>
                              {false && active!=tabName ? (
                                 <textarea placeholder={financialsText} defaultValue={financialsText} disabled={true}/>
                              ) :(
                                 <textarea value={financialsText} defaultValue={financialsText} onChange={(e)=>setFinancialsText(e.target.value)} />
                              )}
                        </Form.Item>
                        {!financials && (<Form.Item
                           label="Financial"
                           name='financialsFile'
                           rules={[
                              {
                              required: active==2,
                              message: 'This field is required'
                              }
                           ]}>
                           <>
                              <Input type='file' multiple onChange={e => { uploadFile(e, 'financialsFile') }} />
                              {uploading > 0 &&
                              <Progress percent={uploading} />
                              }
                           </>
                        </Form.Item>)}
                        {financials && (
                           <div>
                              <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Exisitng Financials Image :</sub><br/>
                              {props.propertyInfo.financialsFile?.map(file => (
                                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                         <a href={file?.URL} download={file?.name} target="_blank" rel="noopener noreferrer">{file?.name}</a><br/>
                                       </sub>
                                    ))
                                 }

                           {active==tabName && (<Form.Item
                              label="Financial"
                              name='financialsFile'
                              rules={[
                                 {
                                 required: active==2,
                                 message: 'This field is required'
                                 }
                              ]}>
                              <>
                                 <Input type='file' multiple onChange={e => { uploadFile(e, 'financialsFile') }} />
                                 {keyFiles.financialsFile.uploadFilePercentage > 0 &&
                                 <Progress percent={keyFiles.financialsFile.uploadFilePercentage} />
                                 }
                              </>
                           </Form.Item>)}
                           </div>
                           )}
                     </>
                  )}
                  {(tabName == 3 ) && ( //location
                     <>
                        <Form.Item name="location" className='text-area'
                        rules={[
                              {
                              required: active==3,
                              message: 'This field is required'
                              }
                           ]}>
                              {false && active!=tabName ? (
                                 <textarea placeholder={locationText?locationText.toString():''} disabled={true} defaultValue={locationText} />
                              ) :(
                                 <textarea value={locationText} defaultValue={locationText} onChange={(e)=>setLocationText(e.target.value)} />
                              )}
                        </Form.Item>
                        {!location && (<Form.Item
                           label="Location Image(s)"
                           name='locationFile'
                           rules={[
                              {
                              required: active==3,
                              message: 'This field is required'
                              }
                           ]}>
                           <>
                              <Input type='file' multiple onChange={e => { uploadFile(e, 'locationFile') }} />
                              {uploading > 0 &&
                              <Progress percent={uploading} />
                              }
                           </>
                        </Form.Item>)}
                        {location && (
                           <div>
                              <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Exisitng Location Images :</sub><br/>
                                   {props.propertyInfo.locationFile?.map(file => (
                                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                         <a href={file?.URL} download={file?.name} target="_blank" rel="noopener noreferrer">{file?.name}</a><br/>
                                       </sub>
                                    ))
                                 }
                                    
                           {active==tabName && (<Form.Item
                              label="Location Image(s)"
                              name='locationFile'
                              rules={[
                                 {
                                 required: active==3,
                                 message: 'This field is required'
                                 }
                              ]}>
                              <>
                                 <Input type='file' multiple onChange={e => { uploadFile(e, 'locationFile') }} />
                                 {keyFiles.locationFile.uploadFilePercentage > 0 &&
                                 <Progress percent={keyFiles.locationFile.uploadFilePercentage} />
                                 }
                              </>
                           </Form.Item>)}
                           </div>
                           )}
                     </>
                  )}
                  {tabName == 4 && ( //faq
                     <>
                        <Form.Item name="FAQs" className='text-area'
                        rules={[
                              {
                              required: active==4,
                              message: 'This field is required'
                              }
                           ]}>
                              {false && active!=tabName ? (
                                 <textarea placeholder={FAQsText} defaultValue={FAQsText} disabled={true}/>
                              ) :(
                                 <textarea value={FAQsText} defaultValue={FAQsText}  onChange={(e)=>setFAQsText(e.target.value)} />
                              )}
                        </Form.Item>
                        {!FAQs && (<Form.Item
                           label="FAQs"
                           name='FAQsFile'
                           rules={[
                              {
                              required: false,
                              message: 'This field is required'
                              }
                           ]}>
                           <>
                              <Input type='file' onChange={e => { uploadFile(e, 'FAQsFile') }} />
                              {keyFiles.FAQsFile.uploadFilePercentage > 0 &&
                              <Progress percent={keyFiles.FAQsFile.uploadFilePercentage} />
                              }
                           </>
                        </Form.Item>)}
                        {FAQs && (
                           <div>
                              <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Exisitng FAQs :</sub>
                              <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                 <a href={props.propertyInfo.FAQsFile?.URL}
                                  download={props.propertyInfo.FAQsFile?.name}
                                  target="_blank" rel="noopener noreferrer">
                                    {props.propertyInfo.FAQsFile?.name}</a></sub>
                                    <br/>
                           {active==tabName && (<Form.Item
                              label="FAQs"
                              name='FAQsFile'
                              rules={[
                                 {
                                 required: false,
                                 message: 'This field is required'
                                 }
                              ]}>
                              <>
                                 <Input type='file' onChange={e => { uploadFile(e, 'FAQsFile') }} />
                                 {keyFiles.FAQsFile.uploadFilePercentage > 0 &&
                                 <Progress percent={keyFiles.FAQsFile.uploadFilePercentage} />
                                 }
                              </>
                           </Form.Item>)}
                           </div>
                           )}
                     </>
                  )}

                  <br/> <br/>
                  <div className='d-flex justify-content-end' style={{marginRight:'50px'}}>
                  {!props.offeringDone && (
                     <Form.Item {...tailFormItemLayout}>
                        <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}disabled={!canSubmit}>Submit</Button>
                     </Form.Item>)}

                  {(props.offeringDone || !editTab) && (
                  <Form.Item {...tailFormItemLayout}>
                     {(props.userInfo?.role === 'issuer company manager') && <Button size='large' style={{ marginLeft: '10px' }} type='secondary' onClick={()=>{setActive(tabName); setEditTab(true)}}>Edit Details</Button>}
                  </Form.Item>)}

                  {props.offeringDone && editTab && (
                     <Form.Item {...tailFormItemLayout}>
                     {(props.userInfo?.role === 'issuer company manager') && <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Update</Button>}
                  </Form.Item>)}
                  </div>
               </Form>
            </Col>
         </Row>
         </>
       )}
      </>
  );
}
