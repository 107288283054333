import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Select,
  Result,
  Col,
  Row,
  message,
  Checkbox,
  Tooltip,
  Progress
} from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SharedService } from '../../../Shared/Shared.service';
import { Country } from '../../../Shared/interfaces';
import { AccountService } from '../../Account.service';
import BigNumber from 'bignumber.js';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ConnectToMetamask from '../../../ConnectToMetamask'
import { MetamaskService } from '../../../Shared/Metamask.service';
import queryString from "query-string";
import "../../Register/SeriesManager/SeriesManager.scss";
import validationPatterns from '../../../Shared/validationPatterns';
import { environment } from "../../../../environments/environment";

const { Option } = Select;


const sharedService = new SharedService();
const accountService=new AccountService()


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const underlyingAssetOptions = sharedService.underlyingAssetOptions;
const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId) => new MetamaskService().useSelectedWallet(userId);

export default () => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedWallet, networkId] = useSelectedWallet('new investor');
  const [countries, setCountries] = useState<Country[]>();
  const [seriesName, setSeriesName] = useState();
  const [termsCheck, setTermsCheck] = useState(false);

  const location = useLocation();
  let { code, email } = queryString.parse(location.search) as {code: string, email: string};
  if(email.includes(' ')){
    email = email.replace(' ', '+');
  }

  const [issuerEmail, setIssuerEmail] = useState<string>();
  const [form]=Form.useForm();
  const history = useHistory();
  const isNotConnectedToMetamask=(MetamaskService.isMetamaskAuthenticated===false) && (networkId!==1 || networkId !== 5);

  form.setFieldsValue({email});

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
      const res = await accountService.getSeriesCompanyName(email);
      if(res.data){
        setSeriesName(res.data);
        form.setFieldsValue({email, companyName: res.data});
      }
    })();
  }, []);


  const register = async (req) => {
    req.tentativeDate = (req.tentativeDate.toDate() as Date).getTime();
    req.amountToRaise = (req.amountToRaise as string).replace(/,/g,'');
    req.code = code;
    req.llpSuperAdminPhoto  = llpSuperAdminPhoto.llpSuperAdminPhoto;
    setSubmitting(true);
    const response = await accountService.registerSeriesManager(req);

    if (response.success) {
      setIssuerEmail(req.email);

    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  }
  //form.setFieldsValue({walletAddress:selectedWallet});
  /* const fetchCountry=(_value)=>{
     let cunt=[]
     for(let i=0;i<tempCountries?.length;i++)
     {
       if(tempCountriesp[i].toString().startsWith(_value))cunt.push(tempCountries[i])
     }
   };*/

   const [llpSuperAdminPhoto, setLlpSuperAdminPhoto] = useState({
    llpSuperAdminPhoto: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    }
  
  });

   const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setLlpSuperAdminPhoto((prev) => ({
      [key]: { URL: null, ...prev[key] },
      ...prev,
    }));

    try {
      setLlpSuperAdminPhoto((prev) => {
        return {
          ...prev,
          [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 },
        };
      });
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setLlpSuperAdminPhoto((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          URL,
          uploading: false,
          uploadFilePercentage: 100,
          name: file.name,
          type: fileType.toLowerCase(),
        },
      }));
      form.setFieldsValue({ [key]: file.name });
    } catch (err) {
      console.error(err);
      setLlpSuperAdminPhoto((prev) => ({
        ...prev,
        [key]: { ...prev[key], uploading: false },
      }));
    }
  };


  return (
    <>
      {/* <SelectedWalletContext.Provider value={{selectedWallet, networkId}}>
        <div hidden={!isNotConnectedToMetamask}>
          <ConnectToMetamask></ConnectToMetamask>
        </div> */}

        <div hidden={!!isNotConnectedToMetamask}>
          <Row justify="center" style={{margin:"60px 0"}}>
            <Col span={16} style={{textAlign:'center'}}>

              {!issuerEmail &&

              <Card>
                <h2 style={{textAlign:'center'}}>Register as a LLP Super Admin</h2>
                <Form
                  {...formItemLayout}
                  form={form}
                  onFinish={register}
                  initialValues={{ 
                  companyCountry: 'India' ,
                  underlyingAsset: 'Real Estate'}}
                  >

                  <Form.Item
                    label='First Name'
                    name="firstName"
                    className='customFormItem'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                        whitespace: true
                      },
                      {
                        validator: (rule, value) => validationPatterns?.name?.validate(value)
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Last Name'
                    name="lastName"
                    className='customFormItem'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your last name!',
                        whitespace: true
                      },
                      {
                        validator: (rule, value) => validationPatterns?.name?.validate(value)
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='E-mail'
                    name="email"
                    className='customFormItemEmail'
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!'
                      },
                      {
                        validator: (rule, value) => validationPatterns?.email?.validate(value)
                      }
                    ]}>
                    <Input disabled/>
                  </Form.Item>

                  <Form.Item
                    label='Password'
                    name="password"
                    className='customFormItemPassword'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!'
                      },
                      {
                        validator: (rule, value) => validationPatterns?.strongPassword?.validate(value)
                      }
                    ]}>
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label='Confirm Password'
                    name="confirm"
                    className='customFormItemConfirm'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) return Promise.resolve();
                          return Promise.reject('The two passwords that you entered do not match!');
                        },
                      }),
                    ]}>
                    <Input.Password />
                  </Form.Item>

                  {/* <Form.Item
                    label='Wallet Address'
                    name="walletAddress"
                    rules={[
                      {
                        required: true,
                        message: 'Please connect to metamask',
                        whitespace: true
                      }
                    ]}>
                    <Input style={{color:'green'}} disabled/>
                  </Form.Item> */}

                  <Form.Item
                    label='Name of Company'
                    name="companyName"
                    className='customFormItemCompany'
                    rules={[
                      {
                        required: true,
                        message: 'Enter Company name here',
                        whitespace: true
                      }
                    ]}>
                    <Input disabled/>
                  </Form.Item>

                  <Form.Item
                      label="Address"
                      name= "streetAddress1"
                      className='customFormItemAddress'
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                          whitespace: true,
                        },
                      ]}
                    >
                     <Input />
                    </Form.Item>

                  <Form.Item
                    label='Company Country'
                    name="companyCountry"
                    className='customFormItemCountry'
                    validateStatus={countries? '' : 'validating'}
                    hasFeedback
                    help= {countries? '' : "Loading Countries..."}
                    rules={[
                      {
                        required: true,
                        message: 'Select Country'
                      }
                    ]}>
                    <Select showSearch placeholder="Select Country" >
                      {countries?.sort(function (a, b){return b.name['en'].localeCompare(a.name['en']);}).map(country =>
                        <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => prevValues.companyCountry !== currentValues.companyCountry}
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label='Phone number'
                          name="phone"
                          className='customFormItemPhone'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Phone number here',
                              whitespace: true
                            },
                            {
                              validator: (rule, value) => validationPatterns?.phoneNumber?.validate(value)
                            }
                          ]}>
                          <PhoneInput country={"in"}/>
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                  {/* {value}
                {isValidPhoneNumber(value)? 'VALID' : 'INVALID'} */}


                  {/* <Form.Item
                    label='Token Phase'
                    name="tokenPhase"
                    rules={[
                      {
                        required: true,
                        message: 'Please input token phase!',
                        whitespace: true
                      },
                    ]}>
                  
                    <Select showSearch placeholder="Select Phase">
                      <Option key={'initial'} value={'Initial'}>{'Initial'}</Option>
                      <Option key={'pre-sale'} value={'Pre-sale'}>{'Pre-sales'}</Option>
                    </Select>
                  </Form.Item> */}

                  <Form.Item 
                    label={
                      <Tooltip title="Your DPIN is a unique Designated Partner Identification Number">
                        DPIN
                      </Tooltip>
                    }
                    name="dPIN"
                    className='customFormItemDPI'
                    rules={[
                      {
                        required: false,
                        message: 'Please input your DPIN!',
                        whitespace: true
                      },
                      {
                        validator: (rule, value) => validationPatterns?.number?.validate(value)
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Amount to raise'
                    name="amountToRaise"
                    className='customFormItemAmount'
                    normalize = {(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g,'');

                      if((new BigNumber(_value).isGreaterThan(0)) && new BigNumber(_value).isLessThan(1000000001)) return new BigNumber(_value).toFormat(0);
                      if(!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input amount to raise!'
                      },
                    ]}>
                    <Input
                      addonAfter='Rs'
                    />
                  </Form.Item>

                  <Form.Item
                    label='Underlying Asset'
                    name="underlyingAsset"
                    className='customFormItemUnderLying'
                    rules={[
                      {
                        required: true,
                        message: 'Please input underlying asset!',
                        whitespace: true
                      }
                    ]}>
                    <Select defaultValue='Real Estate'>
                      {underlyingAssetOptions.map(option => 
                        <Option key={option.value} value={option.value}>{option.name}</Option>)}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label='Tentative Date'
                    name="tentativeDate"
                    className='customFormItemTentative'
                    rules={[
                      {
                        required: true,
                        message: 'Please input tentative date!'
                      }
                    ]}>
                    <DatePicker format="MMMM DD, YYYY" 
                    disabledDate={(current) => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      return current && current.valueOf() < today.getTime();
                    }}/>
                  </Form.Item>
                  <Form.Item
                    label="Photo"
                    name="llpSuperAdminPhoto"
                    className='customFormItemPhoto'
                    valuePropName="fileList"
                    style={{margin:'5px 2px 2px 10px'}}
                  >
                    <>
                    <Tooltip title="LLP Super Admin Photo">
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "llpSuperAdminPhoto");
                        }}
                      />
                      </Tooltip>
                      {llpSuperAdminPhoto.llpSuperAdminPhoto
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            llpSuperAdminPhoto.llpSuperAdminPhoto
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                    <div className='mx-5'>
                    <Checkbox checked={termsCheck} onChange={()=>setTermsCheck(!termsCheck)} style={{width: '100%', marginBottom: '20px'}}>I hereby accept all <Link  to="/terms-and-conditions" target="_blank">terms and conditions</Link> of the platform</Checkbox>

                    </div>

                  <Form.Item {...tailFormItemLayout}>
                    <Button type='primary' htmlType='submit' loading={submitting} disabled={!termsCheck}>Register</Button>
                    {/* <br /> */}
                    {/* <>Or </><Link to='/account/login'>login now!</Link> */}
                  </Form.Item>

                </Form>
              </Card>

              }

              {issuerEmail &&
              <Card>
                <h2 style={{textAlign:'center'}}>Check your Email</h2>
                <Result
                  title={`We sent a verification email to ${issuerEmail}.`}
                  subTitle="Click on the link to get started!"
                  extra={
                    <Button key="console" onClick={() => history.push('/account/resend-email-verification-code')}>
                      Email didn't arrive?
                    </Button>
                  }/>
              </Card>
              }
            </Col>
          </Row>
        </div>
      {/* </SelectedWalletContext.Provider> */}
    </>
  );
}