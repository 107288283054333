import React from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import BuilderPhoto from "../../../assets/images/builders/buildersPhoto.png";
import BuilderDesign from "../../../assets/images/builders/buildersDesign.svg";
import { AccountService } from "../../../components/Account/Account.service";
import { useHistory } from "react-router-dom";

const { TextArea } = Input;
const accountService = new AccountService();
const InterestBuilders = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const BuildersStyle = {
    container: {
      padding: "100px 20% 20px 20%",
    },
    heading: {
      color: "black",
    },
    subHeading: {
      fontWeight: "bold",
      color: "black",
    },
    button: {
      textAlign: "center",
      color: "white",
      borderRadius: "20px",
      backgroundColor: "black",
      width: "30%",
      height: "20%",
    },
  };

  const saveInviteData = async () => {
    try {
      const values = await form.getFieldsValue();
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyName: values.companyName,
        comments: values.comments,
        type: "builders",
      };
      let response = await accountService.submitInterestToRegisterAsPCA(data);
      if (!response.success) {
        message.warning({ content: response.error.message, duration: 5, style:{marginTop:"30px"}});
        
      }else {
      message.success({
        content: "Successfully send interest to marketplace super admin.",
        duration: 5,
        style: { top: "150px" },
      });
      history.push("/")
    }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
      message.error({content:"Something went wrong while sending interest!" ,duration: 5,style:{marginTop:"30px"}});
    }
  };

  return (
    <div style={BuildersStyle.container}>
      <Row>
        <Col
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={BuildersStyle.heading}>
                Revolutionize Your Real Estate Project Funding.
                <br /> Fractionalize and Raise Capital Efficiently.{" "}
              </h4>
              <h2 style={BuildersStyle.subHeading}>Get Started !</h2>
            </span>
            <span
              style={{
                position: "relative",
                right: "-50px",
                overflow: "hidden",
              }}
            >
              <img
                src={BuilderDesign}
                alt="BuilderDesign"
                width="150px"
                height="150px"
                loading="lazy"
                style={{
                  position: "relative",
                  top: "-20px",
                }}
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col
          sm={24}
          md={12}
          lg={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={BuilderPhoto} alt="BuilderPhoto" loading="lazy" />
        </Col>
        <Col sm={24} md={12} lg={12}>
          <Form form={form} onFinish={saveInviteData} layout="vertical">
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input your Company Name",
                },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
            <Form.Item label="Comments" name="comments">
              <TextArea placeholder="Comments" />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              style={BuildersStyle.button}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default InterestBuilders;
