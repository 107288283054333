import React, { useEffect, useState } from "react";
import HomePagee1 from "../../assets/images/banner/HomePagee1.png";
import HomePagee2 from "../../assets/images/banner/HomePagee2.png";
import HomePagee3 from "../../assets/images/banner/HomePagee3.png";

import "../Homepage/Banner.scss";
import maskgrp from "../../assets/images/banner/Mask-group.png"
import text from "../../assets/images/banner/text.png"

function Banner(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  // const [imgArr, setImgArr] = useState([]);

   const { isHomePage } = props;
   
   const imgArr = isHomePage ? [HomePagee1,HomePagee2,HomePagee3] : props.imgArr;
   const textArr = isHomePage ? 
   ['Digitalize, Thrive, Succeed: <br> Future Investments of Real Estate Starts Here',
   'Unlocking Wealth With Innovation:<br> Digitization Tomorrows Real Estate Today',
   'Revolutionize Real Estate with VruddhiX']:props.textArr;
  // console.log(isHomePage);
  // console.log(imgArr);
  // setImgArr([HomePagee1,HomePagee2,HomePagee3]);
  console.log(imgArr);

  const goToPrevSlide = () => {
    const newIndex = (activeIndex - 1 + props.imgArr.length) % props.imgArr.length;
    setActiveIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (activeIndex + 1) % props.imgArr.length;
    setActiveIndex(newIndex);
  };
  const renderStyledText = (text) => {
    const parts = text.split('<br>'); 
    if (parts.length === 2) {
      return (
        <>
          <span className="first-part">{parts[0]}</span>
          <br />
          <span className="second-part">{parts[1]}</span>
        </>
      );
    } else {
      const splitText = text.split('X');
      return (
        <>
          <span>{splitText[0]}</span>
          <span style={{ color: 'green' }}>X</span>
          <span>{splitText[1]}</span>
        </>
      );
    }
  };

  return (
    <div className="banner_image">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {console.log(textArr)}
        {
          imgArr?.map((img,i) => {
            return <>
              <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`} style={{height:props?.size}}>

                <img src={img} class="d-block w-100"  style={{ objectFit: 'cover', height: '100%', width: '100%' }} alt={`carousel-item-${i + 1}`} />
                <div className={`text-overlay text-overlay-${i + 1}`}   >
                <p className="responsive-text">{ isHomePage && renderStyledText(textArr[i])}</p>
              </div>
              </div>
            </>
          })
        }
    
        
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
      </div>
  

    
  );
}

export default Banner;
