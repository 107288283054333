import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Row, Col, Spin, Alert, message,} from "antd";
import {
  UserOutlined,
  LockOutlined,
  ColumnWidthOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory } from "react-router-dom";
// import * as classNames from 'classnames';
import { SharedService } from "../../Shared/Shared.service";
import { AccountService } from "../Account.service";
import { AuthService } from "../../Shared/Auth.service";
import { environment } from "../../../environments/environment";
import "./Login.scss";
import logo from "../../../assets/images/login/VruddhiX.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ForgotPassword from "../Password/ForgotPassword";


import VruddhiX from "../../../assets/images/about-us/VruddhiX.png";
import LogiBackGroundImage from '../../../assets/images/about-us/LogiBackGroundImage.png'
const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();
export default () => {
  const history = useHistory();

  const [reqErr, setReqErr] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(true);
  const [ipAddress, setIPAddress] = useState("");

  const { userInfo, setUserInfo } = useUserContext();
  const [forgotPasswordSubmitting, setForgotPasswordSubmitting] =
  useState(false);
const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
  useState<boolean>(false);
const [form] = Form.useForm();

  useEffect(() => {
    if (!userInfo) return;
    if (userInfo.role === "platform issuer admin") {
      history.push("/platform-issuer-admin/issuer-reg-reqs");
    } else if (userInfo.role === "issuer super admin") {
      history.push("/issuer-super-admin/basic-info-verif");
    } else if (
      userInfo.role === "employee" ||
      userInfo.role === "contractor_consultant_advisor"
    ) {
      history.push(`/team-member/vesting-schedule`);
    } else if (userInfo.role === "issuer token admin") {
      history.push("/token-configurations");
    } else if (userInfo.role === "platform super admin") {
      history.push("/platform-super-admin/manage-api-key");
    } else if (userInfo.role === "issuer counsel") {
      history.push("/token-configurations");
    } else if (userInfo.role === "issuer company officer") {
      history.push("/token-configurations");
    } else if (userInfo.role === "investor") {
      history.push('/connect-wallet');
    } else if (userInfo.role === "platform compliance admin") {
      history.push("/platform-compliance-admin/kyc-verification-request");
    } else if (userInfo.role === "issuer compliance admin") {
      history.push("/issuer-compliance-admin/kyc-verification-request");
    } else if (userInfo.role === "master company series manager") {
      history.push("/series");
    } else if (userInfo.role === "master company compliance admin") {
      history.push("/master-company-super-admin/team-member-kyc-requests");
    } else if (userInfo.role === "issuer company manager"){
      history.push("/connect-wallet");
    } else if (userInfo.role === "master company channel partner") {
      history.push("/series");
    } else if (userInfo.role === "master company financial advisor") {
      history.push("/master-company-super-admin/series-details");
    } 
    // const subscription = props.source.subscribe();
    // return () => {
    //   subscription.unsubscribe();
    // };
  }, [userInfo, history]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (environment.loginCaptha === "false") setExpired(false);
    }, 1000);
  }, []);

  const handleCaptchaChange = (value: any) => {
    setExpired(false);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };

  const login = async ({ email, password }: { [name: string]: string }) => {
    setSubmitting(true);

    // CHECK IF EMAIL IS VERIFIED
    try {
      let data = {
        deviceVerification: environment.twoFactorEmailVerification,
        ipAddress: ipAddress,
      };
      const response = await accountService.login(email, password, data);

      if (response.success) {
        if (response.data.doVerification) {
          window.location.href = `/account/verify-email-otp/${response.data.userId}`;
        } else {
          sharedService.setToken(response.data.token);
          setUserInfo({ ...response.data.user });
        }
      } else {
        console.error(response.error.message);
        setReqErr(response.error.message);
      }
    } catch (err) {
      console.error(err);
      setReqErr("An error ocurred. Try again later");
    }

    setSubmitting(false);
  };

  const toggleForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  };
  const handleSubmitForgotPassword = async (req) => {
    setForgotPasswordSubmitting(true);
    const response = await accountService.forgotPassword(req);
    if (response.success) {
      message.success(
        "Please check, you have received reset password link on your registered email address.",
        7
      );
      form.resetFields();
      setIsForgotPasswordModalOpen(false);
    } else {
      message.error(response.data, 5);
    }
    setForgotPasswordSubmitting(false);
  };
  return (
    <div className="main-container">
      <div>
      <img className="BackgroundImage" src={LogiBackGroundImage} alt='About Us' ></img>
      </div>
      <Row
        justify="center"
        style={{
          marginTop: "60px",
          height: "100vh",
          objectFit: "contain",
          overflow: "hidden",
        }}
      >
        {submitting && (
          <div style={{ textAlign: "center" }}>
            <Spin className="spin-verif" size="large" />
          </div>
        )}
        {!submitting && (
          <Col span={16} style={{ textAlign: "center" }} >
            <Card
              className="card"
              style={{
                // margin: '5% 0 0 15%',
                // width: '70%'
                height: "200%",
                backgroundColor: "transparent",
                zIndex:20,
                border: "0px",
              }}
            >
              
              <div className="logo" >
                <img src={VruddhiX} />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Log In
              </h3>
              <p
                style={{
                  color: "white",
                  marginTop: "-20px",
                  fontSize: "13px",
                  marginBottom: "30px",
                }}
              >
                Login to your account
              </p>
              <Form
                // form = {form}
                // size='middle'
                onFinish={login}
                className="login-form"
                // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
              >
                <div className="formname">
                  <div className="input-label">Email</div>
                  <div>
                    <Form.Item
                      // label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                      ]}
                      validateStatus={reqErr && "error"}
                      style={{
                        width: "100%",
                        margin: "auto",
                        marginBottom: "10px",
                        borderRadius: "50px",
                      }}
                    >
                      <Input
                        style={{ borderRadius: "5px" }}
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <br />
                <div className="formname">
                  <div className="input-label">Password</div>
                  <div>
                    <Form.Item
                      // label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      validateStatus={reqErr && "error"}
                      help={reqErr}
                      style={{
                        width: "100%",
                        margin: "auto",
                        borderRadius: "50px",
                      }}
                    >
                      <Input.Password
                        style={{ borderRadius: "5px" }}
                        prefix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                {environment.loginCaptha === "true" && (
                  <div style={{ marginTop: "30px", display: "inline-block" }}>
                    <ReCAPTCHA
                      theme="light"
                      sitekey={environment.captchaKey}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                )}

                <Form.Item>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={submitting}
                    disabled={expired}
                    style={{
                      padding: "22px 50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "auto",
                      backgroundColor: '#F3BC03',
                      borderRadius: "10px",
                      marginTop:'30px',
                      border:"none",
                      cursor:'pointer',
                    }} 
                  >
                      <span className="login-btnnn">
                        Log in
                      </span>
                   
                  </Button>
                  </div>
                  <br />
                  {/* <a className='login-form-forgot' href="/account/login">
                Forgot password
              </a> */}
                  <div
                    style={{
                      marginTop: "15px",
                      color: "white",
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={toggleForgotPasswordModal}
                  >
                    Forgot password
                  </div>
                  <br />
                  <p style={{ color: "white" }}>
                    Don't have an account yet ? &nbsp;
                    <Link
                      style={{ color: "white" }}
                      to="/account/register/investor"
                    >
                      Register now!
                    </Link>
                  </p>

                  {/* <br/> */}
                  {/* <>Or </> */}
                  <br />
                  {/* <Link to='/account/resend-email-verification-code'>resend email verification link</Link> */}
                </Form.Item>
              </Form>
              <Modal
                toggle={toggleForgotPasswordModal}
                centered
                isOpen={isForgotPasswordModalOpen}
              >
                <ModalHeader toggle={toggleForgotPasswordModal}>
                  Forgot Password
                </ModalHeader>
                <ModalBody>
                  <Row justify="center">
                    <Col span={23} style={{ textAlign: "center" }}>
                      <Card>
                        <h2 style={{ textAlign: "center" }}>Forgot Password</h2>
                        <Form
                          onFinish={handleSubmitForgotPassword}
                          className="login-form"
                        >
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                            style={{
                              width: "300px",
                              margin: "auto",
                              marginBottom: "10px",
                            }}
                          >
                            <Input
                              prefix={
                                <UserOutlined
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder="Email"
                            />
                          </Form.Item>

                          <Form.Item>
                            <Button
                              style={{
                                marginTop: "20px",
                                marginBottom: "15px",
                              }}
                              type="primary"
                              htmlType="submit"
                              className="login-form-button"
                              loading={forgotPasswordSubmitting}
                              disabled={forgotPasswordSubmitting}
                            >
                              {forgotPasswordSubmitting
                                ? "Sending Email..."
                                : "Send Email"}{" "}
                            </Button>
                            {/* <br />
              <a className="login-form-forgot" href="/account/login">
                Login
              </a>
              <br />
              <br /> */}
                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                  </Row>{" "}
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};
