import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import { Row, Col, Card, Typography, Form, Button, Spin, Input, Select, List, Progress, Popconfirm, notification, DatePicker} from 'antd';
import moment from 'moment';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { environment } from '../../../environments/environment';
const {Title} = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();

export default function AdditionalDetails(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [editView, setEditView] = useState(false);
  const dateFormat = 'YYYY/MM/DD';
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  const saveAdditionalDetails= async (_form) => {
    const form = { ..._form };
    form.additionalDetails='done';
    form.stepDone=3;
    form.userId = props.userInfo._id;
    form.companyId = props.userInfo?.company?.id;
    let propertyCount = props.propertyInfo? props.propertyInfo.propertyCount : 1;
    form.targetedInvestorIrr = propertyCount;

    setLoading(true);
    const response = await issuerSuperAdminService.saveAdditionalDetails(form);
   if (response.success) {
      notification.success({
         message: 'Success',
         duration: 0,
       });
   } else {
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
   }
    props.setStepNo(4);
    props.setUpdated(true);
    setLoading(false);
  }
  return (
    <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && !props.additionalDetailsDone && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Portfolio Financials</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveAdditionalDetails} initialValues={{
                  distributionFrequency:"Monthly"
                  }}>
                  <Card>
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label={`Targeted Investor IRR`}
                        name="targetedInvestorIRR"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" />
                      </Form.Item>}
                      {(props.propertyType == 'project' || props.commercialOption=='rent') && <Form.Item
                        label='Targeted Equity Multiple'
                        name="targetedEquityMultiple"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="x"/>
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Year 1 Cash on Cash'
                        name="yearOneCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%"/>
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Stabilized Cash on Cash'
                        name="stabilizedCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%"/>
                     </Form.Item>}
                     {(false) &&<Form.Item
                        label='Minimum Investment in Rs'
                        name="minimumInvestmentInRs"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign}/>
                     </Form.Item>}
                     {/* <Form.Item
                        label='Hold Period'
                        name="holdPeriod"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" addonAfter="Y"/>
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Investment'
                        name="minimumInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign}/>
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='First Distribution'
                        name="firstDistribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number"   addonAfter={environment.currencySign}/>
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Distribution Frequency'
                        name="distributionFrequency"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                    <Select defaultValue="Monthly">
                        <Option value="Monthly">Monthly</Option>
                        <Option value="Quarterly">
                          Quarterly
                        </Option>
                        <Option value="Yearly">Yearly</Option>
                      </Select>                     </Form.Item>}
                     {(props.propertyType == 'commercial'|| props.propertyType==='project') &&<Form.Item
                        label='Investor Profit Share'
                        name="investorProfitShare"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%"/>
                     </Form.Item>}
                     {( props.propertyType==='project' ) &&<Form.Item
                        label='Loan-to-Value'
                        name="loanToValue"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" />
                     </Form.Item>}

                     {false && <Form.Item
                        label='Asset Profile'
                        name="assetProfile"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" />
                     </Form.Item>}

                     {(props.commercialOption=='rent') &&<Form.Item
                        label='Current Occupancy'
                        name="currentOccupancy"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%"/>
                     </Form.Item>}
                    
                     {(props.propertyType == 'project' || props.propertyType=='commercial') &&<Form.Item
                        label='Preferred Return'
                        name="preferredReturn"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%"/>
                     </Form.Item>}
                     <Form.Item
                        label='Total Investment Target'
                        name="totalInvestmentTarget"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign}/>
                     </Form.Item>
                     {(props.propertyType == 'project' ) &&<Form.Item
                        label='Co-Investment'
                        name="coInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign}/>
                     </Form.Item>}


                     {(props.propertyType == 'project') &&<Form.Item
                        label='Targeted Avg Cash Yeild'
                        name="targetedAvgCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" />
                     </Form.Item>}
                     {(props.propertyType == 'project') &&<Form.Item
                        label='Targeted Investment Period'
                        name="targetedInvestmentPeriod"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input addonAfter="years" />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Token Issuance'
                        name="tokenIssuance"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Location | MSA'
                        name="locationMsa"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Property Type'
                        name="propertyType"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Period of Sale'
                        name="periodOfSale"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Type Of Token'
                        name="typeOfToken"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Token Properties'
                        name="tokenProperties"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Sum of Contributions'
                        name="minimumSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Targeted Sum of Contributions'
                        name="targetedSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} />
                     </Form.Item>}
                     {false &&<Form.Item
                        label='Accepted Currencies'
                        name="acceptedCurrencies"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Name Of Token'
                        name="nameOfToken"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Token Symbol'
                        name="tokenSymbol"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}
                     {false && <Form.Item
                        label='Token Standard'
                        name="tokenStandard"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item>}

                     {/* <Form.Item
                        label='Facebook'
                        name="facebook"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='Linkedin'
                        name="linkedin"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='Twitter'
                        name="twitter"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='Other links'
                        name="otherLinks"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input />
                     </Form.Item> */}

                     {/* {(props.propertyType === 'residential' || props.commercialOption == 'rent' || props.propertyType== 'project' || props.propertyType== 'commercial') && */}
                      <Form.Item
                        label={
                          <span>
                             <span style={{ color: '#ff4d4f', fontSize: '12px' }}></span> Projected Funding Deadline
                          </span>
                        }
                        name="projectedFundingDeadline">
                        <DatePicker format={dateFormat} disabledDate={d => !d || d.isSameOrBefore(moment(new Date()).format(dateFormat)) } style={{width:'100%'}} />
                     </Form.Item>
                     {/* } */}

                     <br /><br />
                     <Form.Item style={{ display: "block", float: "right" }}>
                        <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
                     </Form.Item>
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
       {!loading && props.additionalDetailsDone && !editView && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Portfolio Financials</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveAdditionalDetails}>
                  <Card>
                  {(props.propertyType == 'project' || props.commercialOption=='rent') && <Form.Item
                        label={`Targeted Investor IRR`}
                        name="targetedInvestorIRR"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.targetedInvestorIRR} disabled={true} />
                      </Form.Item>}
                      {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Targeted Equity Multiple'
                        name="targetedEquityMultiple"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="x" placeholder={props.propertyInfo.targetedEquityMultiple} disabled={true} />
                     </Form.Item>}
                    {(props.propertyType == 'project' || props.commercialOption=='rent') && <Form.Item
                        label='Year 1 Cash on Cash'
                        name="yearOneCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.yearOneCashOnCash} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Stabilized Cash on Cash'
                        name="stabilizedCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.stabilizedCashOnCash} disabled={true} />
                     </Form.Item>}

                     {(false) &&<Form.Item
                        label='Minimum Investment in Rs'
                        name="minimumInvestmentInRs"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.minimumInvestmentInRs} disabled={true} />
                     </Form.Item>}

                     {/* <Form.Item
                        label='Hold Period'
                        name="holdPeriod"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" addonAfter="Y" placeholder={props.propertyInfo.holdPeriod} disabled={true} />
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Investment'
                        name="minimumInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.minimumInvestment} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='First Distribution'
                        name="firstDistribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.firstDistribution} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Distribution Frequency'
                        name="distributionFrequency"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Select defaultValue="Monthly">
                        <Option value="Monthly">Monthly</Option>
                        <Option value="Quarterly">
                          Quarterly
                        </Option>
                        <Option value="Yearly">Yearly</Option>
                      </Select>
                     </Form.Item>}

                     {false && <Form.Item
                        label='Asset Profile'
                        name="assetProfile"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" placeholder={props.propertyInfo.assetProfile} disabled={true} />
                     </Form.Item>}

                     {(props.propertyType==='project' ) &&<Form.Item
                        label='Loan-to-Value'
                        name="loanToValue"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.loanToValue} addonAfter="%" disabled={true}/>
                     </Form.Item>}
                     {( props.commercialOption=='rent') &&<Form.Item
                        label='Current Occupancy'
                        name="currentOccupancy"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.currentOccupancy} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.propertyType==='commercial') &&<Form.Item
                        label='Investor Profit Share'
                        name="investorProfitShare"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.investorProfitShare} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.propertyType=='commercial') &&<Form.Item
                        label='Preferred Return'
                        name="preferredReturn"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.preferredReturn} disabled={true} />
                     </Form.Item>}
                     <Form.Item
                        label='Total Investment Target'
                        name="totalInvestmentTarget"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.totalInvestmentTarget} disabled={true} />
                     </Form.Item>
                     {(props.propertyType == 'project' ) &&<Form.Item
                        label='Co-Investment'
                        name="coInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.coInvestment} disabled={true} />
                     </Form.Item>}

                     {(props.propertyType == 'project') &&<Form.Item
                        label='Targeted Avg Cash Yeild'
                        name="targetedAvgCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.targetedEquityMultiple} disabled={true}/>
                     </Form.Item>}
                    {(props.propertyType == 'project') && <Form.Item
                        label='Targeted Investment Period'
                        name="targetedInvestmentPeriod"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.targetedInvestmentPeriod} disabled={true}  addonAfter="years" />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Token Issuance'
                        name="tokenIssuance"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenIssuance} disabled={true} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Location | MSA'
                        name="locationMsa"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.locationMsa} disabled={true} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Property Type'
                        name="propertyType"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyType} disabled={true} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Period of Sale'
                        name="periodOfSale"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.periodOfSale} disabled={true} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Type Of Token'
                        name="typeOfToken"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.typeOfToken} disabled={true} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Token Properties'
                        name="tokenProperties"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenProperties} disabled={true} />
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Sum of Contributions'
                        name="minimumSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.minimumSumContribution} addonAfter={environment.currencySign} disabled={true} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Targeted Sum of Contributions'
                        name="targetedSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.targetedSumContribution} addonAfter={environment.currencySign} disabled={true} />
                     </Form.Item>}
                     {false && <Form.Item
                        label='Accepted Currencies'
                        name="acceptedCurrencies"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.acceptedCurrencies} disabled={true} />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Name Of Token'
                        name="nameOfToken"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.nameOfToken} disabled={true} />
                     </Form.Item>
                     <Form.Item
                        label='Token Symbol'
                        name="tokenSymbol"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenSymbol} disabled={true} />
                     </Form.Item> */}
                     {false && <Form.Item
                        label='Token Standard'
                        name="tokenStandard"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenStandard} disabled={true} />
                     </Form.Item>}

                     {/* <Form.Item
                        label='Facebook'
                        name="facebook"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.facebook} disabled={true} />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='Linkedin'
                        name="linkedin"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.linkedin} disabled={true} />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='Twitter'
                        name="twitter"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.twitter} disabled={true} />
                     </Form.Item> */}

                     {/* <Form.Item
                        label='other links'
                        name="otherLinks"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.otherLinks} disabled={true} />
                     </Form.Item> */}

                    {/* {(props.propertyType == 'residential' || props.commercialOption =='rent' || props.propertyType== 'project' || props.propertyType== 'commercial') && */}
                    <Form.Item
                        label={
                          <span>
                             <span style={{ color: '#ff4d4f', fontSize: '12px' }}></span> Projected Funding Deadline
                          </span>
                        }
                        
                        name="projectedFundingDeadline"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <DatePicker format={dateFormat} style={{width:'100%'}} defaultValue={dayjs(props.propertyInfo.projectedFundingDeadline, dateFormat)} disabled={true} disabledDate={d => !d || d?.isSameOrBefore(moment(new Date()).format(dateFormat)) } />
                     </Form.Item>
                     {/* } */}

                     <br /><br />
                      <Form.Item style={{ display: "block", float: "right" }}>
                        {(props.userInfo?.role === 'issuer company manager') &&
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setEditView(true)}
                        >
                          Edit
                        </Button>}
                      </Form.Item>
                      <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
       {!loading && props.additionalDetailsDone && editView && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Portfolio Financials</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveAdditionalDetails}>
                  <Card>
                  {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label={`Targeted Investor IRR`}
                        name="targetedInvestorIRR"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.targetedInvestorIRR} defaultValue={props.propertyInfo.targetedInvestorIRR} />
                      </Form.Item>}
                      {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Targeted Equity Multiple'
                        name="targetedEquityMultiple"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="x" placeholder={props.propertyInfo.targetedEquityMultiple} defaultValue={props.propertyInfo.targetedEquityMultiple} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Year 1 Cash on Cash'
                        name="yearOneCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.yearOneCashOnCash} defaultValue={props.propertyInfo.yearOneCashOnCash} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Stabilized Cash on Cash'
                        name="stabilizedCashOnCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.stabilizedCashOnCash} defaultValue={props.propertyInfo.stabilizedCashOnCash} />
                     </Form.Item>}
                     {(false) &&<Form.Item
                        label='Minimum Investment in Rs'
                        name="minimumInvestmentInRs"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo?.minimumInvestmentInRs} defaultValue={props.propertyInfo.minimumInvestmentInRs} />
                     </Form.Item>}

                     
                     {/* <Form.Item
                        label='Hold Period'
                        name="holdPeriod"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" addonAfter="Y" placeholder={props.propertyInfo.holdPeriod} defaultValue={props.propertyInfo.holdPeriod} />
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Investment'
                        name="minimumInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.minimumInvestment} defaultValue={props.propertyInfo.minimumInvestment} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='First Distribution'
                        name="firstDistribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.firstDistribution}                         addonAfter={environment.currencySign}
                          defaultValue={props.propertyInfo.firstDistribution} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent') &&<Form.Item
                        label='Distribution Frequency'
                        name="distributionFrequency"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                             <Select defaultValue="Monthly">
                        <Option value="Monthly">Monthly</Option>
                        <Option value="Quarterly">
                          Quarterly
                        </Option>
                        <Option value="Yearly">Yearly</Option>
                      </Select>
                     </Form.Item>}

                     {false && <Form.Item
                        label='Asset Profile'
                        name="assetProfile"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="text" placeholder={props.propertyInfo.assetProfile} defaultValue={props.propertyInfo.assetProfile} />
                     </Form.Item>}

                     {(props.propertyType==='project' ) &&<Form.Item
                        label='Loan-to-Value'
                        name="loanToValue"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" placeholder={props.propertyInfo.loanToValue}  addonAfter="%" defaultValue={props.propertyInfo.loanToValue} />
                     </Form.Item>}
                     {( props.commercialOption=='rent') &&<Form.Item
                        label='Current Occupancy'
                        name="currentOccupancy"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.currentOccupancy} defaultValue={props.propertyInfo.currentOccupancy} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.propertyType==='commercial') &&<Form.Item
                        label='Investor Profit Share'
                        name="investorProfitShare"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.investorProfitShare} defaultValue={props.propertyInfo.investorProfitShare} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.propertyType=='commercial') &&<Form.Item
                        label='Preferred Return'
                        name="preferredReturn"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.preferredReturn} defaultValue={props.propertyInfo.preferredReturn} />
                     </Form.Item>}
                     <Form.Item
                        label='Total Investment Target'
                        name="totalInvestmentTarget"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.totalInvestmentTarget} defaultValue={props.propertyInfo.totalInvestmentTarget} />
                     </Form.Item>
                     {(props.propertyType == 'project' ) &&<Form.Item
                        label='Co-Investment'
                        name="coInvestment"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.coInvestment} defaultValue={props.propertyInfo.coInvestment} />
                     </Form.Item>}
                     {(props.propertyType == 'project') &&<Form.Item
                        label='Targeted Avg Cash Yeild'
                        name="targetedAvgCash"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter="%" placeholder={props.propertyInfo.targetedEquityMultiple} defaultValue={props.propertyInfo.targetedEquityMultiple} />
                     </Form.Item>}
                     {(props.propertyType == 'project') &&<Form.Item
                        label='Targeted Investment Period'
                        name="targetedInvestmentPeriod"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.targetedInvestmentPeriod}  addonAfter="years" defaultValue={props.propertyInfo.targetedInvestmentPeriod} />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Token Issuance'
                        name="tokenIssuance"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenIssuance} defaultValue={props.propertyInfo.tokenIssuance} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Location | MSA'
                        name="locationMsa"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.locationMsa} defaultValue={props.propertyInfo.locationMsa} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Property Type'
                        name="propertyType"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyType} defaultValue={props.propertyInfo.propertyType} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Period of Sale'
                        name="periodOfSale"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.periodOfSale} defaultValue={props.propertyInfo.periodOfSale} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Type Of Token'
                        name="typeOfToken"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.typeOfToken} defaultValue={props.propertyInfo.typeOfToken} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Token Properties'
                        name="tokenProperties"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenProperties} defaultValue={props.propertyInfo.tokenProperties} />
                     </Form.Item> */}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Minimum Sum of Contributions'
                        name="minimumSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.minimumSumContribution} defaultValue={props.propertyInfo.minimumSumContribution} />
                     </Form.Item>}
                     {(props.propertyType == 'project' || props.commercialOption=='rent' || props.commercialOption=='sale') &&<Form.Item
                        label='Targeted Sum of Contributions'
                        name="targetedSumContribution"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input type="number" addonAfter={environment.currencySign} placeholder={props.propertyInfo.targetedSumContribution} defaultValue={props.propertyInfo.targetedSumContribution} />
                     </Form.Item>}
                     {false && <Form.Item
                        label='Accepted Currencies'
                        name="acceptedCurrencies"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.acceptedCurrencies} defaultValue={props.propertyInfo.acceptedCurrencies} />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Name Of Token'
                        name="nameOfToken"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.nameOfToken} defaultValue={props.propertyInfo.nameOfToken} />
                     </Form.Item>
                     <Form.Item
                        label='Token Symbol'
                        name="tokenSymbol"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenSymbol} defaultValue={props.propertyInfo.tokenSymbol} />
                     </Form.Item> */}
                     {false && <Form.Item
                        label='Token Standard'
                        name="tokenStandard"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.tokenStandard} defaultValue={props.propertyInfo.tokenStandard} />
                     </Form.Item>}
                     {/* <Form.Item
                        label='Facebook'
                        name="facebook"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.facebook} defaultValue={props.propertyInfo.facebook} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Linkedin'
                        name="linkedin"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.linkedin} defaultValue={props.propertyInfo.linkedin} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Twitter'
                        name="twitter"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.twitter} defaultValue={props.propertyInfo.twitter} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label='Other links'
                        name="otherLinks"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.otherLinks} defaultValue={props.propertyInfo.otherLinks} />
                     </Form.Item> */}
                     {/* {(props.propertyType === 'residential' || props.commercialOption == 'rent' || props.propertyType== 'project' ) && */}
                     <Form.Item
                        label={
                          <span>
                             <span style={{ color: '#ff4d4f', fontSize: '12px' }}></span> Projected Funding Deadline
                          </span>
                        }
                        name="projectedFundingDeadline">
                        <DatePicker format={dateFormat} style={{width:'100%' }} defaultValue={dayjs(props.propertyInfo.projectedFundingDeadline, dateFormat)} disabledDate={d => !d || d?.isSameOrBefore(moment(new Date()).format(dateFormat)) } />
                     </Form.Item>
                     {/* } */}

                     <br /><br />
                     <Form.Item style={{ display: "block", float: "right" }}>
                        <Button size='large' type='primary' htmlType="submit" loading={loading}>Update</Button>
                     </Form.Item>
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
      </>
  );
}
