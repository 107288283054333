import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import Config from "../../configure";
import searchlogo from "../../assets/images/icons/search.png"
import profileIcon from "../../assets/profile.png"
import $ from "jquery";
import "./index.css";
import { environment } from "../../environments/environment";
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      redirect: false,
    };
  }
  toggleNavMenu = () => {
    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 700, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = "0");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    $(document).ready(() => {
      $('.wd_single_index_menu ul li a[href^="#"]').bind(
        "click",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          var $anchor = $(this);
          if ($(window).width() > 991) {
            var headerH = "60";
          } else {
            headerH = "56";
          }
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $($anchor.attr("href")).offset().top - headerH + "px",
              },
              800
            );
        }
      );
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        var target = $(".wd_single_index_menu ul li");
        if (windscroll >= 0) {
          $(".wd_scroll").each(function (i) {
            if ($(this).position().top <= windscroll + 90) {
              target.removeClass("active");
              target.eq(i).addClass("active");
            }
          });
        } else {
          target.removeClass("active");
          $(".wd_single_index_menu ul li:first").addClass("active");
        }
      });
    });
    
    const aboutMenu = (
      <Menu>
        <Menu.Item>
          <Link to="/about-us" onClick={()=> window.scroll({top:0,left:0})} rel="noopener noreferrer">
            About Us
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/mission-vision"onClick={()=> window.scroll({top:0,left:0})}>
            Vision & Mission
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/corporate-responsibility"onClick={()=> window.scroll({top:0,left:0})}>
            Corporate Responsibility
          </Link>
        </Menu.Item>
      </Menu>
    );
    const resourceMenu = (
      <Menu>
        <Menu.Item>
          <Link to="/how-it-works" target="_blank" rel="noopener noreferrer">
            How it works
          </Link>
        </Menu.Item>
        <Menu.Item >
          <Link to="/faq" target="_blank" rel="noopener noreferrer">
            FAQ
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/knowledge-center" target="_blank" rel="noopener noreferrer">
          Knowldege Center
          </Link>
        </Menu.Item>
        {/* <Menu.Item>
          <Link to="#">
          Learn
          </Link>
        </Menu.Item> */}
        <Menu.Item>
          <Link to="/career" target="_blank" rel="noopener noreferrer">
          Career
          </Link>
        </Menu.Item>
      </Menu>
    );

    let navigation = (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-search" >
          {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
            <Link className="home" style={{ fontWeight: "bolder" }} to="/"><li style={{color: "#302302"}}>Home</li></Link>)}
         {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
        <li className="home" style={{fontWeight: 'bold'}}>
          <Dropdown overlay={aboutMenu}>
          <Link >
              <Space>
                <span style={{color: "#302302"}}>About</span>
                {/* <DownOutlined /> */}
              </Space>
              </Link>
          </Dropdown>
        </li>)}

        <Link className="home" style={{ fontWeight: "bolder"}} to="/marketplace"><li style={{color: "#302302"}}>Marketplace</li></Link>
        {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (<Link className="home" style={{ fontWeight: "bolder" }} to="/list-your-property" target="_blank" rel="noopener noreferrer"><li style={{color: "#302302"}}>List Property</li></Link>)}
        {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
        <li className="home" style={{fontWeight: 'bold' }}>
          <Dropdown overlay={resourceMenu} zIndexPopup>
          <Link >
              <Space>
                <span style={{color: "#302302"}}>Resources</span>
                {/* <DownOutlined /> */}
              </Space>
              </Link>
          </Dropdown>
        </li>
        )}

        {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && 
        (<Link className="home" style={{ fontWeight: "bolder"}} to="/features" target="_blank" rel="noopener noreferrer">
          <li style={{color: "#302302"}}>Features</li>
        </Link>)}

        {/* <Link className="blog" style={{ fontWeight: "bolder" }} to="/marketplace"><li>Blogss</li></Link>
        <Link className="token" style={{ fontWeight: "bolder", paddingTop: '2px' }} to="/marketplace"><li>Tokenization</li></Link>*/}
        {/* <Link className="faq" style={{ fontWeight: "bolder", paddingTop: '2px' }} to="/marketplace"><li>FAQ</li></Link>  */}
        {/* <li>
          <img
            src={searchlogo}
            alt="logo" className="search"


          />
        </li> */}
       <Link
          className="home"
          style={{ fontWeight: "bolder" }}
          to="/account/login"
        >
          <span className="login">
            <img style={{height: "20px", alignSelf: "center"}} src={profileIcon}></img> 
            <span style={{color: "#302302",paddingLeft:'5px'}}>Login</span>
          </span>
        </Link>
        <Link to="/account/register/investor">
          <span className="register register-button">Register</span>
        </Link>
      </ul>
    );
    
    return (
      <div id="default" className="gc_main_menu_wrapper">
                <header 
          className={`gc_main_menu_wrapper ${this.state.scroll > this.state.top
            ? "menu_fixed animated fadeInDown"
            : ""
            }`}
        >
          <Container fluid >
            <Row>

            <nav class="navbar navbar-expand-lg navbar-light bg-light " style={{height:'65px'}}>
                <div className={`container-fluid align-items-baseline ${this.state.scroll > this.state.top
            ? "header2"
            : "header1"
            }`}>
                  <div className="col-md-1"></div>
                  {(environment.companyName === 'vastu' || environment.companyName === 'reet') && (
                  <span style={{color: "#102253", fontWeight: "600", fontSize: "32px"}}>{environment.headerTitle}</span>
                  )}
                  {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
                  <Link to={Config.defaultPath} style={{alignSelf: "center"}}>
                    <span style={{color: "#102253", fontWeight: "600", fontSize: "32px"}}>{environment.headerTitle} {environment.companyName === 'vruddhix' && (<span style={{color: "green", marginLeft: '-5px'}}>X</span>)}</span>
                  </Link>
                  )}
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                    {navigation}
                  </div>
                  </div>
              </nav>


              {/* <Col className="col-xs-12 new-col" sm={12} md={3} lg={3}>
                <div className="logo-area">
                  <Link to={Config.defaultPath}>
                    
                    <p id="retapstyle">RETAP</p>
                  </Link>
                  <div className="menu-area" style={{ display: "flex" }}>
                    <div id="register" className="login-btn">
                      <Link
                        to="/account/login"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          fontSize: "13px",
                        }}
                        className="primary-bttn"
                      >
                        <i>Login</i>
                      </Link>
                    </div>
                    <div id="login" className="login-btn">
                      <Link
                        to="/account/register/investor"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          fontSize: "13px",
                        }}
                        className="danger-btn"
                      >
                        <i >Register</i> 
                      </Link>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* <Col lg={6} md={6} sm={6} className="col-xs-6 new-col">
                <div className="menu-area hidden-xs">
                  <nav className="wd_single_index_menu btc_main_menu">
                    {navigation}
                  </nav>
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/login"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        fontSize: "13px",
                      }}
                      className="primary-bttn"
                    >
                    
                      <span>Login</span>
                    </Link>
                  </div>
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/register/investor"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        fontSize: "13px",
                      }}
                      className="danger-btn"
                    >
                    
                      <span>Register</span>
                    </Link>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default Header;
