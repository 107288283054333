import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Investors from './Investors/Investors';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/investors-list`}>
        <Investors/>
      </Route>
    </Switch>
  )
}