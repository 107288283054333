import React, { useRef, useState } from 'react'
import mpStyle from './MarketplaceHeader.module.scss'
import fileDock from '../../assets/images/marketplace/File_dock_search.png'
import Pointers from '../../assets/images/marketplace/Pointers.png'
import Setting_vert from '../../assets/images/marketplace/Setting_vert.png'
import Widget_light from '../../assets/images/marketplace/Widget_light.png'
import home from '../../assets/images/marketplace/home.png'


const menus = [
    {label:'All Properties',icon: fileDock,
        submenu:[]
    },
    {label:'Resale Properties ',icon: home,
    submenu:[]
    },
    {label:'Locations',icon: Pointers,
    submenu:[
        // {label:'location 1',link:'some'},
        // {label:'location 2',link:'somee'},
        // {label:'location 3',link:'some'},
        // {label:'location 3',link:'some'},
        ]
    },
    {label:'All Type',icon: Widget_light,
    submenu:[
        // {label:'Type 1',link:'some'},
        // {label:'Type 2',link:'some'},
        ]
    },
    {label:'Sort By',icon: Setting_vert,
     submenu:[
        // {label:'Highest Price',link:'some'},
        // {label:'Lowest Price',link:'some'},
    ]   
    },

]

const MenuItem = ({index,label,icon,isLast,submenu,selectedMenu}) =>{
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

    return (
        <>
        <div className={`${mpStyle.menuItem} col-12 col-md-2`} style={isLast? {borderRight:'0px'} : {}}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         onClick={()=>selectedMenu(index)}
         >
            <div className="icon">
                <img src={icon} style={{height:'20px'}} alt="" />
            </div>
            <div className="label">{label}</div>
        

        {isHovered && submenu.length > 0 && <div className={`${mpStyle.uCustomDropdown} u-custom-dd`}>
            <ul>
                {submenu.map((smenu,i) => <li key={i} >{smenu.label}</li>)}
            </ul>
        </div>
        }
        </div>
    </>
    )
}



 
const MarketPlaceHeaderMenu = (props:any) => {
    const menuitems = props.view == 'propertyDetails' ? props.menus : menus;
    const containerRef = useRef<any>(null);
    
    const handleScroll = (scrollOffset) => {
        console.log(scrollOffset)
        if (containerRef.current) {
          containerRef.current.scrollLeft += scrollOffset;
          containerRef.current.scrollBy({
            left: scrollOffset,
            behavior: 'smooth'
          });
        }
      };

  return (
    <div  className={`${mpStyle.uMpMenuWraper} ${props.view == 'propertyDetails' ? mpStyle.menuabs : {}} container`}>
        <div ref={containerRef} className={`${props.view == 'propertyDetails' ? mpStyle.scrollable : {}} row menu-wrapper`} style={{minHeight:'81px', justifyContent:'space-evenly'}}>
            {
                
                menuitems.map((menu,index) => (
                    <MenuItem  
                        key={index} 
                        label={menu.label} 
                        icon={menu.icon} 
                        isLast={index === menuitems.length - 1}
                        submenu={menu.submenu}
                        selectedMenu={props.view == 'propertyDetails' ? props.selectedMenu : ()=>{}}
                        index ={menu.tabNo}
                    />
                ))
                
            }

        </div>
        {props.view == 'propertyDetails' &&
            <>
                <button  onClick={() => handleScroll(100)} className={mpStyle.nextu}> {'>'} </button>
                <button  onClick={() => handleScroll(-100)} className={mpStyle.prevu} style={{}}> {'<'} </button>
            </>
        }
    </div>
  )
}

export default MarketPlaceHeaderMenu