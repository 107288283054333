import React from 'react'
import loc from '../../assets/images/marketplace/Blood.png'
import  './MarketplacePropertyCard.scss'
import { environment } from '../../environments/environment'
import moment from 'moment';
import { SharedService } from '../../components/Shared/Shared.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const sharedService = new SharedService();

const MarketplacePropertyCard = ({propertyData,callFrom=''}) => {
    return (
        <>
            <div className={`col-12 ${callFrom != 'createToken' ? 'col-xl-4 col-xxl-3' : ''} c`}
            onClick={()=>{window.location.href = `/property-details?propertyId=${propertyData['_id']}`}}>
                <div className="card realestateCardWrapper">
                    <div className="card-body p-2 d-flex flex-column justify-content-around">
                        <div className="header relative">
                            <div className="businessname text-primary">{propertyData?.propertyName}</div>
                            <div className="businesslocation">
                                <img src={loc} alt=""></img>
                                {`${propertyData?.propertyCity},  ${propertyData?.propertyAddress}`}</div>
                            {/* <div className="tag" style={{ right: '18px', top: '0px', backgroundColor: 'gray' }}>1 BHK
                                <div className="triangle"></div>
                            </div> */}
                        </div>
                        <div className="businesscoverimage">
                            {/* <img src={propertyData['propertyImages'][0]?.URL} alt={propertyData['propertyImages'][0]?.name}></img> */}
                            <LazyLoadImage
                                    alt={propertyData['propertyImages'][0]?.name}
                                    effect="blur"
                                    src={propertyData['propertyImages'][0]?.URL} // use normal <img> attributes as props
                                     />

                            <div className="propertytypestrip" style={propertyData.projectedFundingDeadline ? new Date(propertyData.projectedFundingDeadline) > new Date() ?  {backgroundColor:'#27ae60'} : {} : {}}>
                                {
                                propertyData.projectedFundingDeadline ? new Date(propertyData.projectedFundingDeadline) > new Date() ?  'OFFERING' : 'FULLY FUNDED' : 'FULLY FUNDED'
                                }
                            </div>
                        </div>
                        <div className="businessinfo">
                            <ul>

                               {propertyData.propertyType && <li>
                                    <span className="infolabel">Property Type</span>
                                    <span className="infovalue">{propertyData.propertyType[0]?.toUpperCase() + propertyData.propertyType?.slice(1)} {propertyData.commercialOption ? ' - '+ propertyData.commercialOption[0]?.toUpperCase() + propertyData.commercialOption?.slice(1) :""} </span>
                                </li>
                                }
                               {propertyData.pricePerSqFT && <li>
                                    <span className="infolabel">Price Per(Sq.ft)</span>
                                    <span className="infovalue">{environment.currencySign}{sharedService.formatIndianCurrency(propertyData.pricePerSqFT)}</span>
                                </li>
                                }
                               {propertyData.carpetArea && <li>
                                    <span className="infolabel">Carpet Area(Sq.ft)</span>
                                    <span className="infovalue">{propertyData.carpetArea}</span>
                                </li>
                                }
                               {propertyData.builtUpArea && <li>
                                    <span className="infolabel">Built-up Area(Sq.ft)</span>
                                    <span className="infovalue">{propertyData.builtUpArea}</span>
                                </li>
                                }

                               {
                                <>
                                    {propertyData.currentOccupancy && <li>
                                            <span className="infolabel">Current Occupancy</span>
                                            <span className="infovalue">{propertyData.currentOccupancy}</span>
                                        </li>
                                        }
                                    {propertyData.marketOccupancy && <li>
                                            <span className="infolabel">Market Occupancy</span>
                                            <span className="infovalue">{propertyData.marketOccupancy}</span>
                                        </li>
                                        }
                                    {propertyData.purchasedPrice && <li>
                                            <span className="infolabel">Purchase Price</span>
                                            <span className="infovalue">{sharedService.formatIndianCurrency(propertyData.purchasedPrice)}</span>
                                        </li>
                                        }
                                    {propertyData.landmark && <li>
                                            <span className="infolabel">Landmark</span>
                                            <span className="infovalue">{propertyData.landmark}</span>
                                        </li>
                                        }
                                    {propertyData.propertyAge && <li>
                                            <span className="infolabel">Property Age</span>
                                            <span className="infovalue">{propertyData.propertyAge}</span>
                                        </li>
                                        }
                                    </>
                                }
                               
                               {propertyData.targetedInvestorIRR && <li>
                                    <span className="infolabel">IRR</span>
                                    <span className="infovalue">{propertyData.targetedInvestorIRR}%</span>
                                </li>
                                }
                               {propertyData.targetedEquityMultiple && <li>
                                    <span className="infolabel">Equity Multiple</span>
                                    <span className="infovalue">{propertyData.targetedEquityMultiple}x</span>
                                </li>}
                                {propertyData.yearOneCashOnCash &&<li>
                                    <span className="infolabel">Year 1 Cash on Cash</span>
                                    <span className="infovalue">{propertyData.yearOneCashOnCash}%</span>
                                </li>}
                                {propertyData.stabilizedCashOnCash &&<li>
                                    <span className="infolabel">Stabilized Cash on Cash</span>
                                    <span className="infovalue">{propertyData.stabilizedCashOnCash}%</span>
                                </li>}
                                {propertyData.minimumInvestment &&<li>
                                    <span className="infolabel">Minimum Investment</span>
                                    <span className="infovalue">{environment.currencySign} {sharedService.formatIndianCurrency(propertyData.minimumInvestment)}</span>
                                </li>}
                                {propertyData.assetProfile &&<li>
                                    <span className="infolabel">Asset Profile</span>
                                    <span className="infovalue">{propertyData.assetProfile}</span>
                                </li>}
                                {propertyData.projectedFundingDeadline && new Date(propertyData.projectedFundingDeadline) > new Date() &&
                                    <li>
                                    <span className="infolabel">Projected Funding Deadline</span>
                                    <span className="infovalue">{moment(propertyData.projectedFundingDeadline)?.format('MMMM DD, YYYY')}</span>
                                </li>}
                            </ul>
                        </div>
                        <button className={`btn btn-primary`} style={propertyData.projectedFundingDeadline ? new Date(propertyData.projectedFundingDeadline) > new Date() ?  {backgroundColor:'#27ae60'} : {} : {}}
                        onClick={()=>{window.location.href = `/property-details?propertyId=${propertyData['_id']}`}}
                        >
                            {propertyData.projectedFundingDeadline ? new Date(propertyData.projectedFundingDeadline) > new Date() ? 'View Oppurtunity' : 'Offer Closed' : 'Offer Closed' }
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketplacePropertyCard