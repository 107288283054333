import React, { useState } from "react";
import { Card, Col, Row, Form, Input, Button, message } from "antd";
import { AccountService } from "../Account/Account.service";
import "../InterestPropertyCompanyManager/InterestPropertyCompanyManager.scss";
import LogiBackGroundImage from '../../assets/images/about-us/LogiBackGroundImage.png'


const accountService = new AccountService();

const InterestPropertyCompanyManager = () => {
  const [submitted, setSubmitted] = useState(false);

  const submitInterest = async (values) => {
    try {
      const response = await accountService.submitInterestToRegisterAsPCA(values);
      if (response) {
        message.success("Successfully sent interest to Marketplace Super Admin.");
        setSubmitted(true);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while sending interest!");
    }
  };

  return (
    <div className="main-containerrr">
      <div>
      <img className="BackgroundImage" src={LogiBackGroundImage} alt='About Us' ></img>
      </div>

    
      <br />
      <Row justify="center">
        <Col span={12} style={{ textAlign: "center", border: "none" }}>
          <Card>
            {!submitted ? (
              <Form onFinish={submitInterest} labelCol={{ span: 5 }}>
                <div className="form-item" >
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: "150%", textAlign: "left" }}
                    rules={[{ required: true, message: "Please input your first name!" }]}
                  >
                    <div className="input-area">
                      <Input />
                    </div>

                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: "150%", textAlign: "left" }}
                    rules={[{ required: true, message: "Please input your last name!" }]}
                  >
                    <div className="input-area">
                      <Input />
                    </div>
                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item
                    label="Email"
                    name="email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: "150%", textAlign: "left" }}
                    rules={[{ required: true, type: "email", message: "Please input a valid email!" }]}
                  >
                    <div className="input-area">
                      <Input />
                    </div>
                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item label="Comments" name="comments" labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}  style={{ width: "150%", textAlign: "left" }}>
                    <div className="input-area">
                      <Input.TextArea rows={4} />
                    </div>
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="col-10 text-center">
                    <Button
                      style={{ marginTop: "20px", borderRadius: "20px" ,marginLeft:'100px'}}
                      type="primary" htmlType="submit"

                    >

                      Submit
                    </Button>
                  </div>

                </Form.Item>
              </Form>
            ) : (
              <h6>
                Your interest to register as a property company manager has been successfully submitted to the Marketplace Super Admin. If approved, you will receive an email invite to register.
                <br />
                Now you can go to the marketplace page to see all properties!
              </h6>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InterestPropertyCompanyManager;
