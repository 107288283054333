import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
  Spin,
  Form,
  Radio,
  Checkbox, 
  Table, 
  message, 
  Modal,
  notification,
  Input,
  Popconfirm
} from "antd";
import QRCode from 'qrcode.react';
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { LoadingOutlined } from '@ant-design/icons';
import { InvestorService } from "../Investor.service";
import { useHistory, useParams } from "react-router-dom";
import { AuthService } from "../../Shared/Auth.service";
import moment from "moment";
import Process from "../Process";
import KYCUniqueURLCard from "../KYCUniqueURLCard";
import { environment } from "../../../environments/environment";
import { SharedService } from "../../Shared/Shared.service";
import './EscrowPayment.scss';

const { Title, Paragraph } = Typography;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService()

export default () => {
  const history = useHistory();
  const { userInfo, setUserInfo } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [escrowCompanyDetails, setEscrowCompanyDetails] = useState();
  const [paymentGatewayApiKey, setPaymentGatewayApiKey] = useState();
  const [investmentDetails, setInvestmentDetails] = useState();
  // different steps are start, saved, pending, completed, expired
  const [currentStep, setCurrentStep] = useState('start');
  const [screenNo, setScreenNo] = useState(1);
  const [investmentAmount, setInvestmentAmount] = useState();
  const [pricePerToken, setPricePerToken] = useState();
  const [tokenCount, setTokenCount] = useState();
  const [minFiatAmountForUSDC, setMinFiatAmountForUSDC] = useState();
  const [minFiatAmountForUSDT, setMinFiatAmountForUSDT] = useState();
  const [selectedMinFiatAmount, setSelectedMinFiatAmount] = useState();
  const [coinSelected, setCoinSelected] = useState('usdc');
  const [payableCoinCount, setPayableCoinAmountCount] = useState();
  const [paymentId, setPaymentId] = useState();
  const [QRAddress, setQRAddress] = useState();
  const [QRValidityTS, setQRvalidityTS] = useState();
  const [investmentUniqueId, setInvestmentUniqueId] = useState();
  const [form] = Form.useForm();
  const { companyId } = useParams();
  const typingTimer = useRef(null);
  //console.log('companyId: ', companyId);
 

  useEffect(() => {
    (async () => {
      if(userInfo?._id) {
        setLoading(true);
        try {          
          // get escrowCompanyDetails
          const res1 = await investorService.getCompanyTokenDetailsByCompanyId(companyId);
          if (res1.success && res1.data?.escrowPayment?.paymentGatewayAPIKey?.length > 0) {
            setPaymentGatewayApiKey(res1.data.escrowPayment?.paymentGatewayAPIKey);
            setEscrowCompanyDetails(res1.data);
            setPricePerToken(res1.data.tokenConfigurationProcess?.pricePerToken);

            // min amount details using nowpayment api for usdc
            const res2 = await investorService.getMinAmountForUSDC(res1.data.escrowPayment?.paymentGatewayAPIKey);
            if(res2?.fiat_equivalent) {
              console.log(`res2?.fiat_equivalent: ${res2?.fiat_equivalent}`);
              setMinFiatAmountForUSDC(Math.floor(res2?.fiat_equivalent));
              setSelectedMinFiatAmount(Math.floor(res2?.fiat_equivalent));
            }

            // min amount details using nowpayment api for usdterc20
            const res3 = await investorService.getMinAmountForUSDT(res1.data.escrowPayment?.paymentGatewayAPIKey);
            if(res3?.fiat_equivalent) {
              console.log(`res3?.fiat_equivalent: ${res3?.fiat_equivalent}`);
              setMinFiatAmountForUSDT(Math.floor(res3?.fiat_equivalent));
            }

            // get last payment details
            const res4 = await investorService.getLastEscrowPaymentDetaisByInvestorIdCompanyId(userInfo?._id, companyId);
            if(res4.success && res4?.data?._id) {
              setInvestmentDetails(res4.data);
              setInvestmentUniqueId(res4?.data?._id);
              // if expire or failed create a new payment
              if(res4.data.paymentDetails?.payment_status === 'expired' || res4.data.paymentDetails?.payment_status === 'failed') {
                notification.open({
                  message: 'Last QR Expired',
                  description: 'Trying to generate a new qr',
                  duration: 60,
                });

                let data1 = {
                  "price_amount": res4.data.paymentDetails.price_amount,
                  "price_currency": "inr",
                  "pay_currency": res4.data.paymentDetails.pay_currency,
                  "ipn_callback_url": `${environment.APIURL}/investor/redirectToUI?companyId=${companyId}`,
                  "order_id": Array.from({ length: 10 }, () => Math.random().toString(36)[2]).join(''),
                  "order_description": `price_amount: ${res4.data.paymentDetails.price_amount}, pay_currency: ${res4.data.paymentDetails.pay_currency}`,
                  "is_fixed_rate": true,
                  "is_fee_paid_by_user": true
                };
                const res5 = await investorService.createNewPayment(data1, res1.data.escrowPayment?.paymentGatewayAPIKey);
                if(res5?.payment_id) {
                  setQRAddress(res5.pay_address);
                  setPaymentId(res5.payment_id);
                  setQRvalidityTS(res5.expiration_estimate_date);
                  let data2 = {
                    paymentDetails: res5,
                    companyId,
                    investorId: userInfo?._id,
                    investmentId: res4?.data?._id,
                  }
                  const res6 = await investorService.saveorUpdateEscrowPaymentDetailsByCompanyId(data2, companyId);
                  if(res6.success) {
                    setCoinSelected(res5.pay_currency);
                    setPayableCoinAmountCount(res5.pay_amount);
                    setInvestmentAmount(res5.price_amount);
                    checkPaymentStatus(res5.payment_id, res1.data.escrowPayment?.paymentGatewayAPIKey, res4?.data?._id);
                    setScreenNo(2);
                    setCurrentStep('pending');
                    setFetching(true);

                    notification.open({
                      message: 'New QR generated',
                      description: 'New QR generated. Hence generated a new QR Code for same payment details',
                      duration: 60,
                    });
                  }
                }
              } else if(res4.data.paymentDetails?.payment_status === 'waiting') {
                // any other state start new payment
                setInvestmentUniqueId(res4.data?._id);
                console.log('setting investmentUniqueId:', res4.data?._id);
                setQRAddress(res4.data.paymentDetails?.pay_address);
                setPaymentId(res4.data.paymentDetails?.payment_id);
                setQRvalidityTS(res4.data.paymentDetails?.expiration_estimate_date);
                setCoinSelected(res4.data.paymentDetails?.pay_currency);
                setPayableCoinAmountCount(res4.data.paymentDetails?.pay_amount);
                setInvestmentAmount(res4.data.paymentDetails?.price_amount);
                checkPaymentStatus(res4.data.paymentDetails?.payment_id, res1.data.escrowPayment?.paymentGatewayAPIKey, res4.data?._id);
                setScreenNo(2);
                setCurrentStep('pending');
                setFetching(true);
              } else {
                setFetching(false);
                setCurrentStep('initial');
                setScreenNo(1);
              }
            } else {
              setCurrentStep('start');
              setScreenNo(1);
            }
          } else {
            history.push(`/investor/dashboard`);
          }
        } catch (error) {
          console.error(error);
          notification.open({
            message: 'Error',
            description: error,
            duration: 0,
          });
        }

        setLoading(false);
      }
    })();
  }, [userInfo, companyId]);

  async function checkPaymentStatus(paymentId, requestPaymentGatewayApiKey, reqInvestmentUniqueId) {
    try {
      if(!requestPaymentGatewayApiKey) requestPaymentGatewayApiKey = paymentGatewayApiKey;
      if(!reqInvestmentUniqueId) reqInvestmentUniqueId = investmentUniqueId;

      setFetching(true);
        const response = await investorService.getPaymentStatusById(paymentId, requestPaymentGatewayApiKey);
        
        //mock payment status for testing
        //response.payment_status = 'finished';

        let paymentStatus = response?.payment_status;

        if (paymentStatus === 'waiting') {
            // Payment is still pending, wait for 10 seconds and check again
            setTimeout(() => {
                checkPaymentStatus(paymentId, requestPaymentGatewayApiKey);
            }, 10000);
        } else {
          console.log(`investmentUniqueId: ${investmentUniqueId}`);
          if(!reqInvestmentUniqueId) {
            notification.open({
              message: 'Error',
              description: 'Error investmentId not found while updating payment status in db!',
              duration: 0,
            });
            return false;
          }

            // Payment is no longer pending, handle different statuses accordingly
            if (paymentStatus === 'expired' || paymentStatus === 'failed') {
              let data1 = {
                paymentDetails: response,
                companyId,
                investorId: userInfo?._id,
                investmentId: reqInvestmentUniqueId
              }
              const res = await investorService.saveorUpdateEscrowPaymentDetailsByCompanyId(data1, companyId);
              if(res.success) {
                setTimeout(()=> window.location.reload(), 500);
              }
            } else if(paymentStatus === 'finished' || paymentStatus === 'partially_paid') {
              let data1 = {
                paymentDetails: response,
                status: 'pending',
                companyId,
                investorId: userInfo?._id,
                investmentId: reqInvestmentUniqueId,
                escrowPaymentStatus: 'done'
              }
              const res = await investorService.saveorUpdateEscrowPaymentDetailsByCompanyId(data1, companyId);
              if(res.success) {
                notification.open({
                  message: 'Success',
                  description: 'Successfully paid the required amount to the escrow address. Once your payment gets approved by admins you will be issued tokens!',
                  duration: 0,
                });
                setTimeout(()=> window.location.reload(), 4000);
              }
            }
        }
    } catch (error) {
        // Handle errors if any
        console.error('Error fetching payment status:', error);
        notification.open({
          message: 'Error',
          description: 'Error fetching payment status. Please try again later!',
          duration: 0,
        });
    }
  }

  async function deleteExistingInvestment() {
    try {
      setFetching(true);
      setLoading(true);
      const response = await investorService.deleteEscrowPaymentDetaisById(investmentUniqueId);      
      if (response.success) {
        notification.open({
          message: 'Success',
          description: 'Successfully canceled exisitng payment. You can continue with other payments',
          duration: 0,
        });
      }

      setTimeout(()=> history.push('/investor/dashboard'), 2000);
    } catch (error) {
        // Handle errors if any
        console.error('Error fetching payment status:', error);
        notification.open({
          message: 'Error',
          description: 'Error deleting payment status. Please try again later!',
          duration: 0,
        });
    }
  }

  async function saveInvestmentDetails() {
    try {
      setLoading(true);
      setFetching(true);

      let data1 = {
        "price_amount": investmentAmount,
        "price_currency": "inr",
        "pay_currency": coinSelected,
        "ipn_callback_url": `${environment.APIURL}/investor/redirectToUI?companyId=${companyId}`,
        "order_id": Array.from({ length: 10 }, () => Math.random().toString(36)[2]).join(''),
        "order_description": `price_amount: ${investmentAmount}, pay_currency: ${coinSelected}`,
        "is_fixed_rate": true,
        "is_fee_paid_by_user": true
      };
      const res5 = await investorService.createNewPayment(data1, paymentGatewayApiKey);
      console.log(`res5?.payment_id: ${res5?.payment_id}`);

      if(res5?.payment_id) {
        setQRAddress(res5.pay_address);
        setPaymentId(res5.payment_id);
        setQRvalidityTS(res5.expiration_estimate_date);
        setPayableCoinAmountCount(res5.pay_amount);
        setScreenNo(2);
        setCurrentStep('pending');
        setFetching(true);

        let formData = {investorId:userInfo?._id, companyId, status: 'initial', amountInvested: investmentAmount, tokenCount, coinSelected, paymentDetails: res5, investmentAmount};

        if(isNaN(formData.tokenCount)){
          notification.error({message: 'Amount Invested should be a number only! Type the correct amount again'});
          setLoading(false);
          return false;
        }

        const response = await investorService.saveorUpdateEscrowPaymentDetailsByCompanyId(formData, companyId);
        if (response.success) {
          setInvestmentUniqueId(response.data);
          checkPaymentStatus(res5.payment_id, paymentGatewayApiKey, response.data);
          notification.open({
            message: 'Success',
            description: 'Successfully saved investment details, now you can continue with payments!',
            duration: 0,
          });
        }

        setScreenNo(2);
        setCurrentStep('pending');
      }
    } catch (error) {
        // Handle errors if any
        console.error('Error saving payment details:', error);
        notification.open({
          message: 'Error',
          description: 'Error saving payment details. Please try again later!',
          duration: 0,
        });
    }
    setLoading(false);
  }

  const populateAmountInvestInput = async (e) => {
    let intialValue = e.target.value;
    setInvestmentAmount(intialValue);
    //console.log('companyData.pricePerToken:', pricePerToken);

    const amt = Number((intialValue).replaceAll(',',''));
    const tokenCountTemp = Number(amt) % Number(pricePerToken) !== 0 ? (Number(amt)/ Number(pricePerToken)).toFixed(2) : Number(amt)/ Number(pricePerToken);
    setTokenCount(tokenCountTemp);

    // get estimated coin amount
    if(parseInt(intialValue) >= selectedMinFiatAmount) {    
      clearTimeout(typingTimer.current);

      typingTimer.current = setTimeout(async () => {
          const response = await investorService.getEstimateConvertToCoin(intialValue, coinSelected, paymentGatewayApiKey);
          if (response) {
              setPayableCoinAmountCount(response?.estimated_amount);
          }
      }, 1000);
    }
  }

  const handleCurrencyChange = (e) => {
    setCoinSelected(e.target.value);
    if(e.target.value === 'usdc') {
      setSelectedMinFiatAmount(minFiatAmountForUSDC);
    } else {
      setSelectedMinFiatAmount(minFiatAmountForUSDT);
    }
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    message.success('Copied to clipboard');
  };

  return (
    <>
    {loading && <Spin size='large' style={{textAlign: 'center', marginTop: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} />}
    {!loading && (<>
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            {/* Screen 1 */}
            {screenNo == 1 && (<>
              <Form
                name="escrowInvestmentForm"
                form={form} 
                onFinish={saveInvestmentDetails}
              >
                <h4>Escrow Investment with USDC/USDTC</h4>
                <br/>
                <h6>You have to pay the exact amount</h6>
                <br/>
                <Form.Item>
                  <Radio.Group onChange={handleCurrencyChange} value={coinSelected}>
                    <Radio value="usdc">USDC</Radio>
                    <Radio value="usdterc20">USDT</Radio>
                  </Radio.Group>
                </Form.Item>
                <br/>

                <Form.Item
                  name="investmentAmount"
                  label="Amount to be invested"
                  rules={[
                    {
                      required: true,
                      //min: selectedMinFiatAmount,
                      message: `Please enter a valid amount (minimum Rs. ${selectedMinFiatAmount})`,
                    },
                  ]}
                  help={`Minimum amount should be Rs. ${selectedMinFiatAmount}`}
                >
                  <Input type="number" min={selectedMinFiatAmount} onChange={(e) => populateAmountInvestInput(e)} value={investmentAmount} addonBefore={environment.currencySign} style={{marginLeft: '-160px', width: 'fit-content'}} />
                </Form.Item>

                <br/>
                <Form.Item
                  name="agreement"
                  rules={[
                    {
                      //validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please agree to the PPM of the offering'),
                      required: false,
                    },
                  ]}
                >
                  <Checkbox defaultChecked disabled>I understand and agree to the PPM of the offering</Checkbox>
                </Form.Item>
                <br/>

                <p>{tokenCount && tokenCount !== 0 && `Calculated Token Count: ${tokenCount}`}</p>
                <p>{payableCoinCount && payableCoinCount !== 0 && `Estimated Payble ${coinSelected?.toUpperCase()}: ${payableCoinCount}`}</p>

                <br/>
                <br/>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>)}

            {/* Screen 2 with qr code */}
            {screenNo == 2 && (<>
              <div className="qr-code-parent-wrapper">
                <div className='qr-code-wrapper'>
                  <QRCode value={QRAddress} />
                </div>
                <Title level={3}>Send Payment</Title>
                <Paragraph>To make a payment, send {coinSelected?.toUpperCase()} to this address:</Paragraph>
                <br/>

                <div className='details-tables-wrapper'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e8e8e8', marginBottom: '16px' }}>
                    <div>
                      <Title level={5} style={{ margin: 0 }}>Amount in {coinSelected?.toUpperCase()}</Title>
                    </div>
                    <div>
                      <span style={{ marginRight: '8px' }}>{payableCoinCount}</span>
                      <Button type="primary" onClick={() => handleCopy(payableCoinCount)}>Copy</Button>
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e8e8e8', marginBottom: '16px' }}>
                    <div>
                      <Title level={5} style={{ margin: 0 }}>Amount in INR</Title>
                    </div>
                    <div>
                      <span style={{ marginRight: '8px' }}>{investmentAmount}</span>
                      <Button type="primary" onClick={() => handleCopy(investmentAmount)}>Copy</Button>
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <div>
                      <Title level={5} style={{ margin: 0 }}>{coinSelected?.toUpperCase()} Address</Title>
                    </div>
                    <div>
                      <span style={{ marginRight: '8px' }}>{QRAddress}</span>
                      <Button type="primary" onClick={() => handleCopy(QRAddress)}>Copy</Button>
                    </div>
                  </div>
                </div>

                <Paragraph style={{ margin: '1rem' }}>QR code expires in {moment(QRValidityTS).format('HH:mm:ss')}</Paragraph>
                <br/><br/>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} size="large" tip="We are continuously checking if you have done the payment successfully...">
                </Spin>

                <br/><br/>
                
                <Popconfirm
                  title="Are you sure to cancel this escrow payment?"
                  onConfirm={deleteExistingInvestment}
                >
                  <Button type="primary" danger style={{ marginTop: '1rem' }}>Cancel Payment</Button>
                </Popconfirm>
              </div>
            </>)}
          </Card>
        </Col>
      </Row>
    </>)}
    </>
  );
};