import React, { useState, useEffect } from 'react';
import { Tabs, Card, Row, Col, Typography, message, Button } from 'antd';
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';
import { IssuerRegistrationRequest } from '../../Shared/interfaces';
import './IssuerRegistrationRequests.scss';
import ReqTable from './ReqTable';
import Details from './Details';

const { TabPane } = Tabs;
const {Title} = Typography

const platformIssuerAdminService = new PlatformIssuerAdminService();



export default () => {
  const [pendingFilter, setPendingFilter] = useState({ size: 10, page: 1 });
  const [approvedRejectedFilter, setApprovedRejectedFilter] = useState({ size: 10, page: 1 });
  
  const [pendingReqs, setPendingReqs] = useState<IssuerRegistrationRequest[] | null>(null);
  const [approvedRejectedReqs, setApprovedRejectedReqs] = useState<IssuerRegistrationRequest[] | null>(null);
  
  const [selectedIssuerRegReq, setSelectedIssuerRegReq] = useState<IssuerRegistrationRequest | null>();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setActiveTab] = useState('1'); 


  useEffect(() => {
    const fetchIssuerRegistrationReqs = async () => {
      setLoading(true);
      const filter = activeTab === '1' ? pendingFilter : approvedRejectedFilter;
      const statuses = activeTab === '1' ? ['pending'] : ['verified', 'rejected'];
      
      const response = await platformIssuerAdminService.getIssuerRegistrationReqs(statuses.toString(), filter.size, filter.page);
      
      const reqs: IssuerRegistrationRequest[] = response?.data?.allRequest || [];
      if (activeTab === '1') {
        setPendingReqs(reqs);
      } else {
        setApprovedRejectedReqs(reqs);
      }
      setTotalItems(response?.data?.totalCount || 0);
      setLoading(false);
    };
    
    fetchIssuerRegistrationReqs();
  }, [currentPage, pendingFilter.size, approvedRejectedFilter.size, activeTab]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (activeTab === '1') {
      setPendingFilter(prev => ({ ...prev, page: page }));
    } else {
      setApprovedRejectedFilter(prev => ({ ...prev, page: page }));
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setCurrentPage(1);
  };


  const reviewRegistrationReq = async (status: 'verified' | 'rejected', comments: string) => {
    setSelectedIssuerRegReq((prev: any) => ({ ...prev, [status === 'rejected' ? 'rejecting' : 'verifiying']: true }));
    const response = await platformIssuerAdminService.reviewRegistrationReq({
      requestId: selectedIssuerRegReq?._id as string,
      companyId: selectedIssuerRegReq?.companyId as any,
      status,
      comments,
    });

    if (response.success) {
      if (activeTab === '1') {
        setPendingReqs(prev => prev ? prev.filter(req => req._id !== selectedIssuerRegReq?._id) : null);
      } else {
        setApprovedRejectedReqs(prev => prev ? prev.filter(req => req._id !== selectedIssuerRegReq?._id) : null);
      }
      hideDetails();
      message.success(status === 'verified' ? 'Request verified successfully' : 'Request rejected successfully');
    } else {
      message.error(response.error.message);
      setSelectedIssuerRegReq((prev: any) => ({ ...prev, [status === 'rejected' ? 'rejecting' : 'verifiying']: false }));
    }
  };


  const showDetails = async(issuerRegReq: IssuerRegistrationRequest) => {
    setSelectedIssuerRegReq({...issuerRegReq});
  }


  const hideDetails = () => {
    setSelectedIssuerRegReq(null);
  }



  return (
    <>
      <br/>
      <Row justify="center">
        <Col span={22} style={{textAlign:'center'}}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
              >
              Issuer-LLP Registration Requests
            </Title>
            
            <Tabs defaultActiveKey='1' style={{textAlign:'center'}} onChange={handleTabChange}>
              <TabPane
                tab={
                  <span>
                    <PlaySquareOutlined/>
                    Pending Issuer-LLP Registration Requests
                  </span>
                }
                key='1'
              >
                <ReqTable data={pendingReqs} loading={loading} statuses={['pending']} showDetails={showDetails} 
                    pagination={{ current: currentPage, total: totalItems, pageSize: pendingFilter.size, onChange: handlePageChange, pageSizeOptions: [], showSizeChanger: false }}/>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <ProjectOutlined/>
                    Approved/Rejected Issuer-LLP Registration Requests 
                  </span>
                }
                key='2'
              >
                <ReqTable
                  data={approvedRejectedReqs}
                  loading={loading}
                  statuses={['verified', 'rejected']}
                  showDetails={showDetails}
                  pagination={{ current: currentPage, total: totalItems, pageSize: approvedRejectedFilter.size, onChange: handlePageChange,pageSizeOptions: [], showSizeChanger: false }}
                />

              </TabPane>
            </Tabs>
          </Card>
    
          <Details 
            record={selectedIssuerRegReq} 
            visible={!!selectedIssuerRegReq}
            reviewRegistrationReq={reviewRegistrationReq}
            onCancel={hideDetails}/>

        </Col>
      </Row>
    
    </>
  );
};

