// import * as firebase from 'firebase/app';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

// const headers = {
//   'Content-Type': 'application/json'
// }

const sharedService = new SharedService();


export class PlatformIssuerAdminService {

  private APIURL = environment.APIURL;



  async getIssuerRegistrationReqs(statuses: string, size: number, page: number){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getIssuerRegistrationReqs`, {params: {statuses, size, page}, headers: await sharedService.getAuthHeader()});    
  }

  async reviewRegistrationReq(req: { requestId: string, companyId: string, status: 'verified' | 'rejected', comments: string }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/reviewRegistrationReq`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getIssuerBasicInfoVerifReqs(statuses: string, size: number, page: number) {
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getIssuerBasicInfoVerifReqs`, {params: {statuses, size, page}, headers: await sharedService.getAuthHeader()});
  }

  async reviewBasicInfoVerifReq(req: { requestId: string, companyId: string, status: 'verified' | 'rejected', comments: string }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/reviewBasicInfoVerifReq`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getUsersByRole(role: string, limit: number, searchPage: number){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getUsersByRole`, {params: {role, limit, searchPage}, headers: await sharedService.getAuthHeader()});    
  }
  async getQuestionCategories(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getQuestionCategories`, {headers: await sharedService.getAuthHeader()});    
  }
  async getRegisteredIssuers(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getRegisteredIssuers`, {headers: await sharedService.getAuthHeader()});    
  }
  async setQuestion(req:{category:string,question:string,type:string})
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformIssuerAdmin/setQuestion`,req,{headers:await sharedService.getAuthHeader()});
  }
  async getQuestions()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/platformIssuerAdmin/getQuestions`,{headers:await sharedService.getAuthHeader()});
  }
  async removeQuestion(req:{category:string,question:string,type:string})
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformIssuerAdmin/removeQuestion`,req,{headers:await sharedService.getAuthHeader()});
  }
  async updateQuestion(req:{category:string,question:string,type:string,questionToUpdate:string})
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformIssuerAdmin/updateQuestion`,req,{headers:await sharedService.getAuthHeader()});
  }
  async getQuestionCount(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getQuestionCount`, {headers: await sharedService.getAuthHeader()});    
  }
  async getInvestorRegistratioRequests(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getInvestorRegistrationRequests`, {headers: await sharedService.getAuthHeader()});    
  }
  async verifyInvestor(investor:any){
    return axios.post<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/verifyInvestor`,investor,{headers: await sharedService.getAuthHeader()});    
  }
  async getInvestorsList(){
    return axios.get<any, APIResponse>(`${this.APIURL}/platformIssuerAdmin/getInvestorsList`, {headers: await sharedService.getAuthHeader()});    
  }
  
  async getAllApprovedOrRejectedOrPendingPropertyDetails(companyId){
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/getAllApprovedOrRejectedOrPendingPropertyDetails`, {params: { companyId },headers: await sharedService.getAuthHeader()});    
  }
 


}