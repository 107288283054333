const validationPatterns = {
    strongPassword: {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/,
        errorMessage: 'The password must contain at least 1 number, 1 lowercase, 1 uppercase, 1 special character, and be at least 8 characters long.',
        validate: function(value) {
            if (this.pattern.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(this.errorMessage);
        }
    },
    phoneNumber: {
        pattern: /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/,
        errorMessage: 'Invalid phone number',
        validate: function(value) {
            if (this.pattern.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(this.errorMessage);
        }
    },
    name: {
        pattern: /^[a-zA-Z\s]+$/,
        errorMessage: 'Name should contain only alphabetic characters.',
        validate: function(value) {
            if (this.pattern.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(this.errorMessage);
        }
    },
    email: {
        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        errorMessage: 'Invalid email address',
        validate: function(value) {
          if (this.pattern.test(value)) {
            return Promise.resolve();
          }
          return Promise.reject(this.errorMessage);
        }
    },
    cityAndState: {
        pattern: /^[a-zA-Z\s&\-()]+$/,
        errorMessage: 'Invalid email address',
        validate: function(value) {
          if (this.pattern.test(value)) {
            return Promise.resolve();
          }
          return Promise.reject(this.errorMessage);
        }
    },
    number: {
        pattern: /^\d+$/,
        errorMessage: 'Input should contain only numbers.',
        validate: function(value) {
            if (!value || this.pattern.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(this.errorMessage);
        }
    }
};

export default validationPatterns;
