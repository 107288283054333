import React from "react";
import { environment } from "../../environments/environment";
import "../../layout/knowledgeCenter/knowledgeCenter.scss";
import backimg from "../../assets/images/layouts/knowledgeCenter/topImage.png";
import KnowledgeCenterBanner from "../../assets/images/about-us/KnowledgeCenterBanner.png"
import unlockinvest from "../../assets/images/layouts/knowledgeCenter/unlockingInvestments.png";
import tokenizedimg from "../../assets/images/layouts/knowledgeCenter/tokenizedSec.png";

const KnowledgeCenter = () => {
  return (
    <>
      <div className="KnowledgeAndCenter">
        

        <div className='KnowledegeAndCenter-Banner'>
        <img src={KnowledgeCenterBanner} style={{ marginBottom: "40px" }} />
                <div className="KnowledgeAndCenter-Banner-content">
                <p className='KnowledegeAndCenter-Banner-title'>
                Your Slice of Real Estate, Digitization:
               </p>
                <span className='KnowledegeAndCenter-Banner-title-text'> Explore Seamless Property Transformation</span>
            </div>
        </div>
          
          <br />
        

        <div>
          <div className="welcome-container">
            <div>
              <h1 className="welcome">
                Welcome to VruddhiX Knowledge
                Center: Your Gateway to Informed Investing
              </h1>
            </div>
            <p className="welcome-para">
              At VruddhiX, we believe that
              empowered investors make informed decisions. Our Knowledge Center
              is designed to be your comprehensive resource hub, providing
              valuable insights into the world of real estate investing,
              blockchain technology, and the unique features that set{" "}
              VruddhiX apart.
            </p>
          </div>
            <div className="unlocking-container">
          <div className="unlocking">
            <div className="textarea">
              <p className="unlock-heading">Unlocking Investments:</p>
              <p className="unlock-statement">
                The Guide to Tokenized Securities and How They're Changing
                Finance Why Tokenize Real Estate
              </p>
              <br />
              <div className="unlock-para">
                <p>
                  In recent years, the concept of Real Estate Digitalization has
                  gained significant traction. Within the financial industry,
                  real estate Digitalization stands out as one of the most
                  rapidly evolving domains, presenting investors with an
                  innovative opportunity to engage in digital token investments
                  backed by tangible real-world assets and securities.
                </p>
                <p style={{ marginTop: "-10px" }}>
                  Traditionally perceived as a complex and research-intensive
                  endeavor, real estate investment has undergone notable
                  simplification through the adoption of blockchain technology
                  for Digitalization. This process involves the conversion of
                  physical assets into security tokens or digital shares, which
                  can represent either a singular asset or a fraction of
                  ownership across multiple assets.
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="tokenized">
            <div className="tokenized-image" style={{paddingTop:"5%"}}>
              <img src={tokenizedimg} />
            </div>
            <div className="tokenized-content">
              <h3 style={{ color: "blue" }}>What are Tokenized Securities?</h3>
              <p className="tokenized-para" style={{fontSize:"17px"}}>
                Tokenized securities are conventional financial instruments,
                such as stocks, bonds, and real estate, digitized and
                represented as tokens on a blockchain. Each token corresponds to
                a share or ownership stake in the underlying asset, and
                ownership is recorded on a decentralized and tamper-proof
                ledger.
              </p>

              <h2 className="tokenized-para" style={{marginTop:"4px", fontSize:"20px", fontWeight:"bolder"}}>
                Key Characteristics of Tokenized Securities:
              </h2>

              <ol>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Liquidity:
                  </h5>
                  <p className="tokenized-para">
                    Tokenized securities enhance liquidity by enabling
                    fractional ownership and facilitating trading on secondary
                    markets. Investors can buy and sell tokens more easily
                    compared to traditional securities.
                  </p>
                </li>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Fractional Ownership:
                  </h5>
                  <p className="tokenized-para">
                    Tokenization enables assets to be divided into smaller,
                    tradable units, allowing for fractional ownership. This
                    democratizes investment, making it accessible to a broader
                    spectrum of investors.
                  </p>
                </li>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Reduced Intermediaries:
                  </h5>
                  <p className="tokenized-para">
                    Tokenization can reduce the number of intermediaries
                    involved in the issuance and trading of securities. This can
                    lead to cost savings and increased efficiency in the overall
                    process.
                  </p>
                </li>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Transparency:
                  </h5>
                  <p className="tokenized-para">
                    Blockchain technology provides an immutable and transparent
                    ledger of transactions. This transparency can enhance trust
                    among investors, as they can independently verify ownership,
                    transactions, and other relevant information.
                  </p>
                </li>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Smart Contracts:
                  </h5>
                  <p className="tokenized-para">
                    Smart contracts automate the execution of terms embedded in
                    tokenized securities, such as dividend payments and voting
                    rights. This automation enhances efficiency and reduces
                    administrative overhead.
                  </p>
                </li>
                <li>
                  <h5 style={{ color: "green", marginTop: "-15px" }}>
                    Blockchain Technology:
                  </h5>
                  <p className="tokenized-para">
                    Tokenized securities leverage blockchain for transparent,
                    secure, and decentralized transactions. The technology
                    ensures trust through immutability and traceability.
                  </p>
                </li>

                
              </ol>
            </div>
          </div>
          <div className="advantages-container">
            <div className="advantages-content" style={{ color: "white" }}>
              <h2 style={{ fontWeight: "lighter" }}>
                Advantages of Tokenized Securities:
              </h2>

              <ol style={{ margin: "15px 25px" }}>
                <li>
                  <h5>Liquidity Boost:</h5>
                  <p style={{ fontSize: "17px" }}>
                    Tokenization enhances liquidity by enabling continuous 24/7
                    trading on secondary markets. Fractional ownership makes it
                    easier for investors to buy and sell smaller portions of
                    assets.
                  </p>
                </li>

                <li>
                  <h5>Global Accessibility:</h5>
                  <p style={{ fontSize: "17px" }}>
                    Geographical barriers are eliminated, allowing investors
                    worldwide to participate. Lower entry thresholds ensure a
                    more inclusive investment environment.
                  </p>
                </li>

                <li>
                  <h5>Operational Efficiency:</h5>
                  <p style={{ fontSize: "17px" }}>
                    Automation through smart contracts reduces paperwork and
                    accelerates processes. Faster settlement times and reduced
                    intermediaries contribute to a more efficient ecosystem.
                  </p>
                </li>

                <li>
                  <h5>Enhanced Transparency:</h5>
                  <p style={{ fontSize: "17px" }}>
                    Blockchain's transparency ensures a verifiable and auditable
                    record of ownership and transactions, fostering trust among
                    market participants.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          {/* ===================== */}
          <div className="container" style={{ marginTop: "100px" }}>
            <ol>
              {/* <li>
                <h5>
                  What is VruddhiX, and how does
                  it differ from traditional securities platforms?
                </h5>
                <p>
                  VruddhiX is the world's first
                  legally compliant tokenized securities issuance platform.
                </p>
                <p>
                  Unlike traditional platforms,{" "}
                  VruddhiX leverages blockchain
                  technology to issue securities in a legally compliant manner,
                  offering a new and innovative approach to investment.
                </p>
              </li> */}

              {/* <li>
                <h5>
                  How can I participate in tokenized securities issuance on
                  VruddhiX?
                </h5>
                <p>
                  To participate, users can sign up for an account on the{" "}
                  VruddhiX
                  platform and follow the simple process for tokenized
                  securities issuance. The platform provides step-by-step
                  guidance for both issuers and investors.
                </p>
              </li> */}

              <li>
                <h5>
                  How is the legality of tokenized securities ensured on{" "}
                  VruddhiX?
                </h5>
                <p>
                  VruddhiX operates within the
                  framework of legal compliance, ensuring that all tokenized
                  securities adhere to regulatory standards. The platform
                  implements robust security measures to provide users with a
                  secure and legally sound investment environment.
                </p>
              </li>

              <li>
                <h5>
                  Can small investors participate in tokenized securities
                  issuance on VruddhiX?
                </h5>
                <p>
                  Yes, VruddhiX promotes
                  inclusivity in investment. Small investors have the opportunity to participate in the digitalization of real estate at affordable price, allowing them to engage in revenue-sharing and profit generation with ease. </p>
              </li>

              <li>
                <h5>
                  How does E-Sign work on{" "}
                  VruddhiX?
                </h5>
                <p>
                  E-Sign on VruddhiX enables
                  users to electronically sign documents, contracts, or
                  agreements related to property transactions. This feature
                  streamlines the process, providing a secure and efficient way
                  to validate user consent.
                </p>
              </li>

              <li>
                <h5>
                  What advantages does VruddhiX{" "}
                  offer over traditional equity capital raises?
                </h5>
                <p>
                  VruddhiX provides a more
                  accessible and inclusive platform for issuers and investors.
                  It leverages blockchain for transparency, real-time tracking,
                  and secure transactions, setting new standards for legally
                  compliant digital securities.
                </p>
              </li>

              <li>
                <h5>
                  What does "Liquidity in the Illiquid Asset Market" mean?
                </h5>
                <p>
                  Liquidity in the Illiquid Asset Market refers to the
                  introduction of mechanisms or platforms that enhance the ease
                  and speed of buying or selling assets traditionally considered
                  illiquid, such as real estate or private equity.
                </p>
              </li>

              <li>
                <h5>
                  How does blockchain technology enhance the{" "}
                  VruddhiX platform?
                </h5>
                <p>
                  Blockchain ensures additional transparency and control for
                  investors. It enables a secure and unalterable recording of
                  transactions, offering a clear history of investment
                  activities. Users can track their investments in real-time,
                  fostering trust and confidence.
                </p>
                <br />
                <br />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgeCenter;
