import { Button, Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { TokenConfigurationService } from "../../TokenConfiguration.service";

const tokenConfigurationService = new TokenConfigurationService();
const IPFS_KEYS_LIST = [
  "diligenceReportFile",
  "certificateOfExtensionOfRegistrationOfProject",
  "initialPartnershipAgreement",
  "modifiedPartnershipAgreement",
  "modifiedPartnershipAgreement",
  "mortgageOrLoanAgreement",
  "poa",
  "propertyDueDilligenceDocuments",
  "purchaseAgreementOrSaleAgreement",
  "registrationCertificateOfProject",
  "titleClearnaceCertificate",
  "reraDocs",
];
const { Link } = Typography;
const TokenDetails = ({
  userInfo,
  saveTokenDetails,
  tokenConfigurationProcess,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState<any>(null);
  const [ipfsURLsList, setIpfsURLsList] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const [form] = Form.useForm();
  const url = window.location.origin;

  const urls = {
    propertyUrl: `${url}/property-details?propertyId=${data ? data._id : ""}`,
    marketplaceUrl: `${url}/marketplace`,
  };

  useEffect(() => {
    fetchData();
    getTokenDetails();
  }, []);

  const fetchData = async () => {
    try {
      const response = await tokenConfigurationService.getPropertyDetails(
        userInfo.company?.id
      );

      setdata(response.data[0]);
      form.setFieldsValue({
        propertyName: response?.data[0]?.propertyName,
        propertyAddress: response?.data[0]?.propertyAddress,
        reraNumber: response?.data[0]?.RERANumber,
      });
      generateipfsURLsList(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  function generateipfsURLsList(data) {
    const ipfsList = {};
    for (let key in data) {
      if (IPFS_KEYS_LIST.includes(key)) {
        if (data[key].length > 0) {
          for (let obj of data[key]) {
            if (ipfsList[key]) {
              if (!obj.URL && obj.CommencementCertificate) {
                ipfsList[key].push(obj.CommencementCertificate.URL);
              } else {
                ipfsList[key].push(obj.URL);
              }
            } else {
              if (!obj.URL && obj.CommencementCertificate) {
                ipfsList[key] = [obj.CommencementCertificate.URL];
              } else {
                ipfsList[key] = [obj.URL];
              }
            }
          }
        } else if (data[key]["URL"]) {
          const obj = data[key];
          ipfsList[key] = [obj["URL"]];
        }
      }
    }
    setIpfsURLsList(ipfsList);
  }

  const _saveTokenDetails = async () => {
    try {
      const values = await form.getFieldsValue();
      setLoading(true);
      const tokenDetailsFields = {
        tokenSymbol: values?.tokenSymbol,
        tokenType: values?.tokenType,
        propertyName: values?.propertyName,
        propertyAddress: values?.propertyAddress,
        reraNumber: values?.reraNumber,
        propertyUrl: urls.propertyUrl,
        marketplaceUrl: urls.marketplaceUrl,
        ipfsUrls: ipfsURLsList,
        propertyId:data._id,
      };
      await saveTokenDetails(tokenDetailsFields);
      setLoading(false);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const getTokenDetails = async () => {
    const response = await tokenConfigurationService.getTokenTypeAndDetails(
      tokenConfigurationProcess?.tokenTypeAndDetailsId,
      userInfo.company?.id
    );
    form.setFieldsValue({
      tokenSymbol: response.data?.tokenSymbol,
      tokenType: response.data?.tokenType,
    });
    console.info({
      response,
    });
  };

  const disabledComponent = (
    userInfo.role === 'issuer super admin' ||
    userInfo.role === 'issuer compliance admin' ||
    (userInfo.role === 'issuer token admin' && tokenConfigurationProcess?.status === 'verified')
  );

  const showButton = !disabledComponent;
     

  return (
    <Row justify="center">
      <Col span={20}>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        )}

        {!loading && (
          <>
            <Card
              title="Token Details"
              style={{ marginTop: "20px", margin: "auto", width: "50%" }}
            >
              <Form
                style={{ textAlign: "center" }}
                form={form}
                onFinish={_saveTokenDetails}
              >
                <Form.Item
                  name="tokenSymbol"
                  label="Token Symbol"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Token Symbol!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your Token Symbol"
                    disabled={disabledComponent}
                  />
                </Form.Item>
                <Form.Item
                  name="tokenType"
                  label="Token Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Token Type!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your Token Type"
                    disabled={disabledComponent}
                  />
                </Form.Item>
                <Form.Item
                  name="propertyName"
                  label="LLP Name"
                  style={{ marginLeft: "12px" }}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="propertyAddress"
                  label="Property Address"
                  style={{ marginLeft: "12px" }}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="reraNumber"
                  label="Rera No"
                  style={{ marginLeft: "12px" }}
                >
                  <Input disabled />
                </Form.Item>
                <div
                  style={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={toggle}
                >
                  IPFS URL
                </div>
                {ipfsURLsList && (
                  <Modal isOpen={modal} toggle={toggle} centered>
                    <ModalBody style={{ textAlign: "center" }}>
                      <h2>IPFS Document Url</h2>
                      <ul
                        style={{
                          textAlign: "start",
                          listStyleType: "disc",
                          marginLeft: "15px",
                        }}
                      >
                        {Object.keys(ipfsURLsList).map((ipfs, index) => (
                          <li key={index}>
                            <Link
                              href={ipfsURLsList[ipfs]}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "black", marginBottom: "10px" }}
                            >
                              {ipfs}
                            </Link>
                          </li>
                        ))}
                      </ul>{" "}
                    </ModalBody>
                  </Modal>
                )}
                <Form.Item
                  name="propertyUrl"
                  style={{
                    fontWeight: "bolder",
                    display: "grid",
                    margin: "10px",
                  }}
                >
                  <Typography>
                    <Link
                      href={urls.propertyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", marginBottom: "5px" }}
                    >
                      Property URL
                    </Link>
                  </Typography>
                </Form.Item>
                <Form.Item
                  name="marketplaceUrl"
                  style={{
                    fontWeight: "bolder",
                    display: "grid",
                    margin: "10px",
                  }}
                >
                  <Typography>
                    <Link
                      href={urls.marketplaceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", marginBottom: "5px" }}
                    >
                      Marketplace URL
                    </Link>
                  </Typography>
                </Form.Item>
                <Form.Item style={{ marginTop: "20px" }}>
                {showButton && (
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={disabledComponent}
                  >
                    SAVE MY TOKEN DETAILS
                  </Button>
                   )}
                </Form.Item>
              </Form>
            </Card>
          </>
        )}
      </Col>
    </Row>
  );
};

export default TokenDetails;
