import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import { Card, Input, Button, Row, Col, Progress, Tooltip, Popconfirm, Spin, Form, Typography, Result, Checkbox, List, Alert, Modal, InputNumber, Descriptions, message, Badge, Select, DatePicker } from 'antd';
import { APIResponse, TokenConfigurationProcess, Rule144Config, User, SymbolDetailsAndSTData } from '../../Shared/interfaces';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import parse from 'html-react-parser';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { environment } from '../../../environments/environment';

const { Option } = Select;
const authService = new AuthService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();
const { Text, Title } = Typography;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 0
    }
  }
};
/*
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 14,
      offset: 10
    }
  }
};
*/
export default function PrimeTrustOffering() {
  const { userInfo, setUserInfo }: any = useUserContext();
  const [offeringPhase, setOfferingPhase] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [offeringForm] = Form.useForm();
  const [signingForm] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [offeringFormDiv, setOfferingFormDiv] = useState(false);
  const [signerDiv, setSignerDiv] = useState(false);
  const [signingDiv, setSigningDiv] = useState(false);
  const [signingHTML, setSigningHTML] = useState('')
  const [formData, setFormData] = useState<any>();
  const [responseData, setResponseData] = useState<any>();
  const [showAgreement, setShowAgreement] = useState<any>();
  const [showDescription, setShowDescription] = useState(false);
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(true);
  const [track, setTrack] = useState('1');
  const [isCreated, setIsCreated] = useState(false);
  const [aggFiled, setAggFiled] = useState(false);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([])
  const [currentStatus, setCurrentStatus] = useState('');
  const [agreementModal, setAgreementModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [subsChecked, setSubschecked] = useState(false);
  const [content, setContent] = useState(' ');
  const [subsUp, setSubsUp] = useState('');
  const [subsDown, setSubsDown] = useState('');
  const [subsUpOriginal, setSubsUpOriginal] = useState('');
  const [subsDownOriginal, setSubsDownOriginal] = useState('');
  const [subsSubmitted, setSubsSubmitted] = useState(false)
  const [subscheckDisable, setSubscheckDisable] = useState(false);
  const [offeringFile, setOfferingFile] = useState(
    {
      offeringDetailFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      subscriptionAgreementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
    });
  const [signingDocument, setSigningDocument] = useState(
    {
      signingDocument: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
    }
  );
  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.subscriptionAgreementCreation({ companyName: userInfo.company?.name });
      console.log('data received', response.data)
      if (response.data && response.success) {
        setSubsUp(response.data.up);
        setSubsDown(response.data.down);
        setSubsUpOriginal(response.data.originalUp);
        setSubsDownOriginal(response.data.originalDown);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const currentStatus = await issuerSuperAdminService.currentOfferingStatus({ companyId: userInfo.company?.id });
        console.log(currentStatus.data);
        if (currentStatus.success || currentStatus.data) {
          // if (currentStatus?.data?.status) {
          if (currentStatus?.data?.offeringDetail) {
            setLoading(false);
            setOfferingFormDiv(true);
            setFormData(currentStatus.data.offeringDetail);

            setShowDescription(true);
            setIsCreated(true);

          }
          setLoading(false);
        }
      } catch (e) {
        console.log(e)
        message.error("Something Went Wrong")
      }

    })();
  }, [])


  const handleSigningForm = async (data) => {
    setSubmitting(true);
    data.companyId = userInfo.company?.id;
    data.name = userInfo.firstName + " " + userInfo.lastName;
    data.user_agent = 'CAR (Retap India)';
    data.content = showAgreement.object;
    data.signId = showAgreement.signing_links.issuer_signature.electronic_signature_id;
    const response = await issuerSuperAdminService.handlePrimeTrustSign(data);
    if (response.success) {
      if (track == '1') {
        setCheckOne(true);
        setCheckTwo(false);
        setTrack('2');
      }
      if (track == '2') {
        setCheckOne(true);
        setCheckTwo(true);
        setSignerDiv(false);
        setTrack('3')
        setAggFiled(true);
        setShowDescription(true);
      }
      setSigningDiv(false);
    }
    else {
      console.error(response.error);
      message.error(response.error.message);
    }
    setSubmitting(false);
  }

  const handleOfferingForm = async (data) => {
    data.amount = data.amount.replace(/,/g, '');
    data.investmentIncrementAmount = data.investmentIncrementAmount.replace(/,/g, '');
    data.maxAmount = data.maxAmount.replace(/,/g, '');
    data.maxInvestmentAmount = data.maxInvestmentAmount.replace(/,/g, '');
    data.minAmount = data.minAmount.replace(/,/g, '');
    data.minInvestmentAmount = data.minInvestmentAmount.replace(/,/g, '');
    setSubmitting(true);
    // remove this warning for now
    if (!subsSubmitted) {
      message.error("Create the subscription aggreement");
      setSubmitting(false);
      return;
    }
    if (Number(data.amount) >= Number(data.maxAmount)) {
      setSubmitting(false);
      message.error("Max amount should be greater than Amount");
      return;
    }
    data.subscriptionAgreementData = (subsUpOriginal + content + subsDownOriginal);
    data.status = false;
    data.signingFile = documentsToSubmit;
    data.companyId = userInfo.company?.id;
    data.companyName = userInfo.company?.name;
    data.offeringName = userInfo?.company?.name + '-' + moment(Date.now()).format('LL') + '-' + moment(userInfo?.creationTS).format('LLL');
    data.offeringFile = {
      name: offeringFile.offeringDetailFile.name,
      URL: offeringFile.offeringDetailFile.URL,
      type: offeringFile.offeringDetailFile.type,
    };
    data.subscriptionAgreementFile = {
      name: offeringFile.subscriptionAgreementFile.name,
      URL: offeringFile.subscriptionAgreementFile.URL,
      type: offeringFile.subscriptionAgreementFile.type,
    };
    data.offeringFiles = documentsToSubmit;
    const response = await issuerSuperAdminService.handlePrimeTrustEscrowAccount(data);
    if (response.success) {
      setResponseData(response.data?.apiResponse);//for signing
      setFormData(data);//for description
      setOfferingFormDiv(true);
      setSignerDiv(true);
      message.success("Submitted Successfully! Please close/change the page")
      window.location.reload();
    }
    else {
      console.error(response.error)
      message.error(response.error.message);
    }
    setSubmitting(false);
  }

  const signEscrowAgreement = async (event) => {
    console.log('1');
    if (event.target.checked == true) {
      // setCheckOne(true);
      setShowAgreement(responseData[0]);
      setSigningDiv(true);
      setCheckTwo(true);
      signingForm.setFieldsValue({ email: userInfo.email, companyName: userInfo.company?.name })
    }
    else {
      setSigningDiv(false);
    }
  };
  const signTechServiceAgreement = async (event) => {
    if (event.target.checked == true) {
      // setCheckTwo(true);
      setShowAgreement(responseData[1]);
      setSigningDiv(true);
      setCheckOne(true);
      signingForm.setFieldsValue({ email: userInfo.email, companyName: userInfo.company?.name })
    }
    else {
      setSigningDiv(false);
    }

  };
  const uploadMultiFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });

    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });

    }, async (response) => {

      if (response.success) {
        const URL = response.data.url;
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        let fileDetails: any = { [key]: { URL, name: file.name } };
        form.setFieldsValue(fileDetails);
        setDocuments(prev => prev.concat(file.name));
        setDocumentsToSubmit(prev => prev.concat(fileDetails));
      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }

    });
  }

  const createSubscription = (event) => {
    console.log("createSubscription chaala")
    if (event.target.checked === true) {
      //modal open
      setSubschecked(true);
      console.log("createSubscription checked")
      setAgreementModal(true);
    }
    else {
      setSubschecked(false);
    }
  }
  const deleteItem = (index) => {
    setDocuments(documents.filter(document => document !== documents[index]));
    setDocumentsToSubmit(documentsToSubmit.filter(document => document !== documentsToSubmit[index]));
  }

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    console.log(file);

    if (!file) return;

    // e.target.value = '';

    const fileType = sharedService.getFileType(file.name);


    setOfferingFile(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      current[key].uploadFilePercentage = 0;
      return current;
    })

    issuerSuperAdminService.uploadFile(file, (uploadFilePercent: number) => {
      setOfferingFile((prev) => {
        console.log(uploadFilePercent);
        return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent } });
      });

    }, (err) => {
      console.error(err);
      setOfferingFile((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));

    }, async (response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        setOfferingFile((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase() } }));
        offeringForm.setFieldsValue({ [key]: file.name });

      } else {
        console.error(response.error);
        setOfferingFile((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
      }

    });

  }
  const fileExcrowServiceApplication = async () => {
    setSubmitting(true);
    const response = await issuerSuperAdminService.fileEscrowServiceApplication({ companyId: userInfo.company?.id })
    if (response.success) {
      message.success('Escrow account created')
      setAggFiled(false);
      setIsCreated(true)
      formData.status = true;
    }
    else {
      console.error(response.error)
      message.error(response.error.message);
    }
    setSubmitting(false);
  }
  const modalReturn = () => {
    setAgreementModal(false);
    setSubschecked(false);
  }
  const modalSubmit = () => {
    setAgreementModal(false);
    setSubschecked(true);
    setSubsSubmitted(true);
    setSubscheckDisable(true);
  }
  const modalPreview = () => {
    setPreviewModal(true);
  }
  const handleChange = (content) => {
    setContent(content)
    console.log(content)
  }

  const resubmitHandler = () => {
    setSubsSubmitted(false);
    setSubschecked(false);
    setSubscheckDisable(false);
    setAgreementModal(true);
  }

  return (
    <Row justify='center' style={{ marginBottom: "50px" }}>
      <Col span={20}>
        <br />
        <Card>
          <Modal title="Subscription Agreement" visible={agreementModal}
            width={900}
            style={{ height: '700px' }}
            footer={[
              <Button key="back" onClick={modalPreview}>
                Preview
              </Button>,
              <Button key="back" onClick={modalReturn}>
                Return
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={modalSubmit}>
                Submit
              </Button>,
            ]} >
            <SunEditor height={400} onChange={handleChange} />
          </Modal>
          {loading &&
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size='large' />
            </div>
          }
          <Modal title="Preview" visible={previewModal} width={850} onCancel={() => { setPreviewModal(false) }} onOk={() => { setPreviewModal(false) }} >
            { /*<div dangerouslySetInnerHTML={{__html: subsUp+content+subsDown}} />*/}
            {parse(subsUp + content + subsDown)}
          </Modal>
          <div hidden={offeringFormDiv}>
            <Card style={{ margin: 'auto', textAlign: 'center' }}>
              <Form
                {...formItemLayout}
                layout='vertical'
                title="Enter Following details to proceed:"
                form={offeringForm}
                onFinish={handleOfferingForm}
              >
                <Card >
                  <p style={{ fontWeight: 'bold', fontSize: '25px', textAlign: "left" }}>Issuer Information</p>
                  <Form.Item
                    label={
                      <span>
                        Amount&nbsp;
                        <Tooltip title="The target amount to raise in USD of the offering" placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="amount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Target Amount',
                        whitespace: true
                      }
                    ]}>

                    <Input addonAfter='Rs' />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Max Amount(Should be greater than Amount)&nbsp;
                        <Tooltip title="If your offering allows for oversubscription, this is the maximum amount in USD" placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="maxAmount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your First Name!',
                        whitespace: true
                      }
                    ]}>

                    <Input addonAfter='Rs' />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Min Amount&nbsp;
                        <Tooltip title="If an offering can be partially closed, this is the minimum amount." placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }

                    name="minAmount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your MinAmount',
                        whitespace: true
                      }
                    ]}>

                    <Input addonAfter='Rs' />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Investment Increment Amount(must be the factor of Max Amount)&nbsp;
                        <Tooltip title="The lowest possible increment for an investment." placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="investmentIncrementAmount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        message: 'Investment Increment Amount!',
                        whitespace: true
                      }
                    ]}>

                    <Input addonAfter='Rs' />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Offering Description&nbsp;
                      </span>
                    }

                    name="description"
                    rules={[
                      {
                        message: 'Description of the offering',
                        whitespace: true
                      }
                    ]}>

                    <TextArea maxLength={150} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Offering Details Document&nbsp;
                        <Tooltip title="File of information about the offering that customer service can review regarding the offering." placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }

                    name='offeringDetailFile'
                    rules={[
                      {
                        required: true,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { uploadFile(e, 'offeringDetailFile') }} />
                      {offeringFile.offeringDetailFile.uploadFilePercentage > 0 &&
                        <Progress percent={offeringFile.offeringDetailFile.uploadFilePercentage} />
                      }
                    </>
                  </Form.Item>


                  <Form.Item
                    label={
                      <span>
                        Singing documents&nbsp;
                        <Tooltip title="Documents which should be signed by investor." placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }

                    name='singingFiles'
                    rules={[
                      {
                        message: 'This field is required'
                      }
                    ]}>

                    <Input type='file' onChange={e => { uploadMultiFile(e, 'signingDocument', setSigningDocument, offeringForm) }} />
                    {signingDocument.signingDocument.uploadFilePercentage > 0 &&
                      <><Progress percent={signingDocument.signingDocument.uploadFilePercentage} /><br />
                        <div style={{ textAlign: "left" }}>
                          Files Uploaded:
                          <List itemLayout='horizontal' style={{ textAlign: 'left' }}>
                            {documents?.map((obj, index) => <List.Item style={{ cursor: 'pointer', textAlign: 'left' }} key={index} actions={[
                              <Popconfirm title='This item will be deleted!' onConfirm={() => deleteItem(index)}>
                                <Button type='primary' danger>X</Button>
                              </Popconfirm>]}>
                              <List.Item.Meta title={obj} /></List.Item>)}
                          </List></div></>}

                  </Form.Item>
                </Card>
                <br />
                <Card>
                  <p style={{ fontWeight: 'bold', fontSize: '25px', textAlign: "left" }}>Investors Information</p>
                  <Form.Item
                    label={
                      <span>
                        Max  Investment Amount&nbsp;
                        <Tooltip title="The maximum investment amount allowed by an investor" placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="maxInvestmentAmount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        message: 'Please input your MaxInvestmentAmount',
                        whitespace: true
                      }
                    ]}>

                    <Input addonAfter='Rs' />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Min Investment Amount&nbsp;
                        <Tooltip title="The minimum investment amount allowed by an investor." placement="rightTop">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="minInvestmentAmount"
                    normalize={(value: string, prevValue: any, allValues) => {
                      const _value = value.replace(/,/g, '');

                      if (new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                      if (!_value) return '';
                      return prevValue || '';
                    }}
                    rules={[
                      {
                        message: 'Please input your MinInvestmentAmount',
                        whitespace: true
                      }
                    ]}>
                    <Input addonAfter='Rs' />
                  </Form.Item>
                </Card>
                <Col style={{ textAlign: 'left' }}>
                  <Checkbox checked={subsChecked} onChange={createSubscription} disabled={subscheckDisable}> Create subscripton agreement</Checkbox>
                  <Button type='dashed' shape='round' size='small' hidden={!subsSubmitted} onClick={resubmitHandler}> Resubmit subcription agreement</Button>
                </Col>
                <br />
                <Form.Item {...tailFormItemLayout} >
                  <Col style={{ textAlign: 'left' }}>
                    <Button type='primary' htmlType='submit' loading={submitting}>Save</Button>
                  </Col>
                </Form.Item>
              </Form>
            </Card>
          </div>

          <div hidden={!isCreated}>
            <div style={{ backgroundColor: '#142A44', padding: '15px' }}>
              <sub style={{ color: 'white', fontWeight: 'bold', fontSize: '45px', fontFamily: 'Arial, Helvetica, sans-serif' }}>&nbsp;&nbsp;Offering information</sub>
            </div>
            <Result
              status="success"
              title="Escrow account Created"
              subTitle="Your account will be confirmed by Primetrust" />
          </div>

          {formData && <div hidden={!(showDescription)}>
            <Card style={{ backgroundColor: '#F3F3F3' }}>
              <Row>
                <Col span={8}>
                  <div>
                    <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Offering Name</sub>
                    <br />
                    <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{formData.offeringName}</sub>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Company Name </sub>
                    <br />
                    <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{formData.companyName}</sub>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Amount To Raise</sub>
                    <br />
                    <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{Number(formData.amount).toLocaleString()}</sub>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <div>
                    <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Max Amount</sub>
                    <br />
                    <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{Number(formData.maxAmount).toLocaleString()}</sub>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Min Amount</sub>
                    <br />
                    <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{Number(formData.minAmount).toLocaleString()}</sub>
                  </div>
                </Col>
                {formData?.maxInvestmentAmount &&
                  <Col span={8}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Max. Investment Amount</sub>
                      <br />
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{Number(formData.maxInvestmentAmount).toLocaleString()}</sub>
                    </div>
                  </Col>
                }
              </Row>
              <br />
              <Row>
                {formData?.minInvestmentAmount &&
                  <Col span={8}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Min. Investment Amount</sub>
                      <br />
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{Number(formData.minInvestmentAmount).toLocaleString()}</sub>
                    </div>
                  </Col>
                }
                {formData?.status &&
                  <Col span={8}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Status</sub>
                      <br />
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>Created &nbsp;&nbsp;&nbsp;<Badge status='success' /></sub>
                    </div>
                  </Col>
                }
              </Row>
              <br />
            </Card>
          </div>
          }
          {/* <div hidden={!(signerDiv)}>
            <Checkbox.Group style={{ width: '100%' }} >
              <Row>
                <Col span={12}>
                  <Checkbox disabled={checkOne} value="1" onChange={signEscrowAgreement}>Sign Escrow Agreement</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox disabled={checkTwo} value="2" onChange={signTechServiceAgreement}>Sign Tech-Services Agreement</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div> */}
          {showAgreement && <div hidden={!(signingDiv)}>
            <Fragment >
              <iframe srcDoc={showAgreement.body_html} style={{ height: 600 }} width={1000} />
            </Fragment>
            <div>
              <Form
                {...formItemLayout}
                layout='vertical'
                title="Enter Following details to proceed:"
                form={signingForm}
                onFinish={handleSigningForm}
              >
                <Form.Item
                  label='Name of Signer'
                  name="signerName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Signer Name!',
                      whitespace: true
                    }
                  ]}>

                  <Input />
                </Form.Item>
                <Form.Item
                  label='Email'
                  name="email"
                  rules={[
                    {
                      required: true,
                    }
                  ]}>

                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label='Role in Company'
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please input role!',
                      whitespace: true
                    }
                  ]}>

                  <Input />
                </Form.Item>
                <Form.Item
                  label='Company Name'
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input company Name!',
                      whitespace: true
                    }
                  ]}>

                  <Input disabled
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout} >
                  <Button type='primary' htmlType='submit' loading={submitting}>Sign</Button>
                </Form.Item>


              </Form>
            </div>

          </div>
          }
          {track == '3' && <div hidden={!aggFiled}>
            <br />
            <br />

            <Button onClick={fileExcrowServiceApplication} style={{ margin: 'auto' }} loading={submitting}>File Escrow Service Application</Button>
          </div>
          }
        </Card>
      </Col>
    </Row>
  );
}
