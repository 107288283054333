import { Button, Card, Col, Row, Select, Spin } from "antd";
import React, { useEffect , useState} from "react";

import { PropertyAdminService } from "../PropertyDetails/PropertyAdmin.service";
import "./Disclaimer.css";
import MarketplacePropertyCard from "../../layout/marketplacePropertyCard/MarketplacePropertyCard";
import MarketPlaceSkelton from "./MarketPlaceSkelton";

const propertyAdminService = new PropertyAdminService();

const MarketPlace = () => {
  const [loading, setLoading] = useState(false);
  // const [propertyId, setPropertyId] = useState('');
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties(){
      setLoading(true);
      const properties = await propertyAdminService.getAllProperties();
      if(properties && properties.data?.length > 0){
        const reversedProperties = [...properties.data].reverse();
        setProperties(reversedProperties);
        // setPropertyId(properties.data[0]._id);
      }
      setLoading(false);
    })();
  }, []);


  return (
    <div className="wd_scroll_wrap margin-bt-0" >
      {loading && (
        <MarketPlaceSkelton></MarketPlaceSkelton>
      )}

      {!loading && (
        <>
          <div class="container" style={{marginTop:'150px',marginBottom:'30px'}}>
            <div class="row row-cols-auto g-4">
              {
                properties.map(mp => (
                  <MarketplacePropertyCard propertyData={mp}/>
                ))
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketPlace;
