import React from "react";

const privacyPolicyStyle = {
  container: {
    padding: "100px 20% 20px 20%"
  },
  
  headline: {
    textAlign: "center",
    color: "black",
    fontWeight: "bold",
  },
  subheadline: {
    fontWeight: "bold",
    color: "black",
    fontSize: "15px",
  },
  paragraph: {
    textAlign: "justify",
  },
};
const PrivacyPolicy = () => {
  return (
    <>
      <div style={privacyPolicyStyle.container}>
        <h2 style={privacyPolicyStyle.headline}>Privacy Policy</h2>

        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Use of Platform:
            <br />
          </span>
          Investing in real estate involves inherent risks, such as potential
          financial loss, regulatory changes, economic conditions, and market
          fluctuations. VruddhiX does not offer investment advice, and all
          investment decisions should be made based on your own due diligence
          and consultation with qualified financial advisors.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Legal Considerations:
            <br />
          </span>
          Furthermore, the tokenization of real estate assets involves complex
          legal and regulatory considerations. VruddhiX facilitates the
          tokenization process through legally compliant procedures, but we do
          not provide legal advice. It is essential to consult with legal
          professionals familiar with local regulations before engaging in
          tokenization activities.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Security Notice:
            <br />
          </span>
          While we make every effort to maintain the security and integrity of
          our platform, we cannot guarantee protection against unauthorized
          access, cyber-attacks, or technical failures. Users are responsible
          for safeguarding their accounts and personal information.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Third-Party Disclaimer:
            <br />
          </span>
          VruddhiX may contain links to third-party websites or resources for
          informational purposes. We do not endorse or control the content of
          these third-party sites and are not responsible for any damages or
          losses resulting from their use.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Liability Disclaimer:
            <br />
          </span>
          By using VruddhiX, you acknowledge and agree to the inherent risks
          associated with real estate investment and tokenization activities.
          VruddhiX disclaims any liability for damages or losses arising from
          your use of the platform or reliance on the information provided
          herein.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          <span style={privacyPolicyStyle.subheadline}>
            Platform Policy:
            <br />
          </span>
          We reserve the right to modify, suspend, or discontinue any aspect of
          VruddhiX at any time without prior notice. Your continued use of the
          platform constitutes acceptance of any such changes.
        </p>
        <p style={privacyPolicyStyle.paragraph}>
          Thank you for choosing VruddhiX as your trusted partner in real estate
          investment. Together, let's embark on a journey of innovation,
          sustainability, and positive impact.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
