import { environment } from "../../../environments/environment";
import axios from 'axios';
import { SharedService } from "../Shared.service";
import { APIResponse } from "../interfaces";

const sharedService = new SharedService();

export class EscrowPaymentService {
    private APIURL = environment.APIURL;

     routes = {
        issuercomplianceadmin: 'issuerSuperAdmin/approveOrRejectEscrowPaymentByLLPCAByCompanyId',
        mastercompanycomplianceadmin: 'issuerSuperAdmin/approveOrRejectEscrowPaymentByMCAByCompanyId',
        platformissueradmin:'issuerSuperAdmin/approveOrRejectEscrowPaymentByPSAByCompanyId'
    }

    async approveOrRejectEscrowPayment(companyId,payload,role) {
        return axios.post<any, APIResponse>(
          `${this.APIURL}/${this.routes[role]}?companyId=${companyId}`,
          payload,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getEscrowPaymentByCompanyId(companyId) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getEscrowPayment?companyId=${companyId}`,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getEscrowPaymentForMCAOrPSA() {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getEscrowPaymentForMCAOrPSA`,
          { headers: await sharedService.getAuthHeader() }
        );
      }
}