import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography,Progress, Form, Input, Button, notification, Spin, Descriptions } from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service'
import { AuthService } from '../../Shared/Auth.service';
import { DeleteOutlined } from '@ant-design/icons';


const {Title} = Typography;


const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

export default function EscrowPaymentDeployment(props) {
  const { userInfo } = useUserContext();
  const [escrowDetails, setEscrowDetails] = useState<any>();
  const [escrowAddress, setEscrowAddress] = useState<any>();
  const [escrowStatus, setEscrowStatus] = useState<any>('start');
  const [paymentGatewayAPIKey, setPaymentGatewayAPIKey] = useState<any>();
  const [paymentGatewayAPIKeyInput, setPaymentGatewayAPIKeyInput] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const escrowInfo = await issuerSuperAdminService.getEscrowPaymentByCompanyId(userInfo?.company?.id)
      console.log('escrowInfo: ',escrowInfo)
      if(escrowInfo?.success && escrowInfo?.data?._id){
        setEscrowDetails(escrowInfo?.data);
        setEscrowAddress(escrowInfo?.data?.deployedSmartContractAddress);
        setPaymentGatewayAPIKey(escrowInfo?.data?.paymentGatewayAPIKey);
        if(escrowInfo?.data?.status) setEscrowStatus(escrowInfo?.data?.status);
      }

      setLoading(false);
    })();
  }, []);



  const saveEscrowPayment = async(formValue) => {
    // blockchain integration pending
    // Here we will call the blockchain and get the deployed smart contract address. We need to only store the deployed smart contract address.


    //replace this with original value after blockchain deployment
    let deployedSmartContractAddress = '0x98A66d4c303d1b265bdf812138e48D3418fC915B';
    
    setLoading(true);
    const response = await issuerSuperAdminService.updateEscrowPaymentByCompanyId({deployedSmartContractAddress}, userInfo?.company?.id);

    if (response.success) {
      notification.success({message: 'Success', description: 'Escrow Smart Contract Address  saved successfully'});
      setTimeout(()=>window.location.reload(), 1000);
    } else {
      notification.error({message: 'Error', description: response.error.message});     
    }

    setLoading(false);
  }

  const savePaymentGatewayAPIKey = async() => {
    if(!(paymentGatewayAPIKeyInput?.length > 2)) {
      notification.error({message: 'Error', description: 'Please provide valid payment gateway apiKey'});     
      return false;
    }

    setLoading(true);
    const response = await issuerSuperAdminService.savePaymentGatewayAPIKey({paymentGatewayAPIKey: paymentGatewayAPIKeyInput}, userInfo?.company?.id);

    if (response.success) {
      notification.success({message: 'Success', description: 'paymentGatewayAPIKey saved successfully'});
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notification.error({message: 'Error', description: response.error.message});
    }

    setLoading(false);
  }

  const sendForApproval = async() => {
    try {
      setLoading(true);
      const res = await issuerSuperAdminService.sendEscrowPaymentForApprovalByCompanyId(userInfo?.company?.id)
      if(!res.success){
        notification.error({message: 'Error', description: res.error.message});
        return;
      }

      notification.success({message: 'Success', description: 'Escrow Payment send for approval'});
      console.log(res);
      setTimeout(()=>window.location.reload(), 1000);

    } catch (error) {
      console.log(error)
      notification.error({message: 'Error', description: error});
    }
    setLoading(false);
  }

  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={3} style={{textAlign:'center'}}>Escrow Payment Smart Contract Details</Title>
              <br />
              {!escrowAddress && escrowStatus === 'start' && (
                <>
                  <Button htmlType='submit' size='large' type='primary' loading={loading} onClick={saveEscrowPayment}>
                    Deploy Escrow Smart Contract
                  </Button>
                </>
              )}
              <br/>
              {escrowAddress && (<>Deployed Escrow Smart Contract Address: {escrowAddress}</>)}
              <br/><br/>
              {escrowAddress && escrowStatus === 'start' && (
                <>
                  <Button htmlType='submit' size='large' type='primary' loading={loading} onClick={sendForApproval}>
                    Send For Approval
                  </Button>
                </>
              )}
              <br/>
              {escrowAddress && escrowStatus === 'pending' && escrowDetails['llpCA'] === 'pending' && (
                <>
                  <h6> Your Escrow Payment is awaiting for approvals from LLP Compliance Admin!</h6>
                </>
              )}
              {escrowAddress && escrowStatus === 'pending' && escrowDetails['llpCA'] !== 'pending' && escrowDetails['mca'] === 'pending' && (
                <>
                  <h6> Your Escrow Payment is awaiting for approvals from Marketplace Compliance Admin!</h6>
                </>
              )}
              {escrowAddress && escrowStatus === 'pending' && escrowDetails['llpCA'] !== 'pending' && escrowDetails['mca'] !== 'pending' && escrowDetails['psa'] !== 'pending' && (
                <>
                  <h6> Your Escrow Payment is awaiting for approvals from Platform Super Admin!</h6>
                </>
              )}

              {escrowAddress && escrowStatus === 'rejected' && (
                <>
                  <h6>Sorry! Your Escrow Payment is rejected by {escrowDetails['llpCA'] === 'rejected' && 'LLP Compliance'}{escrowDetails['mca'] === 'rejected' && 'Marketplace Compliance'}{escrowDetails['psa'] === 'rejected' && 'Platform Super'} Admin!</h6>
                </>
              )}
              {escrowAddress && escrowStatus === 'approved' && !paymentGatewayAPIKey && (
                <>
                  <Form form={form}
                    onFinish={savePaymentGatewayAPIKey}
                    style={{ margin: '2% 10% 0 10%', width: '60%' }}>
                    <Form.Item
                      label='Now Payment Gateway APIKey'
                      name="paymentGatewayAPIKeyInput"
                      rules={[
                        {
                          required: true,
                          message: 'Please input paymentGatewayAPIKey!',
                          whitespace: true
                        }
                      ]}>
                      <Input value={paymentGatewayAPIKeyInput} onChange={(e) => setPaymentGatewayAPIKeyInput(e.target.value)} style={{minWidth: '200px'}} />
                    </Form.Item>
                    <br />

                    <Form.Item>
                      <Button type='primary' htmlType='submit' loading={loading}>Save</Button>
                      <br />
                    </Form.Item>

                  </Form>
                </>
              )}
              {escrowAddress && escrowStatus === 'approved' && paymentGatewayAPIKey?.length > 0 && (
                <>
                  <p>Your NowPayments Gateway API Key: {paymentGatewayAPIKey}</p>
                  <h6>Your Escrow Payment details are approved by all of the Admins. Now you can receive payments from Investors!</h6>
                </>
              )}
            </Card>
          }
        </Col>
      </Row>
    </>
  );
}