import axios, { AxiosRequestConfig } from 'axios';
import { environment } from "../../environments/environment";
import { APIResponse } from "../Shared/interfaces";
import { SharedService } from "../Shared/Shared.service";

const sharedService = new SharedService();

export class InvestorService {
  private APIURL = environment.APIURL;

  async getTokens() {
    return axios.post<any, APIResponse>(`${this.APIURL}/investor/getTokens`, {
      headers: await sharedService.getAuthHeader(),
    });
  }
  async getTokenDetails(targetDbString: string) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTokenDetails?targetDbString=${targetDbString}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getBankDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getBankDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllInvestedTokenDetailsByInvestorId(investorId: any,
    page:number,
    size:number
  ) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllInvestedTokenDetailsByInvestorId?investorId=${investorId}`,
      { params:{page,size},headers: await sharedService.getAuthHeader() }
    );
  }
  async getUserRegisteredTokens(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getUserRegisteredTokens`,
      data,
      {
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async addUserRegisteredToken(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/addUserRegisteredToken`,
      data,
      {
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append("doc", file);
    sharedService.uploadDocument(
      `${this.APIURL}/investor/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }

  async submitKYCDetails(formData: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitKYCDetails`,
      formData,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async submitAIDetails(formData: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitAIDetails`,
      formData,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getKYCDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getKYCDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getAiDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getAiDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPayments(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPayments`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getCompanyDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getCompanyDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setFirstTimeInvestButtonClicked(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setFirstTimeInvestButtonClicked`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async acceptTandC(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setTandCAccepted`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setNDASigned(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setNDASigned`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPartnershipAgreementSigned(prop: { investorId: string, investmentCompanyId: string }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getPartnershipAgreementSigned`,
      { headers: await sharedService.getAuthHeader(), params: prop }
    );
  }

  async setPartnershipAgreementSigned(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setPartnershipAgreementSigned`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  
  async getSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getNDASignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getNDASignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPartnershipAgreementURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPartnershipAgreementURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getInvestSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getInvestSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getTestSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTestSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handleSigningDocument(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/handleSigningDocument`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async northCapitalWireTransferPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/northCapitalWireTransferPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveWireTransferPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/saveWireTransferPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenPhaseDetail(companyId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTokenPhaseDetail`,
      companyId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSubscriptionAgreement(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSubscriptionAgreement`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async signSubscriptionAgreement(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/signSubscriptionAgreement`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerTokenInfo(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerTokenInfo`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPrimeTrustEntity(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPrimeTrustEntity`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerDueDiligence(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerDueDiligence`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async generateLinkForKYC(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/generateLinkForKYC`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async buyTokensWithPaymentDetailsAndCompanyId(companyId: any, data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/buyTokensWithPaymentDetailsAndCompanyId?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async payUsingCoinbase(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingCoinbase`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async payUsingSkrill(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingSkrill`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getCoinbasePaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getCoinbasePaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSkrillPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSkrillPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async updateSkrillPaymentStatus(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/skrillStatus`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async completePayment(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/completePayment`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async payUsingOthers(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingOthers`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getSavedPaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getSavedPaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async saveNewWallet(data: any) {
    //TODO DELETE
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/saveNewWallet`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPendingWalletForWhitelisting(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPendingWalletForWhitelisting`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getInvestorsCount(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getInvestorsCount`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestWalletWhitelist(prop: {
    wallet: string;
    tokenSymbol: string;
    country: string;
    isAccredetedInvestor: boolean;
    assetCreationTS: number;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/requestWalletWhitelist`,
      prop,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getWalletWhitelistRequests(prop: { tokenSymbol: string }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getWalletWhitelistRequests`,
      { headers: await sharedService.getAuthHeader(), params: prop }
    );
  }

  
  async getCompanyAndNonAccreditedInvestor() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getCompanyAndNonAccreditedInvestor`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async updateAccreditedInvestore(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/updateAccreditedInvestore`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }


  // add nda details of invetsor
  async submitNdaDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitNdaDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllAvailableTokensForInvestor() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllAvailableTokensForInvestor`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async updatePublicInvestorWithCompanyDetails(companyId,data) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/updatePublicInvestorWithCompanyDetailsByCompanyId?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllProposalsWithStatus() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllProposalsWithStatus`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async castVoteToAProposalByProposalUniqueId(proposalUniqueId,data) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/castVoteToAProposalByProposalUniqueId?proposalUniqueId=${proposalUniqueId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  //escrow payment api starts here
  async getCompanyTokenDetailsByCompanyId(companyId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getCompanyTokenDetailsByCompanyId?companyId=${companyId}`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async getLastEscrowPaymentDetaisByInvestorIdCompanyId(investorId: string, companyId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getLastEscrowPaymentDetaisByInvestorIdCompanyId?investorId=${investorId}&companyId=${companyId}`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async saveorUpdateEscrowPaymentDetailsByCompanyId(data: any, companyId: string) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/saveorUpdateEscrowPaymentDetailsByCompanyId?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async deleteEscrowPaymentDetaisById(escrowPaymentId: string) {
    return axios.delete<any, APIResponse>(
      `${this.APIURL}/investor/deleteEscrowPaymentDetaisById/${escrowPaymentId}`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  // nowPayment API
  async getMinAmountForUSDC(paymentGatewayAPIKey: string) {
    return axios.get<any, APIResponse>(
      `${environment.nowPaymentAPIURL}/min-amount?currency_from=usdc&currency_to=usd&is_fixed_rate=true&is_fee_paid_by_user=true&fiat_equivalent=inr`, 
      { headers: {'x-api-key': paymentGatewayAPIKey}}
    );
  }

  async getMinAmountForUSDT(paymentGatewayAPIKey: string) {
    return axios.get<any, APIResponse>(
      `${environment.nowPaymentAPIURL}/min-amount?currency_from=usdterc20&currency_to=usd&is_fixed_rate=true&is_fee_paid_by_user=true&fiat_equivalent=inr`, 
      { headers: {'x-api-key': paymentGatewayAPIKey}}
    );
  }

  async getPaymentStatusById(paymentId: string, paymentGatewayAPIKey: string) {
    return axios.get<any, APIResponse>(
      `${environment.nowPaymentAPIURL}/payment/${paymentId}`, 
      { headers: {'x-api-key': paymentGatewayAPIKey}}
    );
  }

  async getEstimateConvertToCoin(amount: number, currencyTo: string, paymentGatewayAPIKey: string) {
    return axios.get<any, APIResponse>(
      `${environment.nowPaymentAPIURL}/estimate?amount=${amount}&currency_from=inr&currency_to=${currencyTo}`, 
      { headers: {'x-api-key': paymentGatewayAPIKey}}
    );
  }

  async createNewPayment(data: any, paymentGatewayAPIKey: string) {
    // using fetch here beaucse by default apiKey is appended in request header for axios for this template
    const requestOptions = {
      method: 'POST',
      headers: {
        'x-api-key': paymentGatewayAPIKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
  
    try {
      const response = await fetch(`${environment.nowPaymentAPIURL}/payment`, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('There was a problem with the fetch operation of createNewPayment:', error);
      throw error; // Rethrow the error for handling in the caller
    }
  }

}
