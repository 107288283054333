import React, { useEffect, useState } from 'react'
import { Table, Card, Col, Row, Typography, Button,  message } from 'antd';
import moment from 'moment';
import { AuthService } from '../../Shared/Auth.service';
import { ButtonType } from 'antd/lib/button';
import { IssuerComplianceAdminService } from '../IssuerComplianceAdmin.service';
const { Title } = Typography

const useUserContext = () => new AuthService().useUserContext();
const issuerService = new IssuerComplianceAdminService();

//proposal status
function computeProposalStatus(proposal) {
    const currentTimestamp = new Date().getTime();
    const startTimestamp = proposal.proposalStartDate;
    const endTimestamp = proposal.proposalEndDate;
    const thresholdPercentage = proposal.threshold;
    const minShareholdersRequired = proposal.minShareHolderRequired;
    const yesVotesCount = proposal.yesVotesCount;
    const totalVotes = proposal.totalVotes;
    const yesVotePercentage = (yesVotesCount / totalVotes) * 100;

    if (currentTimestamp < startTimestamp) {
        return "Upcoming Proposal";
    } else if (currentTimestamp > endTimestamp) {
        if (yesVotePercentage >= thresholdPercentage && totalVotes >= minShareholdersRequired) {
            return "Execute";
        } else {
            return "Proposal Failed";
        }
    } else {
        return "Proposal in Process";
    }
}

export default () => {
    const { userInfo } = useUserContext();
    const [proposalDetailsListData, setProposalDetailsListData] = useState<any[]>([]);
    const [submitting, setSubmitting] = useState(false)

    const proposalDetailsListCols = [
        {
            title: 'Proposal Name',
            dataIndex: 'proposalName',
            key: 'proposalName'
        },
        {
            title: 'Start Date',
            dataIndex: 'proposalStartDate',
            key: 'proposalStartDate',
            render: (value: number) => moment(value).format('lll')
        },
        {
            title: 'End Date',
            dataIndex: 'proposalEndDate',
            key: 'proposalEndDate',
            render: (value: number) => moment(value).format('lll')
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            key: 'threshold'
        },
        {
            title: 'Minimum Investors Required',
            dataIndex: 'minShareHolderRequired',
            key: 'minShareHolderRequired'
        },
        {
            title: 'Total Vote',
            dataIndex: 'totalVotes',
            key: 'totalVotes'
        },
        {
            title: 'Voted "Yes"',
            dataIndex: 'yesVotesCount',
            key: 'yesVotesCount'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, proposal) => {
            let type: ButtonType = 'default';
            let buttonStyle = {};
            let disabled = false;
            if (proposal.status === "Execute") {
                type = 'primary';
                buttonStyle = { backgroundColor: '#52c41a', color: 'white' };
            } else if (proposal.status === "Proposal Failed") {
                type = 'default'; 
                buttonStyle = { backgroundColor: '#ff4d4f', color: 'white' };
                disabled = true;
            } else if (proposal.status === "Proposal in Process") {
                type = 'default';
                disabled = true; 
            }
            const handleExecuteClick = () => {
                // Call your API here when the "Execute" button is clicked
                console.log("Execute button clicked ");
            };
            return <Button type={type}  style={{ ...buttonStyle }} disabled={disabled}  onClick={handleExecuteClick}>{proposal.status}</Button>;
            },
        }
    ];


    useEffect(() => {
        if(!userInfo) return;
            (async () => {
                setSubmitting(true);
                const response = await issuerService.getProposalByCompanyId(userInfo?.company?.id)
                if (!response.success) {
                    message.error(response.error.message);
                    setSubmitting(false);
                    return;
                }

                let proposals = response.data.proposal;
                proposals = Array.isArray(proposals) ? proposals : proposals ? [proposals] : [];
                const proposalData = proposals.map(proposal => {
                    const yesVotesCount = proposal?.votings ? proposal?.votings.filter(vote => vote.vote === true).length : 0;
                    const totalVotes = proposal?.votings?.length  ?? 0;
                    const extendedProposal = {
                      ...proposal,
                      yesVotesCount,
                      totalVotes,
                    };
                    const status = computeProposalStatus(extendedProposal);
                    return { ...extendedProposal, status};
                  });
              
                setProposalDetailsListData(proposalData);
                setSubmitting(false);
            }

            )();
        }, [userInfo]
    )


    return (
        <>
            <br />
            <Row justify="center">
                    <Col span={22} style={{ textAlign: 'center' }}>
                        <Card>
                            <Title
                                level={2}
                                style={{
                                    textAlign: 'left',
                                    color: '#1890ff',
                                    fontWeight: 'bold'
                                }}
                            >
                               Proposal Details List
                            </Title>
                            <Table
                                loading={submitting}
                                dataSource={proposalDetailsListData}
                                columns={proposalDetailsListCols}
                                scroll={{ x: true }}
                            />
                        </Card>
                    </Col>
                </Row>

            
        </>
    )
}