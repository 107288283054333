import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../Shared/Shared.service";
import { APIResponse, User } from "../Shared/interfaces";
import { AdminService } from "../IssuerSuperAdmin/Admin/Admin.service";
import {LLP} from "../Shared/LLP/LLP.service";
import TransactionModal from "../Shared/TransactionModal";
import { MetamaskService } from "../Shared/Metamask.service";
import axios from "axios";
import { environment } from "../../environments/environment";
import { TokenConfigurationService } from '../TokenConfigurations/TokenConfiguration.service';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin/IssuerTokenAdmin.service';
import { TokentransferService } from "../services/Tokentransfer.services";

const { Title } = Typography;
const { TabPane } = Tabs;

const adminService = new AdminService();
const sharedService = new SharedService();
const llp = new LLP();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const tokenConfigurationService = new TokenConfigurationService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const TokenRecovery = () => {
  const APIURL = environment.APIURL;
  const history = useHistory();
  const [admins, setAdmins] = useState<User[]>();
  const [loading, setLoading] = useState(true);
  const [adminRoles, setAdminRoles] = useState<any[]>();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [allPendingRequestsDataSource, setAllPendingRequestsDataSource] = useState([]);
  const [allApprovedOrRejectedRequestsDataSource, setAllApprovedOrRejectedRequestsDataSource] = useState([]);
  const [llpAddress, setllpAddress] = useState("");
  const [tokenAdminWallet, settokenAdminWallet] = useState("");
  const tokentransferService = new TokentransferService();
  const [company, setcompany] = useState("");
  const { userInfo, setUserInfo } = useUserContext();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await sharedService.getUsers();
    if (response.data) {
      console.log("getUsers Response: ", response);
      setcompany(response.data?.company?.id);
      allApprovedOrRejectedRequest(response.data?.company?.id);
      const tokenDetail = await issuerTokenAdminService.getTokenDetailsByCompanyId(response.data?.company?.id);
      console.log("token_details ; ",tokenDetail);
      const llpaddress = await tokenConfigurationService.getLLPAddress(tokenDetail?.data?.companyDetails?.companyId);
      console.log("LLP Address = ",llpaddress?.data?.deployedSmartContractAddress);
      setllpAddress(llpaddress?.data?.deployedSmartContractAddress);

      const admins = await tokenConfigurationService.getAllAdminsWallets(tokenDetail?.data?.companyDetails?.companyId);
      console.log("admins : ", admins);
      const tokensadmin = admins?.data?.llpTokenAdminWalletAddress?.split(',');
      if (tokensadmin && tokensadmin.length > 0) {
        settokenAdminWallet(tokensadmin[0]);
      }
    }
    
  };

  const getTokenCount = async (targetInvestorId) => {
    try {
      console.log("InvestorID : ", targetInvestorId);
      const response = await axios.get<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/getAllPaymentDetailsWithAllInvestorDetailsByCompanyId?companyId=${company}`,
        { headers: await sharedService.getAuthHeader() }
      );
  
      console.log("all token count Response: ", response.data);
      const dataArray = response.data;
  
      const reversedArray = [...dataArray].reverse();
      const lastMatchingInvestor = reversedArray.find((item) => item.investorId === targetInvestorId);
  
      if (lastMatchingInvestor) {
        const { tokenCount } = lastMatchingInvestor;
       
        return tokenCount;
      } else {
        
        return 0;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      throw error; // Propagate the error further if needed
    }
  };

  const allApprovedOrRejectedRequest = async (companyId) => {
    const response = await tokentransferService.allApprovedOrRejectedRequestApi(
      companyId
    );
    if (response.data) {
      console.log("allPendingKycRequest Response: ", response);
      if (response.data?.length > 0) {
        setAllApprovedOrRejectedRequestsDataSource(
          response.data.filter(
            (element) => element.companyId === userInfo?.company?.id
          )
        );
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const recoverTokenOnchain = async (oldwallet, newwallet, investorId) => {
    try{
     
    const tokens = await getTokenCount(investorId);

    console.log("Txn Data : ", oldwallet,
      newwallet,
      (BigInt(tokens) * BigInt(10 ** 18)).toString(),
      llpAddress,
      tokenAdminWallet);

    const receipt0 =
        await tokenConfigurationService.transferTokens(
          oldwallet,
          newwallet,
          (BigInt(tokens) * BigInt(10 ** 18)).toString(),
          llpAddress,
          tokenAdminWallet
        );
      console.log("call result : ", receipt0);       
      if (receipt0.status){
        return true;
      }else{
        console.log("Error in Onchain Token Issuance");
        return false;
      }
      
    }catch(e){
      console.log(e);
      return false;
    }
  }
  
  const tokenTransferBYLLPTokenAdminForInvestorApprovedRequest = async (
    compId,
    investorId,
    tokenRecoveryDetailsId,
    oldwallet,
    newwallet
  ) => {
    setLoading(true);

    const onchainstatus = await recoverTokenOnchain(
      oldwallet,
      newwallet,
      investorId
    );
    if (!onchainstatus) {
      setLoading(false);
      notification.error({ message: "Error in onchain call" });
      return;
    }
    setLoading(false);
    const response =
      await tokentransferService.tokenTransferBYLLPTokenAdminForInvestorApprovedRequestApi(
        compId,
        investorId,
        tokenRecoveryDetailsId
      );
    if (response.data) {
      notification.success({
        message: "Success",
        description: "Token tranferred successfully",
      });
      getUsers();
    } else {
      notification.error({
        message: "Error",
        description: response.error.message,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Old Wallet Address",
      dataIndex: "oldWalletAddress",
      key: "oldWalletAddress",
    },
    {
      title: "newWalletAddress",
      dataIndex: "newWalletAddress",
      key: "newWalletAddress",
    },
    {
      title: "Action",
      key: "action",
      render: (value) => {
        return (
          <>
          <div style={{lineBreak: "auto"}}>
            {value.status == "approved" ? (
              value?.tokenTransferredByTokenAdmin == true ? (
                "Transferred"
              ) : (
                <Button
                  type="link"
                  onClick={() =>
                    tokenTransferBYLLPTokenAdminForInvestorApprovedRequest(
                      value.companyId,
                      value.investorId,
                      value._id,
                      value.oldWalletAddress,
                      value.newWalletAddress
                    )
                  }
                >
                  Transfer
                </Button>
              )
            ) : (
              "Rejected"
            )}
          </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await adminService.getAdmins();

      const _admins: User[] = response.data;

      const _adminRoles = sharedService.adminRolesOptions.map((roleOpt) => {
        const result = sharedService.clone(roleOpt);
        result["total"] = _admins.filter((admin) =>
          admin.roles.includes(roleOpt.value as any)
        ).length;

        return result;
      });

      console.log(_adminRoles);

      setAdmins(_admins);
      setAdminRoles(_adminRoles);
    })();
  }, []);

  const Recoverywhitelist = async () => {
    setIsModalVisible(true);
    setTransactions([{details: 'listing the recovered wallet address', submitting: true}]);

    try {

      const receipt = await llp.modifyWhitelist(
        '0x5891cd3cDEf219f5533Af3db08d1311aF1294E4D',
        selectedWallet as string,
        [selectedWallet as string],
        []
      );


      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
       return current;
     });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          {!loading && (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Transfer Recovery Tokens
              </Title>
              <Row>
                <Col
                  span={3}
                  offset={21}
                  style={{ display: "flex", justifyContent: "end" }}
                ></Col>
              </Row>
              <Table dataSource={allApprovedOrRejectedRequestsDataSource} columns={columns} style={{lineBreak: "anywhere"}} />
            </Card>
          )}
        </Col>
      </Row>
      <TransactionModal
        title = 'whitelisting the recovered wallet address'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
};

export default TokenRecovery;