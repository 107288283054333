import React from "react";
import "../../layout/features/Features.scss"
import FeaturesBanner from "../../assets/images/about-us/FeaturesBanner.png";
import visual from "../../assets/images/about-us//visual.png"
import BuildingImage from '../../assets/images/about-us/BuildingImage.png'
import RealEstateBuilder from "../../assets/images/about-us/RealEstateBuilder.png"
import RealEstateAgent from "../../assets/images/about-us/RealEstateAgent.png";
import TickIcon from "../../assets/images/layouts/features/tick-icon.png";
import fractional from "../../assets/images/layouts/features/investor-li-pngs/Temperature.png"
import property from "../../assets/images/layouts/features/investor-li-pngs/block.png"
import investorpng from "../../assets/images/layouts/features/investor-li-pngs/blocks.png"
import liquidity from "../../assets/images/layouts/features/investor-li-pngs/Lineup.png"
import compliance from "../../assets/images/layouts/features/investor-li-pngs/thumbsup.png"
import portfolio from "../../assets/images/layouts/features/investor-li-pngs/portfolio-mgmt.png"
import invest from "../../assets/images/layouts/features/investor-li-pngs/Sort_random.png"
import customized from "../../assets/images/layouts/features/investor-li-pngs/Waterfall.png"
import global from "../../assets/images/layouts/features/investor-li-pngs/Globe.png"
import unlock from "../../assets/images/layouts/features/builder-li-pngs/Unlock.png"
import market from "../../assets/images/layouts/features/builder-li-pngs/Chart_pin.png"
import transparent from "../../assets/images/layouts/features/builder-li-pngs/transparent.png"
import regulatory from "../../assets/images/layouts/features/builder-li-pngs/regulatory.png"
import democratize from "../../assets/images/layouts/features/builder-li-pngs/democratize.png"
import efficient from "../../assets/images/layouts/features/builder-li-pngs/efficient.png"
import global2 from "../../assets/images/layouts/features/builder-li-pngs/Globe2.png"
import reduced from "../../assets/images/layouts/features/builder-li-pngs/reduced.png"
import buildersimg from "../../assets/images/layouts/features/builderspng.png"
import maximize from "../../assets/images/layouts/features/agent-li-pngs/maximize.png"
import simplified from "../../assets/images/layouts/features/agent-li-pngs/simplified.png"
import agentinvestor from "../../assets/images/layouts/features/agent-li-pngs/investor.png"
import increase from "../../assets/images/layouts/features/agent-li-pngs/increase.png"
import agentcompliance from "../../assets/images/layouts/features/agent-li-pngs/compliance.png"
import agentprtflio from "../../assets/images/layouts/features/agent-li-pngs/portfolio.png"
import agentdashboard from "../../assets/images/layouts/features/agent-li-pngs/agent.png" 
import agentproperty from "../../assets/images/layouts/features/agent-li-pngs/property.png"

const Features = () => {
  return (
    <>
      <div className="Features">

        <div className='Features__Bannerr'>
        <img className="bannerimg" src={FeaturesBanner} alt="About Us"></img>
                <div className="Features__Banner-contentt">
                <p className='Features__Banner-title'>
                Invest Wisely, Invest Digitally:            
                 </p>
                <span className='Features__Banner-title-text'> Real Estate Digitalization at Your Fingertips </span>
            </div>
                </div>

        {/* <div className="Features__banner-text">
          <span className="Features__banner-text__title">
            Features
          </span>
          <span className="Features__banner-text__description">
            At VruddhiX, being at the forefront of innovation in the real estate
            sector,
          </span>
          <span className="Features__banner-text__description">
            we deeply understand the essence of corporate responsibility
          </span>
        </div> */}
        <div className="Features__header">
          <div className="Features__header__small">
            Features for
          </div>
          <div className="Features__header__large">
            Real Estate Investor, Real Estate Builders and Real Estate Agents
          </div>
        </div>
        <div className="Features__description">
          At VruddhiX, being at the forefront of innovation in the real estate
          sector, we deeply understand the essence of corporate responsibility.
          It's not just about meeting business goals for us; it's about
          embracing sustainability, ethical practices, and actively engaging
          with our community.
        </div>
        <div className="Features__description" style={{paddingBottom:"3%"}}>
          We aspire to uphold the highest standards, driven by a genuine desire
          to contribute positively to society and the environment. At VruddhiX,
          our commitment to corporate responsibility isn't a checklist; it's a
          heartfelt pledge, woven into the fabric of our identity, guiding our
          every action as we endeavor to make a meaningful and enduring impact
          beyond the realms of real estate innovation.
        </div>

        <div className="Features__investors">
          <div className="Features__investors__title-image">
            <span className="Features__investors__title-image__title">
              <span className="Features__investors__title-image__title__real-estate">
                REAL ESTATE
              </span>
              <span className="Features__investors__title-image__title__investors">
                INVESTORS
              </span>
            </span>
            <img className="investor-image" src={visual} alt="Investors" />
          </div>
          <div className="Features__investors__content">
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content" >
                <div className="Features__investors__content__card__content__title "style={{marginBottom:'-10px'}}>
                   <img src={fractional} style={{height:"40px", width:"35px"}} /> <br /> <br></br>
                  Fractional Ownership:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Participate in fractional ownership easily, owning a share of prime properties alongside other investors.
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={property} style={{height:"40px", width:"35px"}}/>  <br /><br></br>
                  Property Insights:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Access comprehensive property details, high-quality images, and essential information to make informed investment decisions.
                </span>
              </div>
            </div>
            
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={investorpng} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Investor Dashboard:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Manage your investments efficiently with a user-friendly dashboard, ensuring optimal visibility and control.
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={liquidity} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Liquidity & Flexibility:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Trade your real estate tokens 24/7 on secondary markets, enjoying liquidity and portfolio flexibility.
                </span>
              </div>
            </div>
            
           
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={compliance} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Compliance Confidence:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Rest assured that your investments meet regulatory standards, providing a secure and compliant investment experience.
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={portfolio} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Portfolio Management:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Stay organized and track your real estate investments all in one place with our investor-friendly portfolio dashboard.
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={invest} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Invest with Ease:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Sign up effortlessly and get started quickly, exploring exciting real estate investment opportunities
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={customized} style={{height:"40px", width:"35px"}}/> <br /><br></br>
                  Customized Investment:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Tailor your investment journey to match your preferences and goals.
                </span>
              </div>
            </div>
            <div className="Features__investors__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__investors__content__card__content">
                <div className="Features__investors__content__card__content__title"style={{marginBottom:'-10px'}}>
                  <img src={global} style={{height:"40px", width:"35px",marginBottom:'-3px'}}/>  <br /><br></br>
                  Global Reach:
                </div> <br />
                <span className="Features__investors__content__card__content__description">
                  Explore real estate opportunities worldwide, diversifying your investment portfolio without geographical constraints.
                </span>
              </div>
            </div>
            
          </div>
        </div>

        <div className="Features__builders">
          <div className="Features__builders__content">
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title" style={{marginBottom:'10px'}}>
                  <img src={unlock} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Unlock Timely Capital Infusion for On-Time Project Completion: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Empower your construction projects with timely capital infusion from global investors through our fractionalization platform. 
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title" style={{marginBottom:'10px'}}>
                <img src={democratize} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Democratize Ownership: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Make fractional ownership accessible, allowing multiple investors to participate, including retail investors.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={regulatory} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Regulatory Assurance: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Prioritize compliance with confidence, ensuring your tokenized assets align with all relevant laws and regulations.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={reduced} style={{height:"40px", width:"35px",marginBottom:'-2px'}} /> <br /><br></br>
                  Reduced Barriers: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Lower investment barriers, enabling a broader range of investors to participate, diversifying your funding sources.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={global2} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Global Investor Pool: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Attract a diverse range of global investors, accelerating funding for your real estate projects.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={transparent} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Transparent Transactions: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Leverage blockchain's transparency to build trust among investors, providing easy verification of ownership and transactions.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={market} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Market Liquidity: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Increase appeal to investors with the potential for 24/7 trading on secondary markets, offering flexibility and liquidity.
                </span>
              </div>
            </div>
            <div className="Features__builders__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__builders__content__card__content">
                <div className="Features__builders__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={efficient} style={{height:"40px", width:"35px"}} /> <br /><br></br>
                  Efficient Operations: <br />
                </div>
                <span className="Features__builders__content__card__content__description">
                  Automate key processes like rent distribution and property management fees with smart contracts, reducing costs.
                </span>
              </div>
            </div>
          </div>
          <div className="Features__builders__title-image">
            <span className="Features__builders__title-image__title">
              <span className="Features__builders__title-image__title__real-estate">
                REAL ESTATE
              </span>
              <span className="Features__builders__title-image__title__investors">
                BUILDERS
              </span>
            </span>
            <img className="builder-image" src={RealEstateBuilder} alt="Builders" />
          </div>
        </div>

        <div className="Features__agents">
          <div className="Features__agents__title-image">
            <span className="Features__agents__title-image__title">
              <span className="Features__agents__title-image__title__real-estate">
                REAL ESTATE
              </span>
              <span className="Features__agents__title-image__title__investors">
                AGENTS
              </span>
            </span>
            <img src={RealEstateAgent} alt="Agents" className="agents-image" />
          </div>
          <div className="Features__agents__content">
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title" style={{marginBottom:'10px'}}>
                  <img src={maximize} style={{height:"40px", width:"35px"}}/> <br/> <br></br>
                  Maximize Your Earning Potential:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Leverage our platform to connect with global investors, unlocking exciting opportunities for substantial commissions and earnings beyond traditional methods.
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={simplified} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Simplified Registration:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Streamline your onboarding process, making it quick and user-friendly for real estate agents.
                </span>
              </div>
            </div>
            
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={agentinvestor} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Investor Engagement:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Provide investors with an organized and user-friendly portfolio dashboard, simplifying their real estate journey
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={increase} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Increase Your Global Investor Network:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  The more properties you list, the wider your reach becomes. Attract a diverse pool of global investors by showcasing a growing portfolio of listings, enhancing your earning potential
                </span>
              </div>
            </div>
            
            
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={agentcompliance} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Compliance Confidence:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Rest assured that your investments meet regulatory standards, providing a secure and compliant investment experience.
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={agentprtflio} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Portfolio Management:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Stay organized and track your real estate investments all in one place with our investor-friendly portfolio dashboard.
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={agentproperty} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Property Showcase:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Effortlessly present your listings with rich details, captivating images, and vital specs, attracting potential investors effectively.
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card" style={{marginTop:"60px"}} >
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                  Compliance Made Easy:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Benefit from robust KYC and compliance features, ensuring a hassle-free adherence to regulatory standards.
                </span>
              </div>
            </div>
            <div className="Features__agents__content__card">
              {/* <img src={TickIcon} style={{ height: "15px" }} /> */}
              <div className="Features__agents__content__card__content">
                <div className="Features__agents__content__card__content__title"style={{marginBottom:'10px'}}>
                <img src={agentdashboard} style={{height:"40px", width:"35px"}}/> <br/><br></br>
                  Agent Dashboard:<br />
                </div>
                <span className="Features__agents__content__card__content__description">
                  Manage and update your property listings effortlessly with an intuitive dashboard, enhancing visibility and engagement.
                </span>
              </div>
            </div>
            
          </div>
        </div>
        <img src={BuildingImage} alt='About Us'></img>     
      </div>
    </>
  );
};

export default Features;
