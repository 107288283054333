import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Card, Row, Col, Modal, Alert, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';
import { number } from 'prop-types';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 11 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const accountService = new AccountService();
const sharedService = new SharedService();

export const defaultPageLoadRoute = (userInfo, history) => {
  if(userInfo.role === 'platform issuer admin') {
    history.push('/platform-issuer-admin/issuer-reg-reqs');      
  } else if(userInfo.role==='platform super admin') {
    history.push('/platform-super-admin/manage-api-key');
  } else if(userInfo.role==='platform compliance admin') {
    history.push('/platform-compliance-admin/kyc-verification-request')
  } else if(userInfo.role==='investor') {
    history.push('/investor/dashboard');
  } 
  // else if(userInfo.role === 'issuer super admin') {
  //   history.push('/issuer-super-admin/basic-info-verif');
  // } else if(userInfo.role === 'employee' || userInfo.role === 'contractor_consultant_advisor') {
  //   history.push(`/team-member/vesting-schedule`);
  // }else if(userInfo.role==='issuer token admin') {
  //   history.push('/token-configurations');
  // } else if(userInfo.role === 'issuer counsel') {
  //   history.push('/token-configurations');
  // }
  // else if(userInfo.role === 'issuer company officer') {
  //   history.push('/token-configurations');
  // }
  // else if(userInfo.role==='issuer compliance admin')
  // {
  //   history.push('/issuer-compliance-admin/kyc-verification-request')
  // }
  else {
    history.push('/connect-wallet');
  }
}

const useUserContext = () => new AuthService().useUserContext();
const EmailVerification = () => {
    const history = useHistory();
    const { userId } = useParams()

    const [reqErr, setReqErr] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [otp, setOtp] = useState();
    const [otpError, setOtpError] = useState(false);
    const [trustDevice, setTrustDevice] = useState(true);
    const [form] = Form.useForm();

    const { userInfo, setUserInfo } = useUserContext();

    useEffect(() => {
        if(!userInfo) return;
        defaultPageLoadRoute(userInfo, history);
        // const subscription = props.source.subscribe();
        // return () => {
        //   subscription.unsubscribe();
        // };
      },
      [userInfo, history],
    );

    const handleSubmit = async (req) => {
        setSubmitting(true);
        setOtpError(false);
        console.log({trust: trustDevice, otp, userId})
        const response = await accountService.verifyEmailOTP({trust: trustDevice, otp, userId});
        if (response.success) {
            message.success("Successfully Verified device", 7)
            sharedService.setToken(response.data.token);
            setUserInfo({...response.data.user});
        } else {
            message.error(response.error.message, 5);
            setOtpError(true);
        }
        setSubmitting(false);
    }

    return (
        <Row justify="center" style={{ marginTop: "60px" }}>
            <Col span={23} style={{ textAlign: 'center' }}>
                <Card style={{
                    // margin: '5% 0 0 15%',
                    // width: '70%'
                }}>
                    <Form form = {form}
                        // size='middle'
                        onFinish={handleSubmit}
                        className='login-form'
                    // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
                    >
                        <h2 style={{ textAlign: 'center' }}>Trust Device using OTP</h2>
                        <br/>
                        <Form.Item
                            label="Trust Device using OTP"
                            name="otp"
                            rules={[{ required: true, message: 'Please input your OTP!' }]}
                            style={{ width: '300px', margin: 'auto', marginBottom: '20px' }}
                            required={true}
                        >
                            <Input type={number} minLength={6} maxLength={6} value={otp} onChange={(e) => setOtp(e.target.value)} />
                        </Form.Item>
                        <label>
                            <input type="checkbox" checked={trustDevice} onChange={(e) => setTrustDevice(e.target.checked)} />
                            <span style={{marginLeft: '5px'}}>Trust this device for 30 days.</span>
                        </label>

                        {otpError && (<h6 style={{marginTop: '10px', color: 'orangered'}}>Wrong otp entered! Please entered the right OTP sent to your email</h6>)}

                        <Form.Item>
                            <Button
                                style={{ marginTop: '20px' }}
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                loading={submitting}
                            >
                                Veify OTP
                            </Button>
                            <br /><br/>
                            <a className='login-form-forgot' href="/account/login">
                                Login
                            </a>
                            <br />
                            <br />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default EmailVerification;