import React, { useState, useEffect } from "react";
import { Menu, Layout,Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../../Shared/Shared.service";

import "./SideNav.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

const sharedService = new SharedService();

const menuHeader = sharedService.menuHeader;

const appPages = [

  // ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  {
    title: "Connect Your Wallet",
    url: "/connect-wallet",
    icon: <UserOutlined />,
    roles: [
      "master company officer",
      "master company compliance admin",
      "master company series manager",
      "master company super admin",
      "master company counsel",
      "master company channel partner",
      "master company financial advisor",
      "employee",
      "contractor_consultant_advisor",
      "issuer token admin",
      "issuer super admin",
      "platform super admin",
      "platform issuer admin",
      "issuer counsel",
      "investor",
      "issuer company officer",
      "platform compliance admin",
      "issuer compliance admin",
      "issuer company manager"
    ],
  },

 
  {
    title: "Add Property",
    url: "/issuer-super-admin/add-property",
    icon: <UserOutlined />,
    roles: ["issuer company manager"],
  },

  {
    title: "LLP Approval Registration",
    key: "LLP approval-registration",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    children: [
      {
        title: "LLP Registration Requests",
        url: "/platform-issuer-admin/issuer-reg-reqs",
        roles: ["master company super admin"],
      },
      {
        title: "LLP Basic Info Verification Registration",
        url: "/platform-issuer-admin/issuer-basic-info-verif-reqs",
        roles: ["master company super admin"],
      }
    ],
  },
  {
    title: "Basic Information",
    url: "/issuer-super-admin/basic-info-verif",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },

  

  {
    title: "Basic Information",
    url: "/master-company-super-admin/basic-info-verif",
    icon: <UserOutlined />,
    roles: ["master company super admin", "master company officer"],
  },
  {
    title: "LLP Details",
    url: "/master-company-super-admin/series-details",
    icon: <UserOutlined />,
    roles: ["master company super admin", "master company officer","master company financial advisor"],
  },
  {
    title: "Manage Payment Methods",
    url: "/issuer-super-admin/manage-payment-methods",
    icon: <UserOutlined />,
    roles: ["issuer company manager"],
  },
  {
    title: "Admin",
    key: "admin",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
    children: [
      {
        title: "Admin Roles",
        url: "/issuer-super-admin/admin/admin-roles",
        roles: ["issuer super admin"],
      },
      {
        title: "Role Managment",
        url: "/issuer-super-admin/admin/role-managment",
        roles: ["issuer super admin"],
      },
      // {
      //   title: "Team Managment",
      //   url: "/issuer-super-admin/admin/team-managment",
      //   roles: ["issuer super admin"],
      // },
    ],
  },

  {
    title: "LLP Property Approval",
    key: "property-approval-requests",
    url: '/platform-issuer-admin/issuer-property-verif-reqs',
    icon: <UserOutlined />,
    roles: ["issuer super admin", "issuer compliance admin"],
  },


  {
    title: "Bank Info Request",
    url: "/bank-info-request",
    icon: <UserOutlined />,
    roles: [
      "issuer compliance admin",
      "master company compliance admin",
      "platform issuer admin",
    ],
  },

 {
    title: "Escrow Payment Request",
    url: "/escrow-payment-request",
    icon: <UserOutlined />,
    roles: [
      "issuer compliance admin",
      "master company compliance admin",
      "platform issuer admin",
    ],
  },

  {
    title: "Admin",
    key: "admin1",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    children: [
      {
        title: "Admin Roles",
        url: "/master-company-super-admin/admin/admin-roles",
        roles: ["master company super admin"],
      },
      {
        title: "Role Managment",
        url: "/master-company-super-admin/admin/role-managment",
        roles: ["master company super admin"],
      },
      {
        title: "Team Managment",
        url: "/master-company-super-admin/admin/team-managment",
        roles: ["master company super admin"],
      },
    ],
  },
  {
    title: "Token Configuration",
    url: "/token-configurations",
    icon: <UserOutlined />,
    roles: ["issuer token admin", "issuer super admin", "issuer compliance admin"],
  },


  {
    title: "Invite Investors",
    url: "/issuer-super-admin/invite-investors",
    icon: <UserOutlined />,
    roles: ["issuer super admin","issuer company manager"],
  },

  {
    title: "Due Diligence",
    key: "Due Diligence",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin", "issuer super admin", "issuer counsel"],
    children: [
      {
        title: "Add",
        url: "/issuer-super-admin/add-due-diligence-for-issuer",
        roles: ["issuer super admin"],
      },
      {
        title: "Answer Due Diligence",
        url: "/issuer-super-admin/answer-due-diligence",
        //icon: <UserOutlined />,
        roles: ["issuer compliance admin"],
      },
      {
        title: "Review answers",
        url: "/issuer-counsel/review-due-diligence",
        roles: ["issuer counsel"],
      },
      {
        title: "Review answers",
        url: "/issuer-super-admin/review-due-diligence",
        roles: ["issuer super admin"],
      },
    ],
  },


  {
    title: "Master Company Details",
    url: "/master-company-details",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    // roles: ['employee', 'contractor_consultant_advisor', 'investor'],
  },

  //   2*********************************************************************

  {
    title: "LLP",
    url: "/series",
    icon: <UserOutlined />,
    roles: ["master company series manager","master company channel partner"],
  },
  {
    title: "Enquiries",
    url: "/master-company-super-admin/enquiries",
    icon: <UserOutlined />,
    roles: ["master company series manager"],
  },

  //   3*********************************************************************


  {
    title: "Team Member KYC Requests",
    url: "/issuer-compliance-admin/team-member-kyc-requests",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"]
  },
  {
    title: "Team Member KYC Requests",
    url: "/master-company-super-admin/team-member-kyc-requests",
    icon: <UserOutlined />,
    roles: ["master company compliance admin"],
  },
  
  

 


 
  // {
  //   title: "Deed Approval",
  //   url: "/deed-approval",
  //   icon: <UserOutlined />,
  //   roles: ["master company compliance admin"],
  // },

 


  
  {
    title: "Information questions",
    url: "/issuer-super-admin/information-questions",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },

 
  {
    title: "Due diligence for marketplace",
    key: "Due Diligence",
    icon: <UserOutlined />,
    roles: ["platform compliance admin", "platform issuer admin"],
    children: [
      {
        title: "Add",
        url: "/platform-compliance-admin/add-due-diligence-for-issuer",
        roles: ["platform compliance admin", "platform issuer admin"],
      },
      {
        title: "Review answers",
        url: "/platform-compliance-admin/review-due-diligence",
        roles: ["platform compliance admin", "platform issuer admin"],
      },
    ],
  },
  {
    title: "Due Diligence",
    url: "/due-diligence",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
 
  // {
  //   title: "Investor Approval Requests",
  //   url: "/platform-issuer-admin/investor-registration-requests",
  //   icon: <UserOutlined />,
  //   roles: ["issuer super admin"],
  // },
  // {
  //   title: "Proposal Details",
  //   url: "/issuer-compliance-admin/proposal-details",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "issuer super admin",
  //     "issuer compliance admin"
  //   ],
  // },
  // {
  //   title: "Proposal Details List",
  //   url: "/issuer-compliance-admin/proposal-details-list",
  //   icon: <UserOutlined />,
  //   roles: [
  //     // "issuer super admin",
  //     "issuer compliance admin"
  //   ],
  // },

  // 5**************************TA*********************************************

// {
//   title: "Transfer Restrictions",
//   key: "trasnferRestrictions",
//   icon: <UserOutlined />,
//   roles: ["issuer token admin"],
//   children: [
//     {
//       title: "Select Countries",
//       url: "/issuer-token-admin/transfer-restriction/select-countries",
//       roles: ["issuer token admin"],
//     },
//   ],
// },


{
  title: "Issue Tokens",
  url: "/issuer-token-admin/issue-tokens",
  icon: <UserOutlined />,
  roles: ["issuer token admin"],
},
// {
//   title: "Transfer Recovery Token",
//   url: "/transfer-recovery-token",
//   icon: <UserOutlined />,
//   roles: ["issuer token admin"],
// },
// {
//   title: "Investor Approval Rejection",
//   url: "/issuer-super-admin/investor-registration-requests",
//   icon: <UserOutlined />,
//   roles: ["issuer company manager"],
// },


//   *************************llpcounsel*************************************

{
  title: "Review Token Configuration",
  url: "/token-configurations",
  icon: <UserOutlined />,
  roles: ["issuer company officer", "issuer counsel"],
},
{
  title: "Safe Harbor",
  url: "/issuer-counsel/safe-harbor",
  icon: <UserOutlined />,
  roles: ["issuer counsel"],
},
{
  title: "Disclosures",
  url: "/issuer-counsel/disclosures",
  icon: <UserOutlined />,
  roles: ["issuer counsel"],
},
{
  title: "Transaction Documents",
  url: "/issuer-counsel/transaction-documents",
  icon: <UserOutlined />,
  roles: ["issuer counsel"],
},

// ****************************llpcompliance*******************************************

{
  title: "Team Member KYC Requests",
  url: "/platform-compliance-admin/team-member-kyc-requests",
  icon: <UserOutlined />,
  roles: ["platform compliance admin"],
},

{
  title: "Investor KYC Information",
  url: "/issuer-compliance-admin/kyc-verification-request",
  icon: <UserOutlined />,
  roles: ["issuer compliance admin"],
},
{
  title: "Investor KYC Requests",
  url: "/platform-compliance-admin/kyc-verification-request",
  icon: <UserOutlined />,
  roles: ["platform compliance admin","master company compliance admin"],
},
{
  title: "Investor Payments Approval",
  url: "/investor-payment-approval",
  icon: <UserOutlined />,
  roles: ["issuer compliance admin"],
},
// {
//   title: "Whitelist Recovery",
//   url: "/whitelist-recovery",
//   icon: <UserOutlined />,
//   roles: ["issuer compliance admin"],
// },

//   ************************investor**************************************
{
  title: "Dashboard",
  url: "/investor/dashboard",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "Registration Request",
  url: "/investor/registrationRequest",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "NDA",
  url: "/investor/nda",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "KYC Status",
  url: "/investor/kyc-status",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "Payments",
  url: "/investor/payments",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "Token recovery",
  url: "/token-recovery",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "Resolutions",
  url: "/resolutions",
  icon: <UserOutlined />,
  roles: ["investor"],
},
{
  title: "Invest",
  url: "/investor/home",
  icon: <UserOutlined />,
  roles: ["investor"],
},
  // |||||||||||||||||||||||||||||END|||||||||||||||||||||||||||||||||||||||||||



  {
    title: "KYC Status",
    url: "/team-member/KYCUpload",
    icon: <UserOutlined />,
    roles: ["employee", "contractor_consultant_advisor","master company channel partner","master company financial advisor"],
  },
  {
    title: "Token Dashboard",
    url: "/token-dashboard",
    icon: <UserOutlined />,
    roles: [
      "contractor_consultant_advisor",
      "companyMember",
    ],
  },
  {
    title: "View All Issuer Super Admins",
    url: "/platform-issuer-admin/issuer-super-admins",
    icon: <UserOutlined />,
    roles: ["platform issuer admin"],
  },


  {
    title: "Website management",
    url: "/issuer-super-admin/manage-website",
    icon: <UserOutlined />,
    roles: [],
  },
  {
    title: "Service Provider management",
    url: "/issuer-super-admin/manage-service-providers",
    icon: <UserOutlined />,
    roles: [],
  },
  
  {
    title: "View All Issuers",
    url: "/platform-super-admin/view-all-issuers",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },
 
  {
    title: "Add Service Providers",
    url: "/platform-super-admin/add-service-providers",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },
  {
    title: "Manage API key",
    url: "/platform-super-admin/manage-api-key",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },

 
  // {
  //   title: "Review Affiliate Form 144",
  //   url: "/issuer-counsel/review-affiliate-form-144",
  //   icon: <UserOutlined />,
  //   roles: ["issuer counsel"],
  // },


  // {
  //   title: "Master Company Fields",
  //   url: "/platform-issuer-admin/master-company-fields",
  //   icon: <UserOutlined />,
  //   roles: ["platform issuer admin"],
  // },

  {
    title: "Upload Documents",
    url: "/master-company-counsel/upload-documents",
    icon: <UserOutlined />,
    roles: ["master company counsel"],
  },
  
  // {
  //   title: "LLP Details",
  //   url: "/llp-details",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "issuer company manager",
  //   ],
  // },
  // {
  //   title: "LLP Deed Documents",
  //   url: "/deed-docs",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "issuer super admin",
  //   ],
  // },
  // {
  //   title: "All Registered REA & CP",
  //   url: "/master-company-super-admin/all-registered-rea",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "master company channel partner",
  //   ],
  // },
  {
    title: "Investors",
    url: "/real-estate-channel-partner/investors-list",
    icon: <UserOutlined />,
    roles: [
      "master company channel partner", "master company financial advisor",
    ],
  },
  {
    title: "Management Fees",
    url: "/issuer-super-admin/fee-management",
    icon: <UserOutlined />,
    roles: [
       "issuer company manager", "issuer compliance admin"
    ],
  },
  

];

const authService = new AuthService();

const useUserContext = () => authService.useUserContext();

export default () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { userInfo } = useUserContext();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [visiblePages, setVisiblePages] = useState<any>();

  useEffect(() => {
    if (!selectedKeys.includes(pathname)) setSelectedKeys([pathname]);
  }, [pathname, selectedKeys]);

  // useEffect(() => {
  //   if (!userInfo) return;
  //   if (userInfo.role === "platform issuer admin") {
  //     history.push("/platform-issuer-admin/issuer-reg-reqs");
  //   } else if (userInfo.role === "issuer super admin") {
  //     history.push("/issuer-super-admin/basic-info-verif");
  //   } else if (
  //     userInfo.role === "employee" ||
  //     userInfo.role === "contractor_consultant_advisor"
  //   ) {
  //     history.push(`/team-member/vesting-schedule`);
  //   } else if (userInfo.role === "issuer token admin") {
  //     history.push("/token-configurations");
  //   } else if (userInfo.role === "platform super admin") {
  //     history.push("/platform-super-admin/manage-api-key");
  //   } else if (userInfo.role === "issuer counsel") {
  //     history.push("/token-configurations");
  //   } else if (userInfo.role === "issuer company officer") {
  //     history.push("/token-configurations");
  //   } else if (userInfo.role === "investor") {
  //     history.push("/investor/dashboard");
  //   } else if (userInfo.role === "platform compliance admin") {
  //     history.push("/platform-compliance-admin/kyc-verification-request");
  //   } else if (userInfo.role === "issuer compliance admin") {
  //     history.push("/issuer-compliance-admin/kyc-verification-request");
  //   } else if (userInfo.role === "master company series manager") {
  //     history.push("/series");
  //   } else if (userInfo.role === "master company compliance admin") {
  //     history.push("/master-company-super-admin/team-member-kyc-requests");
  //   }
  // }, [userInfo, history]);
  

  useEffect(() => {
    if (userInfo) {
      const v = [...JSON.parse(JSON.stringify(appPages))]
        .filter((page) => page.roles.includes(userInfo?.role as string))
        .map((page) => {
          page.children = page.children?.filter((child) =>
            child.roles.includes(userInfo?.role as string)
          );
          return page;
        });
      
      //if user has no KYC status or REJECTED restrict to specified routes
      if( userInfo.role === "investor" && (!userInfo.status.hasOwnProperty('kyc') || userInfo.status?.kyc =='rejected' || userInfo.status?.kyc =='pending' )) {
        
        let routesToFilter=['KYC Status','Invest','NDA']
        if(userInfo.hasOwnProperty('investorType') && userInfo?.investorType?.toLowerCase()==='public'){
          routesToFilter=['Dashboard','KYC Status','NDA',"Connect Your Wallet"]
        }
        filterInvestorRoutes(v,routesToFilter)
        return;
      }

      setVisiblePages(v)

      if( userInfo.role === "investor" && userInfo.status.hasOwnProperty('kyc') && (userInfo.status?.kyc =='approved' || userInfo.status?.kyc =='rejected')) {
        console.log('v:', v);
        let visiblePages = v.filter((page) => page?.title !== 'Invest')
        if(userInfo.hasOwnProperty('investorType') && userInfo?.investorType?.toLowerCase()==='public'){
          visiblePages = visiblePages.filter((page) => page?.title !== 'Registration Request')
        }
        setVisiblePages(visiblePages);
      }
      if( userInfo.role === "master company channel partner" && (!userInfo.status.hasOwnProperty('kyc') || userInfo.status?.kyc =='rejected' || userInfo.status?.kyc =='pending' )) {
        let routesToFilter=['KYC Status','Invest','NDA']
        filterInvestorRoutes(v,routesToFilter)
        return;
      }

      if (
        userInfo.role === "investor" &&
        userInfo?.country?.toLowerCase() === "united states of america"
      ) {
    
      }
    }
  }, [userInfo]);

  const filterInvestorRoutes = (v,routes) => {
    let filteredRoutes = v.filter(r => routes.includes(r.title) )
    console.log(filteredRoutes)
    setVisiblePages(filteredRoutes)
    console.log('path ',pathname)

    filteredRoutes.forEach(route => {
      if (route.url !== pathname) {
        console.log('no access to route')
          // history.push('kyc-status');
      }
    })
    
  }

  const goTo = ({ key }) => history.push(key);

  return (
    <Sider
      style={{
        overflowY: "auto",
        height: "calc(100vh - 60px)",
        position: "fixed",
        left: 0,
      }}
    >
      {userInfo && (
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectedKeys}
          
        >
          <li
            style={{
              lineHeight: "normal",
              textAlign: "center",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {userInfo.role === "contractor_consultant_advisor" ||
            userInfo.role === "employee"
              ? `PROFILE (${
                  userInfo.role === "contractor_consultant_advisor"
                    ? "CONTRACTOR"
                    : "EMPLOYEE"
                })`
              : menuHeader[userInfo.role]}
          </li>
          {(visiblePages as any[])?.map((page) =>
            !page.children ? (
              <Menu.Item key={page.url} onClick={goTo}>
                {/* {page.icon}  */}
                <Tooltip placement="right" title={page.title}>
                  <span>{page.title}</span>
                </Tooltip>
              </Menu.Item>
            ) : (
              <SubMenu
                key={page.key}
                title={
                  <Tooltip placement="right" title={page.title}>
                    <span>{page.title}</span>
                  </Tooltip>
                }
              >
                {(page.children as any[]).map((child) => (
                  <Menu.Item key={child.url} onClick={goTo}>
                    <Tooltip placement="right" title={child.title}>
                      {child.title}
                    </Tooltip>
                  </Menu.Item>
                ))}
              </SubMenu>
            )
          )}
        </Menu>
      )}
    </Sider>
  );
};
