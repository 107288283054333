import React, { useEffect, useState, useRef } from "react";
import {Table,Card,Col,Row,Typography,Descriptions,Button,Tabs,Radio,message,Input, notification, Modal,
} from "antd";
import { IssuerComplianceAdminService } from "../IssuerComplianceAdmin.service";
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { AuthService } from "../../Shared/Auth.service";
import { SharedService } from "../../Shared/Shared.service";


const { Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const issuerComplianceAdminService = new IssuerComplianceAdminService();

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();


const DeedApproval = () => {
    const [loading, setLoading] = useState(false);
    const [rejectloading, setRejectLoading] = useState(false);
    const [pendingDeedData, setPendingDeedData] = useState<any>([]);
    const [approveRejectDeedData, setApproveRejectDeedData] = useState([]);
    const [rejectModel, setRejectModal] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [repaint, setRepaint] = useState(false);
    const rejReff = useRef<any>(null);
    const [showDetails, setShowDetails] = useState(false);
    const [deedInfoDocs, setDeedInfoDocs] = useState<any>([]);
    

    useEffect(() => {
        (async () =>{
            try {
                setLoading(true);
                const pendingRes = await issuerComplianceAdminService.getPendingApprovalDeed();
                console.log(pendingRes)
                if (pendingRes.success) {
                    const pendingDeedData = pendingRes.data.map(pendingDeed => {
                        return {
                            key: pendingDeed.companyId,
                            companyname: pendingDeed.seriesDetails.name,
                            createdate: new Date(pendingDeed.creationTS).toLocaleString(),
                            contactnumber: pendingDeed.managerDetails && pendingDeed.managerDetails?.contactNumber,
                            email: pendingDeed.managerDetails && pendingDeed.managerDetails?.email,
                            status: pendingDeed.status.toUpperCase(),
                            deedDocument : pendingDeed?.deedDocument
                        };
                      }).sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
                      setPendingDeedData(pendingDeedData);
                      
                    setPendingDeedData(pendingDeedData)
                    setLoading(false);
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
            }
        })();

        (async () =>{
            try {
                setLoading(true);
                const res = await issuerComplianceAdminService.getAllApprovedOrRejectedDeed();
                console.log(res)
                if (res.success) {
                    const approveRejectDeedData = res.data.map(deed => {
                        return {
                            key: deed.companyId,
                            companyname: deed.seriesDetails.name,
                            createdate: new Date(deed.creationTS).toLocaleString(),
                            contactnumber: deed.contactNumber,
                            email: deed.managerDetails && deed.managerDetails?.email,
                            status: deed.status.toUpperCase(),
                        };
                      }).sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
                      setApproveRejectDeedData(approveRejectDeedData);
                      setApproveRejectDeedData(approveRejectDeedData)
                    setLoading(false);
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
            }
        })()
      }, [repaint]);


      const pendingDeedColumns = [
        {
            title: 'Company Name',
            dataIndex: 'companyname',
            key: 'companyname',
            },
            {
            title: 'Create Date',
            dataIndex: 'createdate',
            key: 'createdate',
            },
            {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            },
            {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            },
            {
            title: "ACTION",
            key: "action",
            render: (_,record, index) => (
                <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    type="primary"
                    onClick={() => deedDetails(index)}
                >
                    Details
                </Button>
                </div>
            ),
            },
      ];
    
      const deedDetails = (index: number) => {
        var selectedRecord = pendingDeedData[index];
          setDeedInfoDocs(selectedRecord);
          setShowDetails(true);
  
    }

      const approvedOrRejectedDeedColumns = [
        {
            title: 'Company Name',
            dataIndex: 'companyname',
            key: 'companyname',
            },
            {
            title: 'Create Date',
            dataIndex: 'createdate',
            key: 'createdate',
            },
            {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            },
            {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            },
      ];
    
      const rejectDeed = async()=>{
        if(!rejReff?.current.resizableTextArea.props.value.length){
            notification.info({ message: 'Provide a valid reason' });
            return
        }
        const reason = rejReff?.current.resizableTextArea.props.value;
        try {
            setRejectLoading(true);
            const res = await issuerComplianceAdminService.approveOrRejectDeedDocuments(companyId, {status: 'rejected', rejectionMessage:reason})
            if(res.success){
                notification.success({ message: 'Rejected Successfully' });
                setCompanyId(null);
                setRejectLoading(false);
                setLoading(false);
                setRejectModal(false);
                setShowDetails(false);
                setRepaint(prev => !prev)

            }
        } catch (error) {
            console.log(error);
            setRejectLoading(false);
            setRejectModal(false);
            setCompanyId(null);
            notification.success({ message: error });

        }
      }


      const approveDeed = async(companyid)=>{
        console.log('approveDeed',companyid)
        try {
            setLoading(true);
            const res = await issuerComplianceAdminService.approveOrRejectDeedDocuments(companyid, {status: 'approved'})
            if(res.success){
                notification.success({ message: 'Approved Successfully' });
                setLoading(false);
                setShowDetails(false);
                setRepaint(prev => !prev)
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            notification.success({ message: error });

        }
      }


  return (
    <>
      <br />
      {true && (
        <Modal
          title="Provide a valid reason for rejection"
          centered
          visible={rejectModel}
          onOk={() => rejectDeed()}
          onCancel={() => {
            setRejectModal(false);
            setCompanyId(null);
          }}
        >
          <TextArea style={{ width: 650 }} ref={rejReff} rows={8}></TextArea>
        </Modal>
      )}

      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          {!showDetails && (
            <Card>
              <Title
                level={2}
                style={{
                  textAlign: "left",
                  color: "#1890ff",
                  // fontSize: '30px',
                  fontWeight: "bold",
                }}
              >
                Deed Data List
              </Title>

              <Tabs defaultActiveKey="1" centered>
                <TabPane
                  tab={
                    <span>
                      <PlaySquareOutlined />
                      Pending Deeds
                    </span>
                  }
                  key="1"
                >
                  <div hidden={false}>
                    <Table
                      loading={loading}
                      dataSource={pendingDeedData}
                      columns={pendingDeedColumns}
                    />
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <ProjectOutlined />
                      Approved/Rejected Deeds
                    </span>
                  }
                  key="2"
                >
                  <div hidden={false}>
                    <Table
                      columns={approvedOrRejectedDeedColumns}
                      dataSource={approveRejectDeedData}
                      loading={loading}
                    ></Table>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          )}

          {showDetails && (
            <Card title="Deed Information">
              <div className="document-details">
                <h4>Deed Docs</h4>
                {deedInfoDocs.deedDocument?.length > 0 ?
                  deedInfoDocs.deedDocument?.map((doc, i) => {
                  return (
                    <>
                      <div
                        key={i}
                        style={{
                          textAlign: "left",
                          cursor: "pointer",
                          marginBottom: "10px",
                        }}
                      >
                        <a href={doc.URL} target="_blank">
                          {doc.name}
                        </a>
                        <br />
                      </div>
                    </>
                  );
                })
              :
              <div style={{ textAlign: "center", fontStyle: "italic" }}>No data found.</div>}
              </div>

              <div
                className="controls"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "50px",
                }}
              >
                <Button
                  htmlType="submit"
                  size="large"
                  type="primary"
                  onClick={() => setShowDetails(false)}
                  disabled={false}
                  loading={loading}
                >
                  Back
                </Button>

                {true && (
                  <div className="controls-action">
                    <Button
                      htmlType="submit"
                      style={{ marginRight: "10px" }}
                      onClick={() => approveDeed(deedInfoDocs.key)}
                      size="large"
                      type="primary"
                      disabled={false}
                      loading={loading}
                    >
                      Approve
                    </Button>
                    <Button
                      htmlType="submit"
                      size="large"
                      type="primary"
                      onClick={() => {
                        setRejectModal(true);
                        setCompanyId(deedInfoDocs.key);
                      }}
                      disabled={false}
                      loading={rejectloading}
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}

export default DeedApproval