import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Spin,
  Descriptions,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  User,
  SymbolDetailsAndSTData,
  TeamDepartment,
  TeamRole,
} from "../../../../Shared/interfaces";
// import { SharedService } from '../../../Shared/Shared.service';
import { IssuerSuperAdminService } from "../../../IssuerSuperAdmin.service";
import { AuthService } from "../../../../Shared/Auth.service";
import { SecurityTokenRegistryService } from "../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import BigNumber from "bignumber.js";
import { TokenConfigurationService } from "../../../../TokenConfigurations/TokenConfiguration.service";
import { SharedService } from "../../../../Shared/Shared.service";
// import { MetamaskService } from '../../../Shared/Metamask.service';

import { SecurityTokenService } from "../../../../Shared/SecurityToken/SecurityToken.service";
// import { VestingService } from '../../../../Shared/Vesting/Vesting.service';
import VestingFacet from "../../../../Shared/SecurityToken/Facets/VestingFacet/index";

const { Title } = Typography;

const issuerSuperAdminService = new IssuerSuperAdminService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const sharedService = new SharedService();

const securityTokenService = new SecurityTokenService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();

const useUserContext = () => new AuthService().useUserContext();

// const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function ListTeamManagment() {
  const { userInfo } = useUserContext();
  // const {selectedWallet, networkId} = useSelectedWalletContext();
  const history = useHistory();

  const [filter, setFilter] = useState({ limit: 10, searchPage: 1 });
  const [teamMembers, setTeamMembers] = useState<User[] | null>();
  const [loadingMoreTeamMembers, setLoadingMoreTeamMembers] =
    useState<boolean>();
  const [canLoadMoreTeamMembers, setCanLoadMoreTeamMembers] = useState(true);

  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      const [_tokenConfigurationProcess, _teamMembers] = (
        await Promise.all([
          tokenConfigurationService.getLastTokenConfigurationProcess(),
          issuerSuperAdminService.getTeamMembers(filter.limit, 1),
        ])
      ).map((res) => res.data);

      // if (_tokenConfigurationProcess?.tokenSymbol) {
      //   const _symbolDetailsAndSTData =
      //     await securityTokenRegisteryService.getSymbolDetailsAndSTData(
      //       _tokenConfigurationProcess.tokenSymbol
      //     );
      //   setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      // }

      setTeamMembers(_teamMembers);
      setFilter((prev: any) => ({ ...prev, searchPage: prev.searchPage + 1 }));
      setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
    })();
  }, [userInfo, filter.limit]);

  const loadMoreTeamMembers = async () => {
    if (loadingMoreTeamMembers || !teamMembers || !userInfo) return;

    setLoadingMoreTeamMembers(true);

    const response = await issuerSuperAdminService.getTeamMembers(
      filter.limit,
      filter.searchPage
    );
    const _teamMembers: User[] = response.data;

    setTeamMembers((prev) => [...(prev as any), ..._teamMembers]);
    setLoadingMoreTeamMembers(false);
    setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
  };

  const getExpandableTeamMemberDetails = async (
    expanded: boolean,
    record: User
  ) => {
    if (record["displayableDepartment"]) return;

    // if(!symbolDetailsAndSTData?.securityTokenData.contractAddress) return;

    const [responseDepartment, responseTeamRole] = await Promise.all([
      issuerSuperAdminService.getTeamDepartment(record.departmentId as string),
      issuerSuperAdminService.getTeamRole(record.teamRoleId as string),
    ]);

    let hasVesting = false;

    // if (symbolDetailsAndSTData?.securityTokenData.contractAddress) {
    //   hasVesting = await vestingFacet.hasVestingSchedule(
    //     symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
    //     record.wallet as string
    //   );
    // }

    const department: TeamDepartment = responseDepartment.data;
    const teamRole: TeamRole = responseTeamRole.data;

    const newData = [...(teamMembers as any[])];
    const index = newData.findIndex((member) => member._id === record._id);
    newData[index] = {
      ...newData[index],
      displayableDepartment: department.name,
      displayableTeamRole: teamRole.name,
      hasVesting,
    } as any;
    setTeamMembers(newData);
  };

  const columns = [
    // {
    //   title: '#',
    //   dataIndex: 'columNumber',
    //   render: (value: string, record: User) => teamMembers?.findIndex(member => member._id===record._id) as number + 1,
    // },
    {
      title: "NAME",
      dataIndex: "firstName",
    },
    // {
    //   title: 'LAST NAME',
    //   dataIndex: 'lastName'
    // },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "DEPARTMENT",
      dataIndex: "displayableTeamDepartment",
      render: (value: string, record: User) => value || "None",
    },
    {
      title: "TEAM ROLE",
      dataIndex: "displayableTeamRole",
      render: (value: string, record: User) => value || "None",
    },
    // {
    //   title: "AFFILIATE",
    //   dataIndex: "isAffiliate",
    //   render: (value: User["isAffiliate"]) =>
    //     value ? "Affiliate" : "Not Affiliate",
    // },
    {
      title: "Member Type",
      dataIndex: "corporateRoles",
      render: (corporateRoles: string[], record: User) =>
        sharedService.corporateRolesOptions.find((opt) =>
          corporateRoles?.includes(opt.value)
        )
          ? sharedService.corporateRolesOptions
              .filter((opt) => corporateRoles?.includes(opt.value))
              .map((opt) => opt.name)
              .join(",")
          : "None",
    },
    {
      title: "CREATED ON",
      dataIndex: "creationTS",
      render: (value: User["creationTS"]) => moment(value).format("LLL"),
    },
    {
      title: "DETAILS",
      render: (value, record: User) => {
        return (
          <>
            {(record.roles.includes("employee") ||
              record.roles.includes("contractor_consultant_advisor")) && (
              <Button
                type="link"
                onClick={() => {
                  history.push(
                    `/issuer-super-admin/admin/team-managment/${record._id}`
                  );
                }}
              >
                View
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Title level={1} style={{ textAlign: "center" }}>
              TEAM MANAGMENT
            </Title>
            <Table
              scroll={{ x: true }}
              columns={columns}
              onExpand={getExpandableTeamMemberDetails}
              expandable={{
                expandedRowRender: (record: User) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {!record["displayableDepartment"] && (
                      <div style={{ textAlign: "center" }}>
                        <Spin size="large" />
                      </div>
                    )}
                    {record["displayableDepartment"] && (
                      <Descriptions column={2} style={{}}>
                        <Descriptions.Item
                          style={{ textAlign: "center" }}
                          label="TEAM"
                        >
                          {record.role === "employee"
                            ? "Employee"
                            : "Contractor/Consultant/Advisor"}
                        </Descriptions.Item>
                        <Descriptions.Item
                          style={{ textAlign: "center" }}
                          label="DEPARTMENT"
                        >
                          {record["displayableDepartment"]}
                        </Descriptions.Item>
                        <Descriptions.Item
                          style={{ textAlign: "center" }}
                          label="ROLE"
                        >
                          {record["displayableTeamRole"]}
                        </Descriptions.Item>
                        {record.isAffiliate && record.corporateRoles && (
                          <Descriptions.Item
                            style={{ textAlign: "center" }}
                            label="CORPORATE ROLES"
                          >
                            {record.corporateRoles
                              .map(
                                (cr) =>
                                  sharedService.corporateRolesOptions.find(
                                    (opt) => opt.value === cr
                                  )?.name
                              )
                              .join(", ")}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item
                          style={{ textAlign: "center" }}
                          label="HAS VESTING"
                        >
                          {record["hasVesting"] ? "Yes" : "No"}
                        </Descriptions.Item>
                      </Descriptions>
                    )}
                    {/* <Tag style={{fontSize:'15px'}} color='blue'>
                    TEAM: {record['displayableDepartment'] === 'employee'? 'Employee' : 'Affiliate'}
                  </Tag>
                  <Tag style={{fontSize:'15px'}} color='green'>
                    DEPARTMENT: {record['displayableDepartment']}
                  </Tag>
                  <Tag style={{fontSize:'15px'}} color='purple'>
                    ROLE: {record['displayableTeamRole']}
                  </Tag> */}
                  </div>
                ),
              }}
              dataSource={teamMembers as any[]}
              loading={!teamMembers}
              rowKey="_id"
              pagination={false}
              footer={() => (
                <div style={{ textAlign: "center" }}>
                  {teamMembers && canLoadMoreTeamMembers && (
                    <Button onClick={loadMoreTeamMembers}>
                      <PlusOutlined />
                      Load more
                    </Button>
                  )}
                </div>
              )}
            />
            <div style={{ textAlign: "right" }}>
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() =>
                  history.push(
                    `/issuer-super-admin/admin/role-managment/add-role`
                  )
                }
              >
                ADD ROLE
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() =>
                  history.push(`/issuer-super-admin/team/edit-role`)
                }
              >
                EDIT ROLE
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() =>
                  history.push(`/issuer-super-admin/admin/team-managment/new`)
                }
              >
                ADD MEMBER/EMPLOYEE
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
