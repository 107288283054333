import React, { useState, useEffect } from 'react';
import {AuthService} from "../../Shared/Auth.service";
import {Typography, Tag, Card, Table} from "antd";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { MasterCompanySuperAdminSer } from '../masterCompanySuperAdmin.service';

const { Title } = Typography;

const service = new MasterCompanySuperAdminSer();

const useUserContext = () => new AuthService().useUserContext();


export default () => {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [allAgentAndChannelPartner, setAllAgentAndChannelPartner] = useState([]);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;
      const response = await service.getAllRealEstateAgentAndChannelPartner();
      setAllAgentAndChannelPartner(response.data);
      setLoading(false);

    })();
  }, [userInfo]);


  const seriesName = (value) => `${value}`;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record?.firstName} ${record?.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'Contact Number',
      dataIndex: 'phone',
      key: 'phone',
      // render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: { kyc: string }) => {
        let color = status?.kyc === 'approved' ? 'green' : (status?.kyc === 'rejected' ? 'red' : '#3dc2d3');
        return (
          <Tag color={color} key={status?.kyc}>
            {status?.kyc?.toUpperCase()}
          </Tag>
        );
      },
    }
  ];



  return (
    <div>
    <Title level={2} style={{ textAlign: 'left', marginLeft: '25px', marginTop: '10px' }}>Onboarding REA And CP</Title>
    <Card className="rounded-card">
      <Table columns={columns} dataSource={allAgentAndChannelPartner}  scroll={{ x: 'max-content' }}/>
    </Card>
  </div>
  );
}
