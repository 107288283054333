import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification, Modal, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { AdminService } from "../../IssuerSuperAdmin/Admin/Admin.service";
import { SharedService } from "../Shared.service";
import { User } from "../interfaces";
import { IssuerSuperAdminService } from "../../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { AuthService } from "../Auth.service";
import moment from "moment";
import { EscrowPaymentService } from "./EscrowPaymentRequests.service";


const { Title } = Typography;
const { TabPane } = Tabs;

const authService = new AuthService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => authService.useUserContext();
const escrowService = new EscrowPaymentService();

const EscrowPaymentRequests = () => {
    const { userInfo } = useUserContext();
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [escrowPaymentDataSource, setEscrowPaymentDataSource] = useState<any>([]);
    const [rejectReason, setRejectReason] = useState("");
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [repaint, setRepaint] = useState(false);

    const statusByRoles = {
        issuercomplianceadmin: 'llpCA',
        mastercompanycomplianceadmin: 'mca',
        platformissueradmin:'psa'
    }

    useEffect(() => {
        (async () => {

            setLoading(true);
            const escrowData: any = []
            let escrowInfo;
            if(userInfo?.role == "master company compliance admin" || userInfo?.role == "platform issuer admin"){
                escrowInfo = await escrowService.getEscrowPaymentForMCAOrPSA()
                if (escrowInfo.success ) {
                    if(userInfo?.role == "master company compliance admin"){
                        const EscrowDataRes = escrowInfo.data?.filter(data => ['pending','approved','rejected'].includes(data.mca) && data.llpCA == 'approved')
                        setEscrowPaymentDataSource(EscrowDataRes)
                    }else{
                        const EscrowDataRes = escrowInfo.data?.filter(data => ['pending','approved','rejected'].includes(data.psa) && data.mca =='approved')
                        setEscrowPaymentDataSource(EscrowDataRes)
                    }
                }
            }else{
                escrowInfo= await escrowService.getEscrowPaymentByCompanyId(userInfo?.company?.id)
                escrowData.push(escrowInfo.data)
                if (escrowInfo.success && escrowInfo.data?._id) {
                    setEscrowPaymentDataSource(escrowData)
                }
            }
            console.log('escrow info ', escrowInfo)

        
            setLoading(false);

        })();
    }, [repaint]);

    const columns = [
        {
            title: "Company Name",
            dataIndex: ["companyDetails", "name"],
            key: "companyName",
        },
        {
            title: "Escrow Contract Address",
            dataIndex: "deployedSmartContractAddress",
            key: "deployedSmartContractAddress",
        },
        {
            title: "Status",
            dataIndex: userInfo && statusByRoles[userInfo?.role.replaceAll(' ','')],
            key: "status",
        },
        {
            title: "Create Date",
            dataIndex: "creationTS",
            key: "creationTS",
            render: (d) => moment(d).format("LLL"),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record, index) => {
                return (
                    <>
                        { userInfo && record[statusByRoles[userInfo?.role.replaceAll(' ','')]] === 'pending' && (
                        <>
                            <div className="controls-action">
                                <Button htmlType='submit' style={{marginRight:'10px'}} onClick={()=>approveOrReject('approved')} size='large' type='primary' disabled={false} loading={loading}>
                                    Approve
                                </Button>
                                <Button htmlType='submit' size='large' type='primary' onClick={()=>{setShowRejectPopup(true);}} disabled={false} loading={loading}>
                                    Reject
                                </Button>
                            </div>
                        </>)}
                    </>
                );
            },
        },
    ];

    const approveOrReject = async(status) =>{
        try {
          // console.log(data)
          
          setLoading(true);
          let payload = {status,rejectionMessage:''}
          if(status == 'rejected'){

            if(rejectReason == ''){
                notification.info({message: 'Info', description: 'Provide valid rejection message'});
                return;
              }

            payload.rejectionMessage = rejectReason;
            setShowRejectPopup(false)
          }
          console.log(payload)
          const role = userInfo?.role.replaceAll(' ','');
          
          let companyId = userInfo?.company?.id;
          //incase of these below roles use the companyId of the previous api 
          if(userInfo?.role == 'master company compliance admin' || userInfo?.role == 'platform issuer admin'){
            companyId = escrowPaymentDataSource[0].companyId
          }

          const res = await escrowService.approveOrRejectEscrowPayment(companyId,payload,role)
          console.log(res)
          if(res.success){
            notification.success({message: 'Success', description: `Escrow Info ${status} successfully`});
            setRepaint(prev => !prev)
            setShowDetails(false);
            setTimeout(() => window.location.reload(), 1000);
          }else{
            notification.error({message: 'Error', description: res.error.message});
          }

        } catch (error) {
          console.log(error);
          notification.error({message:'error', description:error})
        }
        setLoading(false);
      }

    return (
        <>
            <br />
            <br />
            <Row justify="center">
                <Col span={23}>
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    )}
                    {!loading && !showDetails && (
                        <Card>
                            <Title level={1} style={{ textAlign: "center" }}>
                                Escrow Information Request
                            </Title>
                            <Row>
                                <Col
                                    span={3}
                                    offset={21}
                                    style={{ display: "flex", justifyContent: "end" }}
                                ></Col>
                            </Row>
                            <Table dataSource={escrowPaymentDataSource} columns={columns} />
                        </Card>
                    )}
                </Col>
            </Row>


            <Modal
                title="Reject Escrow Info"
                visible={showRejectPopup}
                onCancel={() => setShowRejectPopup(false)}
                footer={[
                    <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
                        Submit
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default EscrowPaymentRequests;
