import React from "react";
import { environment } from "../../environments/environment";
import "../../layout/Mission&Vision/Mission&Vision.scss";
import MissionAndVisionBanner from "../../assets/images/banner/MissionAndVisionBanner.png";
import VisionAndMission from "../../assets/images/about-us/VisionAndMission.png"
import OurMission from "../../assets/images/about-us/OurMission.png"

const MissionAndVision = () => {
  return (
    <>
      <div className="MissionAndVision">
        <div className="MissionAndVision__Banner">
        <img src={MissionAndVisionBanner} alt="About Us"></img>
          <div className="MissionAndVision__Banner-content">
          <p className="MissionAndVision__Banner-title"> 
          Investing Redefined:
          </p>
          <span className="MissionAndVision__Banner-title-text">Small Amounts, Big Returns. Fractional Real Estate Awaits</span>
          </div>
        </div>
        {/* <div className="MissionAndVision__header">Our Vision & Mission</div> */}
        <div className="MissionAndVision__our-vision">
          <div className="MissionAndVision__our-vision__content__header">
            <span className="MissionAndVision__our-vision__content__header__top">
              Our Vision
            </span>
            <span className="MissionAndVision__our-vision__content__sub-head">
              At VruddhiX, our vision is to reshape the real estate investment
              landscape by fostering an Indian community where accessibility,
              transparency, and innovation converge.
            </span>
            <span className="MissionAndVision__our-vision__content__description">
              We envision a future where real estate investment is simplified,
              secure, and open to a diverse range of Indian investors. Our
              authentic intelligent solutions follow thorough industry standards
              with legally compliant processes to solve customer problems. So
              let's Invest with confidence in a platform crafted for efficiency
              and tailored to meet your specific needs since we believe
              intuitive care for our customers plays a key role.
            </span>
            <span className="MissionAndVision__our-vision__content__description">
              In this future, we see a real estate landscape transformed,
              breaking free from traditional barriers within India. VruddhiX
              aims to lead this evolution, paving the way for a world where real
              estate investment is not only a sound financial decision but an
              inclusive and straightforward endeavor for Indian investors of all
              backgrounds. Join us in shaping this vision where the doors to
              real estate opportunities in India are wide open, and the journey
              is as secure as it is transformative.
            </span>
          </div>
          <img
            src={VisionAndMission}
            className="MissionAndVision__our-vision__img"
            alt="Tokenization Banner"
            loading="lazy"
          />
        </div>
        <div className="MissionAndVision__our-mission">
          <img
            src={OurMission}
            className="MissionAndVision__our-mission__img"
            alt="Tokenization Banner"
            loading="lazy"
          />
          <div className="MissionAndVision__our-mission__content__header">
            <span className="MissionAndVision__our-mission__content__header__top">
              Our Mission
            </span>
            <span className="MissionAndVision__our-mission__content__description">
              At VruddhiX, our mission is nothing short of pioneering the future
              of real estate investment in India. As trailblazers, we are
              committed to introducing the world's first legally compliant
              tokenized securities issuance platform tailored to the Indian
              market. Our relentless pursuit is fueled by the desire to bring
              liquidity to the historically illiquid asset market in India,
              providing a gateway for small investors to engage in
              revenue-earning properties through fractional ownership.
            </span>
            <span className="MissionAndVision__our-mission__content__description">
              Through this mission, we aspire to democratize real estate
              investment, ensuring accessibility for all, and reshaping the
              landscape of financial inclusivity within India. We are here to
              help you, with an effective and user-friendly marketplace to Raise
              capital, Fractionalize the properties with KYC, Admin Approvals,
              Blockchain based Smart contracts to assure the properties are
              liable with its owners and can be trusted for investment. Onboard
              the Investors let them tokenize your properties, gain profits and
              we build a legally compliant real estate environment with great
              benefits.
            </span>
            <span className="MissionAndVision__our-mission__content__description">
              By leveraging our expertise, we streamline the digitalization
              process, eliminating the complexities associated with all followed
              due diligence, KYC procedures for Issuers and Investors as well as
              for the property details, and property documents with efficient
              building Smart contracts for flexible and protected token creation
              as well as token sale where every transaction is stored on
              blockchain with Admin Approval. In our unwavering dedication to
              revolutionize the Indian real estate sector, VruddhiX stands at
              the forefront of innovation. By empowering small investors and
              transforming traditionally rigid markets, we are laying the
              foundation for a future where real estate investment is not just a
              privilege but a universal opportunity open to everyone in India.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionAndVision;
