import { Card, Col, Row, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState } from 'react'
import { AccountService } from '../../Account/Account.service'
import moment from "moment";


const accountService = new AccountService();

const Enquiries = () => {

    const [intrestedPca,setIntrestedPca] = useState([]);
    const [loading,setLoading] = useState(false);

    const enquiriesCols = [
        {
          title: "First Name",
          dataIndex: "firstName",
          key: "firstName",
        },
        {
          title: "Last Name",
          dataIndex: "lastName",
          key: "lastName",
        },
        {
          title: "EmailL",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Comments",
          dataIndex: "comments",
          key: "comments",
        },
        {
        title: "Create Date",
        dataIndex: "creationTS",
        key: "creationTS",
        render: (text) => moment(text).format("LLL"),
        },
       
      ];
      
      useEffect(()=>{
       (async()=>{
        try {
            setLoading(true);
            const res = await accountService.getInterestPCA();
            console.log(res)
            setIntrestedPca(res.data)
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
        })();
      },[])
    

  return (
    <Row justify="center">
    <Col span={22} style={{ textAlign: "center" }}>
      <Card>
        <Title
          level={2}
          style={{
            textAlign: "left",
            color: "#1890ff",
            // fontSize: '30px',
            fontWeight: "bold",
            marginBottom:'80px'
          }}
        >
          Enquiries
        </Title>

          
        <Table
            columns={enquiriesCols}
            loading={loading}
           dataSource={intrestedPca}
        ></Table>

        </Card>
        </Col>
      </Row>
  )
}

export default Enquiries