import React, { useState, useEffect, useCallback } from "react";
import { Result, Card, Col, Row, Typography, Table, Button, Checkbox, Form, Input, DatePicker, Modal } from "antd";
import { InvestorService } from "../Investor.service";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../Shared/Auth.service";
import moment from "moment";

const { Title } = Typography;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

export default () => {

	const history = useHistory();
	const { userInfo } = useUserContext();
	const companyId = userInfo.company?.id;
	const [currentStatus, setCurrentStatus] = useState(null);
	const [paymentList, setPaymentList] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [formData, setFormData] = useState({});
	const [investedTokens, setInvestedTokens] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const InvestedTokencolumns = [
        {
        title: 'Company Name',
        dataIndex: 'companyname',
        key: 'companyname',
        },
		{
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        },
		{
        title: 'Date of Investment',
        dataIndex: 'doi',
        key: 'doi',
        },
        {
        title: 'Type of Security',
        dataIndex: 'typeOfSecurity',
        key: 'typeOfSecurity',
        },
        {
        title: 'Token Count',
        dataIndex: 'tokenCount',
        key: 'tokenCount',
        },
        {
        title: 'Maximum Investors',
        dataIndex: 'maximuminvestors',
        key: 'maximuminvestors',
        },
        {
        title: 'Lock Period',
        dataIndex: 'lockPeriod',
        key: 'lockPeriod',
        },
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        },
        {
        title: 'Price Per Token',
        dataIndex: 'pricePerToken',
        key: 'pricePerToken',
        },
  ];


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
	console.log("formData::: ", formData);
	
    investorService.buyTokensWithPaymentDetailsAndCompanyId(userInfo._id, companyId, formData)
      .then((response) => {
        if (response.success) {
          console.log(response);
        } else {
          console.log("Error")
        }
      })
      .finally(() => {
        setIsModalVisible(false);
      });
  };
  const onFormChange = useCallback((changedValues, allValues) => {
	//dfdf
	allValues.investorId = userInfo._id;
    setFormData(allValues);
  }, []);
	
	useEffect(() => {
		(async () => {
			console.log(userInfo._id);
			const response = await investorService.getPayments({
				investorId: userInfo._id,
			});
			if (response.success && response.data) {
				console.log(response);
				if (response.data.length <= 0) {
					setCurrentStatus("notSubmitted");
				} else {
					setCurrentStatus("submitted");
					setPaymentList(response.data);
				}
			}
		})();
	}, [userInfo._id]);
 
	useEffect(() => {
		const fetchInvestedTokens = async () => {
		  try {
			setIsLoading(true);
			const investorId = userInfo._id;
			const result = await new InvestorService().getAllInvestedTokenDetailsByInvestorId(investorId);
			if (result.success && result.data) {
				setIsLoading(false);
				const tokensData = result.data.map(tokenDetails => {
				  return {
					key: tokenDetails._id,
					companyname:tokenDetails.companyDetails.name,
					doi:new Date(tokenDetails.creationTS).toLocaleString(),
					tokenCount: tokenDetails.tokenCount,
					maximuminvestors: tokenDetails.totalInvestors,
					description: tokenDetails.description,
					lockPeriod: tokenDetails.lockPeriod,
					status: tokenDetails.status.toUpperCase(),
					pricePerToken: tokenDetails.pricePerToken,
					typeOfSecurity: tokenDetails.typeOfSecurity,
				  };
				});
			  
				setInvestedTokens(tokensData);
			  }
		  } catch (error) {
			setIsLoading(false);
			console.error('Error fetching invested tokens:', error);
		  }
		};
	

		if (userInfo) {
		  fetchInvestedTokens();
		}
	  }, []);

	return (
    <>
      <br />

      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Payments
            </Title>
            <div hidden={true}>
              <Result
                status="warning"
                title="No payments Found!"
                subtitle="you have not done any payment."
                extra={
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => history.push("/investor/home")}
                  >
                    Home
                  </Button>
                }
              ></Result>
            </div>
			{/* <Button type="primary" onClick={showModal}>Invest Now</Button> */}

            <div hidden={false}>
				<Table dataSource={investedTokens} loading={isLoading} columns={InvestedTokencolumns} />
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Invest Now"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ maxWidth: '600px' }} 
      >
        <Form onValuesChange={onFormChange}>
          <Form.Item
            label="Amount of Tokens to Buy"
            name="tokenCount"
            rules={[
              { required: true, message: 'Please enter the token count' },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Bank Name"
            name="bankName"
            rules={[
              { required: true, message: 'Please enter the bank name' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account Number"
            name="accountNumber"
            rules={[
              { required: true, message: 'Please enter the account number' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="IFSC Code"
            name="ifscCode"
            rules={[
              { required: true, message: 'Please enter the IFSC code' },
            ]}
          >
          <Input />
          </Form.Item>
          <Form.Item
            label="Account Name"
            name="accountName"
            rules={[
              { required: true, message: 'Please enter the account name' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Transaction ID"
            name="txnId"
            rules={[
              { required: true, message: 'Please enter the transaction ID' },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
