import React, { useState, useEffect, useRef } from "react";
import {
  List,
  Button,
  Form,
  Radio,
  Input,
  Collapse,
  Progress,
  Modal,
  Checkbox,
  message,
  Space,
  Popconfirm,
  Card,
  Spin
} from "antd";
import { MasterCompanySuperAdminSer } from "../masterCompanySuperAdmin.service";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { SharedService } from "../../Shared/Shared.service";
import { AuthService } from "../../Shared/Auth.service";
import TextArea from "antd/lib/input/TextArea";
import { environment } from "../../../environments/environment";

const auth = new AuthService();
const useUserContext = () => auth.useUserContext();
const sharedService = new SharedService();
const { Panel } = Collapse;
const MasterCompanySuperAdminService = new MasterCompanySuperAdminSer();

function Data() {
  this.question = "";
  this.type = "";
  this.category = "";
  this.answer = "";
  this.isAnswered = false;
  this.partOfWebpage = false;
  this.hidden = false;
}
export default () => {
  const { userInfo } = useUserContext();
  const [generalQuestions, setGeneralQuestions] = useState([]);
  const [offeringDocsQuestions, setOfferingDocsQuestions] = useState([]);
  const [socialMediaQuestions, setSocialMediaQuestions] = useState([]);
  const [businessQuestions, setBusinessQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [displayFlag, setDisplayFlag] = useState(false);//false
  const [questionToAnswer, setQuestionToAnswer] = useState(null);//null
  const [options, setOptionsView] = useState(false);
  const [textArea, setTextAreaView] = useState(false);//false
  const [textBox, setTextBoxView] = useState(false);
  const [attachment, setAttachmentView] = useState(false);
  const [content, setContent] = useState("");
  const [infoModalView, setInfoModalView] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const videoURLRef = useRef(null);
  const campaignTitleRef = useRef(null);
  const [issuerWebpageData, setIssuerWebpageData] = useState(null);
  const [issuerWebpageModalView, setIssuerWebpageModalView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [member1, setMember1] = useState({name: '', image: {}, linkedin: '', twitter: '', about: ''});
  const [member2, setMember2] = useState({name: '', image: {}, linkedin: '', twitter: '', about: ''});
  const [member3, setMember3] = useState({name: '', image: {}, linkedin: '', twitter: '', about: ''});
  const [member4, setMember4] = useState({name: '', image: {}, linkedin: '', twitter: '', about: ''});

  const [filesObj, setFilesObj] = useState({
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      const response = await MasterCompanySuperAdminService.getQuestions({
        company: userInfo?.companyName?.toLowerCase(),
      });
      setQuestions(response.data);
      applyFilter(response.data);

      const issuerWebpageConfigResult =
        await MasterCompanySuperAdminService.getIssuerWebpageConfig({
          companyId: userInfo.company.id,
          company: userInfo.company.name,
        });
      if (issuerWebpageConfigResult.success) {
        await setIssuerWebpageData(issuerWebpageConfigResult.data);
        await setInfoModalView(
          !issuerWebpageConfigResult.data.neverDisplayInfoModal
        );
      } else {
        setInfoModalView(true);
      }
    })();
  }, []);

  const applyFilter = (ques) => {
    const generalQuestions = ques?.filter(
      (question) => question.category === "General"
    );
    setGeneralQuestions(generalQuestions);
    //console.log(generalQuestions);
    const offeringDocsQuestions = ques?.filter(
      (question) => question.category === "Offering docs"
    );
    setOfferingDocsQuestions(offeringDocsQuestions);
    const businessQuestions = ques?.filter(
      (question) => question.category === "Business"
    );
    setBusinessQuestions(businessQuestions);
    const socialMediaQuestions = ques?.filter(
      (question) => question.category === "Social Media"
    );
    setSocialMediaQuestions(socialMediaQuestions);
  };

  const setIssuerWebpageConfig = async (data) => {
    console.log(data);
    const dataToSend = await Object.assign(
      { companyId: userInfo.company.id },
      data
    );
    console.log(dataToSend);
    const response = await MasterCompanySuperAdminService.setIssuerWebpageConfig(
      dataToSend
    );
    if (!response.success || !response.data) {
      message.error("Error while saving data");
    }
  };

  const uploadPromotionalVideo = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      videoURL: videoURLRef.current.state.value,
    });
    await message.success("Video url updated successfully.");
    issuerWebpageData.videoURL = videoURLRef.current.state.value;
    videoURLRef.current.state.value = "";
    setSubmitting(false);
  };

  const uploadCampaignTitle = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      campaignTitle: campaignTitleRef.current.state.value,
    });
    await message.success("Campaign title updated successfully.");
    issuerWebpageData.campaignTitle = campaignTitleRef.current.state.value;
    campaignTitleRef.current.state.value = "";
    setSubmitting(false);
  };

  const uploadCampaignTitleImage = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      campaignTitleImage: {
        URL: filesObj.attachement.URL,
        name: filesObj.attachement.name,
      },
    });
    await message.success("Campaign title image updated successfully.");
    issuerWebpageData.campaignTitleImage = {
      URL: filesObj.attachement.URL,
      name: filesObj.attachement.name,
    };
    filesObj.attachement.URL = null;
    filesObj.attachement.uploadFilePercentage = 0;
    setSubmitting(false);
  };

  const answerQuestion = (question) => {
    setDisplayFlag(true);
    setQuestionToAnswer(question);
    if (question.type === "option") {
      setOptionsView(true);
    }
    if (question.type === "textArea") {
      setTextAreaView(true);
    }
    if (question.type === "textBox") {
      setTextBoxView(true);
    }
    if (question.type === "attachement") {
      setAttachmentView(true);
    }
  };

  const onFinished = async (values) => {
    var data = new Data();
    data.question = questionToAnswer.question;
    data.category = questionToAnswer.category;
    data.type = questionToAnswer.type;
    data.isAnswered = true;
    data.hidden = false;
    data.partOfWebpage = questionToAnswer.partOfWebpage;
    data.company = userInfo.companyName.toLowerCase();
    if (questionToAnswer.type === "textBox") {
      if (values.textBox) {
        if (values.textBox.trim().length <= 0) {
          alert("Answer cannot be empty!!");
          return;
        }
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
      data.answer = values.textBox.trim();
    }
    if (questionToAnswer.type === "textArea") {
      if (values.textArea) {
        data.answer = content;
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
    }
    if (questionToAnswer.type === "option") {
      if (values.option) {
        data.answer = values.option;
      } else {
        alert("Please select a option !");
        return;
      }
    }
    if (questionToAnswer.type === "attachement") {
      if (documentsToSubmit) {
        data.answer = documentsToSubmit;
      } else {
        alert("Please select a file !");
        return;
      }
    }

    //for members
    if (questionToAnswer.question == "TEAM") {
      if(!member1.image.URL || !member2.image.URL || !member3.image.URL || !member4.image.URL){
        alert("Please Upload All Members Images !");
        return;
      }
      
      data.member1 = member1;
      data.member2 = member2;
      data.member3 = member3;
      data.member4 = member4;
    }

    setSubmitting(true);
    setLoading(true);
    const response = await MasterCompanySuperAdminService.setAnswer(data);
    if (response.success) {
      const que = questions.filter(
        (question) => question.question !== questionToAnswer.question
      );
      applyFilter(que);
      setQuestions(que);
      setOptionsView(false);
      setTextAreaView(false);
      setTextBoxView(false);
      setOptionsView(false);
      setAttachmentView(false);
      setFilesObj(initialFileObj);
      setDocuments([]);
      setDocumentsToSubmit([]);
      form.resetFields();
      setDisplayFlag(false);
    }
    setSubmitting(false);
    setLoading(false);
  };

  const handleChange = (content, editor) => {
    setContent(content);
  };

  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    MasterCompanySuperAdminService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat({ URL, name: file.name }));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  //upload to ipfs
  const uploadFileIPFS = async (e, index = 1, form) => {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];

    const fileType = sharedService.getFileType(file.name);
    if(index == 2){
      setMember2({...member2, image: { URL : null, uploadFilePercentage : 0 }});
      try{
        setMember2({...member2, image: { URL : null, uploading: true, uploadFilePercentage : 10 }});
        const {hash} = await sharedService.uploadDocumentToIPFS({file});
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        setMember2({...member2, image: { URL, uploading: false, name: file.name,
            uploadFilePercentage: 100, type: fileType.toLowerCase() }});

        let fileDetails = { 'image2': { URL, name: file.name } };
        form.setFieldsValue(fileDetails);
      }catch(err){
        console.error(err);
        setMember2({...member2, image: { uploading:false }});
      }
    }else if(index == 3){
      setMember3({...member3, image: { URL : null, uploadFilePercentage : 0 }});
      try{
        setMember3({...member3, image: { URL : null, uploading: true, uploadFilePercentage : 10 }});
        const {hash} = await sharedService.uploadDocumentToIPFS({file});
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        setMember3({...member3, image: { URL, uploading: false, name: file.name,
            uploadFilePercentage: 100, type: fileType.toLowerCase() }});

        let fileDetails = { 'image3': { URL, name: file.name } };
        form.setFieldsValue(fileDetails);
      }catch(err){
        console.error(err);
        setMember3({...member3, image: { uploading:false }});
      }
    }else if(index == 4){
      setMember4({...member4, image: { URL : null, uploadFilePercentage : 0 }});
      try{
        setMember4({...member4, image: { URL : null, uploading: true, uploadFilePercentage : 10 }});
        const {hash} = await sharedService.uploadDocumentToIPFS({file});
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        setMember4({...member4, image: { URL, uploading: false, name: file.name,
            uploadFilePercentage: 100, type: fileType.toLowerCase() }});

        let fileDetails = { 'image4': { URL, name: file.name } };
        form.setFieldsValue(fileDetails);
      }catch(err){
        console.error(err);
        setMember4({...member4, image: { uploading:false }});
      }
    }else{
      setMember1({...member1, image: { URL : null, uploadFilePercentage : 0 }});
      try{
        setMember1({...member1, image: { URL : null, uploading: true, uploadFilePercentage : 10 }});
        const {hash} = await sharedService.uploadDocumentToIPFS({file});
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        setMember1({...member1, image: { URL, uploading: false, name: file.name,
            uploadFilePercentage: 100, type: fileType.toLowerCase() }});
        
        let fileDetails = { 'image1': { URL, name: file.name } };
        form.setFieldsValue(fileDetails);
      }catch(err){
        console.error(err);
        setMember1({...member1, image: { uploading:false }});
      }
    }

  }

  const back = () => {
    setOptionsView(false);
    setTextAreaView(false);
    setTextBoxView(false);
    setOptionsView(false);
    setAttachmentView(false);
    setFilesObj(initialFileObj);
    form.resetFields();
    setDisplayFlag(false);
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };
  return (
    <>
      <br />
      {loading && (<Spin size='large' style={{'textAlign': 'center', alignItems: 'center'}}/>)}
      {!loading && (<>
        <div hidden={displayFlag}>
          <Collapse>
            <Panel header="General" key="General" style={{ textAlign: "left" }}>
              <List style={{ textAlign: "left" }} loading={!questions}>
                {generalQuestions?.map((question, index) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={index}
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => answerQuestion(generalQuestions[index])}
                      >
                        Answer
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={question.question}></List.Item.Meta>
                  </List.Item>
                ))}
              </List>
            </Panel>
          </Collapse>

          <Collapse>
            <Panel header="Business" key="Business" style={{ textAlign: "left" }}>
              <List style={{ textAlign: "left" }} loading={!questions}>
                {businessQuestions?.map((question, index) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={index}
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => answerQuestion(businessQuestions[index])}
                      >
                        Answer
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={question.question}></List.Item.Meta>
                  </List.Item>
                ))}
              </List>
            </Panel>
          </Collapse>

          <Collapse>
            <Panel
              header="Social Media"
              key="Social Media"
              style={{ textAlign: "left" }}
            >
              <List style={{ textAlign: "left" }} loading={!questions}>
                {socialMediaQuestions?.map((question, index) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={index}
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => answerQuestion(socialMediaQuestions[index])}
                      >
                        Answer
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={question.question}></List.Item.Meta>
                  </List.Item>
                ))}
              </List>
            </Panel>
          </Collapse>

          <Collapse>
            <Panel
              header="Offering Docs"
              key="Offering Docs"
              style={{ textAlign: "left" }}
            >
              <List style={{ textAlign: "left" }} loading={!questions}>
                {offeringDocsQuestions?.map((question, index) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={index}
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => answerQuestion(offeringDocsQuestions[index])}
                      >
                        Answer
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={question.question}></List.Item.Meta>
                  </List.Item>
                ))}
              </List>
            </Panel>
          </Collapse>
        </div><div hidden={!displayFlag}>
            <Form name="answerForm" form={form} onFinish={onFinished}>
              <Form.Item label="Question">
                <label>
                  <h1>{questionToAnswer?.question}</h1>
                </label>
              </Form.Item>
              <div hidden={!options}>
                <Form.Item name="option">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div hidden={!textArea}>
                <Form.Item name="textArea">
                  <SunEditor value={content} onChange={handleChange} />
                </Form.Item>
              </div>
              <div hidden={!textBox}>
                <Form.Item name="textBox">
                  {
                    questionToAnswer?.question === "TEAM" || questionToAnswer?.question === "INTRODUCTION" ?(
                      <TextArea style={{width: "60%", height: '100px', padding: '10px'}}/>
                    ):(
                      <Input type='url' style={{ width: "60%" }}></Input>
                    )
                  }
                </Form.Item>
              </div>
              <div hidden={!attachment}>
                <Form.Item name="attachement">
                  <Input
                    type="file"
                    onChange={(e) => {
                      uploadFile(e, "attachement", setFilesObj, form);
                    } }
                    disabled={submitting} />
                  {filesObj.attachement.uploadFilePercentage > 0 && (
                    <>
                      <Progress
                        percent={filesObj.attachement.uploadFilePercentage} />
                      <br />
                      <div align="left">
                        Files Uploaded:
                        <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                          {documents?.map((obj, index) => (
                            <List.Item
                              textalign="left"
                              style={{ cursor: "pointer" }}
                              key={index}
                              actions={[
                                <Popconfirm
                                  title="This item will be deleted!"
                                  onConfirm={() => deleteItem(index)}
                                >
                                  <Button type="primary" danger>
                                    X
                                  </Button>
                                </Popconfirm>,
                              ]}
                            >
                              <List.Item.Meta title={obj} />
                            </List.Item>
                          ))}
                        </List>
                      </div>
                    </>
                  )}{" "}
                </Form.Item>
              </div>
              {questionToAnswer?.question === "TEAM" && (
                <div style={{ margin: '50px 0' }}>
                  <Card style={{ marginBottom: '30px', border: '1px solid black' }}>
                    <h4>Member 1</h4>
                    <Form.Item label='Name' name="name1"
                      rules={[{
                        required: true,
                        message: 'Please input member1 name!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member1.name} onChange={(e) => setMember1({ ...member1, name: e.target.value })} />
                    </Form.Item>
                    <Form.Item name="image1" label='* Member1 Image'>
                      <Input type='file' onChange={e => { uploadFileIPFS(e, 1, form); } } />
                      {member1.image && member1.image.uploadFilePercentage > 0 &&
                        <Progress percent={member1.image.uploadFilePercentage} />}
                    </Form.Item>
                    <Form.Item label='Linkedin Url' name="linkedin1"
                      rules={[{
                        required: true,
                        message: 'Please input member1 Linkedin Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member1.linkedin} onChange={(e) => setMember1({ ...member1, linkedin: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Twitter Url' name="twitter1"
                      rules={[{
                        required: true,
                        message: 'Please input member1 Twitter Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member1.twitter} onChange={(e) => setMember1({ ...member1, twitter: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='About information' name="about1"
                      rules={[{
                        required: true,
                        message: 'Please input member1 About information!',
                        whitespace: true
                      }]}>
                      <TextArea style={{ color: 'green' }} value={member1.about} onChange={(e) => setMember1({ ...member1, about: e.target.value })} />
                    </Form.Item>
                  </Card>

                  <Card style={{ marginBottom: '30px', border: '1px solid black' }}>
                    <h4>Member 2</h4>
                    <Form.Item label='Name' name="name2"
                      rules={[{
                        required: true,
                        message: 'Please input member2 name!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member2.name} onChange={(e) => setMember2({ ...member2, name: e.target.value })} />
                    </Form.Item>
                    <Form.Item name="image2" label='* Member2 Image'>
                      <Input type='file' onChange={e => { uploadFileIPFS(e, 2, form); } } />
                      {member2.image && member2.image.uploadFilePercentage > 0 &&
                        <Progress percent={member2.image.uploadFilePercentage} />}
                    </Form.Item>
                    <Form.Item label='Linkedin Url' name="linkedin2"
                      rules={[{
                        required: true,
                        message: 'Please input member2 Linkedin Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member2.linkedin} onChange={(e) => setMember2({ ...member2, linkedin: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Twitter Url' name="twitter2"
                      rules={[{
                        required: true,
                        message: 'Please input member2 Twitter Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member2.twitter} onChange={(e) => setMember2({ ...member2, twitter: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='About information' name="about2"
                      rules={[{
                        required: true,
                        message: 'Please input member2 About information!',
                        whitespace: true
                      }]}>
                      <TextArea style={{ color: 'green' }} value={member2.about} onChange={(e) => setMember2({ ...member2, about: e.target.value })} />
                    </Form.Item>
                  </Card>

                  <Card style={{ marginBottom: '30px', border: '1px solid black' }}>
                    <h4>Member 3</h4>
                    <Form.Item label='Name' name="name3"
                      rules={[{
                        required: true,
                        message: 'Please input member3 name!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member3.name} onChange={(e) => setMember3({ ...member3, name: e.target.value })} />
                    </Form.Item>
                    <Form.Item name="image3" label='* Member3 Image'>
                      <Input type='file' onChange={e => { uploadFileIPFS(e, 3, form); } } />
                      {member3.image && member3.image.uploadFilePercentage > 0 &&
                        <Progress percent={member3.image.uploadFilePercentage} />}
                    </Form.Item>
                    <Form.Item label='Linkedin Url' name="linkedin3"
                      rules={[{
                        required: true,
                        message: 'Please input member3 Linkedin Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member3.linkedin} onChange={(e) => setMember3({ ...member3, linkedin: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Twitter Url' name="twitter3"
                      rules={[{
                        required: true,
                        message: 'Please input member3 Twitter Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member3.twitter} onChange={(e) => setMember3({ ...member3, twitter: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='About information' name="about3"
                      rules={[{
                        required: true,
                        message: 'Please input member3 About information!',
                        whitespace: true
                      }]}>
                      <TextArea style={{ color: 'green' }} value={member3.about} onChange={(e) => setMember3({ ...member3, about: e.target.value })} />
                    </Form.Item>
                  </Card>

                  <Card style={{ marginBottom: '40px', border: '1px solid black' }}>
                    <h4>Member 4</h4>
                    <Form.Item label='Name' name="name4"
                      rules={[{
                        required: true,
                        message: 'Please input member4 name!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member4.name} onChange={(e) => setMember4({ ...member4, name: e.target.value })} />
                    </Form.Item>
                    <Form.Item name="image4" label='* Member4 Image'>
                      <Input type='file' onChange={e => { uploadFileIPFS(e, 4, form); } } />
                      {member4.image && member4.image.uploadFilePercentage > 0 &&
                        <Progress percent={member4.image.uploadFilePercentage} />}
                    </Form.Item>
                    <Form.Item label='Linkedin Url' name="linkedin4"
                      rules={[{
                        required: true,
                        message: 'Please input member4 Linkedin Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member4.linkedin} onChange={(e) => setMember4({ ...member4, linkedin: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Twitter Url' name="twitter4"
                      rules={[{
                        required: true,
                        message: 'Please input member4 Twitter Url!',
                        whitespace: true
                      }]}>
                      <Input style={{ color: 'green' }} value={member4.twitter} onChange={(e) => setMember4({ ...member4, twitter: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='About information' name="about4"
                      rules={[{
                        required: true,
                        message: 'Please input member4 About information!',
                        whitespace: true
                      }]}>
                      <TextArea style={{ color: 'green' }} value={member4.about} onChange={(e) => setMember4({ ...member4, about: e.target.value })} />
                    </Form.Item>
                  </Card>
                </div>)}
              
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  SUBMIT
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button htmlType="button" onClick={() => back()}>
                  BACK
                </Button>
              </Form.Item>
            </Form>
          </div><Modal
            title="Info"
            visible={infoModalView}
            footer={[
              <Button type="primary" onClick={() => setInfoModalView(false)}>
                OK
              </Button>,
            ]}
          >
            Answer to the questions will reflect on your offering webpage.Please
            provide true and accurate information.
            <br />
            <br />
            <Checkbox
              onChange={(e) => setIssuerWebpageConfig({ neverDisplayInfoModal: e.target.checked })}
            >
              Never want to see this message again.
            </Checkbox>
          </Modal><br /><div style={{ textAlign: "right" }}>
            <Space>
              <Button
                type="primary"
                href="/master-company-super-admin/view-and-edit-answers"
              >
                Edit answers
              </Button>
              {/* <Button type="primary" onClick={()=>{setIssuerWebpageModalView(true)}} >Preview Offering page</Button> */}
            </Space>
          </div>
        </>)}
    </>
  );
};
