import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();

export class SeriesService {
  private APIURL = environment.APIURL;


  async getLastSeriesNumber(prop: {
    companyId: string,
  },) {
    return axios.get<any, APIResponse>(`${this.APIURL}/series/getLastSeriesNumber`, {headers: await sharedService.getAuthHeader(), params: prop});
  }

  async createSeries(prop: {
    name: string;
    comments?: string
  }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/series/createSeries`, prop, {headers: await sharedService.getAuthHeader()});
  }

  async getSeries(userId: any) {
    return axios.get<any, APIResponse>(`${this.APIURL}/series/getSeries`, { params: { userId },headers: await sharedService.getAuthHeader()});
  }

  async getSmart() {
    return axios.get<any, APIResponse>(`${this.APIURL}/series/getSmart`, {headers: await sharedService.getAuthHeader()});
  }

  async inviteSeriesManager(prop: {
    email: string,
    seriesId: string,
    userId: string,
    commission: number
  }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/series/inviteSeriesManager`, prop, {headers: await sharedService.getAuthHeader()});
  }

}