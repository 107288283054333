import React, { useState, useEffect } from 'react';
import { AuthService } from "../../Shared/Auth.service";
import { Typography, Tag, Card, Table, Modal, Form, Button, Tooltip, Input, Select, Spin,message,notification } from "antd";
import Icon, { FileExcelOutlined } from '@ant-design/icons';
import moment from "moment";
import { InvestorService } from '../Investor.service';
import { useHistory } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { SeriesService } from '../../Series/Series.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { RealEstateChannelPartnerService } from '../../RealEstateChannelPartner/RealEstateChannelPartner.service';

const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 26 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 16 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const seriesService = new SeriesService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const realEstateChannelPartner = new RealEstateChannelPartnerService();

const useUserContext = () => new AuthService().useUserContext();


export default () => {
  const { userInfo } = useUserContext();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [investors, setInvestors] = useState([]);
  const [series, setAllSeries] = useState<any[]>([]);
  const [displayedInvestors, setDisplayedInvestors] = useState([]);
  const [modalForm] = Form.useForm();
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalInvites, setTotalInvites] = useState(0);

  const [isPropertyModalVisible, setIsPropertyModalVisible] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [investedProperties, setInvestedProperties] = useState([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);

  const fetchData = async (page, size) => {
    if (!userInfo) return;
    setLoading(true);
    try {
      const response = await realEstateChannelPartner.getInvites(userInfo?._id, page, size);
      const allSeries = await realEstateChannelPartner.getAllProperty(userInfo?._id);
      if (response.data) {
        setAllSeries(allSeries.data);
        setInvestors(response?.data?.investorList);
        setDisplayedInvestors(response?.data?.investorList);
        setTotalInvites(response.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [userInfo, isInviteModalVisible, currentPage, pageSize]);

  const openInviteModal = () => {
    modalForm.resetFields();
    setIsInviteModalVisible(true);
  }

  const inviteInvestors = async (formValue) => {
    console.log(formValue, "formValue");
    setLoading(true);

    const response = await issuerSuperAdminService.inviteInvestor({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      seriesId: formValue.seriesId,
      userId: userInfo?._id,
      commission: formValue.commission
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Investor Successfully'
      });
      setIsInviteModalVisible(false);
    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setLoading(false);
  }

  const handleRowClick = async (record) => {
    console.log(record) 
    setSelectedInvestor(record);
    setIsPropertyModalVisible(true);
    await fetchInvestedProperties(record._id);
  };

  const fetchInvestedProperties = async (investorId) => {
    setIsLoadingProperties(true);
    try { 
      // const response = await realEstateChannelPartner.getInvestedProperties(investorId);
      // setInvestedProperties(response.data);
    } catch (error) {
      console.error("Error fetching invested properties:", error);
      message.error("Failed to fetch invested properties");
    }
    setIsLoadingProperties(false);
  };

  const propertyColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Invested Amount',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (amount) => `$${amount.toLocaleString()}`,
    },
    {
      title: 'Investment Date',
      dataIndex: 'investmentDate',
      key: 'investmentDate',
      render: (date) => moment(date).format('lll'),
    },
    // Add more columns as needed
  ];





  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTs',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'Commission %',
      dataIndex: 'commission',
      key: 'commission',
      render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}</span>,
    },
    {
      title: 'Commission Amount',
      dataIndex: 'commission',
      key: 'commission',
      render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}</span>,
    },
    // {
    //   title: 'Invited By',
    //   dataIndex: 'invitedBy',
    //   key: 'invitedBy',
    //   render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}</span>,
    // },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      // render: (status: string) => {
      //   let color = status === 'completed' ? 'green' : ( status === 'rejected' ? 'red': '#3dc2d3');
      //   return (
      //     <Tag color={color} key={status}>
      //       {status.toUpperCase()}
      //     </Tag>
      //   );
      // },
    }
  ];

  const exportToExcel = () => {
    if (displayedInvestors) {
      const exportData = displayedInvestors?.map(({ name, email, amount, creationTS, commissionRate, commissionAmount, invitedBy }) => ({
        name,
        email,
        amount,
        creationTS,
        commissionRate,
        commissionAmount,
        invitedBy
      }));
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, 'Investors');
      XLSX.writeFile(wb, 'InvestorsData.xlsx');
    }
  }

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  }

  return (
    <div>
      {loading && (<div style={{ textAlign: 'center' }}> <br /> <Spin size='large' /></div>)}
      {!loading &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', margin: '23px 25px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <Title level={2} style={{ margin: '0px 10px 0px 0px' }}>Onboarding Investors</Title>
              <Tooltip title="Invite Investors"><Button style={{ borderRadius: '18px', fontWeight: 'bold' }} className="custom-plus-button" onClick={() => openInviteModal()}>+</Button>
              </Tooltip>
            </div>
            <div>
              {/* <span style={{ color: '#216484', fontWeight: 'bold', marginRight: '8px' }}>Total Payout &nbsp;&nbsp;<input value={'$000000'} disabled style={{ borderRadius: '8px', border: '1px solid #216484', padding: '5px' }} /></span> */}
              <Tooltip title="Export To Excel">
                <FileExcelOutlined style={{ fontSize: 'xx-large', color: '#216484' }} onClick={exportToExcel} />
              </Tooltip>
            </div>
          </div>
          <Card style={{ borderRadius: '10px', margin: '20px' }}>
            <Table columns={columns} dataSource={investors} scroll={{ x: 'max-content' }} className="table" style={{ borderRadius: '10px', padding: '0px' }} 
               pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalInvites,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50'],
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}/>
          </Card>
        </>
      }
      <Modal
        title={`Invite Investor`}
        okText='SUBMIT'
        cancelText='CANCEL'
        visible={isInviteModalVisible}
        onOk={() => modalForm.submit()}
        onCancel={() => {
          setIsInviteModalVisible(false);
        }}
        okButtonProps={{
          loading: loading,
        }}
      >

        <Form
          form={modalForm}
          autoComplete={'off'}
          {...formItemLayout}
          labelAlign="left"
          onFinish={inviteInvestors}
        >
          <Form.Item label="First Name" name="firstName"
            rules={[{ required: true, message: 'Please input First Name!' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName"
            rules={[{ required: true, message: 'Please input Last Name!' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Email" name="email"
            rules={[{ required: true, message: 'Please input Email!' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="seriesId" label="Select Property" rules={[{ required: true }]}>
            <Select
              placeholder="Select a Property"
              allowClear
              size="large"
              loading={loading}
            >
              {series?.map(series => (
                <Option key={series._id} value={series._id}>{series.propertyName}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Commission" name="commission"
            rules={[{ required: true, message: 'Please input Commission!' },
            { validator: (_, value) => value <= 100 ? Promise.resolve() : Promise.reject('Commission must be less than or equal to 100') }]}>
            <Input type="number" size="large" addonAfter={'%'} max={100} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
         // title={`Invested Properties for ${selectedInvestor?.firstName} ${selectedInvestor?.lastName}`}
        visible={isPropertyModalVisible}
        onCancel={() => setIsPropertyModalVisible(false)}
        footer={null}
        width={800}
      >
        <Table
          columns={propertyColumns}
          dataSource={investedProperties}
          loading={isLoadingProperties}
          pagination={false}
        />
      </Modal>
    </div>
  );
}
