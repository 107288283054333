import { Button, Card, Col,  Form, Input, Modal, Row,  notification } from 'antd';
import React, {  useState } from 'react'
import styles from './investStyle.module.css'
import { AuthService } from '../Auth.service';
import { InvestorService } from '../../Investor/Investor.service';
import { SharedService } from '../Shared.service';
import { environment } from '../../../environments/environment';


const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService()

const InvestModel = ({displayInvestModel, handleCancel,companyData, investmentCompanyId = "",onSubmit }) => {
  const { userInfo } = useUserContext();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = investmentCompanyId !== '' ? investmentCompanyId : userInfo?.company?.id;
    
    const submitForm = async(form) => {
      try {
        setIsLoading(true);
        
        let formData = {...form, investorId:userInfo?._id}
        if(formData.amountInvested?.toString()?.length > 1 && formData.amountInvested?.toString()?.indexOf(",") > -1) {
          formData.amountInvested = parseFloat(formData.amountInvested.toString().replace(/,/g, ''));
        }

        if(isNaN(formData.tokenCount)){
          notification.error({message: 'Amount Invested should be a number only! Type the correct amount again'});
          setIsLoading(false);
          return false;
        }
        
        console.log('formData', formData);
        const response = await investorService.buyTokensWithPaymentDetailsAndCompanyId(companyId, formData)
          if (response.success) {
            notification.success({ message: 'Success' });
            setIsLoading(false);
            setTimeout(() => {
              window.location.reload()
            }, 500);
            handleCancel();
            if (onSubmit) {
              onSubmit();
            }
          }else{
            setIsLoading(false)
            notification.success({ message: response.error.message });
          }
          
      } catch (error) {
        console.log(error);
        setIsLoading(false)
        notification.success({ message: error });
      }
    };  

    const populateAmountInvestInput = (e) => {
      console.log('companyData.pricePerToken:', companyData.pricePerToken);

      const amt = Number((e.target.value).replaceAll(',',''));
      const tokenCount = Number(amt) % Number(companyData.pricePerToken) !== 0 ? (Number(amt)/ Number(companyData.pricePerToken)).toFixed(2) : Number(amt)/ Number(companyData.pricePerToken);
      form.setFieldsValue({ tokenCount: tokenCount });

      const formattedValue = sharedService.formatIndianCurrency(e.target.value);
      console.log(`formattedValue: ${formattedValue}`);
      form.setFieldsValue({ amountInvested: formattedValue });
    }
  

  return (
    <Modal
      title="Invest Now"
      visible={displayInvestModel}
      width="70%"
      onCancel={handleCancel}
      footer={null}
    >
      <Row justify="center">
        <Col span={23} style={{ textAlign: "center" }}>
          <Card>

            <div className="model-wrapper" style={{display:'grid',gridTemplateColumns:'1fr 1fr',gap:'15px'}}>
              
              <div className={styles.llpBankInfo}>
                <Row>
                <Col span={24}><h3>LLP Account Details</h3></Col>
                  <Col span={24} className={styles.label}>Account Holder Name:</Col>
                  <Col span={24} className={styles.value}>{companyData?.bankData?.accountHolder ?? 'NA'}</Col>
                  <Col span={24} className={styles.label}>Bank Name:</Col>
                  <Col span={24} className={styles.value}>{companyData?.bankData?.bankName ?? 'NA'}</Col>
                  <Col span={24} className={styles.label}>Branch Name:</Col>
                  <Col span={24} className={styles.value}>{companyData?.bankData?.branchName ?? 'NA'}</Col>
                  <Col span={24} className={styles.label}>IFSC CODE:</Col>
                  <Col span={24} className={styles.value}>{companyData?.bankData?.IFSCCode ?? 'NA'}</Col>
                  <Col span={24} className={styles.label}>Account Number:</Col>
                  <Col span={24}className={styles.value}>{companyData?.bankData?.accountNo ?? 'NA'}</Col>
                </Row>
              </div>
              
              <div hidden={false}>
                <Form form={form} onFinish={submitForm}
                labelCol={{ span: 23 }}
                wrapperCol={{ span: 23 }}
                layout="vertical"
                >
                  <Form.Item
                    label="Amount to Invest"
                    name="amountInvested"
                    rules={[
                      { required: true, message: "Please enter the amount to invest" },
                    ]}
                    style={{ marginBottom: "10px" }}
                  >
                  <Input
                      onChange={(e) => populateAmountInvestInput(e)}
                      addonBefore={environment.currencySign}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Token Count"
                    name="tokenCount"
                    rules={[
                      { required: true },
                    ]}
                    style={{ marginBottom: "10px" }}
                  >
                  <Input
                      disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="Bank Name"
                    name="bankName"
                    rules={[
                      { required: true, message: "Please enter the bank name" },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="Account Number"
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the account number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="IFSC Code"
                    name="ifscCode"
                    rules={[
                      { required: true, message: "Please enter the IFSC code" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="Account Name"
                    name="accountName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the account name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="Transaction ID"
                    name="txnId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the transaction ID",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item  wrapperCol={{ offset: 12, span: 12 }} style={{ textAlign: "right", marginTop:'12px' }}>
                      <Button size='large' type='primary' htmlType="submit" loading={isLoading} >Submit</Button>
                  </Form.Item>
                </Form>
              </div>

            </div>

          </Card>
        </Col>
      </Row>
    </Modal>
  );
}

export default InvestModel