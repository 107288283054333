import React from 'react'
import {Link,useParams,useLocation} from 'react-router-dom'
import './builder.scss'
import poly1 from '../../../assets/images/builders/poly1.png';
import poly2 from '../../../assets/images/builders/poly2.png';
import poly3 from '../../../assets/images/builders/poly3.png';
import poly4 from '../../../assets/images/builders/poly4.png';

export const Menuiba = () =>{
    const location = useLocation();
    console.log(location)
    
    return (
        <div className="container menu-iba">
            <div className="row g-4">
                <div className="col-12 col-md-4">
                    <span className='d-block'>REAL ESTATE</span>
                    <Link to="/how-it-works/investor-hiw">
                        <span className={location.pathname?.split('/')[2] =='investor-hiw' || location.pathname.split('/')?.length < 3  ? 'menu-iba-active' : '' + ` label`}>INVESTOR</span>
                    </Link>
                </div>
                <div className="col-12 col-md-4">
                <span className='d-block'>REAL ESTATE</span>
                    <Link to="/how-it-works/builder">
                        <span className={location.pathname.split('/')[2] =='builder' ? 'menu-iba-active' : '' + ` label`} style={{color:'#27AE60'}}>BUILDER</span>
                    </Link>
                </div>
                
                <div className="col-12 col-md-4">
                <span className='d-block'>REAL ESTATE</span>
                    <Link to="/how-it-works/agent">
                        <span className={location.pathname.split('/')[2] =='agent' ? 'menu-iba-active' : '' + ` label`}>AGENT</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

// export const JoinUs = () =>{
//     return (
//         <div className="container mb-5" style={{marginTop:'150px'}}>
//             <div className="row">
//                 <div className="col-12 col-md-6 offset-md-3 text-center">
//                     <span className='text-dark'>Join the</span>
//                     <h3 className='my-1 text-dark'>VruddhiX Club</h3>
//                     <span className='d-block text-dark'>Stay up to date to learn more about our next big steps:</span>
//                     <button style={{backgroundColor:'#27AE60',color:'white'}} className='btn mt-3'>Join Us in Making a Difference.</button>
//                 </div>
//             </div>
//         </div>
//     )
// }

const Builder = () => {

    const builderCardsArr=[
        {
            img:poly1,
            header:'Exclusive Onboarding Experience',
            sub:'Initiate your digitalization journey with an exclusive onboarding experience.',
            detail:`Our user-centric process is designed to accommodate the unique needs of builders, guiding you effortlessly through the integration of your properties into our advanced digital ecosystem.`
        },
        {
            img:poly2,
            header:'Stringent KYC Verification',
            sub:'Integrity within our secure environment',
            detail:`Upholding the highest standards of security, a rigorous Know Your Customer (KYC) verification process is carried out. Real Estate Builders are invited to submit the requisite documentation for meticulous verification, establishing a foundation of trust and integrity within our secure environment.`
        },
        {
            img:poly3,
            header:'Fortified Blockchain Infrastructure',
            sub:'Secure and trusted environment for real estate builders',
            detail:`Immerse your properties in a fortified blockchain infrastructure, safeguarding their integrity in the digital landscape. Blockchain technology serves as the bedrock, ensuring indisputable records, transparent transactions, and an immutable ownership history creating a secure and trusted environment for real estate builders and investors alike.`
        },
        {
            img:poly4,
            header:'Collaborative Networking',
            sub:'Amplified Market Presence and Collaborative Networking',
            detail:`Elevate your properties with amplified market visibility on our distinguished platform, attracting a discerning audience of pot  ential investors. Engage in a sophisticated collaborative ecosystem provided, where real estate builders can seamlessly network with investors, agents, and industry stakeholders to foster growth and strategic business expansion.`
        },
    ];
    
  return (
    <>
    <Menuiba/>
    <div className="container builder-container">
        <div className="row builder-row ">
        { builderCardsArr.map((card,i )=>
                <div key={i} className="col-12 col-md-3 builder-col">
                    <span className='marker'>{i+1}
                    <span className='marker-line'></span>
                    </span>
                    <div className="builder-card-warpper">
                        <img src={card.img} alt="Engage" />
                        <div className="builder-card-info text-center">
                            <h4 className='mt-3 mb-2' style={{color:'#27AE60'}}>{card.header}</h4>
                            <span className='mb-3 text-dark' style={{fontWeight:500}}>{card.sub}</span>
                            <p className='text-dark p-3' style={{fontSize:'13px'}}>
                                {card.detail}
                            </p>
                        </div>
                    </div>
                </div>
        )}
        </div>
    </div>
    {/* <JoinUs/> */}
   </>
  )
}

export default Builder