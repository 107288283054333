import React, {
  useState,
  useEffect,
  Fragment,
  useCallback
} from "react";
import {
  Card,
  Input,
  Button,
  Row,
  Col,
  Form,
  Typography,
  Result,
  Checkbox,
  List,
  message, notification, Spin,
} from "antd";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  filter,
} from "rxjs/operators";
import { from, Observable } from "rxjs";
import { useEventCallback } from "rxjs-hooks";
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
  Rule144Config,
  User,
  RegLaunched, TokenTypeAndDetails,MasterCompanyDetails,
} from "../../../Shared/interfaces";
import { useParams } from 'react-router-dom';
import { SharedService } from "../../../Shared/Shared.service";
import { TokenConfigurationService } from "../../TokenConfiguration.service";
import { SecurityTokenService } from "../../../Shared/SecurityToken/SecurityToken.service";
import { SecurityTokenRegistryService } from "../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import {LLPFactoryService } from "../../../Shared/LLPFactory/LLPFactory.service";
import MainFacet from "../../../Shared/SecurityToken/Facets/MainFacet/index";
import Rule144Facet from "../../../Shared/SecurityToken/Facets/Rule144Facet/index";
import ProcessModal from "../../../Shared/TransactionModal/ProcessModel";
import BigNumber from "bignumber.js";
import moment from "moment";
import {TrustedForwarderService} from "../../../Shared/TrustedForwarder.service";
import TxFeeDelegationModal from "../../../Shared/TxFeeDelegationModal";
import MasterCompanyService from "../../../MasterCompany/MasterCompany.service";
import TransactionModal from "../../../Shared/TransactionModal";
import { IssuerSuperAdminService } from "../../../IssuerSuperAdmin/IssuerSuperAdmin.service";
import MarketplacePropertyCard from "../../../../layout/marketplacePropertyCard/MarketplacePropertyCard";
const { Text, Title } = Typography;
const { TextArea } = Input;

const securityTokenRegistryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenService = new SecurityTokenService();
const rule144Facet = new Rule144Facet();
const mainFacet = new MainFacet();
const trustedForwarderService = new TrustedForwarderService();
const masterCompanyService = new MasterCompanyService();
const llpFactoryService = new LLPFactoryService();
const etherscanURL = sharedService.etherscanURL;
const issueSuperAdminService = new IssuerSuperAdminService();

const ReviewComponent = ({
  tokenConfigurationProcess,
  userInfo,
  _submitTokenConfigForReview,
  isserCounselCheckBoxesForm,
  _reviewFinalTokenConfig,
  submitting,
  isserCounselCheckBoxes,
  setComments,
  comments,
  rejecting,
  verifiying,
}) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
  }
  return (
    <>
      {
        userInfo.role === "issuer token admin" && ["token creation"].includes(tokenConfigurationProcess.status) && (
          <>
            {tokenConfigurationProcess.allCheckboxesSelected && (
              <Result
                title=""
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    loading={submitting}
                    onClick={_submitTokenConfigForReview}
                  >
                    Submit Configuration for Review
                  </Button>,
                ]}
              />
            )}
          </>
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer super admin" && userInfo.role !== "issuer super admin" && (
          <Result title="LLP Super Admin is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer super admin" && (
          <Result
            status="error"
            title="LLP Super Admin rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer token admin" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
            extra={[
              userInfo.role === "issuer token admin" ? (
                <Button
                  type="primary"
                  key="console"
                  loading={submitting}
                  onClick={_submitTokenConfigForReview}
                >
                  Submit Configuration for Review
                </Button>
              ) : null,
            ]}
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer compliance admin" && userInfo.role !== "issuer compliance admin" && (
          <Result title="LLP Compliance Admin is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer compliance admin" && (
          <Result
            status="error"
            title="LLP Compliance Admin rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer super admin" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer counsel" && userInfo.role !== "issuer counsel" && (
          <Result title="Property Company Counsel is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer counsel" && (
          <Result
            status="error"
            title="Property Company Counsel rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer compliance admin" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "verified" && (
          <Result
            status="success"
            title="Token Configuration has been approved by LLP Super Admin, LLP Compliance Admin"
          />
        )
      }
      {
        userInfo.role === "issuer counsel" && tokenConfigurationProcess?.regulation === "regulation-s" && ["pending issuer counsel"].includes(tokenConfigurationProcess?.status as string) && (
          <>
            <Title level={3} style={{ textAlign: "center", color: "blue" }}>
              Requirement for Property Company Counsel under Reg S
            </Title>
            <Form
              form={isserCounselCheckBoxesForm}
              labelAlign="right"
              wrapperCol={{ xs: { span: 24 }, sm: { offset: 2 } }}
            >
              {
                isserCounselCheckBoxes?.map((checkbox, index) => (
                  <Fragment key={index}>
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      valuePropName="checked"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                      name={checkbox.name}
                    >
                      <Checkbox style={{ color: "blue" }}>
                        <span style={{ color: "blue" }}>{checkbox.label}</span>
                      </Checkbox>
                    </Form.Item>
                  </Fragment>
                ))
              }
            </Form>
          </>
        )
      }
      {console.log(userInfo.role, tokenConfigurationProcess.status)}
      {
        ((userInfo.role === "issuer super admin" &&
          [
            "pending issuer super admin",
            "rejected issuer compliance admin",
          ].includes(tokenConfigurationProcess?.status as string)) ||
          (userInfo.role === "issuer compliance admin" &&
            [
              "pending issuer compliance admin",
              "rejected issuer compliance admin",
            ].includes(tokenConfigurationProcess?.status as string)) ||
          (userInfo.role === "issuer counsel" &&
            ["pending issuer counsel"].includes(
              tokenConfigurationProcess?.status as string
            ))) && (
          <>
            <h2>Comments</h2>
            <TextArea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></TextArea>
            <br />
            <br />
            <Checkbox value={checked} onChange={onChange}> I understand and agree to safe harbor </Checkbox>
            <br />
            <br />
            <Button
              key="reject"
              danger
              disabled={verifiying}
              loading={rejecting}
              onClick={() => _reviewFinalTokenConfig("rejected")}
            >
              Reject
            </Button>
            <Button
              key="approve"
              type="primary"
              style={{
                marginLeft: "5px"
              }}
              disabled={rejecting}
              loading={verifiying}
              onClick={() => {
                if (!checked) {
                  message.info("Please agree to safe harbor")
                  return;
                }
                _reviewFinalTokenConfig("verified")
              }}>
              Approve
            </Button>
          </>
        )
      }
    </>
  );
};

export default ({
  reserveTokenSymbol,
  selectedWallet,
  networkId,
  symbolDetailsAndSTData,
  updateSymbolDetailsAndSTData,
  tokenConfigurationProcess,
  userInfo,
  submitTokenConfigForReview,
  reviewFinalTokenConfig,
  submitCheckboxesStatus,
  isRelaunch,
}: {
  reserveTokenSymbol;
  selectedWallet: string | undefined;
  networkId: string | undefined;
  symbolDetailsAndSTData: SymbolDetailsAndSTData | undefined;
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  updateSymbolDetailsAndSTData;
  userInfo: User;
  submitTokenConfigForReview;
  reviewFinalTokenConfig;
  submitCheckboxesStatus;
  isRelaunch: boolean;
}) => {
  const [form] = Form.useForm();
  const [checkBoxesForm] = Form.useForm();
  const [isserCounselCheckBoxesForm] = Form.useForm();
  const [transactions, setTransactions] = useState<{ submitting?: boolean; receipt?: any; details: string }[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tokenSymbolRulesValid, setTokenSymbolRulesValid] = useState(false);
  const [chekingAvailability, setChekingAvailability] = useState<boolean>(false);
  const [rule144ConfigInitialized, setRule144ConfigInitialized] = useState<boolean>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [updatingSymbolDetailsAndSTData, setUpdatingSymbolDetailsAndSTData] = useState<boolean>();
  const [symbolRegEvent, setSymbolRegEvent] = useState<any>();
  const [expiryDateCountDown, setExpiryDateCountDown] = useState<any>();
  const [loadingRule144ConfigInitialized, setLoadingRule144ConfigInitialized] = useState<boolean>();
  const [comments, setComments] = useState("");
  const [verifiying, setVerifiying] = useState<boolean>();
  const [rejecting, setRejecting] = useState<boolean>();
  const [issuerQuestionAnswer, setIssuerQuestionAnswer] = useState<any[]>();
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>([]);

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [txParams, setTxParams] = useState<any>();
  const [masterCompanyDetails, setMasterCompanyDetails] = useState<MasterCompanyDetails>();
  const [isNotReserved, setIsNotReserved] = useState<boolean>(true);
  const [isReservedAndNotDeployed, setIsReservedAndNotDeployed] = useState<boolean>()
  const [usertokenSymbol, setUserTokenSymbol] = useState<any>();
  const [tokenAddress, setTokenAddress] = useState<any>();
  const [creationTime, setCreationTime] = useState<any>();
  const [isDeployed, setIsDeployed] = useState<any>();
  const [transactinModelVisible,setTransactionModelVisible] = useState<boolean>(false);
  const [tokenSymbolReservationPeriod, setTokenSymbolReservationPeriod] = useState<number>();
  const [tokenSymbolData, setTokenSymbolData] = useState("")
  const [intialLoading, setIntialLoading] = useState(true)
  const isserCounselCheckBoxes = [
    { label: "Completion of safe harbor", name: "completion of safe harbor" },
    { label: "Due diligence", name: "due diligence" },
    { label: "Disclosure drafting", name: "disclosure drafting" },
    { label: "Preparation of transaction documentation", name: "preparation of transaction documentation", },
  ];
  const tokenConfigCheckboxes = [
    { label: "TOKEN TYPE & DETAILS", name: "tokenTypeAndDetails" },
    // { label: "PHASE", name: "phase" },
    { label: "TOKEN SYMBOL", name: "tokenSymbol" },
  ];
 
  const approvalWorkflowCheckboxes = [
    {label: 'LLP Setup Approval Workflow', value:'llpSetupApprovalWorkflow'},
    {label: 'Property Addition Approval Workflow', value:'propertyAdditionApprovalWorkflow'},
    {label: 'Bank Escrow Approval Workflow', value:'bankEscrowApprovalWorkflow'},
    //{label: 'Token Approval Workflow', value:'tokenApprovalWorkflow'},
    {label: 'Due Diligence Approval Workflow', value:'dueDiligenceApprovalWorkflow'},
    //{label: 'Deed Approval Workflow', value:'deedApprovalWorkflow'},
    //{label: 'Crypto Escrow Approval Workflow', value:'cryptoEscrowApprovalWorkflow'},
    //{label: 'Investment Workflow - Bank', value:'investmentWorkflowBank'},
    //{label: 'Investment Workflow - Digital Currency', value:'investmentWorkflowDigitalCurrency'},
  ]

  const propertyDocumentCheckboxes = [
    //{label: 'Sales Deed', value:'salesDeed'},
  ]

  const { configType, tokenConfigurationProcessId } =
  useParams<{ configType: string; tokenConfigurationProcessId: string }>();

  const [propertyData, setPropertyData] = useState<any>(null);
  const [bankInfo, setBankInfo] = useState<any>(null);
  const precision = securityTokenService.precision;

  const isRegRelaunched = regulationsLaunched.some(
    (regLaunched) =>
      tokenConfigurationProcess?.regulation === sharedService.regOptions.find(
        (regOp) =>
          regOp.shortValue === regLaunched.regulation &&
          regLaunched.creationTS.toString() ===
          tokenConfigurationProcess?.creationTS.toString()
      )?.value
  );

  const [setTokenSymbol, isTokenSymbolAvailable] = useEventCallback(
      (tokenSymbol$: Observable<string>) =>
        tokenSymbol$.pipe(
          tap(() => setTokenSymbolRulesValid(false)),
          filter((term) => !!term && !!term.trim() && term.length <= 10),
          tap(() => setTokenSymbolRulesValid(true)),
          tap(() => setChekingAvailability(true)),
          debounceTime(600),
          distinctUntilChanged(),
          switchMap((term) =>
            from(
              llpFactoryService.tokenSymbolAvailable(
                term.toUpperCase(),
                (masterCompanyDetails?.contractAddress) ? masterCompanyDetails?.contractAddress as string : (sessionStorage.getItem("masterCompanyDetailsContractAddress") as string)
              )
            )
          ),
          tap(() => setChekingAvailability(false))
        ),
  );

  
  useEffect(()=>{
    (async()=> {
      const _masterCompanyDetails : MasterCompanyDetails = (await tokenConfigurationService.getMasterCompanyDetails()).data
      setMasterCompanyDetails(_masterCompanyDetails);
      console.log("_masterCompanyDetails: ", _masterCompanyDetails);
      sessionStorage.setItem("masterCompanyDetailsContractAddress", _masterCompanyDetails?.contractAddress);
    })();
  },[])
  
  useEffect(() => {
    (async () => {
      if (!tokenConfigurationProcess) return;
      console.log('tokenConfiguration:', tokenConfigurationProcess);

      if(tokenConfigurationProcess?.tokenSymbol && tokenConfigurationProcess.tokenSymbol.length > 1) {
        setTokenSymbol(tokenConfigurationProcess?.tokenSymbol);
        if(tokenConfigurationProcess?.tokenSymbolReservationPeriod) {
          setTokenSymbolReservationPeriod(parseInt(tokenConfigurationProcess?.tokenSymbolReservationPeriod));
        }
        setIsNotReserved(false);
        setSymbolRegEvent({ transactionHash: "" });
        setIsReservedAndNotDeployed(true)
        setUpdatingSymbolDetailsAndSTData(false);
        setIsModalVisible(false);
        setTransactionModelVisible(false);
      }
      // const symbolRegistrationEvents = await securityTokenRegistryService.getSymbolRegistrationEvent(
      //   symbolDetailsAndSTData.symbolDetails.owner as string,
      //   symbolDetailsAndSTData?.symbolDetails.registrationDate as string
      // );
      // setSymbolRegEvent(symbolRegistrationEvents[0]);
      // if (!symbolDetailsAndSTData.symbolDetails.isDeployed) return;
      // setLoadingRule144ConfigInitialized(true);
      // const [_rule144ConfigInitialized, _regulationsLaunched,] = await Promise.all([
      //   rule144Facet.rule144ConfigInitialized(
      //     // symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     ""
      //   ),
      //   mainFacet.getRegulationsLaunched(
      //     // symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ""
      //   ),
        
      // ]);
      // setRule144ConfigInitialized(_rule144ConfigInitialized);
      // setRegulationsLaunched(_regulationsLaunched);
      // setLoadingRule144ConfigInitialized(false);
    })();
  }, [tokenConfigurationProcess]);

  // useEffect(() => {
  //   // if (!symbolDetailsAndSTData || !_isReservedAndNotDeployed) return;
  //   // const timer = sharedService.countdown(
  //   //   +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000 - Date.now(),
  //   //   (displayable, finished: boolean) => {
  //   //     setExpiryDateCountDown(displayable);
  //   //   }
  //   // );
  //   // return () => {
  //   //   if (timer) clearInterval(timer);
  //   // };
  // }, []);

  useEffect(() => {
    (async () => {
      const _issuerQuestionAnswer: any[] = (
        await tokenConfigurationService.getIssuerQuestionAnswer()
      ).data;
      setIssuerQuestionAnswer(_issuerQuestionAnswer);
      _issuerQuestionAnswer.forEach((checkbox) => {
        checkBoxesForm.setFieldsValue({
          [checkbox.question]: checkbox.isAnswered,
        });
      });
      tokenConfigCheckboxes.forEach((checkbox) => {
        checkBoxesForm.setFieldsValue({ [checkbox.name]: true });
      });

      const tokenAddress = await tokenConfigurationService.getLLPAddress(userInfo.company?.id);
      // console.log("token_address:", tokenAddress);
      if(tokenAddress.data?.deployedSmartContractAddress && tokenAddress.data?.deployedSmartContractAddress != "" ) {
        setIsDeployed(true);
        setTokenAddress(tokenAddress.data.deployedSmartContractAddress);
        setCreationTime(tokenAddress.data.creationTS);
      }
      const tokenConfigRes = await tokenConfigurationService.getTokenConfigurationProcessById({ tokenConfigurationProcessId });
      console.log("sss: ", tokenConfigRes);

      approvalWorkflowCheckboxes.forEach((checkbox,index) => {
        checkBoxesForm.setFieldsValue({
          [checkbox.value]:  index < 3
        });
      });
      
      setUserTokenSymbol(tokenConfigRes?.data?.tokenSymbol);     
      getPropertyData();
      getBankData();  
    })();
  }, []);
 
  const getBankData = async ()=> {
    try {
      const response = await issueSuperAdminService.getBankInfoByCompanyId(userInfo.company?.id)
      if(response.data){
        setBankInfo(response.data)
      }
      console.log('bank info here ...,',response)
    } catch (error) {
      console.log(error);
    }  
  }

  const getPropertyData = async()=>{
    try {
      const response = await issueSuperAdminService.getPropertyDetails(userInfo.company?.id)
      console.log('property data is...', response)
      if(response.data){
        setPropertyData(response.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const minifyTxHash = (hash: string) => {
    if (!hash) return;
    return sharedService.minifyTxHash(hash);
  };

  const minifyAddress = (hash: string) => {
    return sharedService.minifyAddress(hash);
  };

  const formatDate = (unFormatedDate: string) => {
    return moment(new Date(+unFormatedDate)).format("LL");
  };

  const openTxFeeDelegationModal = async(formValue) => {
    setTxParams(formValue);
    setIsDelegationModalVisible(true);
  }

  const _reserveTokenSymbol = async (prop: {delegate: boolean}) => {
    // if (!isTokenSymbolAvailable) return;

    setIsModalVisible(true);
    setTransactions([{ details: "Reserving Token Symbol", submitting: true }]);
    console.log("txn : ", txParams)
    setUserTokenSymbol(txParams.tokenSymbol);
    try {
      const receipt = await reserveTokenSymbol({...txParams, delegate: prop.delegate});
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = true;
        current[0].receipt = "";
        return current;
      });
      setSymbolRegEvent({ transactionHash: "" });
    } catch (err) {
      console.error(err);
    }
    // setTransactions((prev) => {
    //   const current = JSON.parse(JSON.stringify(prev));
    //   current[0].submitting = true;
    //   return current;
    // });
  };

  const createToken = async (prop: {delegate: boolean}) => {

    setTransactionModelVisible(true)
    setTransactions([
      { details: "Creating your Security Token", submitting: true },
    ]);
    try {

      const [
        admins,
        rule144Res,
        typeAndDetailsRes,
        issuersWalletsRes,
        masterCompany
      ] = await Promise.all([
        tokenConfigurationService.getAllAdminsWallets(userInfo.company?.id as string),
        tokenConfigurationService.getRule144Config(
          tokenConfigurationProcess?.rule144ConfigId as string,
          userInfo.company?.id as string
        ),
        tokenConfigurationService.getTokenTypeAndDetails(tokenConfigurationProcess?.tokenTypeAndDetailsId as string, userInfo.company?.id as string),
        tokenConfigurationService.getIssuersWallets(),
        tokenConfigurationService.getMasterCompanyDetails()
      ]);

      const _monthUnit = rule144Res?.data?.lockPeriod;
      const issuersWallets: string[] = issuersWalletsRes.data;
      const totalinvestors = typeAndDetailsRes?.data?.totalInvestors;
      
      console.log("master company details : ", masterCompany.data.contractAddress);
      console.log("company_id : ", userInfo.company?.id);

      console.log("admins : ", admins.data);
      console.log("admins : ", admins.data.llpSuperAdminWalletAddress);
      // data.llpTokenAdminWalletAddress
      console.log("totalinvestors : ", totalinvestors);
      console.log("issuer wallet : ", issuersWallets);
      console.log("wallet : ", selectedWallet);
      console.log("tokename : ", txParams.tokenName);
      console.log("symbol : ", usertokenSymbol);
      console.log("lock : ", _monthUnit);

       const receipt0 =
        await llpFactoryService.generateNewSecurityToken(
          txParams.tokenName,
          usertokenSymbol,
          _monthUnit as number,
          masterCompany.data?.contractAddress, // LLP Factory address 
          totalinvestors,
          "0x0000000000000000000000000000000000000000",
          [],
          admins.data.llpSuperAdminWalletAddress?.split(','),
          admins.data.llpComplianceAdminWalletAddress?.split(','),
          admins.data.llpTokenAdminWalletAddress?.split(',')
        );

      console.log("call result : ", receipt0);  
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].receipt = receipt0;
        return current;
      });

      console.log("ss", receipt0.events.NewLLP.returnValues[2]);
      if (!receipt0.status) return;

      await tokenConfigurationService.saveLLPAddress({
        deployedSmartContractAddress: receipt0.events.NewLLP.returnValues[2],
        companyId: userInfo.company?.id
      });

      await tokenConfigurationService.saveLLPSymbol({
        tokenSymbol: usertokenSymbol,
        companyId: userInfo.company?.id
      });

      //  setRule144ConfigInitialized(true);
    } catch (err) {
      console.error(err);
      setIsDeployed(false);
    }

    setTransactions((prev) => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach((transaction) => (transaction.submitting = false));
      return current;
    });
    setIsDeployed(true)
  };

  const saveRule144Config = async (prop: {delegate: boolean}) => {
    // console.log(await ownershipFacet.owner(symbolDetailsAndSTData?.securityTokenData.contractAddress as string));

    setIsModalVisible(true);
    setTransactions([{ details: "Saving Token Rule Configuration", submitting: true }]);
    try {
      const decimals = symbolDetailsAndSTData?.securityTokenData.decimals as string;
      const response = await tokenConfigurationService.getRule144Config(
        tokenConfigurationProcess?.rule144ConfigId as string,
        userInfo.company?.id as string
      );
      if (!response.success) {
        setTransactions((prev) => {
          const current = JSON.parse(JSON.stringify(prev));
          current[0].submitting = false;
          return current;
        });
        return;
      }
      const _rule144Config: Rule144Config = response.data;
      // const receipt = await rule144Facet.saveRule144Config(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      //   selectedWallet as string,
      //   new BigNumber(_rule144Config.maxAffiliateSellPercentage)
      //     .times(new BigNumber(10).pow(precision))
      //     .toFixed(0),
      //   _rule144Config.maxAffiliateAmountMonths,
      //   new BigNumber(_rule144Config.maxAffiliateAmount).toFixed(0),
      //   _rule144Config.maxAffiliateTokensMonths,
      //   new BigNumber(_rule144Config.maxAffiliateTokens)
      //     .times(new BigNumber(10).pow(decimals))
      //     .toFixed(0),
      //   _rule144Config.lockPeriod,
      //   {delegate: prop.delegate}
      // );
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = "";
        return current;
      });
      // if (receipt.status) 
      setRule144ConfigInitialized(true);
    } catch (err) {
      console.error(err);
    }
    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const relaunchToken = async (prop: {delegate: boolean}) => {
    setIsModalVisible(true);
    setTransactions([{ details: "Relaunching Token", submitting: true }]);
    try {
      const shortRegValue = sharedService.regOptions.find(
        (regOp) => regOp.value === tokenConfigurationProcess?.regulation
      )?.shortValue as string;



      const res = await tokenConfigurationService.getTokenTypeAndDetails(tokenConfigurationProcess?.tokenTypeAndDetailsId as string, userInfo.company?.id as string);
      const _typeAndDetails: TokenTypeAndDetails = res.data;

      // const receipt = await mainFacet.relaunchToken(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      //   selectedWallet as string,
      //   {
      //     regulation: shortRegValue,
      //     regDTransferableOutsideUSA: tokenConfigurationProcess?.regDTransferableOutsideUSA as boolean,
      //     creationTS: tokenConfigurationProcess?.creationTS as number,
      //     typeOfSecurity: tokenConfigurationProcess?.typeOfSecurity as string,
      //     commonStock: _typeAndDetails.stock || '',
      //   },
      //   {delegate: prop.delegate}
      // );
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = "";
        return current;
      });
      // if (receipt.status)
        setRegulationsLaunched((prev) => {
          const current = sharedService.clone(prev) as RegLaunched[];
          current.push({
            regulation: shortRegValue,
            dateLaunched: Math.floor(Date.now() / 100).toString(),
            regDTransferableOutsideUSA: tokenConfigurationProcess?.regDTransferableOutsideUSA as boolean,
            creationTS: tokenConfigurationProcess?.creationTS.toString() as string,
            index: current.length.toString(),
            legendRemoved: false,
            typeOfSecurity: tokenConfigurationProcess?.typeOfSecurity as string,
            commonStock: _typeAndDetails.stock
          });
          return current;
        });
    } catch (err) {
      console.error(err);
    }
    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const _reviewFinalTokenConfig = async (status: "verified" | "rejected") => {
    try {
      if (
        userInfo.role === "issuer counsel" &&
        tokenConfigurationProcess?.regulation === "regulation-s" &&
        ["pending issuer counsel"].includes(
          tokenConfigurationProcess?.status as string
        )
      ) {
        await isserCounselCheckBoxesForm.validateFields();
      }
      if (status === "verified") setVerifiying(true);
      else setRejecting(true);
      await reviewFinalTokenConfig(status, comments);
      if (status === "verified") setVerifiying(false);
      else setRejecting(false);
    } catch (err) {
      console.error(err);
    }
  };

  const _submitTokenConfigForReview = async () => {
    setSubmitting(true);
    await submitTokenConfigForReview();
    setSubmitting(false);
  };

  const _submitCheckboxesStatus = async () => {
    //if (issuerQuestionAnswer?.find((checkbox) => !checkbox.isAnswered)) return;
    setSubmitting(true);
    await submitCheckboxesStatus();
    setSubmitting(false);
    window.location.reload();
  };



  // const saveCheckboxesStatus = async() => {
  //   const response = await tokenConfigurationService.saveCheckboxesStatus({checkboxes: checkBoxesForm.getFieldsValue()});
  //   if (!response.success) console.error(response.error.message);
  // }

  // const isNotReserved = !symbolDetailsAndSTData || (!symbolDetailsAndSTData.symbolDetails.isDeployed && Date.now() > +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000);
  // const isReservedAndNotDeployed = symbolDetailsAndSTData && !symbolDetailsAndSTData.symbolDetails.isDeployed && Date.now() <= +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000;
  // const isDeployed = symbolDetailsAndSTData?.symbolDetails.isDeployed;

  const closeModal = async () => {
    setUpdatingSymbolDetailsAndSTData(true);
    // if (tokenConfigurationProcess?.tokenSymbol) 
    // await updateSymbolDetailsAndSTData();
    setIsNotReserved(false)
    setIsReservedAndNotDeployed(true)
    setUpdatingSymbolDetailsAndSTData(false);
    setIsModalVisible(false);
    setTransactionModelVisible(false);
    window.location.reload();
  };

  useEffect(() => {
    getTokenSymbol();
  }, [tokenSymbolData]);

const getTokenSymbol = async () => {
  try {
  const response = await tokenConfigurationService.getTokenTypeAndDetails(
    tokenConfigurationProcess?.tokenTypeAndDetailsId as string,
    userInfo.company?.id
  );
  setTokenSymbolData(response?.data?.tokenSymbol)
  setIntialLoading(false)
  } catch (error) {
    console.log(error);
  }
}

  if (intialLoading) {
    return <>Loading...</>
  }

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        {
          isNotReserved && !isDeployed && userInfo.role === "issuer token admin" &&   (
            <Col span={16}>
              <h1>Reserve Your Token Symbol</h1>
              <h5>
                Your token symbol will be reserved {/*for <b>15 days</b>*/}, and is
                permanently yours once you create your Token. This reservation
                ensures that no other organization can create a token symbol
                identical to yours using CCAP platform.
              </h5>
              <Card style={{ borderColor: "darkgrey" }} bordered={false}>
                <Form onFinish={openTxFeeDelegationModal} form={form} initialValues={{
                  tokenSymbol: tokenSymbolData
                }}>
                  <h2>Enter Token Symbol</h2>
                  <Form.Item
                    name="tokenSymbol"
                    validateStatus={
                      !tokenSymbolRulesValid
                        ? ""
                        : chekingAvailability
                          ? "validating"
                          : !isTokenSymbolAvailable
                            ? "success"
                            : "error"
                    }
                    help={
                      !tokenSymbolRulesValid
                        ? null
                        : chekingAvailability
                          ? "validating"
                          : !isTokenSymbolAvailable
                            ? "Symbol available. Reserve NOW!"
                            : "Symbol already reserved"
                    }
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                        whitespace: true,
                      },
                      {
                        max: 10,
                        message: "Up to 10 characters",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setTokenSymbol(e.target.value)} />
                  </Form.Item>

                  <Form.Item
                      label="For how many days token symbol must be reserved"
                      name="tokenSymbolReservationPeriod"
                      rules={[
                        { required: true, message: 'Please input tokenSymbolReservationPeriod!' }]}
                  >
                      <Input type='number' min={1} max={180} onChange={(e) => setTokenSymbolReservationPeriod(parseInt(e.target.value))} addonAfter="days" />
                  </Form.Item>

                  <h3>
                    <b>Issuer ETH Address</b>
                  </h3>
                  <Input
                    disabled
                    value={selectedWallet}
                    style={{ textAlign: "center" }}
                  />
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: "3%" }}
                    >
                      Reserve Token Symbol
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          )
         }

        {/* This is all admin approvals flow. If a token created by token admin, it needs to get approved by issuer super amdin, issuer company office, issuer cousel.
        But here as we are deploying while submiting the token in a single step, so this steps are not required at all. */}
        {/* This commetented out code can be changed in future if required. */}
        {
          isReservedAndNotDeployed && !isDeployed &&(
            <Col span={16}>
              <Card bordered={false} >
                <Row>
                  {
                    userInfo.role !== "issuer token admin" && (
                      <Col span={16}>
                        <Title>Approve Security Token</Title>
                        <Text>
                          Approve the Security token before the token reservation
                          expires. If you let the token reservation expire, the
                          token symbol selected will be available for others to
                          claim.
                        </Text>
                      </Col>
                    )
                  }

                  {
                    userInfo.role === "issuer token admin" && (
                      <Col span={16}>
                        <Title style={{fontSize:'1.3rem', color:'#08a3edf0'}}> Create your Security Token</Title>
                        {/* <Text>
                          Create your security token before your token reservation
                          expires. If you let your token reservation expire, the
                          token symbol you selected will be available for others to
                          claim.
                        </Text> */}
                      </Col>
                    )
                  }

                  {/* <Col push={2} span={6}>
                    <Card
                      bordered={false}
                      className="card-shadow"
                      headStyle={{ backgroundColor: "steelblue", color: "white" }}
                      title="Time left"
                    >
                      {
                        expiryDateCountDown && (
                          <>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.days}
                              </span>{" "}
                              days
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.hours}
                              </span>{" "}
                              hours
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.minutes}
                              </span>{" "}
                              minutes
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.seconds}
                              </span>{" "}
                              seconds
                            </p>
                          </>
                        )
                      }
                    </Card>
                  </Col> */}

                  <Col span={24}>
                    {/* {
                      !tokenConfigurationProcess?.legendCreationFile && (
                        <Result title="Legend not created by Property Company Counsel" />
                      )
                    }
                    {
                      tokenConfigurationProcess?.legendCreationFile && !tokenConfigurationProcess.isLegendAdded && (
                        <Result title="Legend not added to your Token" />
                      )
                    } */}
                    {
                      !isDeployed && (
                        <>
                          
                    
                          <Title style={{fontSize:'1rem'}}>
                            Review / Submit
                          </Title>
                          {/* {
                             (
                              <div
                                style={{ borderStyle: "solid", borderWidth: "1px" }}
                              >
                                <Text>
                                  Check the box if each of the following information
                                  is already provided. If not, submit the information
                                  and then check. Submit only if all the information
                                  is provided.
                                </Text>
                              </div>
                            )
                          } */}
                          {
                            !isDeployed && (
                              <>
                                <br />

                                <hr />
                                {/* <Row>
                                  <Col xs={{ span: 24 }} sm={{ offset: 2 }}>
                                    <List style={{ textAlign: "left" }}>
                                      {issuerQuestionAnswer?.map(
                                        (checkbox, index) => (
                                          <List.Item key={index}>
                                            <List.Item.Meta
                                              title={checkbox.question}
                                              description={
                                                <>
                                                  {
                                                    checkbox.type === "attachement" && (
                                                      <a
                                                        href={checkbox.answer}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        Download File
                                                      </a>
                                                    )
                                                  }
                                                  {
                                                    checkbox.type !== "attachement" && (
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html: checkbox.answer,
                                                        }}
                                                      >
                                                      </div>
                                                    )
                                                  }
                                                </>
                                              }
                                            ></List.Item.Meta>
                                          </List.Item>
                                        )
                                      )
                                      }
                                    </List>
                                  </Col>
                                </Row> */}
                                {/* <hr /> */}
                              </>
                            )}
                          {
                            !isDeployed && (
                              <Form
                                form={checkBoxesForm}
                                labelAlign="right"
                                wrapperCol={{ xs: { span: 24 }, sm: { offset: 2 } }}
                                onFinish={_submitCheckboxesStatus}
                              >
                                {/* {issuerQuestionAnswer?.map((checkbox, index) => (
                                  <Fragment key={index}>
                                    <Form.Item
                                      style={{ marginBottom: "0px" }}
                                      valuePropName="checked"
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required",
                                        },
                                      ]}
                                      name={checkbox.name}
                                    >
                                      <Checkbox
                                        style={{ color: "blue" }}
                                        disabled
                                        defaultChecked={true}
                                      >
                                        <span style={{ color: "blue" }}>
                                          {checkbox.question}
                                        </span>
                                      </Checkbox>
                                    </Form.Item>
                                  </Fragment>
                                ))
                                } */}
                                <p className="my-2" style={{fontWeight:'bold', fontSize:'1rem'}}>Approval Workflows</p>
                                <div className="row">
                                  {
                                    approvalWorkflowCheckboxes.map((checkbox,index)=>(
                                      <div key={index} className="col-md-4">
                                        <Form.Item
                                        // style={{ marginBottom: "0px" }}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                        ]}
                                        name={checkbox.value}
                                      >
                                        <Checkbox
                                          disabled = {index < 3}
                                          style={{ color: "#6197FF" }}
                                        >
                                          <span style={{ color: "#6197FF", fontWeight:600,fontSize:'0.8rem' }}>
                                            {checkbox.label}
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                      </div>
                                    ))
                                  }
                                </div>

                                {propertyDocumentCheckboxes.length > 0 && 
                                  <>
                                    <p  className="my-2" style={{fontWeight:'bold', fontSize:'1rem'}}>Property Document</p>
                                    <div className="row">
                                      {
                                        propertyDocumentCheckboxes?.map((checkbox:any,index)=> (
                                          <div key={index} className="col-md-6">
                                            <Form.Item
                                            style={{ marginBottom: "0px" }}
                                            valuePropName="checked"
                                            rules={[
                                              {
                                                required: true,
                                                message: "This field is required",
                                              },
                                            ]}
                                            name={checkbox?.value}
                                          >
                                            <Checkbox
                                              style={{ color: "#6197FF" }}
                                            >
                                              <span style={{ color: '#6197FF' , fontWeight:600,fontSize:'0.8rem' }}>
                                                {checkbox?.label}
                                              </span>
                                            </Checkbox>
                                          </Form.Item>
                                          </div>
                                        ))
                                      }                                
                                      
                                    </div>
                                  </>
                                }



                               

                                {/* {
                                  tokenConfigurationProcess?.regulation === "regulation-d" && (
                                    <Fragment key={123}>
                                      <Form.Item
                                        style={{ marginBottom: "0px" }}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: true,
                                          },
                                        ]}
                                        name="SECFilled"
                                      >
                                        <Checkbox
                                          style={{ color: "blue" }}
                                        //onChange={saveCheckboxesStatus}
                                        >
                                          <span style={{ color: "blue" }}>
                                            Issuer filed for Regulation D with
                                            Excemption with SEC
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                    </Fragment>
                                  )
                                } */}

                                {/* {tokenConfigurationProcess?.regulation === "regulation-s" && (
                                  <Fragment key={123}>
                                    <Form.Item
                                      style={{ marginBottom: "0px" }}
                                      valuePropName="checked"
                                      rules={[
                                        {
                                          required: false,
                                        },
                                      ]}
                                      name="SECFilled"
                                    >
                                      <Checkbox
                                        style={{ color: "blue" }}
                                      //onChange={saveCheckboxesStatus}
                                      >
                                        <span style={{ color: "blue" }}>
                                          Issuer filed for Regulation S with Excemption with SEC
                                        </span>
                                      </Checkbox>
                                    </Form.Item>
                                  </Fragment>
                                )
                                } */}
                                <br />
                                <Row>
                                  <Col xs={{ span: 24 }} >
                                    <p style={{ fontWeight: "bold",   fontSize:'1rem'}}>
                                      TOKEN CONFIGURATION
                                    </p>
                                  </Col>
                                </Row>
                                <div className="row">
                                {
                                  tokenConfigCheckboxes.map((checkbox, index) => (
                                    <div className="col">
                                      <Form.Item
                                        style={{ marginBottom: "0px" }}
                                        valuePropName="checked"
                                        className="token-check"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                        ]}
                                        name={checkbox.name}
                                      >
                                        <Checkbox
                                          style={{ color: "#6197FF" }}
                                          disabled
                                        // onChange={saveCheckboxesStatus}
                                        >
                                          <span style={{ color: '#6197FF',fontWeight:600,fontSize:'0.8rem' }}>
                                            {checkbox.label}
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                      </div>
                                   
                                  ))
                                }
                                </div>
                                <br />
                                <hr />

                                     <div className="container ">
                                    <div className="row align-items-start">
                                      {/* <div className="col-6 ">
                                        <div className="border border-secondary "  style={{backgroundColor:'#e8e8e8b8'}}>
                                          <p className="m-0 p-2" style={{fontWeight:'bold',fontSize:'1.2rem'}}>Team</p>
                                          <hr  className="m-0"/>
                                          <div className="p-4">
                                          <p className="builder" style={{marginBottom:'22px'}}>Builder-Arkesh Upadhyay</p>

                                          </div>
                                          </div>
                                      </div> */}
                                      
                                      <div className="col-12">
                                       <div className="border border-secondary " style={{backgroundColor:'#e8e8e8b8',minHeight:'140.75px'}}>
                                        <p className="m-0 p-2" style={{fontWeight:'bold',fontSize:'1.2rem'}}>Partner Detail</p>
                                        <hr className="m-0" />
                                        <div className="p-4">
                                        {
                                          propertyData ?

                                          propertyData?.teamMembers?.map((member,i) => (
                                            <p key={i} className="names m-0 ">{member.memberName}</p>
                                          ))
                                          :
                                          <div style={{ textAlign: 'center' }}>
                                              <Spin size='small' />
                                            </div>
                                        }
                                       
                                        </div>
                                      
                                        </div>
                                      
                                       
                                       
                                      </div>
                                
                                    </div>
                                  </div>
                                   <div className="container mt-3">
                                  <div className="row">
                                    <div className="col-12 ">
                                      <div className="border border-secondary p-2 mt-2"style={{backgroundColor:'#f8f7f1'}} >
                                        <p className="m-0 " style={{fontWeight:'bold' , fontSize:'1.2rem'}}>Bank Details</p>
                                  
                                      <hr className="m-0" />
                                      <div className="bank-dertail d-flex justify-content-between text-center">
                                      {/* <ul className="py-3 mx-2">
                                        <li style={{fontSize:'0.8rem'}}>Invested Amount</li>
                                        <span style={{fontWeight:'bold'}}>10,00,000</span>
                                      </ul>
                                      <ul className="py-3 mx-2">
                                        <li style={{fontSize:'0.8rem'}}>Number Of Tokens</li>
                                        <span style={{fontWeight:'bold'}}>10</span>
                                      </ul>
                                      <ul className="py-3 mx-2">
                                        <li style={{fontSize:'0.8rem'}}>Trnasaction ID</li>
                                        <span style={{fontWeight:'bold'}}>12345</span>
                                      </ul> */}
                                      <ul className="py-3 mx-2"> 
                                        <li style={{fontSize:'0.8rem'}}>Bank Name</li>
                                        <span style={{fontWeight:'bold'}}>{bankInfo?.bankName}</span>
                                      </ul>
                                      <ul className="py-3 mx-2"> 
                                        <li style={{fontSize:'0.8rem'}}>Branch</li>
                                        <span style={{fontWeight:'bold'}}>{bankInfo?.branchName}</span>
                                      </ul>
                                      <ul className="py-3 mx-2"> 
                                        <li style={{fontSize:'0.8rem'}}>IFSC</li>
                                        <span style={{fontWeight:'bold'}}>{bankInfo?.IFSCCode}</span>
                                      </ul>
                                      <ul className="py-3 mx-2"> 
                                        <li style={{fontSize:'0.8rem'}}>Account Number</li>
                                        <span style={{fontWeight:'bold'}}>{bankInfo?.accountNo}</span>
                                      </ul>
                                      <ul className="py-3 mx-2" >
                                        <li style={{fontSize:'0.8rem'}}>Account Name </li>
                                        <span style={{fontWeight:'bold'}}>{bankInfo?.accountHolder}</span>
                                      </ul>
                                      
                                      </div>

                                      </div>
                                     
                                    </div>
                                  </div>
                                  </div>
                                  <br />
                                {
                                  userInfo.role === "issuer token admin" && tokenConfigurationProcess?.status !== 'token creation' &&
                                  tokenConfigurationProcess?.status === "create" && (
                                    <>
                                      <div className="row">
                                        <div className="col-6">
                                          <Form.Item
                                            name="safeHarbor"
                                            // rules={[
                                            //   {
                                            //     required: true,
                                            //     message: "This field is required",
                                            //   },
                                            // ]}
                                          >
                                            <Checkbox
                                              style={{ color: "blue" }}
                                              //onChange={saveCheckboxesStatus}
                                            >
                                              <span style={{ color: "blue" }}>
                                                I understand and agree to safe harbor
                                              </span>
                                            </Checkbox>
                                          </Form.Item>

                                          <Form.Item>
                                        <Button
                                          loading={submitting}
                                          type="primary"
                                          htmlType="submit"
                                          size="large"
                                        >
                                          SUBMIT
                                        </Button>
                                      </Form.Item>
                                        </div>
                                      </div>

                                      
                                    </>
                                  )}
                              </Form>
                            )}
                        </>
                      )}

                    <ReviewComponent
                      tokenConfigurationProcess={tokenConfigurationProcess}
                      userInfo={userInfo}
                      _submitTokenConfigForReview={_submitTokenConfigForReview}
                      isserCounselCheckBoxesForm={isserCounselCheckBoxesForm}
                      _reviewFinalTokenConfig={_reviewFinalTokenConfig}
                      submitting={submitting}
                      isserCounselCheckBoxes={isserCounselCheckBoxes}
                      setComments={setComments}
                      comments={comments}
                      rejecting={rejecting}
                      verifiying={verifiying}
                    />

                    {tokenConfigurationProcess?.status === "verified" &&
                      userInfo.role === "issuer token admin" && (
                        <>
                          <Form
                            form={form}
                            onFinish={openTxFeeDelegationModal}
                            initialValues={{ additionalTokenInfo: "" }}
                          >
                            <h2>Token name</h2>
                            <Form.Item
                              name="tokenName"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                  whitespace: true,
                                },
                                {
                                  max: 20,
                                  message: "Up to 20 characters",
                                },
                              ]}
                            >
                              <Input placeholder="Enter token name" />
                            </Form.Item>
                            <h2>Additional token information</h2>
                            <Form.Item
                              name="additionalTokenInfo"
                              rules={[
                                {
                                  max: 30,
                                  message: "Up to 30 characters",
                                },
                              ]}
                            >
                              <Input placeholder="Paste link here" />
                            </Form.Item>
                            <br />
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                              >
                                Create my Security Token
                              </Button>
                            </Form.Item>
                          </Form>
                        </>
                      )}
                  </Col>
                </Row>
              </Card>
              
            </Col>
          )
        }

        {
          isDeployed && (
            <Col span={16}>
              <Card style={{border:'1px solid #6197FF',backgroundColor:'#1991FF0D'}}>
                <Result
                  status={"success"}
                  title={
                    <>
                    <div style={{fontWeight:600}} className="m-0">
                    Token deployed successfully
                    </div>
                      {!isRelaunch && (
                        <>
                          
                          {/* {
                            sharedService.regOptions.find(
                              (regOp) =>
                                regOp.value ===
                                tokenConfigurationProcess?.regulation
                            )?.name as string
                          }{" "} */}
                          <div style={{fontWeight:600}} className="m-0">
                          Launched successfully
                          </div>
                        </>
                      )}
                      {isRelaunch && (
                        <>
                          <br />
                          {/* {
                            sharedService.regOptions.find(
                              (regOp) =>
                                regOp.value ===
                                tokenConfigurationProcess?.regulation
                            )?.name as string
                          }{" "} */}
                          {isRegRelaunched
                            ? "Relaunched successfully"
                            : "Not yet Relaunched"}
                        </>
                      )}
                    </>
                  }
                />

                {/* {!rule144ConfigInitialized &&
                  !loadingRule144ConfigInitialized &&
                  symbolRegEvent &&
                  userInfo.role === "issuer token admin" && (
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                      <Button type="primary" onClick={openTxFeeDelegationModal}>
                        Save Token Rules On Blockchain
                      </Button>
                    </div>
                  )} */}
                  <div className="content-wrapper text-center">
                <div style={{ display:'inline-block',padding:'10px', margin:'0 auto', border:'1px dotted gray',backgroundColor:'white'}}>
                  <Title
                    level={2}
                    style={{ fontWeight: "bold", marginBottom: "0",fontSize:'1.2rem' }}
                  >
                    Token name
                  </Title>
                  <Title level={3} style={{ marginTop: "0" }}>
                    <span style={{color:'#6197FF'}}>
                    {usertokenSymbol}
                    </span>
                    
                  </Title>
                  <Title
                    level={2}
                    style={{ fontWeight: "bold", marginBottom: "0",fontSize:'1.2rem' }}
                  >
                    Token address
                  </Title>
                  <span style={{color:'#6197FF'}}>
                  {tokenAddress}
                  </span>
                   
                </div>
                </div>
                {isRelaunch && !isRegRelaunched && (
                  <>
                    <ReviewComponent
                      tokenConfigurationProcess={tokenConfigurationProcess}
                      userInfo={userInfo}
                      _submitTokenConfigForReview={_submitTokenConfigForReview}
                      isserCounselCheckBoxesForm={isserCounselCheckBoxesForm}
                      _reviewFinalTokenConfig={_reviewFinalTokenConfig}
                      submitting={submitting}
                      isserCounselCheckBoxes={isserCounselCheckBoxes}
                      setComments={setComments}
                      comments={comments}
                      rejecting={rejecting}
                      verifiying={verifiying}
                    />

                    {tokenConfigurationProcess?.status === "verified" &&
                      userInfo.role === "issuer token admin" && (
                        <>
                          <div
                            style={{ textAlign: "center", marginBottom: "10px" }}
                          >
                            <Button
                              type="primary"
                              size="large"
                              onClick={openTxFeeDelegationModal}
                            >
                              Relaunch Token under{" "}
                              {
                                sharedService.regOptions.find(
                                  (regOp) =>
                                    regOp.value ===
                                    tokenConfigurationProcess?.regulation
                                )?.name as string
                              }
                            </Button>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Card>
            </Col>
          )}

        {(isReservedAndNotDeployed || isDeployed) && (
          <Col span={8}>
            <Card
              bordered={false}
              style={{ marginLeft: "20px" }}
              
            >
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#1991FF",
                  padding: "13px 25px",
                }}
              >
                <p style={{  color: "white", margin:'0',paddingRight:'12px' }}>
                  Token Symbol
                </p>
                <Title level={2} style={{ backgroundColor: "#1991FF",color:'white' }}>
                  {usertokenSymbol}
                </Title>
              </div>
              <br />


               <Col span={24} className="w-100">
                {propertyData && <MarketplacePropertyCard propertyData={propertyData} callFrom={'createToken'}/>}
                </Col>

              {/* <div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Symbol Issuance Transaction
                </p>
                <a
                  href={`${etherscanURL[networkId as string]}/tx/${symbolRegEvent?.transactionHash
                    }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {minifyTxHash(symbolRegEvent?.transactionHash)}
                </a>
              </div> */}
              <br />
              {creationTime && (<div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Symbol Issuance Date
                </p>
                  <p>
                    {console.log(`creationTime: ${creationTime}`)}
                    {formatDate(creationTime)}
                  </p>
              </div>)}
              {/* <div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Issuer's ETH address
                </p>
                {symbolDetailsAndSTData && (
                  <a
                    href={`${etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner
                      }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {minifyAddress(symbolDetailsAndSTData.symbolDetails.owner)}
                  </a>
                )}
              </div> */}
            </Card>
          </Col>
        )}
      </Row>
      <ProcessModal
        title={
          isRelaunch
            ? "Relaunch of Token"
            : (isNotReserved && `Token Symbol Reserved for ${tokenSymbolReservationPeriod} days.`) ||
            (isReservedAndNotDeployed && "Token Creation") ||
            (isDeployed && "Token Rules Configuration")
        }
        // transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => closeModal()}
        closingModal={updatingSymbolDetailsAndSTData}
      />
      <TransactionModal
        title={
            (isReservedAndNotDeployed && "Token Creation")
        }
        transactions={transactions}
        isModalVisible={transactinModelVisible}
        closeModal={() => closeModal()}
        closingModal={updatingSymbolDetailsAndSTData}
      />
      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          isRelaunch
            ? relaunchToken({delegate})
            : (isNotReserved && _reserveTokenSymbol({delegate})) ||
            (isReservedAndNotDeployed && createToken({delegate})) ||
            (isDeployed && saveRule144Config({delegate}));

          setIsDelegationModalVisible(false);
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

    </>
  );
};