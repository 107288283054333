import { Button, Card, Col, Form, Modal, notification, Popconfirm, Radio, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TokenConfigurationProcess } from '../../Shared/interfaces';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../TokenConfiguration.service';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import { AuthService } from '../../Shared/Auth.service';
import { BankService } from '../../Shared/BankInformationRequests/BankInfoRequests.service';

const {Title} = Typography;

const regulationRadioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const tokenConfigurationService = new TokenConfigurationService();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();
const bankService = new BankService()

export default function TokenConfigurationList() {

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isRelaunchTokenModalVisible, setIsRelaunchTokenModalVisible] = useState<boolean>();
  const [relaunchTokenForm] = Form.useForm();
  // const [relaunchRegulation, setRelaunchRegulation] = useState('');

  const [tokenConfigurationProcesses, setTokenConfigurationProcesses] = useState<TokenConfigurationProcess[]>();
  const [tokenAddress, settokenAddress] = useState<string>("");
  const [isPropertyAvailable, setisPropertyAvailable] = useState(false);
  const [isPaymentInfoOrPropertyInfoApproved, setIsPaymentInfoOrPropertyInfoApproved] = useState(false);

  const {userInfo} = useUserContext();
  console.log(tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed));
  

  useEffect(() => {
    (async () => {
      if (userInfo) {
        try {
          const _tokenConfigurationProcesses = (await tokenConfigurationService.getTokenConfigurationProcesses()).data as TokenConfigurationProcess[];
          console.log("token_data: ", _tokenConfigurationProcesses);
          setTokenConfigurationProcesses(_tokenConfigurationProcesses);
          const tokenAddress = await tokenConfigurationService.getLLPAddress(userInfo.company?.id);
          console.log("token_address:", tokenAddress);
          settokenAddress(tokenAddress.data.deployedSmartContractAddress);
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setLoading(false);
        }
      }
    })();

    (async () => {
      if (userInfo) {
        try {
          const propertydetails = await tokenConfigurationService.getPropertyDetails(userInfo.company?.id);
          if(
            propertydetails['data'][0].propertyDocuments =='done' &&
            propertydetails['data'][0].hasOwnProperty('teamMembers') && 
            propertydetails['data'][0].teamMembers.length >= 2
            ){
              setisPropertyAvailable(true);
            }
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setLoading(false);
        }
      }
    })();

    (async () => {
      if (userInfo) {
        try {
          const bankdetails = await bankService.getBankInfoByCompanyId(userInfo?.company?.id)
          if(bankdetails.success && bankdetails.data?._id ){
              if(bankdetails.data?.status == 'approved')
                setIsPaymentInfoOrPropertyInfoApproved(true)
            }
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [userInfo]);


  const columns = [
    {
      title: 'Token Address',
      dataIndex: 'regulation',
      render: (value: string) => tokenAddress
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')

    }, 
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (value: TokenConfigurationProcess['status']) => {
        return value === 'rule 144' ? 'pending' : value;
      },  
    },
    {
      title: 'TOKEN SALE ENDED ON',
      dataIndex: 'tokenSaleClosedOn',
      render: (value: number) => value && tokenConfigurationProcesses && tokenConfigurationProcesses['isTokenSaleClosed'] ? moment(value).format('lll') : '-'

    },
    {
      title: 'ACTION',
      render: (value, record: TokenConfigurationProcess) => {
        return (
          <>
            <Button size='small' type='primary' onClick={() => history.push(`/token-configurations/${record._id}/create`)}>
              View
            </Button>

            {/* {userInfo?.role === 'issuer counsel' && 
              <>
                <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-counsel/create-legend/${record._id}`)}>
                  Create Legend
                </Button>
                <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-counsel/remove-legend/${record._id}`)}>
                  Remove Legend
                </Button>
              </>
            } */}

            {userInfo?.role === 'issuer token admin' && 
              <>
                {/* <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-token-admin/remove-legend/${record._id}`)}>
                  Remove Legend
                </Button> */}
              </>
            }
            {console.log(userInfo?.role, record.status, record.isTokenSaleClosed)}
            {userInfo?.role === 'issuer token admin' && record.status === 'verified' && !record.isTokenSaleClosed &&
              <>
              <Popconfirm
                title="This token sale will be closed and you can not recieve any investment more"
                onConfirm={closeTokenSale}
              >
                <Button size='small' style={{marginLeft: '5px'}} type='primary'>
                  Close Token Sale
                </Button>
              </Popconfirm>
              </>
            }
          </>
        );
      }
    }
  ];

  // const availableRegs = sharedService.regOptions.filter(option => !tokenConfigurationProcesses?.find(process => process.regulation === option.value));


  const openRelaunchTokenModal = () => {
    if(!isPropertyAvailable){ //if documents are pending or teamMembers are not minimum of two:
      notification.info({
        message:'info',
        description:'You need to add minimum two members and finish uploading property documents on property page'
      });
      return;
    }
    if(!isPaymentInfoOrPropertyInfoApproved){ //if payment info is not approved
      notification.info({
        message:'info',
        description:'You payment information has not been approved yet'
      });
      return;
    }
    
    if(!tokenConfigurationProcesses?.length) return history.push(`/token-configurations/new-config/type-and-details`);

    if(tokenConfigurationProcesses?.some(process => !process.isTokenSaleClosed)) {
      return notification.error({message: 'ERROR', description: 'You have to close the last Token Sale in order to relaunch the Token'});
    }
    setIsRelaunchTokenModalVisible(true);
  }

  const closeTokenSale = async () => {
    const res = await tokenConfigurationService.closeTokenSale(userInfo?.company?.id);
    if(!res.success || res.error) {
      console.error(res.error);
      notification.error({message: 'Error', description: 'Something went Wrong! Please try again later!'});
    }else {
      notification.success({message: 'Success', description:'Successfully Closed Token Sale! Now you can issue tokens to investors!' });
      setTimeout(() => window.location.reload(), 1000);
    }
  }


  return (

  <>
    <br/><br/>

    <Row justify="center">

      <Col span={23}>
        {loading &&  
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }
        {!loading && 
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Token Configurations</Title>

            {userInfo?.role === 'issuer token admin' && 
              <div style={{textAlign:'right', marginBottom: '10px'}}>
                {/* <Button style={{marginRight:'5px'}} size='large' onClick={()=> setCryptoAccountModalVisible(true)}>
                  ADD CRYPTO ACCOUNTS
                </Button> */}
                <Button type="primary" size='large' onClick={openRelaunchTokenModal}>
                  {tokenConfigurationProcesses?.length? '': 'LAUNCH TOKEN'}
                </Button>
              </div>
            }

            <Table
              columns={columns}
              dataSource={tokenConfigurationProcesses}
              rowKey='_id'
              pagination={false}
            />
          </Card>
        }
      </Col>

    </Row>


    <Modal
      title={'Relanch token'}
      okText='Confirm'
      cancelText='Cancel'
      visible={isRelaunchTokenModalVisible}
      onOk={()=>relaunchTokenForm.submit()}
      onCancel={()=>{setIsRelaunchTokenModalVisible(false); relaunchTokenForm.resetFields()}}
    >


      {!tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed)?

        <>
          {/* {availableRegs.length &&  */}
            {/* <> */}
              <p>
                Select the new Regulation for your Token
              </p>

              <Form form={relaunchTokenForm} labelAlign='right' onFinish={(formValue)=>{history.push(`/token-configurations/new-config/type-and-details?relaunchRegulation=${formValue.relaunchRegulation}`)}}>
                <Form.Item
                  name="relaunchRegulation"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]}
                >
                  <Radio.Group>
                    {sharedService.regOptions.map(option => (
                      <React.Fragment key={option.value}>
                        <Radio value={option.value} style={regulationRadioStyle}>{option.name}</Radio>
                      </React.Fragment>
                    ))}
                  </Radio.Group>
                </Form.Item>

              </Form>
            {/* </> */}
          {/* } */}

          {/* {!availableRegs.length && 
            <h3>No available Regulations</h3>
          } */}

        </>

        : 

        <p>
          You have to close the Token Sale first
        </p>
      
      
      }



      
    </Modal>



  </>
  );

}