import React, { useState, useEffect } from 'react';
import { AuthService } from "../../Shared/Auth.service";
import { Typography, Tag, Card, Table, Modal, Form, Button, Tooltip, Input, Select, Spin, message, notification } from "antd";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { SeriesService } from '../../Series/Series.service';
import { RealEstateChannelPartnerService } from '../RealEstateChannelPartner.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { environment } from '../../../environments/environment';

const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 26 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 16 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const realEstateChannelPartner = new RealEstateChannelPartnerService();
const issuerSuperAdminService = new IssuerSuperAdminService();

const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const { userInfo } = useUserContext();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [investors, setInvestors] = useState([]);
  const [series, setAllSeries] = useState<any[]>([]);
  const [displayedInvestors, setDisplayedInvestors] = useState([]);
  const [modalForm] = Form.useForm();
  const [invitationInvestForm] = Form.useForm();
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isInviteFormModalVisible, setIsInviteFormModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalInvites, setTotalInvites] = useState(0);
  const [selectedInvestor, setSelectedInvestor] = useState<any>(null);
  const [inviteLoading, setInviteLoading] = useState(false);

  const [isPropertyModalVisible, setIsPropertyModalVisible] = useState(false);
  const [investedProperties, setInvestedProperties] = useState([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const defaultCommissionPercentage = environment?.commission;


  const fetchData = async (page, size) => {
    if (!userInfo) return;
    setLoading(true);
    try {
      const response = await realEstateChannelPartner.getInvites(userInfo?._id, page, size);
      const allSeries = await realEstateChannelPartner.getAllProperty(userInfo?._id);
      if (response.data) {

        const investorsWithTotalInvestment = response.data.investorList.map(investor => {
          const totalInvested = investor.investmentDetails.reduce((sum, detail) => {
            return sum + (detail.amountInvested || 0);
          }, 0);
          const commissionPercentage = parseFloat(investor.commission) || defaultCommissionPercentage;
          const totalCommission = (totalInvested * commissionPercentage) / 100;
          return { 
            ...investor, 
            totalAmountInvested: totalInvested,
            totalCommissionAmount: totalCommission
          };
        });
        setInvestors(investorsWithTotalInvestment);

        setAllSeries(allSeries.data?.allPropertyDetails);
        // setInvestors(response?.data?.investorList);
        setTotalInvites(response.data.totalCount);
        const startIndex = (page - 1) * size;
        const endIndex = startIndex + size;
        setDisplayedInvestors(response?.data?.investorList?.slice(startIndex, endIndex));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [userInfo, currentPage, pageSize]);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Total Invested Amount',
      dataIndex: 'totalAmountInvested',
      key: 'totalAmountInvested',
      render: (amount) => <span style={{ color: '#3dc2d3' }}> ₹ {amount.toLocaleString()}</span>, 
    },
    {
      title: 'Commission %',
      dataIndex: 'commission',
      key: 'commission',
      render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}%</span>,
    },
    {
      title: 'Commission Amount',
      dataIndex: 'totalCommissionAmount',
      key: 'totalCommissionAmount',
      render: (text: string) => <span style={{ color: '#3dc2d3' }}>{text}</span>,
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTS',
      render: (value) => moment(value).format('lll')
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showInviteModal(record)}>Send Invite</Button>
      )
    }
  ];

  const propertyColumns = [
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
    },
    {
      title: 'Invested Amount',
      dataIndex: 'amountInvested',
      key: 'amountInvested',
      render: (amount) => `₹ ${amount.toLocaleString()}`,
    },
    {
      title: 'Investment Date',
      dataIndex: 'creationTS',
      key: 'creationTS',
      render: (date) => moment(date).format('lll'),
    },
  ];


  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    fetchData(pagination.current, pagination.pageSize);
  };

  //For Sending Invition  Invest On Property  Start
  const showInviteModal = (record) => {
    modalForm.resetFields();
    setSelectedInvestor(record);
    setIsInviteModalVisible(true);
  };

  const sendInvitationForInvest = async (values) => {
    setInviteLoading(true);
    try {
      const data = {
        userId: userInfo?._id,
        propertyId: values.propertyId,
        investorId: selectedInvestor?._id
      };
      const response = await realEstateChannelPartner.sendPropertyUrlToinvestor(data);
      if (response.success) {
        message.success("Invite sent successfully");
        setIsInviteModalVisible(false);
        fetchData(currentPage, pageSize);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      console.error("Error sending invite:", error);
      message.error("Failed to send invite");
    } finally {
      setInviteLoading(false);
    }
  };
  //For Sending Invition  Invest On Property  End

  //Invite new investor Start
  const openInviteFormModal = () => {
    modalForm.resetFields();
    setIsInviteFormModalVisible(true);
  }

  const inviteInvestors = async (formValue) => {
    setLoading(true);
    const response = await issuerSuperAdminService.inviteInvestor({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      seriesId: formValue.seriesId,
      userId: userInfo?._id,
      commission: formValue.commission
    });

    if (response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Investor Successfully'
      });
      setIsInviteFormModalVisible(false);
    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setLoading(false);
  }
  //Invite new investor End


  const handleRowClick = async (record) => {
    setSelectedInvestor(record);
    setIsPropertyModalVisible(true);
    await fetchInvestedProperties(record._id);
  };

  const fetchInvestedProperties = async (investorId) => {
    setIsLoadingProperties(true);
    try { 

      const response = await realEstateChannelPartner.getInvestmentDetails(investorId);
      const investmentDetails = response?.data?.investmentDetails || [];
      const processedInvestmentDetails = investmentDetails.map(detail => ({
        ...detail,
        propertyName: detail.propertyDetails[0]?.propertyName || 'N/A'
      }));
      setInvestedProperties(processedInvestmentDetails);

    } catch (error) {
      console.error("Error fetching invested properties:", error);
      message.error("Failed to fetch invested properties");
    }
    setIsLoadingProperties(false);
  };

  return (
    <div>
      {loading && (<div style={{ textAlign: 'center' }}> <br /> <Spin size='large' /></div>)}
      {!loading &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', margin: '23px 25px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <Title className='fontForBold' level={2} style={{ margin: '0px 10px 0px 0px' }}>Investors</Title>
              <Tooltip title="Invite Investors"><Button style={{ borderRadius: '18px', fontWeight: 'bold' }} className="custom-plus-button" onClick={() => openInviteFormModal()}>+</Button>
              </Tooltip>
            </div>

          </div>
          <Card style={{ borderRadius: '10px', margin: '20px' }}>
            <Table
              columns={columns}
              dataSource={investors}
              scroll={{ x: 'max-content' }}
              className="table"
              style={{ borderRadius: '10px', padding: '0px' }}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalInvites,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50'],
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onDoubleClick: () => handleRowClick(record),
              })}
            />
          </Card>
          <Modal
            title="Send Invite"
            visible={isInviteModalVisible}
            onCancel={() => setIsInviteModalVisible(false)}
            footer={null}
          >
            <Form
              {...formItemLayout}
              form={invitationInvestForm}
              name="inviteForm"
              onFinish={sendInvitationForInvest}
            >
              <Form.Item
                name="propertyId"
                label="Select Company"
                rules={[{ required: true, message: 'Please select a company!' }]}
              >
                <Select placeholder="Select a company">
                  {series?.map(series => (
                    <Option key={series._id} value={series._id}>{series.propertyName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={inviteLoading}>
                  Send Invite
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title={`Invite Investor`}
            okText='SUBMIT'
            cancelText='CANCEL'
            visible={isInviteFormModalVisible}
            onOk={() => modalForm.submit()}
            onCancel={() => {
              setIsInviteFormModalVisible(false);
            }}
            okButtonProps={{
              loading: loading,
            }}
          >

            <Form
              form={modalForm}
              autoComplete={'off'}
              {...formItemLayout}
              labelAlign="left"
              onFinish={inviteInvestors}
            >
              <Form.Item label="First Name" name="firstName"
                rules={[{ required: true, message: 'Please input First Name!' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Last Name" name="lastName"
                rules={[{ required: true, message: 'Please input Last Name!' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Email" name="email"
                rules={[{ required: true, message: 'Please input Email!' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item name="seriesId" label="Select Property" rules={[{ required: true }]}>
                <Select
                  placeholder="Select a Property"
                  allowClear
                  size="large"
                  loading={loading}
                >
                  {series?.map(series => (
                    <Option key={series.seriesId} value={series.seriesId}>{series.propertyName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Commission" name="commission"
                rules={[{ required: true, message: 'Please input Commission!' },
                { validator: (_, value) => value <= 100 ? Promise.resolve() : Promise.reject('Commission must be less than or equal to 100') }]}>
                <Input type="number" size="large" addonAfter={'%'} max={100} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
         title={`Properties Invested By ${selectedInvestor?.firstName} ${selectedInvestor?.lastName}`}
        visible={isPropertyModalVisible}
        onCancel={() => setIsPropertyModalVisible(false)}
        footer={null}
        width={800}
      >
        <Table
          columns={propertyColumns}
          dataSource={investedProperties}
          loading={isLoadingProperties}
          pagination={false}
        />
      </Modal>
        </>
      }
    </div>
  );
}