import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Descriptions,
  Spin,
  Table,
  Button,
  Modal,
  Result,
  notification,
} from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { AuthService } from '../../Shared/Auth.service';
import {
  SymbolDetailsAndSTData,
  VestingScheduleFromContract,
  TokenModule,
  RegLaunched,
  TokenConfigurationProcess,
} from '../../Shared/interfaces';
import { SharedService } from '../../Shared/Shared.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { TeamMemberService } from '../TeamMember.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { useHistory } from 'react-router-dom';

import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import VestingFacet from '../../Shared/SecurityToken/Facets/VestingFacet/index';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import GeneralTransferManagerFacet from '../../Shared/SecurityToken/Facets/GeneralTransferManagerFacet';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";

const { Title, Text } = Typography;
const sharedService = new SharedService();
const teamMemberService = new TeamMemberService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

const securityTokenService = new SecurityTokenService();
const generalTransferMangerFacet = new GeneralTransferManagerFacet();
const vestingFacet = new VestingFacet();
const mainFacet = new MainFacet();
const tokenConfigurationProcesss = new TokenConfigurationService();
const erc1410Facet = new ERC1410Facet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default () => {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [submitting, setSubmitting] = useState<boolean>();
  const [transactionReceipt, setTransactionReceipt] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();

  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();
  const [vestingSchedule, setVestingSchedule] =
    useState<VestingScheduleFromContract>();
  const [percentageToWithdraw, setPercentageToWithdraw] = useState<string>();
  const [monthUnit, setMonthUnit] = useState('60');
  const precision = securityTokenService.precision;

  const [displayableDepartment, setDisplayableDepartment] = useState<string>();
  const [displayableTeamRole, setDisplayableTeamRole] = useState<string>();
  const [hasVesting, setHasVesting] = useState<boolean>();
  const history = useHistory();
  const [KYCPending, setKYCPending] = useState(true);
  //
  // const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>(
  //   []
  // );
  const [tokenConfigurationProcess, setTokenConfigurationProcess] =
    useState<TokenConfigurationProcess>();

  useEffect(() => {
    (async () => {
      if (
        userInfo?.role !== 'employee' &&
        userInfo?.role !== 'contractor_consultant_advisor'
      ) {
        return;
      }

      const response = await teamMemberService.getKYCDetails({
        teamMemberId: userInfo?._id,
      });

      if (response.success && response.data) {
        if (response.data.length > 0) {
          setKYCPending(false);
        } else {
          setKYCPending(true);
          history.push('/team-member/KYCUpload');
        }
      }
    })();
  }, [history, userInfo]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      let _symbolDetailsAndSTData: SymbolDetailsAndSTData | null = null;
      let _hasVesting: boolean | null = null;
      let _monthUnit: string | null = null;
      // let _regulationsLaunched: RegLaunched[];

      const [department, teamRole, _tokenConfigurationProcess] = (
        await Promise.all([
          issuerSuperAdminService.getTeamDepartment(
            userInfo.departmentId as string
          ),
          issuerSuperAdminService.getTeamRole(userInfo.teamRoleId as string),
          tokenConfigurationProcesss.getLastTokenConfigurationProcess(),
        ])
      ).map((response) => response.data);

      setDisplayableDepartment(department?.name);
      setDisplayableTeamRole(teamRole?.name);
      setTokenConfigurationProcess(_tokenConfigurationProcess);

      if (_tokenConfigurationProcess?.tokenSymbol) {
        _symbolDetailsAndSTData =
          await securityTokenRegisteryService.getSymbolDetailsAndSTData(
            _tokenConfigurationProcess.tokenSymbol
          );
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

        if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return;

        [_monthUnit, _hasVesting] = await Promise.all([
          mainFacet.monthUnit(
            _symbolDetailsAndSTData.securityTokenData.contractAddress
          ),
          vestingFacet.hasVestingSchedule(
            _symbolDetailsAndSTData.securityTokenData.contractAddress,
            userInfo.wallet as string
          ),
          // mainFacet.getRegulationsLaunched(
          //   _symbolDetailsAndSTData.securityTokenData.contractAddress
          // ),
        ]);

        setMonthUnit(_monthUnit);
        setHasVesting(_hasVesting);
        // setRegulationsLaunched(_regulationsLaunched);
      }

      if (_hasVesting) {
        const [_vestingScheduleTemp, _percentageToWithdraw] = await Promise.all(
          [
            vestingFacet.getVestingSchedule(
              _symbolDetailsAndSTData?.securityTokenData
                .contractAddress as string,
              userInfo.wallet as string
            ),
            vestingFacet.getPercentageToWithdrawFromVesting(
              _symbolDetailsAndSTData?.securityTokenData
                .contractAddress as string,
              userInfo.wallet as string
            ),
          ]
        );

        const _vestingSchedule = { ..._vestingScheduleTemp };
        _vestingSchedule.vesting = _vestingScheduleTemp.vesting.map(
          (schedule) => ({
            endOfMonth: schedule.endOfMonth,
            isFixed: schedule.isFixed,
            lockPeriod: schedule.lockPeriod,
            percent: schedule.percent,
          })
        );
        _vestingSchedule.vesting.forEach(
          (schedule, index) => (schedule['key'] = index)
        );

        setVestingSchedule(_vestingSchedule);
        setPercentageToWithdraw(_percentageToWithdraw);
        console.log(_vestingSchedule);
        console.log(_percentageToWithdraw);
      }
    })();
  }, [userInfo]);

  const previousVestingDuration = (
    record: VestingScheduleFromContract['vesting'][0]
  ) => {
    const index = vestingSchedule?.vesting.findIndex(
      (schedule) => schedule['key'] === record['key']
    );
    return index
      ? +(vestingSchedule?.vesting[index - 1].endOfMonth as string)
      : 0;
  };

  const columns = [
    {
      title: 'Vesting Schedule',
      dataIndex: 'vestingScheduleNumber',
      render: (value: string, record) => `Vesting ${record['key'] + 1}`,
    },
    {
      title: <>Fixed or divide equaly</>,
      dataIndex: 'isFixed',
      render: (value: boolean, record) => {
        return <div>{value ? 'Fixed' : 'Divide Equaly'}</div>;
      },
    },
    {
      title: 'End of vesting month',
      dataIndex: 'endOfMonth',
      render: (
        value: string,
        record: VestingScheduleFromContract['vesting'][0]
      ) => {
        const formatedEndOfMonth = (
          endOfMonth: string | number,
          creationTS: string
        ) =>
          moment(
            new BigNumber(endOfMonth)
              .times(monthUnit)
              .plus(creationTS)
              .times(1000)
              .toNumber()
          ).format('lll');

        return (
          <>
            {record.isFixed &&
              formatedEndOfMonth(
                record.endOfMonth,
                vestingSchedule?.creationTS as string
              )}
            {!record.isFixed &&
              `${formatedEndOfMonth(
                previousVestingDuration(record) + 1,
                vestingSchedule?.creationTS as string
              )} 
                - 
              ${formatedEndOfMonth(
                record.endOfMonth,
                vestingSchedule?.creationTS as string
              )}`}
          </>
        );
      },
    },
    {
      title: 'Vesting %',
      dataIndex: 'percent',
      render: (value: string) =>
        new BigNumber(value).div(new BigNumber(10).pow(precision)).toFixed(),
    },
    {
      title: 'Lock Period',
      dataIndex: 'lockPeriod',
      render: (value: string) => `${value} months`,
    },
  ];

  const withdrawFromVesting = async () => {
    if (!tokenConfigurationProcess?.isTokenSaleClosed) {
      return notification.error({
        message: 'Token Sale is still open',
        description: 'You have to wait until Token Sale is Closed by Issuer',
        duration: 20,
      });
    }
    setLoadingCanTransfer(true);

    const _from = '0x0000000000000000000000000000000000000000';
    const _to = selectedWallet as string;
    const _value = new BigNumber(vestingSchedule?.amount as string)
      .times(percentageToWithdraw as string)
      // .div(new BigNumber(10).pow(symbolDetailsAndSTData?.securityTokenData.decimals as string))
      .div(new BigNumber(10).pow(precision))
      .div(100)
      .toFixed();


    const _totalPartitions = await erc1410Facet.totalPartitions(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string
    )

    const canTransfer = await erc1410Facet.canTransferByPartition(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      _from,
      _to,
      _totalPartitions[_totalPartitions.length - 1],
      _value,
      // _regulationsLaunched.length - 1,
      // false
    );
    //
    // const canTransfer = await generalTransferMangerFacet.canTransfer(
    //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
    //   _from,
    //   _to,
    //   _value,
    // );

    console.log(canTransfer);

    setLoadingCanTransfer(false);

    if (canTransfer[0] !== '0x51') {
      notification.error({
        message: 'Token Transfer error',
        description: sharedService.bytes32ToString(canTransfer[1]),
      });
      return;
    }

    setIsModalVisible(true);
    setSubmitting(true);

    try {
      const receipt = await vestingFacet.withdrawFromVesting(
        symbolDetailsAndSTData?.securityTokenData
          .contractAddress as string as string,
        selectedWallet as string
      );

      setTransactionReceipt(receipt);

      if (receipt?.status) {
        setVestingSchedule((prev: any) => ({
          ...prev,
          vestedPercent: new BigNumber(prev.vestedPercent)
            .plus(percentageToWithdraw as string)
            .toFixed(),
        }));
        setPercentageToWithdraw('0');
      }
    } catch (err) {
      console.error(err);
    }

    setSubmitting(false);
  };

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {(!userInfo || !displayableDepartment || !displayableTeamRole) && (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          )}
          {userInfo && displayableDepartment && displayableTeamRole && (
            <>
              <Card>
                <Title level={1} style={{ textAlign: 'center' }}>
                  User's details
                </Title>
                <Descriptions bordered column={2} style={{}}>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="First name"
                  >
                    {userInfo.firstName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Last name"
                  >
                    {userInfo.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Email"
                  >
                    {userInfo.email}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Team"
                  >
                    {userInfo.role === 'employee'
                      ? 'Employee'
                      : 'Contractor/Consultant/Advisor'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Department"
                  >
                    {displayableDepartment}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Role"
                  >
                    {displayableTeamRole}
                  </Descriptions.Item>
                  {userInfo.isAffiliate && userInfo.corporateRoles && (
                    <Descriptions.Item
                      style={{ textAlign: 'center' }}
                      label="CORPORATE ROLES"
                    >
                      {userInfo.corporateRoles.join(', ')}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    style={{ textAlign: 'center' }}
                    label="Has Vesting"
                  >
                    {hasVesting ? 'Yes' : 'No'}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <br />
              <br />

              {hasVesting && (
                <>
                  {!vestingSchedule && (
                    <div style={{ textAlign: 'center' }}>
                      <br />
                      <Spin size="large" />
                    </div>
                  )}
                  {vestingSchedule &&
                    percentageToWithdraw &&
                    symbolDetailsAndSTData && (
                      <Card style={{ marginBottom: '120px' }}>
                        <Title level={1} style={{ textAlign: 'center' }}>
                          Vesting Schedule
                        </Title>

                        {selectedWallet?.toLowerCase() !==
                          userInfo.wallet?.toLowerCase() && (
                          <>
                            <Title level={2} style={{ textAlign: 'center' }}>
                              Wrong selected wallet on metamask
                            </Title>
                            <Result
                              status="error"
                              title={
                                <p>
                                  Select the wallet{' '}
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${
                                      sharedService.etherscanURL[
                                        networkId as string
                                      ]
                                    }/address/${userInfo.wallet}`}
                                  >
                                    {sharedService.minifyAddress(
                                      userInfo.wallet as string
                                    )}
                                  </a>{' '}
                                  in order to use your Vesting Schedule
                                </p>
                              }
                            />
                          </>
                        )}

                        {selectedWallet?.toLowerCase() ===
                          userInfo.wallet?.toLowerCase() && (
                          <>
                            <Descriptions bordered column={2} style={{}}>
                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Total amount for Vesting"
                              >
                                {new BigNumber(vestingSchedule.amount)
                                  .div(
                                    new BigNumber(10).pow(
                                      symbolDetailsAndSTData.securityTokenData
                                        .decimals
                                    )
                                  )
                                  .toFixed()}{' '}
                                {
                                  symbolDetailsAndSTData.securityTokenData
                                    .symbol
                                }
                              </Descriptions.Item>
                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Total Schedules"
                              >
                                {vestingSchedule.vesting.length}
                              </Descriptions.Item>

                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Created on"
                              >
                                {moment(
                                  new BigNumber(vestingSchedule.creationTS)
                                    .times(1000)
                                    .toNumber()
                                ).format('LLL')}
                              </Descriptions.Item>

                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Duration"
                              >
                                {vestingSchedule.duration} months
                              </Descriptions.Item>

                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Amount withdrawn"
                              >
                                {new BigNumber(vestingSchedule.amount)
                                  .times(vestingSchedule.vestedPercent)
                                  .div(
                                    new BigNumber(10).pow(
                                      symbolDetailsAndSTData.securityTokenData
                                        .decimals
                                    )
                                  )
                                  .div(new BigNumber(10).pow(precision))
                                  .div(100)
                                  .toFixed()}{' '}
                                {
                                  symbolDetailsAndSTData.securityTokenData
                                    .symbol
                                }{' '}
                                (
                                {new BigNumber(vestingSchedule.vestedPercent)
                                  .div(new BigNumber(10).pow(precision))
                                  .decimalPlaces(4)
                                  .toFixed()}
                                %)
                              </Descriptions.Item>

                              <Descriptions.Item
                                style={{ textAlign: 'center' }}
                                label="Available to withdraw"
                              >
                                {new BigNumber(vestingSchedule.amount)
                                  .times(percentageToWithdraw)
                                  .div(
                                    new BigNumber(10).pow(
                                      symbolDetailsAndSTData.securityTokenData
                                        .decimals
                                    )
                                  )
                                  .div(new BigNumber(10).pow(precision))
                                  .div(100)
                                  .toFixed()}{' '}
                                {
                                  symbolDetailsAndSTData.securityTokenData
                                    .symbol
                                }{' '}
                                (
                                {new BigNumber(percentageToWithdraw)
                                  .div(new BigNumber(10).pow(precision))
                                  .decimalPlaces(4)
                                  .toFixed()}
                                %)
                              </Descriptions.Item>
                            </Descriptions>

                            <br />

                            <Table
                              columns={columns}
                              dataSource={vestingSchedule.vesting}
                              pagination={false}
                            />
                            <br />

                            <div style={{ textAlign: 'center' }}>
                              <Title level={3}>
                                You have{' '}
                                {new BigNumber(vestingSchedule.amount)
                                  .times(percentageToWithdraw)
                                  .div(
                                    new BigNumber(10).pow(
                                      symbolDetailsAndSTData.securityTokenData
                                        .decimals
                                    )
                                  )
                                  .div(new BigNumber(10).pow(precision))
                                  .div(100)
                                  .toFixed()}{' '}
                                {
                                  symbolDetailsAndSTData.securityTokenData
                                    .symbol
                                }{' '}
                                available to withdraw
                              </Title>
                              <br />
                              {new BigNumber(
                                percentageToWithdraw
                              ).isGreaterThan(0) && (
                                <Button
                                  size="large"
                                  type="primary"
                                  loading={loadingCanTransfer}
                                  onClick={withdrawFromVesting}
                                >
                                  WITHDRAW NOW
                                </Button>
                              )}
                            </div>
                          </>
                        )}
                      </Card>
                    )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>

      <Modal
        title={`Transaction processing`}
        closable={false}
        onOk={() => setIsModalVisible(false)}
        keyboard={false}
        maskClosable={false}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: !!submitting }}
        okText={'Close'}
        visible={isModalVisible}
      >
        <Row>
          {isModalVisible && (
            <Col span={24}>
              <Title level={3}>Vesting withdrawal</Title>
              <br />
            </Col>
          )}
          <Col span={1}>
            {submitting && <Spin size="large" />}
            {!submitting && transactionReceipt?.status && (
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '25px' }}
              />
            )}
            {!submitting &&
              (!transactionReceipt || !transactionReceipt.status) && (
                <CloseCircleTwoTone
                  twoToneColor="#ff0000"
                  style={{ fontSize: '25px' }}
                />
              )}
          </Col>
          <Col span={22} offset={1}>
            <Text style={{ fontWeight: 'bold' }}>Withdrawing from Vesting</Text>
            <br />
            <br />
            {transactionReceipt && (
              <Text style={{ color: 'grey' }}>
                Transaction details on Etherscan:
                <a
                  style={{ marginLeft: '6px' }}
                  href={`${
                    sharedService.etherscanURL[networkId as string]
                  }/tx/${transactionReceipt.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {sharedService.minifyTxHash(
                    transactionReceipt.transactionHash
                  )}
                </a>
              </Text>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
