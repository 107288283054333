import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();


export class RealEstateChannelPartnerService {

  private APIURL = environment.APIURL;

  // get invites
  async getInvites(userId:any,page:number,size:number) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/channelPartner/getInvestorList?userId=${userId}`,
      {  params:{page,size},headers: await sharedService.getAuthHeader() }
    );
  }

  // save Invite
  async sendPropertyUrlToinvestor(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/channelPartner/sendPropertyUrlToinvestor`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllProperty(userId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/channelPartner/getAllProperty`,
      {  params: { userId },headers: await sharedService.getAuthHeader() }
    );
  }

    // get getInvestmentDetails
    async getInvestmentDetails(investorId:any) {
      return axios.get<any, APIResponse>(
        `${this.APIURL}/channelPartner/getInvestmentDetails`,
        { params: { investorId },headers: await sharedService.getAuthHeader() }
      );
    }



}