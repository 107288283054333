
import React, { useEffect, useState } from "react";
import "../../Register/InvestorRegistration/InvestorRegistration.scss";
import { AccountService } from "../../Account.service";
import RegistrationInvestor from '../../../../assets/images/about-us/RegistrationInvestor.png'
import VruddhiXImage from '../../../../assets/images/about-us/VruddhiX.png'
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Alert,
  Result,
  DatePicker,
  Checkbox,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../../../Shared/Shared.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import ConnectToMetamask from "../../../ConnectToMetamask";
import { MetamaskService } from "../../../Shared/Metamask.service";
import { AuthService } from "../../../Shared/Auth.service";
import queryString from "query-string";

const { Option } = Select;

const sharedService = new SharedService();
const accountService = new AccountService();
const metamaskService = new MetamaskService();

const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();
const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId) =>
  new MetamaskService().useSelectedWallet(userId);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    offset: 2,
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useUserInfo();
  const [selectedWallet, networkId] = useSelectedWallet("new investor");
  const [registered, setRegistered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUSAStates] = useState();
  const [form] = Form.useForm();
  const [userCountry, setUserCountry] = useState();
  const [cCountry, setCCountry] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const [registrationMode, setRegistrationMode] = useState("NORMAL");

  const isNotConnectedToMetamask =
    MetamaskService.isMetamaskAuthenticated === false &&
    (networkId !== 1 || networkId !== 5);

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
    })();

    (async () => {
      await axios
        .get(
          "https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91"
        )
        .then((response) => {
          //console.log(response.country_name);
          setUserCountry(response.country_name);
        })
        .catch((response) => console.log(response));
    })();
  }, []);

  const location = useLocation();
  let { code, email, seriesId, userId } = queryString.parse(location.search);
  if (email?.length > 0 && email.indexOf(" ") > -1) {
    email = email.replaceAll(" ", "+");
  }

  const forwardToLogin = () => {
    history.push("/account/login");
  };

  const currentCountry = (value) => {
    if (value == "United States of America") setCCountry(true);
    else setCCountry(false);

    let countryCode = countries.find((country) => country.name.en == value)._id;
    setCountryCode(countryCode.toLowerCase());
  };

  const calculateAge = (dateOfBirth) => {
    var dob = new Date(dateOfBirth);

    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);

    return age;
  };

  const register = async (req) => {
    if (
      !req.country ||
      req.country?.toUpperCase() !== userCountry?.toUpperCase()
    ) { 
      console.log('registration is from different country');
      // setCountryError(true);
      // return;
    }

    setCountryError(false);
    setSubmitting(true);
    const age = calculateAge(req.dateOfBirth);
    if (age < 18) {
      message.error("Investor age should be greater than or eqauls to 18!");
      setSubmitting(false);
      return;
    }

    if (!seriesId || !email || !code || !userId) {
      registerInvestorAsPublic(req);
      return;
    }

    try {
      let res = await accountService.getSeriesCompanyDetails(seriesId);
      let seriesDetails = res.data;
      if (seriesId && seriesDetails?.companyId) {
        req.seriesId = seriesId;
        req.code = code;
        req.companyObj = {
          id: seriesDetails.companyId,
          name: seriesDetails.name,
        };
        req.companyId = seriesDetails.companyId;
        req.invitedById = userId
      } else {
        return message.error(
          "Issuer company not found, please try after some time!"
        );
      }
      
      registerInvestorAsNormal(req);
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while registering!");
    }

    setSubmitting(false);
  };

  async function registerInvestorAsNormal(req) {
    req.invitedById = userId
    const response = await accountService.registerInvestor(req);
    if (response.success) {
      message.success("Successfully registered.");
      setRegistered(true);
      form.resetFields();
    } else {
      message.error(response.error.message);
    }
  }

  async function registerInvestorAsPublic(req) {
    console.log("will regsiter as public here");
    setRegistrationMode("PUBLIC");
    req.invitedById = userId
    const response = await accountService.registerInvestorAsPublic(req);
    if (response.success) {
      message.success("Successfully registered.");
      setRegistered(true);
      form.resetFields();
    } else {
      message.error(response.error.message);
    }
  }

  // useEffect(() => {
  //   form.setFieldsValue({ walletAddress: selectedWallet });
  // }, [selectedWallet, form]);
  // form.setFieldsValue({ walletAddress: selectedWallet });
  if (email) {
    form.setFieldsValue({ email: email });
  }
  const renderLabelWithAsterisk = (label) => (
    <span>
      {label} <span style={{ color: "red" }}>*</span>
    </span>
  );
  return (
    <>
      <div className="investor-registration-form  container-fluid">
        <div className="row ">
          <div className="col col-12" style={{ backgroundColor: '#102253' }}>
            <div className="logo-imagee">
            <img src={VruddhiXImage}alt="Image"></img>
            </div>
          
            <div className="Invetor-Registraction-Image">
            <div className="Image-title">
              <p className="image-title-text">
              Discover Exciting Real Estate <br/> Investment Opportunities
              </p>    
            </div>
            <img className="building-image" src={RegistrationInvestor} alt="investor"></img>
            </div>
          </div>
          <div className="left-side-wrapper col col-4 py-4" style={{ backgroundColor:'white' }}>
           <div className="side-bar-title py-3" >
            <p style={{fontSize:'26px',color:'black',fontWeight:500}}> Experience Seamless Commercial and Residential Real Estate Investment through VruddhiX.</p>
           
           <div className="Check-boxes">
           <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
          <label class="form-check-label" for="flexCheckDefault">
            <p style={{color:'black',fontSize:'20px',fontWeight:400}}>Unlock Opportunities.</p>
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
          <label class="form-check-label" for="flexCheckChecked">
            <p style={{color:'black',fontSize:'20px',fontWeight:400}}> Eliminate Hassles</p>
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
          <label class="form-check-label" for="flexCheckChecked">
            <p style={{color:'black',fontSize:'20px',fontWeight:400}}>Invest with Confidence</p>
          </label>
        </div>
        </div>
          </div>
          </div>
          <div className="col col-8 ">
            <div className="row justify-content-center align-items-center" >
            <Card>
              <div hidden={registered}>
                {registered && (
                  <Alert
                    message="We have received your registration request. Please self verify your email address to proceed."
                    type="success"
                  />
                )}
                <div className="InvestorRegistration-container">
                  <div className="InvestorRegistration-form-container">
                    <h3>Investor Registration</h3>
                    <Link to="/account/login">
                      <h4 style={{color:'black'}}>Already An Investor? <span style={{color:'#F3BC03'}}>Login Now</span></h4>
                    </Link>
  
                    <Form
                      {...formItemLayout}
                      form={form}
                      onFinish={register}
                      className="InvestorRegistration-form"
                      initialValues={{ country: "India" }}
                    >
                      <Form.Item
                        label={renderLabelWithAsterisk("First name")}
                        name="firstName"
                        rules={[{ required: true, message: "Please input your First Name!", whitespace: true }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input className="input-field" />
                      </Form.Item>
  
                      <Form.Item
                        label={renderLabelWithAsterisk("Last name")}
                        name="lastName"
                        rules={[{ required: true, message: "Please input your Last Name!", whitespace: true }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input />
                      </Form.Item>
  
                      <Form.Item
                        label={renderLabelWithAsterisk("E-mail")}
                        name="email"
                        rules={[
                          { type: "email", message: "The input is not valid E-mail!" },
                          { required: true, message: "Please input your E-mail!" },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={!!email} />
                      </Form.Item>
  
                      <Form.Item
                        label={renderLabelWithAsterisk("Password")}
                        name="password"
                        hasFeedback
                        rules={[
                          { required: true, message: "Please input your password!" },
                          {
                            validator: (rule, value) => {
                              const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                              if (strongRegex.test(value)) return Promise.resolve();
                              return Promise.reject("The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long");
                            },
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input/>
                      </Form.Item>
  
                      <Form.Item
                        label={renderLabelWithAsterisk("Confirm Password")}
                        name="confirm"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          { required: true, message: "Please confirm your password!" },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue("password") === value) return Promise.resolve();
                              return Promise.reject("The two passwords that you entered do not match!");
                            },
                          }),
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input/>
                      </Form.Item>
  
                      <Form.Item
                      label={renderLabelWithAsterisk("Date of Birth")}
                      className="label-style"
                      name="dateOfBirth"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your date of birth",
                        },
                      ]}
                    >
                      {/* <br /> */}
                      {/* <div style={{ border: "2px solid #8486a6", borderRadius: "10px"}}>  */}
                      <DatePicker
                        placeholder="(YYYY-MM-DD)"
                        style={{width:'100%' }}
                      />
                      {/* </div> */}
                    </Form.Item>
  
                      <Form.Item
                        label={renderLabelWithAsterisk("Country")}
                        name="country"
                        className="label-style"
                        validateStatus={countries ? "" : "validating"}
                        help={countries ? "" : "Loading Countries..."}
                        style={{ marginLeft: "" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                      {/* <div style={{border: '2px solid #8486a6', width:'100%',boxSizing:'border-box' }}> */}
                        <Select showSearch placeholder="Select Country" onChange={currentCountry} >
                          {countries?.map((country) => (
                            <Select.Option key={country._id} value={country.name["en"]}>
                              {country.name["en"]}
                            </Select.Option>
                          ))}
                        </Select>
                        {/* </div> */}
                        
                      </Form.Item>
                      {countryError && (
                      <Alert
                        message="The country you select and the country you are operating are different you can only register from the country you select"
                        type="error"
                      ></Alert>
                    )} 

                      <Form.Item
                        label={renderLabelWithAsterisk("Contact Number")}
                        name="contactNumber"
                        className="label-style"
                        width="100"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        
                        rules={[
                          {
                            required: true,
                            message: "Enter Contact Number ",
                            whitespace: true,
                            
                          },
                        ]}
                      // width
                      >
                        {/* <br /> */}
                        <PhoneInput
                          country={countryCode}
                          style={{
                            
                           
                          }}
                        />
                      </Form.Item>
                      <div className="">{cCountry && (
                      <Form.Item
                        label={renderLabelWithAsterisk("State")}
                        name="state"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="label-style"
                        rules={[
                          {
                            required: true,
                            message: "Please input state!",
                            whitespace: true,
                          },
                        ]}
                      >
                        {/* <br /> */}
                        {/* <div style={{ border: "2px solid #8486a6", borderRadius: "10px" ,height:'40px'}}> */}
                        <Select
                          style={{   width: "100%"}}
                          showSearch
                          placeholder="Select state"
                          autoComplete="new-password"
                        >
                          {usaStates?.map((state) => (
                            <Option
                              key={state?.state_id}
                              value={state.state_name}
                            >
                              {state.state_name}
                            </Option>
                          ))}
                        </Select>
                        {/* </div> */}
                      </Form.Item>
                    )}
                      </div>
                       <div className="">{!cCountry && (
                      <Form.Item
                        label={renderLabelWithAsterisk("State")}
                        name="state"
                        className="label-style"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Please input state!",
                            whitespace: true,
                          },
                        ]}
                      >
                        
                        <Input
                          style={{
                            // marginLeft: "-110px",
                            
                          }}
                        />
                      </Form.Item>
                      )}
                      </div>

                      <Form.Item
                        label={renderLabelWithAsterisk("City")}
                        className="label-style"
                        name="city"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "City is required" }]}
                      >
                        {/* <br /> */}
                        <Input
                          placeholder=""
                          style={{
                            // marginLeft: "-110px",
                           
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label={renderLabelWithAsterisk("Street Address 1")}
                        name="streetAddress1"
                        className="label-style"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Street address 1 is required",
                          },
                        ]}
                      >
                        {/* {" "} */}
                        {/* <br /> */}
                        <Input
                          style={{
                            // marginLeft: "-110px",
                           
                          }}
                          placeholder=""
                        />
                      </Form.Item>

                      <Form.Item
                      label={renderLabelWithAsterisk("Street Address 2")}
                      name="streetAddress2"
                      className="label-style"
                      labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Street address 2 is required",
                        },
                      ]}
                    >
                      {/* <br /> */}
                      <Input
                        style={{
                          // marginLeft: "-110px",
                        
                        }}
                        placeholder=""
                      />
                    </Form.Item>
                    <Form.Item
                      label={renderLabelWithAsterisk("PINCode")}
                      name="zipCode"
                      className="label-style"
                      labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      // style={{marginLeft:"-30px"}}
                      rules={[
                        {
                          required: true,
                          message: "Please input PINCode!",
                        },
                      ]}
                    >
                      {/* <br /> */}
                      <Input
                        min={0}
                        style={{
                          width: "100%",
                          // marginLeft: "-110px",
                          
                          
                        }}
                      />
                    </Form.Item>
  
                   
  
                      <Checkbox
                        checked={termsCheck}
                        onChange={() => setTermsCheck(!termsCheck)}
                        style={{ width: "100%", marginTop: "10px",color:'#000000' }}
                      >
                        I hereby accept all <Link to="/terms-of-use"><span style={{color:'#838D9B'}}>terms and conditions</span></Link> of the platform
                      </Checkbox>
                      <div className="  d-flex justify-content-start mt-3">
                      <Form.Item {...tailFormItemLayout}>
                        
                        <Button
                          type="warning"
                          htmlType="submit"
                          loading={submitting}
                          disabled={!termsCheck}
                          style={{border:'none',color:'white',backgroundColor:'#F3BC03', fontWeight:600                         }}
                          className="investor-register-btn"
                        >
                          Register Now
                        </Button>
                       
                      </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div hidden={!registered}>
              <Result
                status="success"
                title={
                  registrationMode === "PUBLIC"
                    ? "Registered Successfully"
                    : "We received your registration request."
                }
                subTitle={
                  registrationMode === "PUBLIC"
                    ? "Please verify you email before clicking below button to login!"
                    : "Wait for the approval by the system to get successfully registered to Retap India."
                }
                extra={[
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={forwardToLogin}
                  >
                    Login
                  </Button>,
                ]}
              ></Result>
                
              </div>
            </Card>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
